/*
    node_js
    3/14/2021 7:22 PM
    by Oleksandr
*/

import React from 'react'
import { useCwpContext } from '../../../contexts/CwpContext'

import { handleMenuButton } from '../../../utils/helpers'

const MainMenu = ({ isPlt, isEpp }) => {
  const { pttWindows, setPttWindows, setTrackPlanSet } = useCwpContext()

  const handleClick = (e) => {
    const window = e.target.dataset.menu
    handleMenuButton(window, setPttWindows)
  }

  return (
    <div
      className="windowSdd"
      style={{
        zIndex: 1000000,
        bottom: '0px',
        position: 'fixed',
        height: '28px',
        width: '100%',
      }}
    >
      <ul>
        {(isPlt || isEpp) && (
          <li>
            <span
              className={`buttonSDD ${
                pttWindows.rwyWindow ? 'buttonSDDpressed' : ''
              }`}
              data-menu="rwyWindow"
              onClick={handleClick}
            >
              RWY
            </span>
          </li>
        )}
        <li>
          <span
            className={`buttonSDD ${
              pttWindows.mtcdWindow ? 'buttonSDDpressed' : ''
            }`}
            data-menu="mtcdWindow"
            onClick={handleClick}
          >
            MTCD
          </span>
        </li>
        <li>
          <span
            className={`buttonSDD`}
            onClick={() => setTrackPlanSet(new Set())}
          >
            RTE OFF
          </span>
        </li>
      </ul>
    </div>
  )
}

export default MainMenu
