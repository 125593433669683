/*
    node_js
    3/23/2021 12:40 AM
    by Oleksandr
*/

import Window from '../Window'
import React from 'react'
import { useCwpContext } from '../../../../../contexts/CwpContext'

const SpeedVectorWindow = () => {
  const { setPttWindows, speedVector, setSpeedVector } = useCwpContext()
  const sVButtonValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20]

  return (
    <Window
      label="Speed Vector #1"
      width={'auto'}
      dXY={{ dx: 360, dy: 80 }}
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, speedVectorWindow: false }))
      }
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            'auto auto auto auto auto auto auto auto auto auto auto auto auto',
          gridGap: '4px',
          color: 'grey',
          textAlign: 'center',
          padding: '3px',
        }}
      >
        <span
          className={`buttonSDD ${
            speedVector.isVisible ? 'buttonSDDpressed' : ''
          }`}
          style={{ minWidth: '18px', width: 'max-content' }}
          onClick={() => {
            setSpeedVector({
              ...speedVector,
              isVisible: !speedVector.isVisible,
            })
          }}
        >
          {speedVector.isVisible ? 'ON' : 'OFF'}
        </span>
        {sVButtonValue.map((value) => (
          <span
            key={'sVButton' + value}
            className={`buttonSDD ${
              speedVector.time === value ? 'buttonSDDpressed' : ''
            }`}
            style={{ minWidth: '20px', padding: '0px', width: 'auto' }}
            onClick={(e) =>
              setSpeedVector({
                ...speedVector,
                time: parseInt(e.target.outerText),
                isVisible: true,
              })
            }
          >
            {value}
          </span>
        ))}
      </div>
    </Window>
  )
}

export default SpeedVectorWindow
