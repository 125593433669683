/*
    node_js
    2/2/2021 12:23 AM
    by Oleksandr
*/
import React from 'react'
import SsrTarget from './SsrTarget'

const Target = (props) => {
  const { setSelectedFlight, selectedFlight, id, addTrackPlan } = props
  let clickHoldTimer = null
  const handleMouseDown = (e) => {
    if (e.buttons === 1)
      clickHoldTimer = setTimeout(() => {
        setSelectedFlight(selectedFlight === id ? null : id)
      }, 1000)

    if (e.buttons === 2) {
      addTrackPlan(id)
    }
  }
  const handleMouseUp = (e) => {
    clearTimeout(clickHoldTimer)
  }
  const target = (
    <SsrTarget
      {...props}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    />
  )
  return target
}
export default Target
