/*
    node_js
    2/22/2021 7:23 PM
    by Oleksandr
*/
class Auth {
  constructor() {
    this.authenticated = false
    this.showLogIn = async (login, password, cb) => {
      const loginData = {
        username: login,
        password: password,
      }
      fetch('https://lvivacc.site/wp-json/jwt-auth/v1/token', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(loginData), // body data type must match "Content-Type" header
      })
        .then(function (response) {
          return response.json()
        })
        .then((res) => {
          localStorage.setItem('token', res.token)
          localStorage.setItem('user_nicename', res.user_nicename)
          localStorage.setItem('user_email', res.user_email)
          localStorage.setItem('user_display_name', res.user_display_name)
          localStorage.setItem(
            'atm_code',
            res.atm_code ? res.atm_code : undefined,
          )
        })
        .then(() => {
          if (
            localStorage.getItem('token') !== 'undefined' &&
            localStorage.getItem('token') !== null
          ) {
            this.authenticated = true
            cb({ success: true })
          } else {
            this.authenticated = false
            console.log('ERROR')
            cb({ success: false })
          }
        })
    }
  }

  login(login, password, cb) {
    this.showLogIn(login, password, cb)
  }

  validateToken = (cb) => {
    let token = localStorage.getItem('token')
    if (token !== 'undefined' && token !== null) {
      fetch('https://lvivacc.site/wp-json/jwt-auth/v1/token/validate', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token,
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      })
        .then((resp) => {
          return resp.json()
        })
        .then((res) => {
          if (res.code === 'jwt_auth_valid_token') {
            this.authenticated = true
            cb({ success: true })
          } else {
            this.authenticated = false
            cb({ success: false })
          }
        })
    } else {
      this.authenticated = false
      cb({ success: false })
    }
  }

  logout(cb) {
    this.authenticated = false
    cb()
  }

  isAuthenticated() {
    return this.authenticated
  }
}

export default new Auth()
