/*
    node_js
    5/22/2021 3:45 AM
    by Oleksandr
*/

import { useEffect, useState } from 'react'
import { useCwpContext } from '../../contexts/CwpContext'

const FDP = () => {
  const {
    tickValue,
    flights,
    setFlights,
    conflictNumberList,
    sectors,
    workloadArray,
    setWorkloadArray,
  } = useCwpContext()

  const [workloadArr, setWorkloadArr] = useState([])
  useEffect(() => {
    if (!flights || !flights[0] || tickValue % 5 !== 0) {
      return
    }
  }, [
    tickValue,
    flights,
    setFlights,
    conflictNumberList,
    workloadArr,
    workloadArray,
    setWorkloadArray,
    sectors,
  ])

  return <></>
}
export default FDP
