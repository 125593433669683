export const exercises = async (searchParams) => {
  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: searchParams,
  }

  const response = await fetch(
    'https://lvivacc.site/ptt/exercise_2.php',
    requestOptions,
  )
  return await response.json()
}
