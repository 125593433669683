const EditRoutePath = ({ editRoutePathArray }) => {
  const getEditRoutePathPoints = () => {
    return editRoutePathArray.reduce((prev, path) => {
      const [x, y] = path
      return prev + `${x}, ${y} `
    }, '')
  }

  return (
    <g>
      {
        <defs>
          <marker
            id="red-circle"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            orient="auto"
          >
            <circle fill="yellow" cx="5" cy="5" r="5" />
          </marker>
        </defs>
      }
      {
        <polyline
          key={'polyline_editRoute'}
          markerMid="url(#red-circle)"
          points={getEditRoutePathPoints()}
          fill={'none'}
          stroke={'yellow'}
          pointerEvents="none"
        />
      }
    </g>
  )
}

export default EditRoutePath
