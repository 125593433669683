/*
    node_js
    4/5/2021 12:09 AM
    by Oleksandr
*/

import React, { useEffect, useState } from 'react'

const VideoWithVolume = (props) => {
  const { video, videoStream } = props
  const [average, setAverage] = useState(0)

  useEffect(() => {
    if (!videoStream) return
    const audioContext = new AudioContext({ latencyHint: 'playback' })
    const analyser = audioContext.createAnalyser()
    const microphone = audioContext.createMediaStreamSource(videoStream)
    analyser.smoothingTimeConstant = 0.3
    analyser.fftSize = 1024
    microphone.connect(analyser)
    const visualizeData = () => {
      const animationController = window.requestAnimationFrame(visualizeData)
      if (videoStream.paused) {
        return cancelAnimationFrame(animationController)
      }
      const array = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(array)
      let values = 0

      const length = array.length
      for (let i = 0; i < length; i++) {
        values += array[i]
      }

      const average = values / length
      setAverage(parseInt(average))
    }
    visualizeData()
  }, [videoStream])
  return (
    <div
      style={{ position: 'relative', zIndex: '14', border: '1px solid grey' }}
    >
      {video}
      <svg
        style={{
          width: '100%',
          height: '7px',
          overflow: 'hidden',
          position: 'absolute',
          left: '0px',
          top: '0px',
        }}
      >
        <rect fill="green" height="7" width={parseInt(average)} />
      </svg>
    </div>
  )
}

export default VideoWithVolume
