/*
    node_js
    3/14/2021 7:22 PM
    by Oleksandr
*/
import React from 'react'
import { useCwpContext } from '../../../contexts/CwpContext'
import { Link } from 'react-router-dom'

import { download } from '../../../calc/calc'
import { getTASfromCAS } from '../../../calc/geometry/airspeedConverter'

import { handleMenuButton } from '../../../utils/helpers'

import '../CWP.css'

let squawks = []
let assignedSquawks = -1
for (let i = 1; i < 2; i++) {
  for (let j = 1; j < 2; j++) {
    for (let x = 1; x < 8; x++) {
      for (let z = 1; z < 8; z++) {
        squawks.push(i + '' + j + '' + x + '' + z)
      }
    }
  }
}
const getSquawk2 = () => {
  assignedSquawks++
  if (assignedSquawks > squawks.length - 1) assignedSquawks = 0
  return squawks[assignedSquawks]
}

const InfoPanel = ({ isPlt, isEpp, isCon, isDemo }) => {
  const {
    pttWindows,
    setPttWindows,
    flights,
    exerciseName,
    startDate,
    timeValue,
    setSessionId,
    setIsConnected,
    isConnected,
    sectors,
  } = useCwpContext()

  const handleClick = (e) => {
    const window = e.target.dataset.menu
    handleMenuButton(window, setPttWindows)
  }

  return (
    <div
      className="windowSdd"
      style={{ zIndex: 1000000, top: '0px', position: 'fixed', height: '28px' }}
    >
      <ul>
        <li>
          <Link to="/" className={'buttonSDD'}>
            Home
          </Link>
        </li>
        <li>
          <span
            className={`buttonSDD ${
              pttWindows.aswToolbox1Window ? 'buttonSDDpressed' : ''
            }`}
            data-menu="aswToolbox1Window"
            onClick={handleClick}
          >
            ASW Toolbox
          </span>
        </li>
        {!isCon && !isDemo && (
          <li>
            <span
              className={`buttonSDD ${
                pttWindows.exerciseDescriptionWindow ? 'buttonSDDpressed' : ''
              }`}
              style={{ width: 'auto' }}
              data-menu="exerciseDescriptionWindow"
              onClick={handleClick}
            >
              {exerciseName}
            </span>
          </li>
        )}
        {(isPlt || isEpp) && (
          <>
            <li>
              <span
                className={`buttonSDD ${
                  pttWindows.exerciseWindow ? 'buttonSDDpressed' : ''
                }`}
                data-menu="exerciseWindow"
                onClick={handleClick}
              >
                Exercise
              </span>
            </li>
          </>
        )}
        {isEpp && (
          <>
            <li>
              <span
                className={`buttonSDD ${
                  pttWindows.activeWindow ? 'buttonSDDpressed' : ''
                }`}
                data-menu="activeWindow"
                onClick={handleClick}
              >
                Active
              </span>
            </li>
            <li>
              <span
                className={`buttonSDD ${
                  pttWindows.historicalWindow ? 'buttonSDDpressed' : ''
                }`}
                data-menu="historicalWindow"
                onClick={handleClick}
              >
                Historical
              </span>
            </li>
          </>
        )}
        {(isPlt || isCon) && (
          <li>
            <span
              className={`buttonSDD ${
                pttWindows.plannerWindow ? 'buttonSDDpressed' : ''
              }`}
              data-menu="plannerWindow"
              onClick={handleClick}
            >
              Planner
            </span>
          </li>
        )}
        {(isPlt || isEpp) && (
          <>
            <li>
              <span
                className={`buttonSDD ${
                  pttWindows.workloadWindow ? 'buttonSDDpressed' : ''
                }`}
                data-menu="workloadWindow"
                onClick={handleClick}
              >
                Workload
              </span>
            </li>
          </>
        )}
        {isEpp && (
          <li>
            <span
              className={'buttonSDD'}
              onClick={() => {
                let text = ''
                flights.forEach((flight) => {
                  let route = ''

                  if (flight.passedPoints) {
                    let entryPointIndex = -1
                    let entryPointTime = ''
                    if (flight.entryCop) {
                      entryPointIndex = flight.passedPoints
                        .map((e) => e.pp.pP)
                        .indexOf(flight.entryCop)
                      entryPointTime = new Date(
                        startDate.getTime() +
                          (flight.passedPoints[entryPointIndex].index -
                            flight.timeShift) *
                            4000,
                      ).toLocaleTimeString('es-ES')
                      entryPointTime =
                        entryPointTime.substr(0, 2) +
                        entryPointTime.substr(3, 2)
                    }
                    flight.passedPoints.forEach((point, index) => {
                      if (point.pp.pP.length !== 4 && point.pp.pP !== false) {
                        let time =
                          index === entryPointIndex ? '/H' + entryPointTime : ''
                        route += point.pp.pP + time + ' DCT '
                      } else if (point.pp.pP === false) {
                        return
                      } else {
                        route = route.substr(0, route.length - 5)
                      }
                    })
                  }

                  let eobt = new Date(
                    startDate.getTime() + (0 - flight.timeShift) * 4000,
                  ).toLocaleTimeString('es-ES')
                  eobt = eobt.substr(0, 2) + eobt.substr(3, 2)

                  text +=
                    flight.fpl[0] +
                    '-' +
                    flight.fpl[1] +
                    '/A' +
                    getSquawk2() +
                    '-' +
                    flight.fpl[2] +
                    '\n'
                  text +=
                    '-' +
                    flight.fpl[3] +
                    '-' +
                    flight.fpl[4].replace('Z', '') +
                    '\n'
                  text += '-' + flight.fpl[5].substr(0, 4) + eobt + '\n'
                  text +=
                    (
                      '-N0' +
                      parseInt(getTASfromCAS(flight.cas, flight.rfl * 100)) +
                      'F' +
                      flight.rfl +
                      ' ' +
                      route
                    )
                      .match(/(.{1,68}\s|[&|$])\s*/g)
                      .join('\n') + '\n'
                  text += '-' + flight.fpl[7] + '\n'
                  text +=
                    '-' +
                    flight.fpl[8].match(/(.{1,68}\s|[)])\s*/g).join('\n') +
                    '\n\n'
                })
                download(exerciseName + '.txt', text)
              }}
            >
              Download
            </span>
          </li>
        )}
        {(isPlt || isCon) && (
          <li>
            <span
              className={'buttonSDD'}
              onClick={() => {
                if (!isConnected) {
                  let sessionID = prompt('Session ID:')
                  if (sessionID === null) return
                  setSessionId(sessionID)
                  setIsConnected(true)
                } else if (isConnected) {
                  setIsConnected(false)
                }
              }}
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </span>
          </li>
        )}

        {(isPlt || isEpp) && (
          <li>
            <span
              className={'buttonSDD'}
              style={{ color: 'green' }}
              data-menu="sectorWindow"
              onClick={handleClick}
            >
              {sectors.ops.sector}-{sectors.ops.freq}
            </span>
          </li>
        )}
        <li>
          <span
            className={`buttonSDD ${
              pttWindows?.fpActionWindow ? 'buttonSDDpressed' : ''
            }`}
            data-menu="fpActionWindow"
            onClick={() =>
              setPttWindows((prev) => ({
                ...prev,
                fpActionWindow: !prev.fpActionWindow,
                options: {
                  ...prev.options,
                  fpActionWindow: { flightId: null },
                },
              }))
            }
          >
            {'FP Action'}
          </span>
        </li>
        <li>
          <span
            className={`buttonSDD ${
              pttWindows?.communicationWindow ? 'buttonSDDpressed' : ''
            }`}
            data-menu="communicationWindow"
            onClick={() =>
              setPttWindows((prev) => ({
                ...prev,
                communicationWindow: !prev.communicationWindow,
              }))
            }
          >
            {'Comm'}
          </span>
        </li>
        <li>
          <span
            className={`buttonSDD ${
              pttWindows.systemTimeWindow ? 'buttonSDDpressed' : ''
            }`}
            data-menu="systemTimeWindow"
            onClick={handleClick}
          >
            {new Date(
              startDate.getTime() + timeValue * 1000,
            ).toLocaleTimeString('es-ES')}
          </span>
        </li>
        <li>
          <span
            className={`buttonSDD ${
              pttWindows.mainMenu ? 'buttonSDDpressed' : ''
            }`}
            data-menu="mainMenu"
            onClick={handleClick}
          >
            Menu
          </span>
        </li>
      </ul>
    </div>
  )
}

export default InfoPanel
