/*
    node_js
    3/15/2021 8:59 AM
    by Oleksandr
*/

import HistFlightsList from './lists/HistFlightsList'
import Window from './Window'
import React, { useState } from 'react'
import { useCwpContext } from '../../../../contexts/CwpContext'
import {
  historicalColumns,
  historicalColumnsText,
} from '../../../../utils/constants'

const HistoricalWindow = () => {
  const {
    historicalFlights,
    setFlights,
    flights,
    setPttWindows,
    startDate,
    runways,
    updateFlightForEpp,
  } = useCwpContext()

  const [q, setQ] = useState({
    c: '',
    type: '',
    adep: '',
    ades: '',
    entryCop: '',
    exitCop: '',
    rfl: '',
  })

  const [histSort, setHistSort] = useState('rfl')
  const [searchFields, setSearchFields] = useState({})

  const search = (rows) =>
    rows.filter((row) =>
      historicalColumns.every((column) => {
        if (!q[column]) {
          return 1
        }
        return (
          row[column] &&
          q[column] &&
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(q[column].toLowerCase()) > -1
        )
      }),
    )
  const filter = (rows) =>
    flights.length > 0
      ? rows.filter((row) => flights.every((flt) => flt.id !== row.id))
      : rows

  const sort = (arr) =>
    arr.sort((a, b) =>
      a[histSort] > b[histSort] ? 1 : b[histSort] > a[histSort] ? -1 : 0,
    )

  const handleInputChange = (e) => {
    setSearchFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.toUpperCase().replaceAll(' ', ''),
    }))
  }

  const handleFilter = () => {
    const filterObj = {}
    historicalColumns.map((column2) => {
      filterObj[column2] = searchFields[`filterHistFlights_${column2}`]
    })
    setQ(filterObj)
  }

  return (
    <Window
      isVisible={true}
      toggleWindow={() =>
        setPttWindows((prev) => ({ ...prev, historicalWindow: false }))
      }
      label={'Historical Flights'}
      width="fit-content"
      maxContentHeight={550}
    >
      <table>
        <thead>
          <tr style={{ height: '27px' }}>
            {historicalColumns.map((column, index) => (
              <td className="headerTh" key={`td-${index}`}>
                <input
                  className="filterHistFlights"
                  type="text"
                  id={'filterHistFlights_' + column}
                  name={'filterHistFlights_' + column}
                  value={searchFields[`filterHistFlights_${column}`] || ''}
                  onChange={handleInputChange}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleFilter()
                    }
                  }}
                />
              </td>
            ))}
            <td>
              <span className="buttonSDD" onClick={handleFilter}>
                Update
              </span>
            </td>
          </tr>
          <tr>
            {historicalColumns.map((heading, index) => (
              <th
                className="headerTh"
                onClick={() => setHistSort(heading)}
                key={`h-${index}`}
              >
                {historicalColumnsText[index]}
              </th>
            ))}
            <th className="headerTh" />
          </tr>
        </thead>

        <HistFlightsList
          historicalFlights={sort(
            filter(search(historicalFlights)).slice(0, 400),
          )}
          columns={historicalColumns}
          setFlights={setFlights}
          flights={flights}
          startDate={startDate}
          runways={runways}
          updateFlightForEpp={updateFlightForEpp}
        />
      </table>
    </Window>
  )
}

export default HistoricalWindow
