import React from 'react'
import './Input.scss'

//styles
const Input = ({ name, label, type = 'text', required, value, onChange }) => {
  return (
    <div className="input-component-wrapper">
      <label className="input-wrapper" htmlFor={name}>
        <input
          type={type}
          name={name}
          id={name}
          onChange={onChange}
          value={value || ''}
          required={required}
          placeholder=" "
        />
        <span className="label">{label}</span>
      </label>
    </div>
  )
}

export default Input
