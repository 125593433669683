/*
    node_js
    3/5/2021 6:55 AM
    by Oleksandr
*/

import { useEffect } from 'react'
import { calcFlight } from '../../calc/calc'
import { useCwpContext } from '../../contexts/CwpContext'

const DemoManager = () => {
  const {
    flights,
    tickValue,
    setTickValue,
    setTimeValue,
    setTickSpeed,
    setTickPause,
    setZoom,
    setIsDemoMsgWindowVisible,
  } = useCwpContext()

  const assumeFlight = (flight, index) => {
    flight.flight[index].isAssumed = true
    flight.flight[index].isAdvanced = false
    calcFlight(flight, index)
  }

  useEffect(() => {
    let ind = flights.map((e) => e.id).indexOf('AAB03856')
    if (ind === -1) return
    let flight = flights[ind]
    if (!flights) return
    if (tickValue === 3) {
      setZoom(10000)
      setTimeValue(11)

      assumeFlight(flight, 3)
    }
    if (tickValue === 5) {
      flight.flight[5].sA = 10000
      calcFlight(flight, 5)
      setIsDemoMsgWindowVisible(false)
    }
    if (tickValue === 8) {
      flight.flight[tickValue].nP = 13
      calcFlight(flight, tickValue)
    }
    if (tickValue === 10) {
      setTickSpeed(500)
    }
    if (tickValue === 20) {
      setTickValue(50)
      setTimeValue(203)
    }
    if (tickValue === 53) {
      setTickSpeed(100)
    }
    if (tickValue === 56) {
      flight.flight[tickValue].sA = 3000
      calcFlight(flight, tickValue)
    }
    if (tickValue === 70) {
      setTickValue(150)
      setTimeValue(603)
    }
    if (tickValue === 160) {
      flight.flight[tickValue].nP = 16
      calcFlight(flight, tickValue)
    }
    if (tickValue === 215) {
      setTickPause(true)
    }
  }, [tickValue])
  return <></>
}

export default DemoManager
