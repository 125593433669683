const airlines = [
  {
    code: 'ADY',
    callsign: 'NAWRAS',
  },
  {
    code: 'ADZ',
    callsign: 'MIGRATOR',
  },
  {
    code: 'AEA',
    callsign: 'EUROPA',
  },
  {
    code: 'AEE',
    callsign: 'AEGEAN',
  },
  {
    code: 'AEG',
    callsign: 'AIREST CARGO',
  },
  {
    code: 'AEH',
    callsign: 'VEGA',
  },
  {
    code: 'AEI',
    callsign: 'POLISH BIRD',
  },
  {
    code: 'AEJ',
    callsign: 'AEROJET',
  },
  {
    code: 'AEK',
    callsign: 'AEROCON',
  },
  {
    code: 'AEL',
    callsign: 'RHOMBUS',
  },
  {
    code: 'AEM',
    callsign: 'AEROMADRID',
  },
  {
    code: 'AEO',
    callsign: 'AERO',
  },
  {
    code: 'AEP',
    callsign: 'AEROTEC',
  },
  {
    code: 'AER',
    callsign: 'ACE AIR',
  },
  {
    code: 'AES',
    callsign: 'GEMINA',
  },
  {
    code: 'AET',
    callsign: 'AEROTHAI',
  },
  {
    code: 'AEV',
    callsign: 'AEROVENTAS',
  },
  {
    code: 'AEX',
    callsign: 'AEROX',
  },
  {
    code: 'AFA',
    callsign: 'BLUE ALFA',
  },
  {
    code: 'AFB',
    callsign: 'BULAF',
  },
  {
    code: 'AFC',
    callsign: 'AERORUTAS',
  },
  {
    code: 'AFE',
    callsign: 'AIRFAST',
  },
  {
    code: 'AFF',
    callsign: 'AIRFERRY',
  },
  {
    code: 'AFG',
    callsign: 'ARIANA',
  },
  {
    code: 'AFH',
    callsign: 'AIR AFGHANISTAN',
  },
  {
    code: 'AFI',
    callsign: 'AFRICAWORLD',
  },
  {
    code: 'AFJ',
    callsign: 'JAMBO',
  },
  {
    code: 'AFL',
    callsign: 'AEROFLOT',
  },
  {
    code: 'AFM',
    callsign: 'EPIC AIR',
  },
  {
    code: 'AFN',
    callsign: 'HEKLA',
  },
  {
    code: 'AFP',
    callsign: 'PORTUGUESE AIR FORCE',
  },
  {
    code: 'AFQ',
    callsign: 'ALBA',
  },
  {
    code: 'AFR',
    callsign: 'AIRFRANS',
  },
  {
    code: 'AFW',
    callsign: 'BLACKSTAR',
  },
  {
    code: 'AFY',
    callsign: 'AFRICA CHARTERED',
  },
  {
    code: 'AGE',
    callsign: 'AEROMANAGE',
  },
  {
    code: 'AGH',
    callsign: 'ALTAGNA',
  },
  {
    code: 'APJ',
    callsign: 'AIR PEACH',
  },
  {
    code: 'APK',
    callsign: 'PEACE BIRD',
  },
  {
    code: 'APL',
    callsign: 'AEREO PRINCIPAL',
  },
  {
    code: 'APM',
    callsign: 'RED FINN',
  },
  {
    code: 'APN',
    callsign: 'AIR ALPES',
  },
  {
    code: 'APR',
    callsign: 'PRIMORDIALES',
  },
  {
    code: 'APS',
    callsign: 'AEROPARADISE',
  },
  {
    code: 'APU',
    callsign: 'APUKA',
  },
  {
    code: 'APV',
    callsign: 'PHILIP AIR',
  },
  {
    code: 'APX',
    callsign: 'APEX',
  },
  {
    code: 'APZ',
    callsign: 'AIR PREMIA',
  },
  {
    code: 'AQA',
    callsign: 'ATCO',
  },
  {
    code: 'AQQ',
    callsign: 'APSARA',
  },
  {
    code: 'AQS',
    callsign: 'AIRSTREAM',
  },
  {
    code: 'AQU',
    callsign: 'QUARIUS',
  },
  {
    code: 'ARA',
    callsign: 'ARIK AIR',
  },
  {
    code: 'ARB',
    callsign: 'AIRBAHN',
  },
  {
    code: 'ARD',
    callsign: 'CORDILLERAS',
  },
  {
    code: 'ARE',
    callsign: 'LAN COLOMBIA',
  },
  {
    code: 'ARF',
    callsign: 'ANIMAL FLIGHTS',
  },
  {
    code: 'ARG',
    callsign: 'ARGENTINA',
  },
  {
    code: 'ARH',
    callsign: 'AEROHELCA',
  },
  {
    code: 'ARI',
    callsign: 'AEROVICS',
  },
  {
    code: 'ARK',
    callsign: 'LINK SERVICE',
  },
  {
    code: 'ARL',
    callsign: 'AIRLEC',
  },
  {
    code: 'ARM',
    callsign: 'SUNSKY',
  },
  {
    code: 'ARN',
    callsign: 'AERONEX',
  },
  {
    code: 'ARO',
    callsign: 'ACERO',
  },
  {
    code: 'ARP',
    callsign: 'IVORYCORP',
  },
  {
    code: 'ARQ',
    callsign: 'AIRCO',
  },
  {
    code: 'ARR',
    callsign: 'AIR ARMENIA',
  },
  {
    code: 'ARS',
    callsign: 'METSERVICE',
  },
  {
    code: 'ART',
    callsign: 'SMART LYNX',
  },
  {
    code: 'ARU',
    callsign: 'ARUBA',
  },
  {
    code: 'ARV',
    callsign: 'ARAVCO',
  },
  {
    code: 'ARW',
    callsign: 'JAMAICA SHUTTLE',
  },
  {
    code: 'ARX',
    callsign: 'AIREX',
  },
  {
    code: 'ARY',
    callsign: 'YERAZ',
  },
  {
    code: 'ASA',
    callsign: 'ALASKA',
  },
  {
    code: 'ASB',
    callsign: 'AIR SPRAY',
  },
  {
    code: 'ASD',
    callsign: 'AIR SINAI',
  },
  {
    code: 'ASE',
    callsign: 'ASERTEC',
  },
  {
    code: 'ASF',
    callsign: 'AUSTRIAN AIRFORCE',
  },
  {
    code: 'BBC',
    callsign: 'BANGLADESH',
  },
  {
    code: 'BBD',
    callsign: 'BLUE CARGO',
  },
  {
    code: 'BBE',
    callsign: 'BABEL AIR',
  },
  {
    code: 'BBG',
    callsign: 'CANDIA BIRD',
  },
  {
    code: 'BBI',
    callsign: 'SKY NET',
  },
  {
    code: 'BBJ',
    callsign: 'BLUE KOREA',
  },
  {
    code: 'BBK',
    callsign: 'BABAK',
  },
  {
    code: 'BBN',
    callsign: 'BRABAZON',
  },
  {
    code: 'BBO',
    callsign: 'BABCOCK',
  },
  {
    code: 'BBR',
    callsign: 'SANTA BARBARA',
  },
  {
    code: 'BBW',
    callsign: 'BEEBEE AIRWAYS',
  },
  {
    code: 'BBX',
    callsign: 'BLUEBEL',
  },
  {
    code: 'BBY',
    callsign: 'YEMEN BLUE',
  },
  {
    code: 'BBZ',
    callsign: 'COBRA',
  },
  {
    code: 'BCA',
    callsign: 'BLUE SKY MONGOLIA',
  },
  {
    code: 'BCB',
    callsign: 'WAVEBIRD',
  },
  {
    code: 'BCF',
    callsign: 'BACH',
  },
  {
    code: 'BCG',
    callsign: 'BELGIAN COASTGUARD',
  },
  {
    code: 'BCH',
    callsign: 'BEACHBALL',
  },
  {
    code: 'BCI',
    callsign: 'BLUE ISLAND',
  },
  {
    code: 'BCJ',
    callsign: 'BLUE BOY',
  },
  {
    code: 'BCK',
    callsign: 'BANKCHECK',
  },
  {
    code: 'BCN',
    callsign: 'BLUE OCEAN',
  },
  {
    code: 'BCR',
    callsign: 'BACKER',
  },
  {
    code: 'BCS',
    callsign: 'EUROTRANS',
  },
  {
    code: 'BCV',
    callsign: 'MORABEST',
  },
  {
    code: 'BCY',
    callsign: 'CITY-IRELAND',
  },
  {
    code: 'BDA',
    callsign: 'BLUE DART',
  },
  {
    code: 'BDF',
    callsign: 'BORDER FORCE',
  },
  {
    code: 'BDG',
    callsign: 'BULLDOG',
  },
  {
    code: 'BDI',
    callsign: 'BIRDIE',
  },
  {
    code: 'BDJ',
    callsign: 'CROWN JET',
  },
  {
    code: 'BDN',
    callsign: 'GAUNTLET',
  },
  {
    code: 'BDR',
    callsign: 'BADR AIR',
  },
  {
    code: 'BDS',
    callsign: 'SOUTH ASIAN',
  },
  {
    code: 'BDV',
    callsign: 'ABERDAV',
  },
  {
    code: 'BDX',
    callsign: 'SKY PUSHER',
  },
  {
    code: 'BEE',
    callsign: 'JERSEY',
  },
  {
    code: 'BEF',
    callsign: 'BALEAR EXPRESS',
  },
  {
    code: 'BEG',
    callsign: 'BULGARIAN EAGLE',
  },
  {
    code: 'BEK',
    callsign: 'BEKAIR',
  },
  {
    code: 'BNM',
    callsign: 'MELITAIR',
  },
  {
    code: 'BNO',
    callsign: 'NORSE',
  },
  {
    code: 'BNS',
    callsign: 'BANCSTAR',
  },
  {
    code: 'BNT',
    callsign: 'BENTIU AIR',
  },
  {
    code: 'BNV',
    callsign: 'BENANE',
  },
  {
    code: 'BNX',
    callsign: 'AIR BARINAS',
  },
  {
    code: 'BNY',
    callsign: 'BOUNTY',
  },
  {
    code: 'BOA',
    callsign: 'KUMANOVO',
  },
  {
    code: 'BOB',
    callsign: 'BACKBONE',
  },
  {
    code: 'BOC',
    callsign: 'AEROBONA',
  },
  {
    code: 'BOD',
    callsign: 'UGABOND',
  },
  {
    code: 'BOE',
    callsign: 'BOEING',
  },
  {
    code: 'BOF',
    callsign: 'BORNFLIGHT',
  },
  {
    code: 'BOG',
    callsign: 'BIRD DOG',
  },
  {
    code: 'BOH',
    callsign: 'BOHEMIA',
  },
  {
    code: 'BOL',
    callsign: 'BOL',
  },
  {
    code: 'BON',
    callsign: 'BH AIRLINES',
  },
  {
    code: 'BOO',
    callsign: 'BOOKAJET',
  },
  {
    code: 'BOR',
    callsign: 'BOLIDORSA',
  },
  {
    code: 'BOS',
    callsign: 'MISTRAL',
  },
  {
    code: 'BOT',
    callsign: 'BOTSWANA',
  },
  {
    code: 'BOV',
    callsign: 'BOLIVIANA',
  },
  {
    code: 'BOX',
    callsign: 'GERMAN CARGO',
  },
  {
    code: 'BPA',
    callsign: 'BLUE PANORAMA',
  },
  {
    code: 'BPH',
    callsign: 'BLACK PHOENIX',
  },
  {
    code: 'BPO',
    callsign: 'PIROL',
  },
  {
    code: 'BPS',
    callsign: 'BASE',
  },
  {
    code: 'BPT',
    callsign: 'BONUS',
  },
  {
    code: 'BRA',
    callsign: 'BELRESCUE',
  },
  {
    code: 'BRB',
    callsign: 'BRA-TRANSPAEREOS',
  },
  {
    code: 'BRC',
    callsign: 'BRAVI',
  },
  {
    code: 'BRE',
    callsign: 'AVIABREEZE',
  },
  {
    code: 'BRF',
    callsign: 'AIR BRAVO',
  },
  {
    code: 'BRG',
    callsign: 'BERING AIR',
  },
  {
    code: 'BRH',
    callsign: 'BRIGHTSTAR',
  },
  {
    code: 'BRI',
    callsign: 'BARRICK',
  },
  {
    code: 'BRJ',
    callsign: 'BAIRLINE',
  },
  {
    code: 'BRK',
    callsign: 'BARTOK',
  },
  {
    code: 'BRL',
    callsign: 'BRASS LINE',
  },
  {
    code: 'BRN',
    callsign: 'BRANSON',
  },
  {
    code: 'BRO',
    callsign: 'BROADSWORD',
  },
  {
    code: 'BRP',
    callsign: 'AEROBRA',
  },
  {
    code: 'BRQ',
    callsign: 'BURAQAIR',
  },
  {
    code: 'CCT',
    callsign: 'CONNECT',
  },
  {
    code: 'CCW',
    callsign: 'COPTRADE AIR',
  },
  {
    code: 'CCY',
    callsign: 'CHERRY',
  },
  {
    code: 'CDA',
    callsign: 'AEROCARDAL',
  },
  {
    code: 'CDC',
    callsign: 'LOONG AIR',
  },
  {
    code: 'CDE',
    callsign: 'COMEX',
  },
  {
    code: 'CDF',
    callsign: 'SERVICIO AEREO',
  },
  {
    code: 'CDG',
    callsign: 'SHANDONG',
  },
  {
    code: 'CDL',
    callsign: 'CAROLINA',
  },
  {
    code: 'CDN',
    callsign: 'CANADIAN',
  },
  {
    code: 'CDO',
    callsign: 'TCHADIA',
  },
  {
    code: 'CDQ',
    callsign: 'CHODANG FLIGHT',
  },
  {
    code: 'CDR',
    callsign: 'CANADAIR',
  },
  {
    code: 'CDS',
    callsign: 'SPECDAS',
  },
  {
    code: 'CDT',
    callsign: 'AEROREUS',
  },
  {
    code: 'CDV',
    callsign: 'SKOL',
  },
  {
    code: 'CDX',
    callsign: 'CEDAREX',
  },
  {
    code: 'CDY',
    callsign: 'CADDY',
  },
  {
    code: 'CEA',
    callsign: 'CORP-X',
  },
  {
    code: 'CEB',
    callsign: 'CEBU AIR',
  },
  {
    code: 'CEE',
    callsign: 'CENTRA AEREOS',
  },
  {
    code: 'CEF',
    callsign: 'CZECH AIR FORCE',
  },
  {
    code: 'CEG',
    callsign: 'CEGA',
  },
  {
    code: 'CEL',
    callsign: 'CEIBA LINE',
  },
  {
    code: 'CEN',
    callsign: 'SHUMBA',
  },
  {
    code: 'CEP',
    callsign: 'CHIPOLA',
  },
  {
    code: 'CER',
    callsign: 'COSMER',
  },
  {
    code: 'CES',
    callsign: 'CHINA EASTERN',
  },
  {
    code: 'CET',
    callsign: 'CENTRAFRICAIN',
  },
  {
    code: 'CEU',
    callsign: 'ASAS AIR',
  },
  {
    code: 'CEV',
    callsign: 'CENTEV',
  },
  {
    code: 'CEW',
    callsign: 'TRYDONAIR',
  },
  {
    code: 'CEY',
    callsign: 'CENTURYFLIGHT',
  },
  {
    code: 'CEZ',
    callsign: 'CESSZANI',
  },
  {
    code: 'CFA',
    callsign: 'FEILONG',
  },
  {
    code: 'CFB',
    callsign: 'FOREBASE',
  },
  {
    code: 'CFC',
    callsign: 'CANFORCE',
  },
  {
    code: 'CFD',
    callsign: 'AERONAUT',
  },
  {
    code: 'CFE',
    callsign: 'FLYER',
  },
  {
    code: 'CFF',
    callsign: 'AEROFAN',
  },
  {
    code: 'CNB',
    callsign: 'CITYHUN',
  },
  {
    code: 'CNC',
    callsign: 'CENCOR',
  },
  {
    code: 'CND',
    callsign: 'DUTCH CORENDON',
  },
  {
    code: 'CNF',
    callsign: 'CANARY',
  },
  {
    code: 'CNG',
    callsign: 'SID-AIR',
  },
  {
    code: 'CNI',
    callsign: 'SERAER',
  },
  {
    code: 'CNK',
    callsign: 'CHINOOK',
  },
  {
    code: 'CNL',
    callsign: 'WYO-AIR',
  },
  {
    code: 'CNM',
    callsign: 'MENGYUAN',
  },
  {
    code: 'CNO',
    callsign: 'SCANOR',
  },
  {
    code: 'CNR',
    callsign: 'CONAERO',
  },
  {
    code: 'CNS',
    callsign: 'CHRONOS',
  },
  {
    code: 'CNT',
    callsign: 'CONNECT CARGO',
  },
  {
    code: 'CNU',
    callsign: 'AIR CONSUL',
  },
  {
    code: 'CNV',
    callsign: 'CONVOY',
  },
  {
    code: 'CNW',
    callsign: 'TANG',
  },
  {
    code: 'COB',
    callsign: 'COBRA JET',
  },
  {
    code: 'COI',
    callsign: 'COAVIATION',
  },
  {
    code: 'COL',
    callsign: 'COLONY',
  },
  {
    code: 'COM',
    callsign: 'COMAIR',
  },
  {
    code: 'CON',
    callsign: 'CONOCO',
  },
  {
    code: 'COO',
    callsign: 'COVE',
  },
  {
    code: 'COP',
    callsign: 'COPPER STATE',
  },
  {
    code: 'COT',
    callsign: 'MARA',
  },
  {
    code: 'COV',
    callsign: 'HELICENTRE',
  },
  {
    code: 'COW',
    callsign: 'COWI',
  },
  {
    code: 'COX',
    callsign: 'COX AVIACION',
  },
  {
    code: 'COY',
    callsign: 'COYNE AIR',
  },
  {
    code: 'COZ',
    callsign: 'COSMIC AIR',
  },
  {
    code: 'CPA',
    callsign: 'CATHAY',
  },
  {
    code: 'CPD',
    callsign: 'CAPITAL DELTA',
  },
  {
    code: 'CPF',
    callsign: 'CAP-FLIGHT',
  },
  {
    code: 'CPG',
    callsign: 'CORPORANG',
  },
  {
    code: 'CPI',
    callsign: 'AIRCAI',
  },
  {
    code: 'CPJ',
    callsign: 'DRAGON EYE',
  },
  {
    code: 'CPK',
    callsign: 'CEDAR',
  },
  {
    code: 'CPL',
    callsign: 'CHAPARRAL',
  },
  {
    code: 'CPN',
    callsign: 'CASPIAN',
  },
  {
    code: 'DAA',
    callsign: 'DECUR',
  },
  {
    code: 'DAC',
    callsign: 'DOMINION AIR',
  },
  {
    code: 'DAD',
    callsign: 'DEWAN EXPRESS',
  },
  {
    code: 'DAE',
    callsign: 'YELLOW',
  },
  {
    code: 'DAF',
    callsign: 'DANISH AIRFORCE',
  },
  {
    code: 'DAH',
    callsign: 'AIR ALGERIE',
  },
  {
    code: 'DAI',
    callsign: 'DENA',
  },
  {
    code: 'DAL',
    callsign: 'DELTA',
  },
  {
    code: 'DAN',
    callsign: 'DANACO',
  },
  {
    code: 'DAP',
    callsign: 'DAP',
  },
  {
    code: 'DAR',
    callsign: 'DANISH ARMY',
  },
  {
    code: 'DAS',
    callsign: 'TYLOS',
  },
  {
    code: 'DAT',
    callsign: 'DAUNTLESS',
  },
  {
    code: 'DAV',
    callsign: 'DANA AIR',
  },
  {
    code: 'DAY',
    callsign: 'DAYA',
  },
  {
    code: 'DBA',
    callsign: 'DOUBLE-A',
  },
  {
    code: 'DBC',
    callsign: 'DIAMOND BACK',
  },
  {
    code: 'DBJ',
    callsign: 'DUCHESS',
  },
  {
    code: 'DCA',
    callsign: 'DELCARIBE',
  },
  {
    code: 'DCC',
    callsign: 'CARICARGO',
  },
  {
    code: 'DCD',
    callsign: 'DUCARD',
  },
  {
    code: 'DCF',
    callsign: 'DESERT STAR',
  },
  {
    code: 'DCL',
    callsign: 'DON CARLOS',
  },
  {
    code: 'DCM',
    callsign: 'DOT COM',
  },
  {
    code: 'DCN',
    callsign: 'DIPLOMATIC CLEARANCE',
  },
  {
    code: 'DCO',
    callsign: 'AEROJAZ',
  },
  {
    code: 'DCP',
    callsign: 'NILECAT',
  },
  {
    code: 'DSP',
    callsign: 'SURAVIATION',
  },
  {
    code: 'DSQ',
    callsign: 'DASAB AIR',
  },
  {
    code: 'DSR',
    callsign: 'DAIRAIR',
  },
  {
    code: 'DST',
    callsign: 'STAR AVIATION',
  },
  {
    code: 'DSU',
    callsign: 'DELTA STATE',
  },
  {
    code: 'DTA',
    callsign: 'DTA',
  },
  {
    code: 'DTH',
    callsign: 'TASSILI AIR',
  },
  {
    code: 'DTN',
    callsign: 'DATA AIR',
  },
  {
    code: 'DTO',
    callsign: 'AERODESIERTO',
  },
  {
    code: 'DTR',
    callsign: 'DANISH',
  },
  {
    code: 'DUA',
    callsign: 'DUSHANBE AIR',
  },
  {
    code: 'DUB',
    callsign: 'DUBAI',
  },
  {
    code: 'DUC',
    callsign: 'RICHAT',
  },
  {
    code: 'DUG',
    callsign: 'ATSAVEN',
  },
  {
    code: 'DUN',
    callsign: 'DUNAIR',
  },
  {
    code: 'DVI',
    callsign: 'AERO DAVINCI',
  },
  {
    code: 'DVN',
    callsign: 'ADVENTIA',
  },
  {
    code: 'DVR',
    callsign: 'DIVI AIR',
  },
  {
    code: 'DVY',
    callsign: 'DEVIL RAY',
  },
  {
    code: 'DVZ',
    callsign: 'AEROTAXI DVZ',
  },
  {
    code: 'DWG',
    callsign: 'DARK WING',
  },
  {
    code: 'DWI',
    callsign: 'DOMINICAN',
  },
  {
    code: 'DWR',
    callsign: 'DELAWARE',
  },
  {
    code: 'DXH',
    callsign: 'EAST STAR',
  },
  {
    code: 'DXT',
    callsign: 'DEXTER',
  },
  {
    code: 'DYM',
    callsign: 'DYNAMIC SKY',
  },
  {
    code: 'DYN',
    callsign: 'AERODYNAMICS',
  },
  {
    code: 'DZR',
    callsign: 'DOZER',
  },
  {
    code: 'EAC',
    callsign: 'EXECAIR',
  },
  {
    code: 'EAD',
    callsign: 'AERO-ESCOLA',
  },
  {
    code: 'EAF',
    callsign: 'ELECTRA',
  },
  {
    code: 'EAI',
    callsign: 'GEMSTONE',
  },
  {
    code: 'EAK',
    callsign: 'EAKAZ',
  },
  {
    code: 'EAL',
    callsign: 'EASTERN',
  },
  {
    code: 'EAM',
    callsign: 'EMBASSY AIR',
  },
  {
    code: 'EAN',
    callsign: 'NIGERIA EXPRESS',
  },
  {
    code: 'EAO',
    callsign: 'MARINO',
  },
  {
    code: 'EAP',
    callsign: 'AERO-PYRENEES',
  },
  {
    code: 'EAR',
    callsign: 'EJECUTIVO-AEREO',
  },
  {
    code: 'EAS',
    callsign: 'AEROSPACE',
  },
  {
    code: 'ENL',
    callsign: 'OPTIMOS',
  },
  {
    code: 'ENS',
    callsign: 'ENTERGY SHUTTLE',
  },
  {
    code: 'ENT',
    callsign: 'ENTERAIR',
  },
  {
    code: 'ENW',
    callsign: 'AERONOR',
  },
  {
    code: 'ENY',
    callsign: 'ENVOY',
  },
  {
    code: 'ENZ',
    callsign: 'ENZO',
  },
  {
    code: 'EOA',
    callsign: 'LOMBARDA',
  },
  {
    code: 'EOK',
    callsign: 'AEROHANGUK',
  },
  {
    code: 'EOL',
    callsign: 'EOLE',
  },
  {
    code: 'EOP',
    callsign: 'EOLO PLUS',
  },
  {
    code: 'EOS',
    callsign: 'ELIOSSOLA',
  },
  {
    code: 'EPA',
    callsign: 'DONGHAI AIR',
  },
  {
    code: 'EPB',
    callsign: 'DONG JET',
  },
  {
    code: 'EPC',
    callsign: 'EUROPILOT',
  },
  {
    code: 'EPI',
    callsign: 'EPIC RED',
  },
  {
    code: 'EPJ',
    callsign: 'ESPEJO',
  },
  {
    code: 'EPL',
    callsign: 'EMPRESARIALES',
  },
  {
    code: 'EPR',
    callsign: 'EMPEROR',
  },
  {
    code: 'EPS',
    callsign: 'EPPS AIR',
  },
  {
    code: 'EPU',
    callsign: 'ELITACAPULCO',
  },
  {
    code: 'EQL',
    callsign: 'EQUATORIAL',
  },
  {
    code: 'EQT',
    callsign: 'EQUITY',
  },
  {
    code: 'EQX',
    callsign: 'EQUINOX',
  },
  {
    code: 'ERA',
    callsign: 'ROCAN',
  },
  {
    code: 'ERE',
    callsign: 'AIR ERIE',
  },
  {
    code: 'ERF',
    callsign: 'GULLIVER',
  },
  {
    code: 'ERH',
    callsign: 'ERA',
  },
  {
    code: 'ERI',
    callsign: 'ASERGIO',
  },
  {
    code: 'ERK',
    callsign: 'AEROSEC',
  },
  {
    code: 'ERL',
    callsign: 'AEROLAB',
  },
  {
    code: 'ERM',
    callsign: 'EOMAAN',
  },
  {
    code: 'ERN',
    callsign: 'ERNEST',
  },
  {
    code: 'ERO',
    callsign: 'AEROECOM',
  },
  {
    code: 'ERT',
    callsign: 'ERITREAN',
  },
  {
    code: 'ERU',
    callsign: 'RIDDLE',
  },
  {
    code: 'ERX',
    callsign: 'EARTH AIR',
  },
  {
    code: 'ERY',
    callsign: 'ERIE SHORE',
  },
  {
    code: 'ESA',
    callsign: 'MALAGAIR',
  },
  {
    code: 'ESC',
    callsign: 'SOLAMERICA',
  },
  {
    code: 'ESD',
    callsign: 'EASTINDO',
  },
  {
    code: 'ESE',
    callsign: 'ENSENADA ESPECIAL',
  },
  {
    code: 'FDJ',
    callsign: 'FREEDOM JET',
  },
  {
    code: 'FDK',
    callsign: 'DAMAVIA',
  },
  {
    code: 'FDL',
    callsign: 'FARMINGDALE STATE',
  },
  {
    code: 'FDM',
    callsign: 'LIBERTY',
  },
  {
    code: 'FDN',
    callsign: 'FLYING DOLPHIN',
  },
  {
    code: 'FDO',
    callsign: 'FRENCH CUSTOM',
  },
  {
    code: 'FDR',
    callsign: 'FEDAIR',
  },
  {
    code: 'FDS',
    callsign: 'FLYDOC',
  },
  {
    code: 'FDT',
    callsign: 'FREEDOM EAGLE',
  },
  {
    code: 'FDX',
    callsign: 'FEDEX',
  },
  {
    code: 'FDY',
    callsign: 'FRIENDLY',
  },
  {
    code: 'FEA',
    callsign: 'FAR EASTERN',
  },
  {
    code: 'FEE',
    callsign: 'FARES',
  },
  {
    code: 'FEG',
    callsign: 'SKY EGYPT',
  },
  {
    code: 'FEI',
    callsign: 'ARCTIC EAGLE',
  },
  {
    code: 'FEM',
    callsign: 'ZIM AIRLINK',
  },
  {
    code: 'FEN',
    callsign: 'FENESTRON',
  },
  {
    code: 'FEO',
    callsign: 'FERINCO',
  },
  {
    code: 'FES',
    callsign: 'AERO ALFE',
  },
  {
    code: 'FET',
    callsign: 'FREIGHT LINE',
  },
  {
    code: 'FEX',
    callsign: 'FLIGHTEXEC',
  },
  {
    code: 'FFC',
    callsign: 'FAIROAKS',
  },
  {
    code: 'FFD',
    callsign: 'FLORIAN',
  },
  {
    code: 'FFE',
    callsign: 'SMART TRAINER',
  },
  {
    code: 'FFF',
    callsign: 'INTER FREEDOM',
  },
  {
    code: 'FFH',
    callsign: 'PEACE AIR',
  },
  {
    code: 'FFI',
    callsign: 'INFINIT',
  },
  {
    code: 'FFL',
    callsign: 'FOREFLIGHT',
  },
  {
    code: 'FFM',
    callsign: 'FIREFLY',
  },
  {
    code: 'FFS',
    callsign: 'FORESTRY',
  },
  {
    code: 'FFT',
    callsign: 'FRONTIER FLIGHT',
  },
  {
    code: 'FFU',
    callsign: 'FERRANTI',
  },
  {
    code: 'FFV',
    callsign: 'SWIFT TANGO',
  },
  {
    code: 'FFX',
    callsign: 'FLEX BRASIL',
  },
  {
    code: 'FFY',
    callsign: 'FLYERS AIR',
  },
  {
    code: 'FGA',
    callsign: 'GEORGIA FED',
  },
  {
    code: 'FGC',
    callsign: 'FORESTALES',
  },
  {
    code: 'FGD',
    callsign: 'FIREGUARD',
  },
  {
    code: 'FTA',
    callsign: 'FRONTIER-AIR',
  },
  {
    code: 'FTC',
    callsign: 'FANTASTIC',
  },
  {
    code: 'FTD',
    callsign: 'FORTITUDE',
  },
  {
    code: 'FTH',
    callsign: 'FOOTHILLS',
  },
  {
    code: 'FTJ',
    callsign: 'HU TAI',
  },
  {
    code: 'FTK',
    callsign: 'FIRETAC',
  },
  {
    code: 'FTL',
    callsign: 'FLIGHT-AVIA',
  },
  {
    code: 'FTM',
    callsign: 'FLYTEAM',
  },
  {
    code: 'FTO',
    callsign: 'WAGNER',
  },
  {
    code: 'FTP',
    callsign: 'FOOTPRINT',
  },
  {
    code: 'FTR',
    callsign: 'FINISTAIR',
  },
  {
    code: 'FTU',
    callsign: 'FLIGHT UNIVERSITY',
  },
  {
    code: 'FTY',
    callsign: 'FLY TYROL',
  },
  {
    code: 'FTZ',
    callsign: 'GREY BIRD',
  },
  {
    code: 'FUC',
    callsign: 'FUMICAR',
  },
  {
    code: 'FUH',
    callsign: 'GALLANT',
  },
  {
    code: 'FUJ',
    callsign: 'FUJAIRAH',
  },
  {
    code: 'FUL',
    callsign: 'ORANGE CRANE',
  },
  {
    code: 'FUM',
    callsign: 'FUNLINE',
  },
  {
    code: 'FUP',
    callsign: 'FUMIPALMA',
  },
  {
    code: 'FUS',
    callsign: 'FUMISOL',
  },
  {
    code: 'FVA',
    callsign: 'AIR VIRGINIA',
  },
  {
    code: 'FVJ',
    callsign: 'VALUEJET',
  },
  {
    code: 'FVS',
    callsign: 'FALCON AVIATION',
  },
  {
    code: 'FWD',
    callsign: 'FAIR WIND',
  },
  {
    code: 'FWI',
    callsign: 'FRENCH WEST',
  },
  {
    code: 'FWK',
    callsign: 'FLIGHTWORKS',
  },
  {
    code: 'FWL',
    callsign: 'FLO WEST',
  },
  {
    code: 'FWR',
    callsign: 'FLIGHTAWARE',
  },
  {
    code: 'FXI',
    callsign: 'FAXI',
  },
  {
    code: 'FXP',
    callsign: 'SUNDIAL',
  },
  {
    code: 'FXW',
    callsign: 'FLEXWING',
  },
  {
    code: 'FYA',
    callsign: 'FLYANT',
  },
  {
    code: 'FYE',
    callsign: 'FLYME',
  },
  {
    code: 'FYG',
    callsign: 'FLYING GROUP',
  },
  {
    code: 'FYJ',
    callsign: 'FASTJET',
  },
  {
    code: 'FYL',
    callsign: 'FLYINGLUX',
  },
  {
    code: 'FYN',
    callsign: 'FLANDY',
  },
  {
    code: 'GLU',
    callsign: 'LAKES CARGO',
  },
  {
    code: 'GLV',
    callsign: 'AVANTI',
  },
  {
    code: 'GLX',
    callsign: 'RUSSIAN BIRD',
  },
  {
    code: 'GLY',
    callsign: 'GALAXY AVIATION',
  },
  {
    code: 'GMA',
    callsign: 'GAMA',
  },
  {
    code: 'GMC',
    callsign: 'GENERAL MOTORS',
  },
  {
    code: 'GMD',
    callsign: 'GULF MED',
  },
  {
    code: 'GME',
    callsign: 'GLOBE MERIDIAN',
  },
  {
    code: 'GMG',
    callsign: 'GEE-EM HELICOPTERS',
  },
  {
    code: 'GMH',
    callsign: 'HUGHES',
  },
  {
    code: 'GMJ',
    callsign: 'GAMISA',
  },
  {
    code: 'GML',
    callsign: 'GEEANDEL',
  },
  {
    code: 'GMN',
    callsign: 'GARMIN',
  },
  {
    code: 'GMR',
    callsign: 'GOLDEN MYANMAR',
  },
  {
    code: 'GMT',
    callsign: 'GRUPOMONTERREY',
  },
  {
    code: 'GNA',
    callsign: 'SERVIGANA',
  },
  {
    code: 'GNC',
    callsign: 'GUINEA CARGO',
  },
  {
    code: 'GND',
    callsign: 'GRAND VEGAS',
  },
  {
    code: 'GNF',
    callsign: 'GREEN FLAG',
  },
  {
    code: 'GNJ',
    callsign: 'GAIN JET',
  },
  {
    code: 'GNR',
    callsign: 'GAMBIA INTERNATIONAL',
  },
  {
    code: 'GNX',
    callsign: 'AEROGENEX',
  },
  {
    code: 'GNY',
    callsign: 'GERMAN NAVY',
  },
  {
    code: 'GNZ',
    callsign: 'GONZO',
  },
  {
    code: 'GOF',
    callsign: 'GOF-AIR',
  },
  {
    code: 'GOJ',
    callsign: 'GOJET',
  },
  {
    code: 'GOL',
    callsign: 'CARGOLAAR',
  },
  {
    code: 'GOM',
    callsign: 'GOMEL',
  },
  {
    code: 'GOP',
    callsign: 'GOSPA AIR',
  },
  {
    code: 'GOS',
    callsign: 'GOLDEN SKY',
  },
  {
    code: 'GOW',
    callsign: 'GOAIR',
  },
  {
    code: 'GOZ',
    callsign: 'GROZNYY AVIA',
  },
  {
    code: 'GPA',
    callsign: 'GOLD PAC',
  },
  {
    code: 'GPD',
    callsign: 'GOODSPEED',
  },
  {
    code: 'GPL',
    callsign: 'GESPLANE',
  },
  {
    code: 'GPM',
    callsign: 'GRUPOMED',
  },
  {
    code: 'GPR',
    callsign: 'GPM AEROSERVICIO',
  },
  {
    code: 'GPX',
    callsign: 'IVORY',
  },
  {
    code: 'GRA',
    callsign: 'FLEX',
  },
  {
    code: 'GRB',
    callsign: 'GRAY BIRD',
  },
  {
    code: 'GRC',
    callsign: 'GRACE FLIGHT',
  },
  {
    code: 'HGR',
    callsign: 'HANG',
  },
  {
    code: 'HGT',
    callsign: 'HIGHTECH',
  },
  {
    code: 'HHA',
    callsign: 'ATLANTIC HONDURAS',
  },
  {
    code: 'HHE',
    callsign: 'HELI HOLLAND',
  },
  {
    code: 'HHH',
    callsign: 'CORINIUM',
  },
  {
    code: 'HHN',
    callsign: 'ROOSTER',
  },
  {
    code: 'HHP',
    callsign: 'HELENIA',
  },
  {
    code: 'HHS',
    callsign: 'HIJET',
  },
  {
    code: 'HIB',
    callsign: 'HELIBRAVO',
  },
  {
    code: 'HIF',
    callsign: 'HIFSA',
  },
  {
    code: 'HIL',
    callsign: 'HELI ALPES',
  },
  {
    code: 'HIM',
    callsign: 'HIMALAYA',
  },
  {
    code: 'HIN',
    callsign: 'HOLDING GROUP',
  },
  {
    code: 'HIP',
    callsign: 'STARSA',
  },
  {
    code: 'HJA',
    callsign: 'AIRHAITI',
  },
  {
    code: 'HJE',
    callsign: 'GOSA',
  },
  {
    code: 'HJI',
    callsign: 'SHIV AIR',
  },
  {
    code: 'HJL',
    callsign: 'BIZJET',
  },
  {
    code: 'HJT',
    callsign: 'HELTAIR',
  },
  {
    code: 'HKA',
    callsign: 'SPEND AIR',
  },
  {
    code: 'HKB',
    callsign: 'CLASSIC',
  },
  {
    code: 'HKC',
    callsign: 'MASCOT',
  },
  {
    code: 'HKE',
    callsign: 'HONGKONG SHUTTLE',
  },
  {
    code: 'HKG',
    callsign: 'HONGKONG GOVERNMENT',
  },
  {
    code: 'HKH',
    callsign: 'HAWKHUNGARY',
  },
  {
    code: 'HKI',
    callsign: 'HAWKEYE',
  },
  {
    code: 'HKL',
    callsign: 'HAK AIRLINE',
  },
  {
    code: 'HKN',
    callsign: 'HANKINS',
  },
  {
    code: 'HKR',
    callsign: 'DATA BIRD',
  },
  {
    code: 'HKS',
    callsign: 'HELIBUS',
  },
  {
    code: 'HKV',
    callsign: 'HUCAVEN',
  },
  {
    code: 'HLA',
    callsign: 'HELLENIC ARMY',
  },
  {
    code: 'HLC',
    callsign: 'HELICAP',
  },
  {
    code: 'HLE',
    callsign: 'HELIMED',
  },
  {
    code: 'HLF',
    callsign: 'HOMELAND',
  },
  {
    code: 'HLH',
    callsign: 'HALA AIR',
  },
  {
    code: 'HLI',
    callsign: 'HELI SAINT-TROPEZ',
  },
  {
    code: 'HLJ',
    callsign: 'HELLOJETS',
  },
  {
    code: 'ISH',
    callsign: 'ISLAND HOP',
  },
  {
    code: 'ISI',
    callsign: 'ISLANDMEX',
  },
  {
    code: 'ISM',
    callsign: 'STORK',
  },
  {
    code: 'ISR',
    callsign: 'ISRAIR',
  },
  {
    code: 'ISS',
    callsign: 'AIR ITALY',
  },
  {
    code: 'ISW',
    callsign: 'PINTADERA',
  },
  {
    code: 'ITA',
    callsign: 'CAFEX',
  },
  {
    code: 'ITE',
    callsign: 'AEROTAXI',
  },
  {
    code: 'ITF',
    callsign: 'AIR AVITA',
  },
  {
    code: 'ITH',
    callsign: 'INTRANS NIGERIA',
  },
  {
    code: 'ITI',
    callsign: 'INTAIRLINE',
  },
  {
    code: 'ITK',
    callsign: 'INTERLINK',
  },
  {
    code: 'ITL',
    callsign: 'ITALFLY',
  },
  {
    code: 'ITM',
    callsign: 'ISLAND TYME',
  },
  {
    code: 'ITN',
    callsign: 'TITANLUX',
  },
  {
    code: 'ITS',
    callsign: 'INTER-STATE',
  },
  {
    code: 'ITY',
    callsign: 'ITARROW',
  },
  {
    code: 'IVA',
    callsign: 'AEROBOLIVAR',
  },
  {
    code: 'IWD',
    callsign: 'IBERWORLD',
  },
  {
    code: 'IWY',
    callsign: 'ISLANDWAYS',
  },
  {
    code: 'IXR',
    callsign: 'X-BIRD',
  },
  {
    code: 'IYC',
    callsign: 'AERO THEBE',
  },
  {
    code: 'IYE',
    callsign: 'YEMENI',
  },
  {
    code: 'IZA',
    callsign: 'IZHAVIA',
  },
  {
    code: 'IZG',
    callsign: 'ZAGROS',
  },
  {
    code: 'IZM',
    callsign: 'IZMIR',
  },
  {
    code: 'JAA',
    callsign: 'JET ASIA',
  },
  {
    code: 'JAB',
    callsign: 'AIR BAGAN',
  },
  {
    code: 'JAC',
    callsign: 'COMMUTER',
  },
  {
    code: 'JAE',
    callsign: 'JADE CARGO',
  },
  {
    code: 'JAF',
    callsign: 'BEAUTY',
  },
  {
    code: 'JAI',
    callsign: 'JET AIRWAYS',
  },
  {
    code: 'JAL',
    callsign: 'JAPANAIR',
  },
  {
    code: 'JAM',
    callsign: 'SUNTRACK',
  },
  {
    code: 'JAN',
    callsign: 'JANES',
  },
  {
    code: 'JAR',
    callsign: 'AIRLINK',
  },
  {
    code: 'JAS',
    callsign: 'JET SETTER',
  },
  {
    code: 'JAT',
    callsign: 'ROCKSMART',
  },
  {
    code: 'JTD',
    callsign: 'NEW JET',
  },
  {
    code: 'JTE',
    callsign: 'JETEX',
  },
  {
    code: 'JTI',
    callsign: 'JETCLIPPER',
  },
  {
    code: 'JTL',
    callsign: 'JET LINX',
  },
  {
    code: 'JTM',
    callsign: 'JET COAST',
  },
  {
    code: 'JTN',
    callsign: 'JET TEST',
  },
  {
    code: 'JTR',
    callsign: 'JESTER',
  },
  {
    code: 'JTS',
    callsign: 'JETSERVICE',
  },
  {
    code: 'JTT',
    callsign: 'MOSCOW JET',
  },
  {
    code: 'JTU',
    callsign: 'ZHETYSU',
  },
  {
    code: 'JTZ',
    callsign: 'STEEL JET',
  },
  {
    code: 'JUA',
    callsign: 'JUPITER GAMBIA',
  },
  {
    code: 'JUB',
    callsign: 'JUBBA',
  },
  {
    code: 'JUC',
    callsign: 'JUBA CARGO',
  },
  {
    code: 'JUP',
    callsign: 'JUMP AIR',
  },
  {
    code: 'JUS',
    callsign: 'JET USA',
  },
  {
    code: 'JUT',
    callsign: 'JUTLAND',
  },
  {
    code: 'JVE',
    callsign: 'HURON',
  },
  {
    code: 'JWD',
    callsign: 'WIJAYA AIR',
  },
  {
    code: 'JWX',
    callsign: 'JETWAYS',
  },
  {
    code: 'JXT',
    callsign: 'VANNIN',
  },
  {
    code: 'JXX',
    callsign: 'COCONUT',
  },
  {
    code: 'JYA',
    callsign: 'SUNBIZ',
  },
  {
    code: 'JYH',
    callsign: 'TRANS JADE',
  },
  {
    code: 'JYP',
    callsign: 'CORP-FLIGHT',
  },
  {
    code: 'JYR',
    callsign: 'TAECA',
  },
  {
    code: 'JZA',
    callsign: 'JAZZ',
  },
  {
    code: 'JZR',
    callsign: 'JAZEERA',
  },
  {
    code: 'KAB',
    callsign: 'AIR POHANG',
  },
  {
    code: 'KAC',
    callsign: 'KUWAITI',
  },
  {
    code: 'KAG',
    callsign: 'AVIATION COLLEGE',
  },
  {
    code: 'KAI',
    callsign: 'KAISER',
  },
  {
    code: 'KAJ',
    callsign: 'KARTHAGO',
  },
  {
    code: 'KAL',
    callsign: 'KOREANAIR',
  },
  {
    code: 'KAM',
    callsign: 'SUNKAR',
  },
  {
    code: 'KAN',
    callsign: 'KANSAS',
  },
  {
    code: 'KAP',
    callsign: 'CAIR',
  },
  {
    code: 'KAR',
    callsign: 'IKAR',
  },
  {
    code: 'KAV',
    callsign: 'ARMHELI',
  },
  {
    code: 'KAY',
    callsign: 'KAYAK',
  },
  {
    code: 'KAZ',
    callsign: 'KAZLUX',
  },
  {
    code: 'KBA',
    callsign: 'BOREK AIR',
  },
  {
    code: 'KRO',
    callsign: 'KROONK',
  },
  {
    code: 'KRP',
    callsign: 'CARPATAIR',
  },
  {
    code: 'KRS',
    callsign: 'ATLAS',
  },
  {
    code: 'KRT',
    callsign: 'KIRKNEWTON',
  },
  {
    code: 'KRU',
    callsign: 'KARUN',
  },
  {
    code: 'KRV',
    callsign: 'KHORIV-AVIA',
  },
  {
    code: 'KSA',
    callsign: 'SKY CAMEL',
  },
  {
    code: 'KSF',
    callsign: 'KENT',
  },
  {
    code: 'KSI',
    callsign: 'KISSARI',
  },
  {
    code: 'KSM',
    callsign: 'KOSMOS',
  },
  {
    code: 'KSP',
    callsign: 'SAEP',
  },
  {
    code: 'KST',
    callsign: 'KING STAR',
  },
  {
    code: 'KSU',
    callsign: 'K-STATE',
  },
  {
    code: 'KSV',
    callsign: 'KOSFLIGHT',
  },
  {
    code: 'KTA',
    callsign: 'CITY LINK',
  },
  {
    code: 'KTB',
    callsign: 'TRANSBALTIKA',
  },
  {
    code: 'KTK',
    callsign: 'AZUR AIR',
  },
  {
    code: 'KTL',
    callsign: 'KNOTTSBERRY',
  },
  {
    code: 'KTO',
    callsign: 'KRATOS',
  },
  {
    code: 'KTU',
    callsign: 'TRANSPORT UNI',
  },
  {
    code: 'KTV',
    callsign: 'KATAVIA',
  },
  {
    code: 'KUA',
    callsign: 'TRANSPORTES KAUA',
  },
  {
    code: 'KUD',
    callsign: 'AIR KUDOS',
  },
  {
    code: 'KUG',
    callsign: 'KUWAITI GOV',
  },
  {
    code: 'KUH',
    callsign: 'KUSH AVIATION',
  },
  {
    code: 'KUK',
    callsign: 'KUDLIK',
  },
  {
    code: 'KUR',
    callsign: 'KAY-AVIATION',
  },
  {
    code: 'KUS',
    callsign: 'KUSWAG',
  },
  {
    code: 'KVA',
    callsign: 'CATATUMBO',
  },
  {
    code: 'KVN',
    callsign: 'KOREA AVIATION',
  },
  {
    code: 'KVT',
    callsign: 'ALL AFRICA',
  },
  {
    code: 'KWN',
    callsign: 'KWENA',
  },
  {
    code: 'KWX',
    callsign: 'KAY DUB',
  },
  {
    code: 'KXA',
    callsign: 'FINDER AIR',
  },
  {
    code: 'KXP',
    callsign: 'XPRESS KARGO',
  },
  {
    code: 'KYC',
    callsign: 'SKYBIRD CHARTER',
  },
  {
    code: 'KYD',
    callsign: 'SKYAD',
  },
  {
    code: 'KYE',
    callsign: 'SKY CUBE',
  },
  {
    code: 'KYR',
    callsign: 'SKY AERONAUTICAL',
  },
  {
    code: 'LLN',
    callsign: 'ALLEN',
  },
  {
    code: 'LLP',
    callsign: 'SKYPOL',
  },
  {
    code: 'LLR',
    callsign: 'ALLIED',
  },
  {
    code: 'LLS',
    callsign: 'SERVIESTRELLA',
  },
  {
    code: 'LLT',
    callsign: 'CLASSIC JET',
  },
  {
    code: 'LLY',
    callsign: 'LETSY',
  },
  {
    code: 'LMA',
    callsign: 'AEROLIMA',
  },
  {
    code: 'LMD',
    callsign: 'LAMIA',
  },
  {
    code: 'LME',
    callsign: 'LIMAIR EXPRESS',
  },
  {
    code: 'LMG',
    callsign: 'SOUTH AFRICAN',
  },
  {
    code: 'LMJ',
    callsign: 'MASTERJET',
  },
  {
    code: 'LMK',
    callsign: 'LANDMARK',
  },
  {
    code: 'LMP',
    callsign: 'SAISA',
  },
  {
    code: 'LMR',
    callsign: 'LAMAIR',
  },
  {
    code: 'LMS',
    callsign: 'LOMAS',
  },
  {
    code: 'LMU',
    callsign: 'ALMASRIA',
  },
  {
    code: 'LNA',
    callsign: 'ELNAIR',
  },
  {
    code: 'LNE',
    callsign: 'AEROLANE',
  },
  {
    code: 'LNG',
    callsign: 'LIGHTNING',
  },
  {
    code: 'LNI',
    callsign: 'LION INTER',
  },
  {
    code: 'LNK',
    callsign: 'LINK',
  },
  {
    code: 'LNM',
    callsign: 'AIR RING',
  },
  {
    code: 'LNQ',
    callsign: 'FASTLINK',
  },
  {
    code: 'LNX',
    callsign: 'LONEX',
  },
  {
    code: 'LOA',
    callsign: 'LEOAIR TRAINER',
  },
  {
    code: 'LOC',
    callsign: 'LOCAIR',
  },
  {
    code: 'LOF',
    callsign: 'WATERSKI',
  },
  {
    code: 'LOG',
    callsign: 'LOGAN',
  },
  {
    code: 'LOJ',
    callsign: 'LOJY AIR',
  },
  {
    code: 'LOK',
    callsign: 'ALOK AIR',
  },
  {
    code: 'LOL',
    callsign: 'EASY CHARTER',
  },
  {
    code: 'LON',
    callsign: 'MISR',
  },
  {
    code: 'LOP',
    callsign: 'LINTON',
  },
  {
    code: 'LOR',
    callsign: 'LEO CHARTER',
  },
  {
    code: 'LOS',
    callsign: 'LOSSIE',
  },
  {
    code: 'LOT',
    callsign: 'LOT',
  },
  {
    code: 'LOX',
    callsign: 'LOX JET',
  },
  {
    code: 'LPA',
    callsign: 'LEAP',
  },
  {
    code: 'LPC',
    callsign: 'NETSTAR',
  },
  {
    code: 'LPD',
    callsign: 'LEOPARD',
  },
  {
    code: 'LPE',
    callsign: 'LANPERU',
  },
  {
    code: 'LPL',
    callsign: 'LEASE-A-PLANE',
  },
  {
    code: 'MDO',
    callsign: 'KUDA',
  },
  {
    code: 'MDR',
    callsign: 'AEROPLANOS',
  },
  {
    code: 'MDS',
    callsign: 'MID-SOUTH',
  },
  {
    code: 'MDT',
    callsign: 'MIDNIGHT',
  },
  {
    code: 'MDW',
    callsign: 'MEDWING',
  },
  {
    code: 'MDX',
    callsign: 'MEDAIR',
  },
  {
    code: 'MEA',
    callsign: 'CEDAR JET',
  },
  {
    code: 'MEC',
    callsign: 'MERCAIR',
  },
  {
    code: 'MEF',
    callsign: 'EMPENNAGE',
  },
  {
    code: 'MEI',
    callsign: 'AVALON',
  },
  {
    code: 'MEJ',
    callsign: 'MEDJET',
  },
  {
    code: 'MEK',
    callsign: 'MED-TRANS',
  },
  {
    code: 'MEL',
    callsign: 'MEGA AIR',
  },
  {
    code: 'MEM',
    callsign: 'MERIDIAN CHERRY',
  },
  {
    code: 'MEN',
    callsign: 'RAHAL',
  },
  {
    code: 'MER',
    callsign: 'METHOW',
  },
  {
    code: 'MES',
    callsign: 'MESABA',
  },
  {
    code: 'MET',
    callsign: 'METMAN',
  },
  {
    code: 'MEV',
    callsign: 'MED-VIEW',
  },
  {
    code: 'MFA',
    callsign: 'SEAHORSE',
  },
  {
    code: 'MFB',
    callsign: 'MOUNTAINHELI',
  },
  {
    code: 'MFC',
    callsign: 'EAST WIND',
  },
  {
    code: 'MFD',
    callsign: 'FREIGHT DOG',
  },
  {
    code: 'MFG',
    callsign: 'MID AFRICA',
  },
  {
    code: 'MFJ',
    callsign: 'SEGURO',
  },
  {
    code: 'MFL',
    callsign: 'MCFLY',
  },
  {
    code: 'MFR',
    callsign: 'MIDLINE FREIGHT',
  },
  {
    code: 'MFT',
    callsign: 'YORKAIR',
  },
  {
    code: 'MFY',
    callsign: 'MEDIFLY MEX',
  },
  {
    code: 'MGA',
    callsign: 'MAG AVIACION',
  },
  {
    code: 'MGC',
    callsign: 'MONGOLIA CARGO',
  },
  {
    code: 'MGD',
    callsign: 'MIAMI AIR',
  },
  {
    code: 'MGE',
    callsign: 'MAGELLAN',
  },
  {
    code: 'MGF',
    callsign: 'FOGLIA',
  },
  {
    code: 'MGG',
    callsign: 'ELMAGAL',
  },
  {
    code: 'MGI',
    callsign: 'MARGHI',
  },
  {
    code: 'MGL',
    callsign: 'MONGOL AIR',
  },
  {
    code: 'MGM',
    callsign: 'AERO EMM-GEE-EMM',
  },
  {
    code: 'MGO',
    callsign: 'MANGO',
  },
  {
    code: 'MGP',
    callsign: 'MAGPIE',
  },
  {
    code: 'MRX',
    callsign: 'SPEEDMARK',
  },
  {
    code: 'MRY',
    callsign: 'AIR MARINE',
  },
  {
    code: 'MRZ',
    callsign: 'MARS',
  },
  {
    code: 'MSA',
    callsign: 'MISTRAL WINGS',
  },
  {
    code: 'MSB',
    callsign: 'LONG',
  },
  {
    code: 'MSE',
    callsign: 'EGYPTAIR EXPRESS',
  },
  {
    code: 'MSF',
    callsign: 'MINSHENG',
  },
  {
    code: 'MSG',
    callsign: 'SAR-REGIONAL',
  },
  {
    code: 'MSH',
    callsign: 'MARSHALAIR',
  },
  {
    code: 'MSI',
    callsign: 'MOTOR SICH',
  },
  {
    code: 'MSJ',
    callsign: 'MAGNUM AIR',
  },
  {
    code: 'MSL',
    callsign: 'MARSLANDAIR',
  },
  {
    code: 'MSM',
    callsign: 'AEROMAS EXPRESS',
  },
  {
    code: 'MSN',
    callsign: 'MILCARGO',
  },
  {
    code: 'MSP',
    callsign: 'SEGURIDAD',
  },
  {
    code: 'MSQ',
    callsign: 'META',
  },
  {
    code: 'MSR',
    callsign: 'EGYPTAIR',
  },
  {
    code: 'MST',
    callsign: 'MASTER',
  },
  {
    code: 'MSU',
    callsign: 'MALUTI SKY',
  },
  {
    code: 'MSX',
    callsign: 'EGYPTAIR CARGO',
  },
  {
    code: 'MSY',
    callsign: 'MASSEY',
  },
  {
    code: 'MTA',
    callsign: 'GAK AVIATION',
  },
  {
    code: 'MTB',
    callsign: 'AEROMETROPOLIS',
  },
  {
    code: 'MTD',
    callsign: 'MATADOR',
  },
  {
    code: 'MTE',
    callsign: 'TELEJET',
  },
  {
    code: 'MTF',
    callsign: 'INTERJET',
  },
  {
    code: 'MTG',
    callsign: 'AERO TETE',
  },
  {
    code: 'MTH',
    callsign: 'RESEARCH',
  },
  {
    code: 'MTI',
    callsign: 'MONTERREY AIR',
  },
  {
    code: 'MTJ',
    callsign: 'METROJET',
  },
  {
    code: 'MTK',
    callsign: 'AIRMETACK',
  },
  {
    code: 'MTL',
    callsign: 'MITAVIA',
  },
  {
    code: 'MTM',
    callsign: 'METACHEM',
  },
  {
    code: 'MTN',
    callsign: 'MOUNTAIN',
  },
  {
    code: 'MTO',
    callsign: 'MARATHON',
  },
  {
    code: 'MTR',
    callsign: 'METRO',
  },
  {
    code: 'MTS',
    callsign: 'MEDSERVICE',
  },
  {
    code: 'MTU',
    callsign: 'BLUE RAIDER',
  },
  {
    code: 'MTW',
    callsign: 'MAURITANIA AIRWAYS',
  },
  {
    code: 'MTX',
    callsign: 'MULTITAXI',
  },
  {
    code: 'NWF',
    callsign: 'SECOND CITY',
  },
  {
    code: 'NWG',
    callsign: 'NORWING',
  },
  {
    code: 'NWH',
    callsign: 'NORTHERN WINGS',
  },
  {
    code: 'NWK',
    callsign: 'NETLINK',
  },
  {
    code: 'NWL',
    callsign: 'NORTHWRIGHT',
  },
  {
    code: 'NWS',
    callsign: 'NORDLAND',
  },
  {
    code: 'NXS',
    callsign: 'NEXUS AVIATION',
  },
  {
    code: 'NXT',
    callsign: 'NEXTNET',
  },
  {
    code: 'NXU',
    callsign: 'NEXUS',
  },
  {
    code: 'NYB',
    callsign: 'BELGIAN NAVY',
  },
  {
    code: 'NYL',
    callsign: 'NILE',
  },
  {
    code: 'NYS',
    callsign: 'NYASA',
  },
  {
    code: 'NYT',
    callsign: 'YETI AIRLINES',
  },
  {
    code: 'NYX',
    callsign: 'NYX AIR',
  },
  {
    code: 'NZM',
    callsign: 'MOUNTCOOK',
  },
  {
    code: 'OAC',
    callsign: 'ORIENTAL AIR',
  },
  {
    code: 'OAD',
    callsign: 'ORSCOM',
  },
  {
    code: 'OAE',
    callsign: 'OMNI-EXPRESS',
  },
  {
    code: 'OAL',
    callsign: 'OLYMPIC',
  },
  {
    code: 'OAN',
    callsign: 'OTAN',
  },
  {
    code: 'OAO',
    callsign: 'DVINA',
  },
  {
    code: 'OAR',
    callsign: 'BOSS AIR',
  },
  {
    code: 'OAS',
    callsign: 'ORCHID',
  },
  {
    code: 'OAV',
    callsign: 'OMNI',
  },
  {
    code: 'OAW',
    callsign: 'HELVETIC',
  },
  {
    code: 'OBC',
    callsign: 'OLYCAM',
  },
  {
    code: 'OBJ',
    callsign: 'OBJETIVO',
  },
  {
    code: 'OBK',
    callsign: 'AMAKO AIR',
  },
  {
    code: 'OBS',
    callsign: 'ORBEST',
  },
  {
    code: 'OBT',
    callsign: 'COSMIC GIRL',
  },
  {
    code: 'OCA',
    callsign: 'AROSCA',
  },
  {
    code: 'OCE',
    callsign: 'HELIOCEAN',
  },
  {
    code: 'OCJ',
    callsign: 'OVERSTAR',
  },
  {
    code: 'OCL',
    callsign: 'SOCA',
  },
  {
    code: 'OCN',
    callsign: 'OCEAN',
  },
  {
    code: 'OCO',
    callsign: 'AIR COLLEGE',
  },
  {
    code: 'ODM',
    callsign: 'JETAFRICA',
  },
  {
    code: 'OEA',
    callsign: 'ORIENT THAI',
  },
  {
    code: 'OED',
    callsign: 'ORION CHARTER',
  },
  {
    code: 'OES',
    callsign: 'ART AUSTRIA',
  },
  {
    code: 'ONY',
    callsign: 'ONLY JET',
  },
  {
    code: 'OOT',
    callsign: 'OOTBAS',
  },
  {
    code: 'OPM',
    callsign: 'OPTIMA',
  },
  {
    code: 'OPN',
    callsign: 'OPEN AIR',
  },
  {
    code: 'OPS',
    callsign: 'OPS-JET',
  },
  {
    code: 'OPT',
    callsign: 'OPTIONS',
  },
  {
    code: 'OPV',
    callsign: 'OPERADORA DE VUELOS',
  },
  {
    code: 'ORA',
    callsign: 'LONG ISLAND',
  },
  {
    code: 'ORC',
    callsign: 'ORIENTAL BRIDGE',
  },
  {
    code: 'ORE',
    callsign: 'ORANGE AVIATION',
  },
  {
    code: 'ORF',
    callsign: 'OMAN',
  },
  {
    code: 'ORG',
    callsign: 'ORENAVIA',
  },
  {
    code: 'ORH',
    callsign: 'ORSHA',
  },
  {
    code: 'ORI',
    callsign: 'RED GLOBE',
  },
  {
    code: 'ORK',
    callsign: 'ORCA',
  },
  {
    code: 'ORN',
    callsign: 'ORANGE JET',
  },
  {
    code: 'ORO',
    callsign: 'CAPRI',
  },
  {
    code: 'ORT',
    callsign: 'SKYWALKER',
  },
  {
    code: 'ORZ',
    callsign: 'ZOREX',
  },
  {
    code: 'OSC',
    callsign: 'UNCIELO',
  },
  {
    code: 'OSD',
    callsign: 'SECDEF',
  },
  {
    code: 'OSJ',
    callsign: 'OSCARJET',
  },
  {
    code: 'OSL',
    callsign: 'SOSOLISO',
  },
  {
    code: 'OSN',
    callsign: 'AEROSAN',
  },
  {
    code: 'OSS',
    callsign: 'NOTICIOSOS',
  },
  {
    code: 'OST',
    callsign: 'OSTATE',
  },
  {
    code: 'OSU',
    callsign: 'SCARLET',
  },
  {
    code: 'OSY',
    callsign: 'OPEN SKIES',
  },
  {
    code: 'OTA',
    callsign: 'OUTLAW',
  },
  {
    code: 'OTC',
    callsign: 'AIR TRAVEL',
  },
  {
    code: 'OTF',
    callsign: 'ORANGESKY',
  },
  {
    code: 'OTK',
    callsign: 'ON TRACK',
  },
  {
    code: 'OTN',
    callsign: 'LASTP',
  },
  {
    code: 'OTR',
    callsign: 'ORIENTROC',
  },
  {
    code: 'OTS',
    callsign: 'TRESALIA',
  },
  {
    code: 'OTT',
    callsign: 'OTT AIRLINES',
  },
  {
    code: 'OUA',
    callsign: 'CRIMSON',
  },
  {
    code: 'OUF',
    callsign: 'ELEMENT',
  },
  {
    code: 'PIN',
    callsign: 'ROAD RUNNERS',
  },
  {
    code: 'PIO',
    callsign: 'PIONEER',
  },
  {
    code: 'PIP',
    callsign: 'PILOT',
  },
  {
    code: 'PIR',
    callsign: 'PAMIR',
  },
  {
    code: 'PIS',
    callsign: 'PARSIS',
  },
  {
    code: 'PIU',
    callsign: 'PRIMA',
  },
  {
    code: 'PJA',
    callsign: 'PRIVATE FLIGHT',
  },
  {
    code: 'PJC',
    callsign: 'PITTSBURGH JET',
  },
  {
    code: 'PJE',
    callsign: 'PEE JAY',
  },
  {
    code: 'PJF',
    callsign: 'PROJET',
  },
  {
    code: 'PJP',
    callsign: 'PRINCELY JETS',
  },
  {
    code: 'PJS',
    callsign: 'JETAVIATION',
  },
  {
    code: 'PJT',
    callsign: 'PARTNERJET',
  },
  {
    code: 'PJZ',
    callsign: 'PRIMEJET',
  },
  {
    code: 'PKP',
    callsign: 'PIKES PEAK',
  },
  {
    code: 'PKR',
    callsign: 'PAKKER AVIO',
  },
  {
    code: 'PKW',
    callsign: 'PLATINUM WEST',
  },
  {
    code: 'PKZ',
    callsign: 'PRAVI',
  },
  {
    code: 'PLA',
    callsign: 'POLYAIR',
  },
  {
    code: 'PLB',
    callsign: 'POLYBLUE',
  },
  {
    code: 'PLC',
    callsign: 'SPECIAL',
  },
  {
    code: 'PLF',
    callsign: 'POLISH AIRFORCE',
  },
  {
    code: 'PLG',
    callsign: 'PILGRIM',
  },
  {
    code: 'PLL',
    callsign: 'AIRPAL',
  },
  {
    code: 'PLM',
    callsign: 'PULLMAN',
  },
  {
    code: 'PLN',
    callsign: 'PLANAR',
  },
  {
    code: 'PLQ',
    callsign: 'PLANETCAM',
  },
  {
    code: 'PLR',
    callsign: 'POLARIS',
  },
  {
    code: 'PLT',
    callsign: 'PALMETTO',
  },
  {
    code: 'PLU',
    callsign: 'PILATUS MEXICO',
  },
  {
    code: 'PLV',
    callsign: 'PERLA',
  },
  {
    code: 'PLX',
    callsign: 'POOLEX',
  },
  {
    code: 'PLY',
    callsign: 'PUMA BRASIL',
  },
  {
    code: 'PLZ',
    callsign: 'PALS HOPE',
  },
  {
    code: 'PMA',
    callsign: 'PAN MALAYSIA',
  },
  {
    code: 'PMC',
    callsign: 'PRIMAC',
  },
  {
    code: 'PMF',
    callsign: 'ALFESTIVAL',
  },
  {
    code: 'PMI',
    callsign: 'AEREOPRIM',
  },
  {
    code: 'PML',
    callsign: 'PRIME NIGERIA',
  },
  {
    code: 'PMM',
    callsign: 'PARADIGM',
  },
  {
    code: 'PWC',
    callsign: 'PRATT',
  },
  {
    code: 'PWF',
    callsign: 'PRIVATE WINGS',
  },
  {
    code: 'PXA',
    callsign: 'PECOTOX',
  },
  {
    code: 'PXC',
    callsign: 'PALAU EXPRESS',
  },
  {
    code: 'PXG',
    callsign: 'PHOENIX GLOBAL',
  },
  {
    code: 'PXJ',
    callsign: 'PHOENIX JET',
  },
  {
    code: 'PXR',
    callsign: 'PIXAIR',
  },
  {
    code: 'PXT',
    callsign: 'PACK COAST',
  },
  {
    code: 'PXX',
    callsign: 'PINE STATE',
  },
  {
    code: 'PYA',
    callsign: 'POUYA AIR',
  },
  {
    code: 'PYC',
    callsign: 'AEROPYCSA',
  },
  {
    code: 'PYN',
    callsign: 'POYSTON',
  },
  {
    code: 'PYZ',
    callsign: 'PLAYERS AIR',
  },
  {
    code: 'PZA',
    callsign: 'AEREO PARAZA',
  },
  {
    code: 'QAF',
    callsign: 'AMIRI',
  },
  {
    code: 'QAI',
    callsign: 'CHICKPEA',
  },
  {
    code: 'QAJ',
    callsign: 'DAGOBERT',
  },
  {
    code: 'QAQ',
    callsign: 'QURINEA AIR',
  },
  {
    code: 'QAS',
    callsign: 'QUISQUEYA',
  },
  {
    code: 'QAZ',
    callsign: 'SAMRUK',
  },
  {
    code: 'QBA',
    callsign: 'BILQIS',
  },
  {
    code: 'QBD',
    callsign: 'SEAGULL AIR',
  },
  {
    code: 'QCL',
    callsign: 'ACLA',
  },
  {
    code: 'QDA',
    callsign: 'SKY LEGEND',
  },
  {
    code: 'QFA',
    callsign: 'QANTAS',
  },
  {
    code: 'QFZ',
    callsign: 'FARS QESHM',
  },
  {
    code: 'QGA',
    callsign: 'QUADRIGA',
  },
  {
    code: 'QHD',
    callsign: 'QUAHADI',
  },
  {
    code: 'QID',
    callsign: 'QUID',
  },
  {
    code: 'QJT',
    callsign: 'JIU TIAN',
  },
  {
    code: 'QLK',
    callsign: 'Q LINK',
  },
  {
    code: 'QNK',
    callsign: 'KABO',
  },
  {
    code: 'QNR',
    callsign: 'QUEEN AIR',
  },
  {
    code: 'QNT',
    callsign: 'SHARQ',
  },
  {
    code: 'QNZ',
    callsign: 'QANTAS JETCONNECT',
  },
  {
    code: 'QPL',
    callsign: 'QUPULA',
  },
  {
    code: 'QQE',
    callsign: 'QREX',
  },
  {
    code: 'RIV',
    callsign: 'RIVIERA',
  },
  {
    code: 'RIX',
    callsign: 'RECTRIX',
  },
  {
    code: 'RJA',
    callsign: 'JORDANIAN',
  },
  {
    code: 'RJB',
    callsign: 'PANELLENIC',
  },
  {
    code: 'RJC',
    callsign: 'COLUMBIA JET',
  },
  {
    code: 'RJD',
    callsign: 'ROTANA',
  },
  {
    code: 'RJE',
    callsign: 'REYNOLDS JET',
  },
  {
    code: 'RJJ',
    callsign: 'MAVEX',
  },
  {
    code: 'RJM',
    callsign: 'MILLEN',
  },
  {
    code: 'RJR',
    callsign: 'MELITA',
  },
  {
    code: 'RJS',
    callsign: 'ASERJET',
  },
  {
    code: 'RJT',
    callsign: 'RA JET',
  },
  {
    code: 'RJZ',
    callsign: 'JORDAN AIR FORCE',
  },
  {
    code: 'RKA',
    callsign: 'AIR SAKHA',
  },
  {
    code: 'RKK',
    callsign: 'ROCKTOWN',
  },
  {
    code: 'RKS',
    callsign: 'ROCKSTAR',
  },
  {
    code: 'RKT',
    callsign: 'BLUE GREEN',
  },
  {
    code: 'RLA',
    callsign: 'ROLEMA',
  },
  {
    code: 'RLB',
    callsign: 'SUNLIGHT',
  },
  {
    code: 'RLE',
    callsign: 'RICO',
  },
  {
    code: 'RLH',
    callsign: 'SENDI',
  },
  {
    code: 'RLI',
    callsign: 'RELIANT',
  },
  {
    code: 'RLJ',
    callsign: 'REAL JET',
  },
  {
    code: 'RLM',
    callsign: 'ROYAL AMERICAN',
  },
  {
    code: 'RLO',
    callsign: 'REVILO',
  },
  {
    code: 'RLR',
    callsign: 'RATTLER',
  },
  {
    code: 'RLT',
    callsign: 'REAL TONGA',
  },
  {
    code: 'RLU',
    callsign: 'RUSLINE AIR',
  },
  {
    code: 'RLX',
    callsign: 'RELAX',
  },
  {
    code: 'RLY',
    callsign: 'LOYAUTE',
  },
  {
    code: 'RLZ',
    callsign: 'ALIZE',
  },
  {
    code: 'RMA',
    callsign: 'ROCKY MOUNTAIN',
  },
  {
    code: 'RMD',
    callsign: 'AIR AMDER',
  },
  {
    code: 'RMF',
    callsign: 'ANGKASA',
  },
  {
    code: 'RMG',
    callsign: 'RUMUGU AIR',
  },
  {
    code: 'RMI',
    callsign: 'POINT AIRLINE',
  },
  {
    code: 'RMK',
    callsign: 'SIMRIK AIR',
  },
  {
    code: 'RMN',
    callsign: 'RAINMAN',
  },
  {
    code: 'RMO',
    callsign: 'ARM-AERO',
  },
  {
    code: 'SAK',
    callsign: 'RED ARROWS',
  },
  {
    code: 'SAM',
    callsign: 'SAM',
  },
  {
    code: 'SAN',
    callsign: 'SILVAIR NET',
  },
  {
    code: 'SAO',
    callsign: 'SAVSER',
  },
  {
    code: 'SAP',
    callsign: 'TOBOL',
  },
  {
    code: 'SAQ',
    callsign: 'SINBAD',
  },
  {
    code: 'SAR',
    callsign: 'SPRINTAIR CARGO',
  },
  {
    code: 'SAS',
    callsign: 'SCANDINAVIAN',
  },
  {
    code: 'SAT',
    callsign: 'SATA',
  },
  {
    code: 'SAW',
    callsign: 'SHAMWING',
  },
  {
    code: 'SAX',
    callsign: 'SABAH AIR',
  },
  {
    code: 'SAY',
    callsign: 'SKY PATH',
  },
  {
    code: 'SAZ',
    callsign: 'SWISS AMBULANCE',
  },
  {
    code: 'SBA',
    callsign: 'SOL BRASIL',
  },
  {
    code: 'SBB',
    callsign: 'BALKAN JET',
  },
  {
    code: 'SBC',
    callsign: 'SABIAN AIR',
  },
  {
    code: 'SBD',
    callsign: 'SIBIA',
  },
  {
    code: 'SBE',
    callsign: 'STARBASE',
  },
  {
    code: 'SBF',
    callsign: 'S-BAR',
  },
  {
    code: 'SBH',
    callsign: 'AEROSAAB',
  },
  {
    code: 'SBI',
    callsign: 'SIBERIAN AIRLINES',
  },
  {
    code: 'SBJ',
    callsign: 'TRANS SAHARA',
  },
  {
    code: 'SBK',
    callsign: 'MAWINGU',
  },
  {
    code: 'SBL',
    callsign: 'SKY BIRD',
  },
  {
    code: 'SBM',
    callsign: 'TROPICAL SKY',
  },
  {
    code: 'SBN',
    callsign: 'SKYBOSNIA',
  },
  {
    code: 'SBO',
    callsign: 'STABAIR',
  },
  {
    code: 'SBP',
    callsign: 'SHAHIN BALL',
  },
  {
    code: 'SBQ',
    callsign: 'SKIBBLE',
  },
  {
    code: 'SBR',
    callsign: 'FREIGHTER',
  },
  {
    code: 'SBS',
    callsign: 'SEABORNE',
  },
  {
    code: 'SBT',
    callsign: 'TAFTAN',
  },
  {
    code: 'SBU',
    callsign: 'BLACK FIN',
  },
  {
    code: 'SBV',
    callsign: 'RIPPLE',
  },
  {
    code: 'SCA',
    callsign: 'AVIATOR',
  },
  {
    code: 'SCB',
    callsign: 'SP CARGO',
  },
  {
    code: 'SCC',
    callsign: 'SEA-COASTER',
  },
  {
    code: 'SCD',
    callsign: 'ASSOCIATED',
  },
  {
    code: 'SGU',
    callsign: 'SOLPARAGUAYO',
  },
  {
    code: 'SHA',
    callsign: 'SHREE-AIR',
  },
  {
    code: 'SHC',
    callsign: 'SKY HARBOR CHEYENNE',
  },
  {
    code: 'SHE',
    callsign: 'SHELL',
  },
  {
    code: 'SHF',
    callsign: 'VORTEX',
  },
  {
    code: 'SHG',
    callsign: 'SHOP AIR',
  },
  {
    code: 'SHH',
    callsign: 'SKY HIGH',
  },
  {
    code: 'SHI',
    callsign: 'SHIRAZI',
  },
  {
    code: 'SHJ',
    callsign: 'SHARJAH',
  },
  {
    code: 'SHL',
    callsign: 'SAMSON',
  },
  {
    code: 'SHM',
    callsign: 'SHELTAM',
  },
  {
    code: 'SHN',
    callsign: 'SUGAR ALFA',
  },
  {
    code: 'SHP',
    callsign: 'SAF',
  },
  {
    code: 'SHQ',
    callsign: 'SHANGHAI CARGO',
  },
  {
    code: 'SHS',
    callsign: 'SHIRAK',
  },
  {
    code: 'SHT',
    callsign: 'SHUTTLE',
  },
  {
    code: 'SHU',
    callsign: 'AURORA',
  },
  {
    code: 'SHV',
    callsign: 'SHAVANO',
  },
  {
    code: 'SHW',
    callsign: 'SHADOW',
  },
  {
    code: 'SHX',
    callsign: 'SLIM AIR',
  },
  {
    code: 'SHZ',
    callsign: 'ELITE SHARES',
  },
  {
    code: 'SIA',
    callsign: 'SINGAPORE',
  },
  {
    code: 'SIB',
    callsign: 'SEA AIR',
  },
  {
    code: 'SIC',
    callsign: 'SICHART',
  },
  {
    code: 'SID',
    callsign: 'SIDERAL',
  },
  {
    code: 'SIE',
    callsign: 'SEREX',
  },
  {
    code: 'SIF',
    callsign: 'AIR SIAL',
  },
  {
    code: 'SIL',
    callsign: 'SILVER WINGS',
  },
  {
    code: 'SIO',
    callsign: 'SIRIO',
  },
  {
    code: 'SIQ',
    callsign: 'SCIENCE QUEST',
  },
  {
    code: 'SIR',
    callsign: 'SALAIR',
  },
  {
    code: 'SIS',
    callsign: 'SILVERSPEED',
  },
  {
    code: 'SIV',
    callsign: 'SOLENT-IVOIRE',
  },
  {
    code: 'SIX',
    callsign: 'DRIVE ORANGE',
  },
  {
    code: 'SIY',
    callsign: 'SERENITY',
  },
  {
    code: 'SIZ',
    callsign: 'AEROSILZA',
  },
  {
    code: 'SPB',
    callsign: 'SPRING CLASSIC',
  },
  {
    code: 'SPC',
    callsign: 'PORT',
  },
  {
    code: 'SPD',
    callsign: 'SKY PRIME',
  },
  {
    code: 'SPE',
    callsign: 'SPRAGUE',
  },
  {
    code: 'SPF',
    callsign: 'SPACE WORLD',
  },
  {
    code: 'SPG',
    callsign: 'SPEEDWING',
  },
  {
    code: 'SPH',
    callsign: 'SAPPHIRE-CHARTER',
  },
  {
    code: 'SPI',
    callsign: 'SOUTH PACIFIC',
  },
  {
    code: 'SPJ',
    callsign: 'SPECTRUM',
  },
  {
    code: 'SPK',
    callsign: 'SOLSTAS LAB',
  },
  {
    code: 'SPL',
    callsign: 'CORPORATIVOS LAGUNA',
  },
  {
    code: 'SPN',
    callsign: 'SEPAHAN',
  },
  {
    code: 'SPO',
    callsign: 'SPORAVIA',
  },
  {
    code: 'SPP',
    callsign: 'SAPPHIRE',
  },
  {
    code: 'SPR',
    callsign: 'SPEEDAIR',
  },
  {
    code: 'SPS',
    callsign: 'SALDUERO',
  },
  {
    code: 'SPT',
    callsign: 'SERVICIOS SUP',
  },
  {
    code: 'SPU',
    callsign: 'SPUTTER',
  },
  {
    code: 'SPV',
    callsign: 'SERVICIOS PRIVADOS',
  },
  {
    code: 'SPW',
    callsign: 'ROYAL SPIRIT',
  },
  {
    code: 'SPY',
    callsign: 'THAI SPACE',
  },
  {
    code: 'SPZ',
    callsign: 'SPEED SERVICE',
  },
  {
    code: 'SQA',
    callsign: 'SLOVAK AEROCLUB',
  },
  {
    code: 'SQD',
    callsign: 'AERO SQUAD',
  },
  {
    code: 'SQF',
    callsign: 'SLOVAK AIRFORCE',
  },
  {
    code: 'SQH',
    callsign: 'SASQUATCH',
  },
  {
    code: 'SQM',
    callsign: 'GOLDEN FIN',
  },
  {
    code: 'SQP',
    callsign: 'SKYUP',
  },
  {
    code: 'SQS',
    callsign: 'SKY QUEEN',
  },
  {
    code: 'SQU',
    callsign: 'REMUDO',
  },
  {
    code: 'SRA',
    callsign: 'SAUDI ARABIAN',
  },
  {
    code: 'SRB',
    callsign: 'SERBIAN AIR FORCE',
  },
  {
    code: 'SRC',
    callsign: 'SEARCA',
  },
  {
    code: 'SRE',
    callsign: 'SERAMI',
  },
  {
    code: 'SRF',
    callsign: 'SAN RAFAEL',
  },
  {
    code: 'SRH',
    callsign: 'SIEMREAP AIR',
  },
  {
    code: 'SRI',
    callsign: 'AIRSAFARI',
  },
  {
    code: 'SRJ',
    callsign: 'SURIJET',
  },
  {
    code: 'SXN',
    callsign: 'SAXONAIR',
  },
  {
    code: 'SXP',
    callsign: 'SOUTHPAC EXPRESS',
  },
  {
    code: 'SXT',
    callsign: 'SERTAXI',
  },
  {
    code: 'SXY',
    callsign: 'SAFARI EXPRESS',
  },
  {
    code: 'SXZ',
    callsign: 'SOCALAIR',
  },
  {
    code: 'SYA',
    callsign: 'SYPHAXAIR',
  },
  {
    code: 'SYB',
    callsign: 'SKYBIZ',
  },
  {
    code: 'SYC',
    callsign: 'SYSTEC',
  },
  {
    code: 'SYD',
    callsign: 'SKYDOCTOR',
  },
  {
    code: 'SYF',
    callsign: 'SKY FIRST',
  },
  {
    code: 'SYG',
    callsign: 'SYNERGY',
  },
  {
    code: 'SYK',
    callsign: 'AEROCAB',
  },
  {
    code: 'SYL',
    callsign: 'AIR YAKUTIA',
  },
  {
    code: 'SYM',
    callsign: 'VOLO SKY',
  },
  {
    code: 'SYN',
    callsign: 'SYNCRUDE',
  },
  {
    code: 'SYO',
    callsign: 'SYERSTON',
  },
  {
    code: 'SYP',
    callsign: 'SYNESIS',
  },
  {
    code: 'SYR',
    callsign: 'SYRIANAIR',
  },
  {
    code: 'SYS',
    callsign: 'SHAWBURY',
  },
  {
    code: 'SYT',
    callsign: 'SKYTRACK',
  },
  {
    code: 'SYU',
    callsign: 'SKY UNLIMITED',
  },
  {
    code: 'SYV',
    callsign: 'SPECIAL SYSTEM',
  },
  {
    code: 'SYY',
    callsign: 'SKY COACH',
  },
  {
    code: 'SYZ',
    callsign: 'ZIL AIR',
  },
  {
    code: 'SZA',
    callsign: 'AIR ASTRO',
  },
  {
    code: 'SZG',
    callsign: 'STOTZ AGRO',
  },
  {
    code: 'SZL',
    callsign: 'ESWATINI',
  },
  {
    code: 'SZL',
    callsign: 'ESWATINI',
  },
  {
    code: 'SZN',
    callsign: 'SENSA',
  },
  {
    code: 'SZS',
    callsign: 'SPINNAKER',
  },
  {
    code: 'SZT',
    callsign: 'AERO ZEE',
  },
  {
    code: 'TAA',
    callsign: 'AERO COSTA',
  },
  {
    code: 'TAB',
    callsign: 'TRANS-BAJIO',
  },
  {
    code: 'TAC',
    callsign: 'TAURUS',
  },
  {
    code: 'TAE',
    callsign: 'TAME',
  },
  {
    code: 'TAG',
    callsign: 'TAGSPIRIT',
  },
  {
    code: 'TAI',
    callsign: 'TACA',
  },
  {
    code: 'TIV',
    callsign: 'THRIVE',
  },
  {
    code: 'TIW',
    callsign: 'TIACA',
  },
  {
    code: 'TIX',
    callsign: 'MATRIX',
  },
  {
    code: 'TJA',
    callsign: 'TAPJETS',
  },
  {
    code: 'TJB',
    callsign: 'ALADIN',
  },
  {
    code: 'TJD',
    callsign: 'ALISERIO',
  },
  {
    code: 'TJE',
    callsign: 'TOMZAJET',
  },
  {
    code: 'TJK',
    callsign: 'TAJIKAIR',
  },
  {
    code: 'TJS',
    callsign: 'TYROLJET',
  },
  {
    code: 'TJT',
    callsign: 'TWINJET',
  },
  {
    code: 'TKA',
    callsign: 'TOURLIZ',
  },
  {
    code: 'TKC',
    callsign: 'TURKISH CARGO',
  },
  {
    code: 'TKK',
    callsign: 'TARKA',
  },
  {
    code: 'TKM',
    callsign: 'TACOMA',
  },
  {
    code: 'TKU',
    callsign: 'UGATRANS',
  },
  {
    code: 'TLA',
    callsign: 'AEROCLA',
  },
  {
    code: 'TLC',
    callsign: 'CARIB-X',
  },
  {
    code: 'TLE',
    callsign: 'AEROUTIL',
  },
  {
    code: 'TLI',
    callsign: 'SPIRIT',
  },
  {
    code: 'TLK',
    callsign: 'STARLINK',
  },
  {
    code: 'TLM',
    callsign: 'MENTARI',
  },
  {
    code: 'TLN',
    callsign: 'AERO ATLA',
  },
  {
    code: 'TLR',
    callsign: 'AIR LIBYA',
  },
  {
    code: 'TLS',
    callsign: 'TEALSY',
  },
  {
    code: 'TLT',
    callsign: 'TURTLE',
  },
  {
    code: 'TLU',
    callsign: 'AEROTOLUCA',
  },
  {
    code: 'TLV',
    callsign: 'PAJAROS',
  },
  {
    code: 'TLX',
    callsign: 'TELESIS',
  },
  {
    code: 'TLY',
    callsign: 'TOPFLY',
  },
  {
    code: 'TLZ',
    callsign: 'TRANSLIZ',
  },
  {
    code: 'TMA',
    callsign: 'TANGO LIMA',
  },
  {
    code: 'TMC',
    callsign: 'TRAIL BLAZER',
  },
  {
    code: 'TMD',
    callsign: 'TRANSMANDU',
  },
  {
    code: 'TME',
    callsign: 'TAIMEN',
  },
  {
    code: 'TMG',
    callsign: 'TRILINES',
  },
  {
    code: 'TMI',
    callsign: 'TAMIRWAYS',
  },
  {
    code: 'TMK',
    callsign: 'TOMAHAWK',
  },
  {
    code: 'TML',
    callsign: 'TAM AIRLINE',
  },
  {
    code: 'TVD',
    callsign: 'AEROFLIGHT',
  },
  {
    code: 'TVF',
    callsign: 'FRANCE SOLEIL',
  },
  {
    code: 'TVJ',
    callsign: 'THAIVIET JET',
  },
  {
    code: 'TVL',
    callsign: 'TRAVEL SERVICE',
  },
  {
    code: 'TVP',
    callsign: 'JET TRAVEL',
  },
  {
    code: 'TVQ',
    callsign: 'SLOVAKTRAVEL',
  },
  {
    code: 'TVR',
    callsign: 'TERRAAVIA',
  },
  {
    code: 'TVS',
    callsign: 'SKYTRAVEL',
  },
  {
    code: 'TVT',
    callsign: 'TAXIS TOLUCA',
  },
  {
    code: 'TVV',
    callsign: 'PARAMITA',
  },
  {
    code: 'TWB',
    callsign: 'TEEWAY',
  },
  {
    code: 'TWC',
    callsign: 'CANAIR',
  },
  {
    code: 'TWD',
    callsign: 'TRADEWINGS',
  },
  {
    code: 'TWF',
    callsign: 'CLOUD RUNNER',
  },
  {
    code: 'TWG',
    callsign: 'TWIN-GOOSE',
  },
  {
    code: 'TWI',
    callsign: 'TAILWIND',
  },
  {
    code: 'TWT',
    callsign: 'TRANSWISATA',
  },
  {
    code: 'TWW',
    callsign: 'WELWITCHIA',
  },
  {
    code: 'TWY',
    callsign: 'TWILIGHT',
  },
  {
    code: 'TXA',
    callsign: 'TEXAIR',
  },
  {
    code: 'TXC',
    callsign: 'TRANSEXPORT',
  },
  {
    code: 'TXD',
    callsign: 'TAXI OESTE',
  },
  {
    code: 'TXF',
    callsign: 'ALFE',
  },
  {
    code: 'TXH',
    callsign: 'TEXTRON',
  },
  {
    code: 'TXI',
    callsign: 'AEREOTAXIS',
  },
  {
    code: 'TXJ',
    callsign: 'TAIXIANG',
  },
  {
    code: 'TXL',
    callsign: 'TAXI COZATL',
  },
  {
    code: 'TXM',
    callsign: 'TAXIMEX',
  },
  {
    code: 'TXN',
    callsign: 'TEXAS NATIONAL',
  },
  {
    code: 'TXP',
    callsign: 'TAJIK EXPRESS',
  },
  {
    code: 'TXT',
    callsign: 'TEXAS CHARTER',
  },
  {
    code: 'TXV',
    callsign: 'TAXIVALLARTA',
  },
  {
    code: 'TXX',
    callsign: 'COWBOY',
  },
  {
    code: 'TXZ',
    callsign: 'EXPRESS AIR',
  },
  {
    code: 'TYA',
    callsign: 'TAIMYR',
  },
  {
    code: 'TYJ',
    callsign: 'TYROL MALTA',
  },
  {
    code: 'TYT',
    callsign: 'TRINITY',
  },
  {
    code: 'TYW',
    callsign: 'TYROL AMBULANCE',
  },
  {
    code: 'TZA',
    callsign: 'AERO TOMZA',
  },
  {
    code: 'VAQ',
    callsign: 'VAQUERO',
  },
  {
    code: 'VAS',
    callsign: 'ATRAN',
  },
  {
    code: 'VAW',
    callsign: 'SOFIA JET',
  },
  {
    code: 'VBA',
    callsign: 'NOVE',
  },
  {
    code: 'VBB',
    callsign: 'AIR LUBO',
  },
  {
    code: 'VBW',
    callsign: 'BURKINA',
  },
  {
    code: 'VCG',
    callsign: 'THUNDER CAT',
  },
  {
    code: 'VCH',
    callsign: 'CONSORCIO HELITEC',
  },
  {
    code: 'VCI',
    callsign: 'CI-TOURS',
  },
  {
    code: 'VCJ',
    callsign: 'OBELIX',
  },
  {
    code: 'VCM',
    callsign: 'CARMEN',
  },
  {
    code: 'VCN',
    callsign: 'AVCON',
  },
  {
    code: 'VCR',
    callsign: 'VOE CRUISER',
  },
  {
    code: 'VCT',
    callsign: 'VISCOUNT AIR',
  },
  {
    code: 'VCV',
    callsign: 'CONVIASA',
  },
  {
    code: 'VCY',
    callsign: 'VINCY',
  },
  {
    code: 'VDA',
    callsign: 'VOLGA',
  },
  {
    code: 'VDC',
    callsign: 'PRIVIUM',
  },
  {
    code: 'VDG',
    callsign: 'VIAJES DON GOYO',
  },
  {
    code: 'VDO',
    callsign: 'AVANDARO',
  },
  {
    code: 'VDR',
    callsign: 'VOLDIR',
  },
  {
    code: 'VEC',
    callsign: 'VECAR',
  },
  {
    code: 'VEJ',
    callsign: 'VENEJECUTIV',
  },
  {
    code: 'VEK',
    callsign: 'ROTCEV',
  },
  {
    code: 'VEN',
    callsign: 'TRANSAVEN AIRLINE',
  },
  {
    code: 'VES',
    callsign: 'VIEQUES',
  },
  {
    code: 'VET',
    callsign: 'POINSETT AIR',
  },
  {
    code: 'VEX',
    callsign: 'VIRGIN EXPRESS',
  },
  {
    code: 'VFC',
    callsign: 'VASCO AIR',
  },
  {
    code: 'VFM',
    callsign: 'VIFEMED',
  },
  {
    code: 'VFS',
    callsign: 'VISION FLIGHT',
  },
  {
    code: 'VGA',
    callsign: 'AIR VICTORY',
  },
  {
    code: 'VGB',
    callsign: 'HONG XIANG',
  },
  {
    code: 'VGC',
    callsign: 'VANGUARDIA COLIMA',
  },
  {
    code: 'WAE',
    callsign: 'WESTERN EXPRESS',
  },
  {
    code: 'WAF',
    callsign: 'FLAMENCO',
  },
  {
    code: 'WAI',
    callsign: 'BUSHWOOD',
  },
  {
    code: 'WAL',
    callsign: 'DORAL',
  },
  {
    code: 'WAM',
    callsign: 'TAXI CARGO',
  },
  {
    code: 'WAN',
    callsign: 'WATANIYA',
  },
  {
    code: 'WAR',
    callsign: 'WARBIRDS',
  },
  {
    code: 'WAV',
    callsign: 'WARBELOW',
  },
  {
    code: 'WAW',
    callsign: 'WING SHUTTLE',
  },
  {
    code: 'WAY',
    callsign: 'GARONNE',
  },
  {
    code: 'WAZ',
    callsign: 'WIZZ SKY',
  },
  {
    code: 'WBA',
    callsign: 'ALBAJET',
  },
  {
    code: 'WCC',
    callsign: 'WEST COAST',
  },
  {
    code: 'WCD',
    callsign: 'WHITE CLOUD',
  },
  {
    code: 'WCM',
    callsign: 'WORLD CARGO',
  },
  {
    code: 'WCO',
    callsign: 'COLUMBIA HELI',
  },
  {
    code: 'WCP',
    callsign: 'WHITECAP',
  },
  {
    code: 'WCY',
    callsign: 'TITAN AIR',
  },
  {
    code: 'WDE',
    callsign: 'SANDSTONE',
  },
  {
    code: 'WDG',
    callsign: 'WATCHDOG',
  },
  {
    code: 'WDK',
    callsign: 'WOODSTOCK',
  },
  {
    code: 'WDR',
    callsign: 'WIND RIDER',
  },
  {
    code: 'WDS',
    callsign: 'WINDS',
  },
  {
    code: 'WDW',
    callsign: 'WOODWARD',
  },
  {
    code: 'WDY',
    callsign: 'WINDY CITY',
  },
  {
    code: 'WEA',
    callsign: 'DAYSTAR',
  },
  {
    code: 'WEB',
    callsign: 'WEB-BRASIL',
  },
  {
    code: 'WED',
    callsign: 'WENDA',
  },
  {
    code: 'WEN',
    callsign: 'ENCORE',
  },
  {
    code: 'WES',
    callsign: 'WEST INDIAN',
  },
  {
    code: 'WEV',
    callsign: 'VICTORIA',
  },
  {
    code: 'WEW',
    callsign: 'WESTWIND',
  },
  {
    code: 'WEY',
    callsign: 'WEB ACADEMY',
  },
  {
    code: 'WFC',
    callsign: 'SWIFTCOPTERS',
  },
  {
    code: 'XFL',
    callsign: 'EX-FLIGHT',
  },
  {
    code: 'XFX',
    callsign: 'AIRCORP',
  },
  {
    code: 'XGN',
    callsign: 'NEXGEN',
  },
  {
    code: 'XGO',
    callsign: 'PASTIS',
  },
  {
    code: 'XJC',
    callsign: 'EXCLUSIVE JET',
  },
  {
    code: 'XLE',
    callsign: 'AQUILA',
  },
  {
    code: 'XLF',
    callsign: 'STARWAY',
  },
  {
    code: 'XLJ',
    callsign: 'XCEL JET',
  },
  {
    code: 'XLK',
    callsign: 'SAFARILINK',
  },
  {
    code: 'XLL',
    callsign: 'TINGA-TINGA',
  },
  {
    code: 'XLR',
    callsign: 'TEXEL',
  },
  {
    code: 'XLS',
    callsign: 'EXCELAIRE',
  },
  {
    code: 'XLT',
    callsign: 'INFRAERO',
  },
  {
    code: 'XMR',
    callsign: 'AUTHORITY',
  },
  {
    code: 'XMS',
    callsign: 'SANTA',
  },
  {
    code: 'XMX',
    callsign: 'SENEAM',
  },
  {
    code: 'XOJ',
    callsign: 'EXOJET',
  },
  {
    code: 'XRC',
    callsign: 'TUNISIA CARGO',
  },
  {
    code: 'XRO',
    callsign: 'CRAMER',
  },
  {
    code: 'XSM',
    callsign: 'AIRSMART',
  },
  {
    code: 'XSN',
    callsign: 'SANTANA',
  },
  {
    code: 'XSR',
    callsign: 'AIRSHARE',
  },
  {
    code: 'ZTZ',
    callsign: 'TOWER',
  },
  {
    code: 'ABY',
    callsign: 'ARABIA',
  },
  {
    code: 'ABZ',
    callsign: 'ISLAND LIFEFLIGHT',
  },
  {
    code: 'ACA',
    callsign: 'AIR CANADA',
  },
  {
    code: 'ACB',
    callsign: 'AIRCONTI',
  },
  {
    code: 'ACD',
    callsign: 'ACADEMY',
  },
  {
    code: 'ACE',
    callsign: 'SKY ACE',
  },
  {
    code: 'ACF',
    callsign: 'FORCAN',
  },
  {
    code: 'ACI',
    callsign: 'AIRCALIN',
  },
  {
    code: 'ACJ',
    callsign: 'SINOJET',
  },
  {
    code: 'ACK',
    callsign: 'ACK AIR',
  },
  {
    code: 'ACL',
    callsign: 'ADMIRE',
  },
  {
    code: 'ACN',
    callsign: 'AZUL CONECTA',
  },
  {
    code: 'ACO',
    callsign: 'WEBER',
  },
  {
    code: 'ACP',
    callsign: 'ASTRAL CARGO',
  },
  {
    code: 'ACQ',
    callsign: 'ACE CARGO',
  },
  {
    code: 'ACR',
    callsign: 'AEROCENTER',
  },
  {
    code: 'ACS',
    callsign: 'AIRCRAFT SALES',
  },
  {
    code: 'ACT',
    callsign: 'AMERICAN CHECK',
  },
  {
    code: 'ACU',
    callsign: 'AFRISPIRIT',
  },
  {
    code: 'ACV',
    callsign: 'CARNAIR',
  },
  {
    code: 'ACW',
    callsign: 'ACRO JETS',
  },
  {
    code: 'ADA',
    callsign: 'AUSCAL',
  },
  {
    code: 'ADB',
    callsign: 'ANTONOV BUREAU',
  },
  {
    code: 'ADC',
    callsign: 'AD ASTRA',
  },
  {
    code: 'ADG',
    callsign: 'AEREA TRAINING',
  },
  {
    code: 'ADI',
    callsign: 'AUDELI',
  },
  {
    code: 'ADK',
    callsign: 'ADCO',
  },
  {
    code: 'ADL',
    callsign: 'COTSWOLD',
  },
  {
    code: 'ADM',
    callsign: 'DREAM COLLEGE',
  },
  {
    code: 'ADN',
    callsign: 'AERODIENST',
  },
  {
    code: 'ADO',
    callsign: 'AIR DO',
  },
  {
    code: 'ADP',
    callsign: 'AERODIPLOMATIC',
  },
  {
    code: 'ADR',
    callsign: 'ADRIA',
  },
  {
    code: 'ADT',
    callsign: 'ARRENDA-TRANS',
  },
  {
    code: 'ADV',
    callsign: 'ADVANCED',
  },
  {
    code: 'ALD',
    callsign: 'AIR LEISURE',
  },
  {
    code: 'ALE',
    callsign: 'ALLIANCE MALTA',
  },
  {
    code: 'ALF',
    callsign: 'ACEFORCE',
  },
  {
    code: 'ALI',
    callsign: 'BLUELIFT',
  },
  {
    code: 'ALK',
    callsign: 'SRILANKAN',
  },
  {
    code: 'ALL',
    callsign: 'ARABIA AVIA',
  },
  {
    code: 'ALN',
    callsign: 'ALKAN AVIATION',
  },
  {
    code: 'ALO',
    callsign: 'ALLEGHENY',
  },
  {
    code: 'ALP',
    callsign: 'ALLPOINTS',
  },
  {
    code: 'ALQ',
    callsign: 'ALANNA',
  },
  {
    code: 'ALS',
    callsign: 'ALASDELSUR',
  },
  {
    code: 'ALT',
    callsign: 'AEROLINEAS CENTRALES',
  },
  {
    code: 'ALU',
    callsign: 'LUXORJET',
  },
  {
    code: 'ALV',
    callsign: 'AEROPOSTAL',
  },
  {
    code: 'ALX',
    callsign: 'ALPIJETS',
  },
  {
    code: 'ALY',
    callsign: 'ALAS URUGUAY',
  },
  {
    code: 'ALZ',
    callsign: 'AZURE',
  },
  {
    code: 'AMA',
    callsign: 'MOALEM',
  },
  {
    code: 'AMB',
    callsign: 'CIVIL AIR AMBULANCE',
  },
  {
    code: 'AMC',
    callsign: 'AIR MALTA',
  },
  {
    code: 'AMD',
    callsign: 'MERCY WING',
  },
  {
    code: 'AME',
    callsign: 'AIRMIL',
  },
  {
    code: 'AMF',
    callsign: 'AMFLIGHT',
  },
  {
    code: 'AMG',
    callsign: 'AIR MINAS',
  },
  {
    code: 'AMI',
    callsign: 'AIRMEK',
  },
  {
    code: 'AMJ',
    callsign: 'AMJET EXEC',
  },
  {
    code: 'AML',
    callsign: 'MALAWI',
  },
  {
    code: 'AMN',
    callsign: 'MONTENEGRO',
  },
  {
    code: 'AMP',
    callsign: 'ATSA',
  },
  {
    code: 'AMQ',
    callsign: 'AMEX',
  },
  {
    code: 'AMR',
    callsign: 'AIR AM',
  },
  {
    code: 'AMT',
    callsign: 'AIR MOUNTAIN',
  },
  {
    code: 'AMU',
    callsign: 'AIR MACAU',
  },
  {
    code: 'AMX',
    callsign: 'AEROMEXICO',
  },
  {
    code: 'AMY',
    callsign: 'AIR MANDALAY',
  },
  {
    code: 'AMZ',
    callsign: 'AMIYI AIR',
  },
  {
    code: 'AUD',
    callsign: 'GENIE',
  },
  {
    code: 'AUE',
    callsign: 'AIR UNIVERSE',
  },
  {
    code: 'AUG',
    callsign: 'GEO UNIVERSITY',
  },
  {
    code: 'AUH',
    callsign: 'SULTAN',
  },
  {
    code: 'AUI',
    callsign: 'UKRAINE INTERNATIONAL',
  },
  {
    code: 'AUK',
    callsign: 'AURIC SERVICES',
  },
  {
    code: 'AUL',
    callsign: 'ARCHANGELSK AIR',
  },
  {
    code: 'AUM',
    callsign: 'ATLAMUR',
  },
  {
    code: 'AUN',
    callsign: 'COMMON SKY',
  },
  {
    code: 'AUO',
    callsign: 'UNIFORM OSCAR',
  },
  {
    code: 'AUR',
    callsign: 'AYLINE',
  },
  {
    code: 'AUT',
    callsign: 'AUSTRAL',
  },
  {
    code: 'AUU',
    callsign: 'ARGO',
  },
  {
    code: 'AUY',
    callsign: 'AUSA',
  },
  {
    code: 'AUZ',
    callsign: 'URUGUAYO',
  },
  {
    code: 'AVA',
    callsign: 'AVIANCA',
  },
  {
    code: 'AVB',
    callsign: 'BEAUPAIR',
  },
  {
    code: 'AVC',
    callsign: 'AIRTEAM VARNA',
  },
  {
    code: 'AVD',
    callsign: 'ALAMO',
  },
  {
    code: 'AVE',
    callsign: 'AVEX AIR',
  },
  {
    code: 'AVF',
    callsign: 'AVES FLIGHT',
  },
  {
    code: 'AVH',
    callsign: 'KENT HELI',
  },
  {
    code: 'AVJ',
    callsign: 'ATOMIC',
  },
  {
    code: 'AVK',
    callsign: 'AVIATE-COPTER',
  },
  {
    code: 'AVL',
    callsign: 'SKY VENTURES',
  },
  {
    code: 'AVM',
    callsign: 'AVEMEX',
  },
  {
    code: 'AVN',
    callsign: 'AIR VAN',
  },
  {
    code: 'AVO',
    callsign: 'AVIATION WORK',
  },
  {
    code: 'AVP',
    callsign: 'AVIA PUEBLA',
  },
  {
    code: 'AVQ',
    callsign: 'AVIC',
  },
  {
    code: 'AVR',
    callsign: 'AVAEXPRESS',
  },
  {
    code: 'AVS',
    callsign: 'AVIALSA',
  },
  {
    code: 'AVV',
    callsign: 'WING MAN',
  },
  {
    code: 'AVW',
    callsign: 'AVIRO',
  },
  {
    code: 'AVY',
    callsign: 'AEROVARADERO',
  },
  {
    code: 'AVZ',
    callsign: 'AIR VALENCIA',
  },
  {
    code: 'AWC',
    callsign: 'ZAP',
  },
  {
    code: 'BEL',
    callsign: 'BEELINE',
  },
  {
    code: 'BEN',
    callsign: 'FRANKLIN',
  },
  {
    code: 'BER',
    callsign: 'BERLIN',
  },
  {
    code: 'BET',
    callsign: 'BETA CARGO',
  },
  {
    code: 'BEU',
    callsign: 'BATELEUR',
  },
  {
    code: 'BEV',
    callsign: 'BEV AIR',
  },
  {
    code: 'BEY',
    callsign: 'BESTFLY',
  },
  {
    code: 'BEZ',
    callsign: 'SEA BREEZE',
  },
  {
    code: 'BFC',
    callsign: 'BASLER',
  },
  {
    code: 'BFD',
    callsign: 'MEDIA JET',
  },
  {
    code: 'BFF',
    callsign: 'AIR BAFFIN',
  },
  {
    code: 'BFG',
    callsign: 'BRIGHT FLIGHT',
  },
  {
    code: 'BFK',
    callsign: 'NORTHERN EXPRESS',
  },
  {
    code: 'BFL',
    callsign: 'BUFFALO',
  },
  {
    code: 'BFM',
    callsign: 'FAN MAY',
  },
  {
    code: 'BFO',
    callsign: 'BAKHTAR',
  },
  {
    code: 'BFR',
    callsign: 'BURKLINES',
  },
  {
    code: 'BFT',
    callsign: 'BORDER',
  },
  {
    code: 'BFW',
    callsign: 'SUMMAN',
  },
  {
    code: 'BFX',
    callsign: 'BLUE FOX',
  },
  {
    code: 'BFY',
    callsign: 'MWANGO BEST',
  },
  {
    code: 'BGA',
    callsign: 'BELOUGA',
  },
  {
    code: 'BGB',
    callsign: 'BIG BEND',
  },
  {
    code: 'BGC',
    callsign: 'YANDU',
  },
  {
    code: 'BGE',
    callsign: 'AEROBRIDGE',
  },
  {
    code: 'BGF',
    callsign: 'BULGARIAN',
  },
  {
    code: 'BGG',
    callsign: 'AERO BG',
  },
  {
    code: 'BGH',
    callsign: 'BALKAN HOLIDAYS',
  },
  {
    code: 'BGI',
    callsign: 'BRITISH GULF',
  },
  {
    code: 'BGN',
    callsign: 'SKYBUDDY',
  },
  {
    code: 'BGR',
    callsign: 'BRIDGER',
  },
  {
    code: 'BGS',
    callsign: 'SUNNY',
  },
  {
    code: 'BGY',
    callsign: 'SKIMMER',
  },
  {
    code: 'BHA',
    callsign: 'BUDDHA AIR',
  },
  {
    code: 'BHC',
    callsign: 'BAHIA',
  },
  {
    code: 'BHD',
    callsign: 'BANGKOK DUSIT',
  },
  {
    code: 'BHI',
    callsign: 'SHARIF',
  },
  {
    code: 'BHK',
    callsign: 'BLUHALKIN',
  },
  {
    code: 'BHL',
    callsign: 'BRISTOW',
  },
  {
    code: 'BHN',
    callsign: 'BRISTOW HELICOPTERS',
  },
  {
    code: 'BRR',
    callsign: 'MOUNTAIN AIR',
  },
  {
    code: 'BRS',
    callsign: 'BRAZILIAN AIR FORCE',
  },
  {
    code: 'BRT',
    callsign: 'BRITISH',
  },
  {
    code: 'BRU',
    callsign: 'BELARUS AVIA',
  },
  {
    code: 'BRX',
    callsign: 'BRAATHENS',
  },
  {
    code: 'BRY',
    callsign: 'BURAIR',
  },
  {
    code: 'BRZ',
    callsign: 'GREMAIR',
  },
  {
    code: 'BSA',
    callsign: 'BISSAU INTERNATIONAL',
  },
  {
    code: 'BSC',
    callsign: 'BIG SHOT',
  },
  {
    code: 'BSD',
    callsign: 'AIRLINES STAR',
  },
  {
    code: 'BSH',
    callsign: 'CAPITAL ROTOR',
  },
  {
    code: 'BSJ',
    callsign: 'SKYBUS JET',
  },
  {
    code: 'BSK',
    callsign: 'BISCAYNE',
  },
  {
    code: 'BSL',
    callsign: 'AIRBRASIL',
  },
  {
    code: 'BSX',
    callsign: 'BASSAKA',
  },
  {
    code: 'BSY',
    callsign: 'BIG SKY',
  },
  {
    code: 'BTH',
    callsign: 'BALTIJAS HELICOPTERS',
  },
  {
    code: 'BTI',
    callsign: 'AIRBALTIC',
  },
  {
    code: 'BTK',
    callsign: 'BATIK',
  },
  {
    code: 'BTL',
    callsign: 'BALTIA',
  },
  {
    code: 'BTM',
    callsign: 'BLUE TEAM',
  },
  {
    code: 'BTN',
    callsign: 'BHUTAN AIR',
  },
  {
    code: 'BTP',
    callsign: 'BAHRIA TOWN',
  },
  {
    code: 'BTQ',
    callsign: 'BOUTIQUE',
  },
  {
    code: 'BTU',
    callsign: 'ROLLS',
  },
  {
    code: 'BTX',
    callsign: 'BEETLE',
  },
  {
    code: 'BTZ',
    callsign: 'BRISTOW GROUP',
  },
  {
    code: 'BUC',
    callsign: 'EUROCHARTER',
  },
  {
    code: 'BUF',
    callsign: 'SPEEDHAWK',
  },
  {
    code: 'BUG',
    callsign: 'HEXBUG',
  },
  {
    code: 'BUR',
    callsign: 'AIR BUCHAREST',
  },
  {
    code: 'BUT',
    callsign: 'BUTLERS',
  },
  {
    code: 'BVA',
    callsign: 'BUFFALO AIR',
  },
  {
    code: 'BVG',
    callsign: 'BEEVEEGEE VIAJES',
  },
  {
    code: 'BVI',
    callsign: 'DRAKE',
  },
  {
    code: 'BVL',
    callsign: 'BULGARY',
  },
  {
    code: 'BVN',
    callsign: 'SHOW-ME',
  },
  {
    code: 'BVR',
    callsign: 'BAVARIAN',
  },
  {
    code: 'BVT',
    callsign: 'BERJAYA',
  },
  {
    code: 'CFG',
    callsign: 'CONDOR',
  },
  {
    code: 'CFH',
    callsign: 'CARE FLIGHT',
  },
  {
    code: 'CFI',
    callsign: 'CHINA JET',
  },
  {
    code: 'CFL',
    callsign: 'CALIBRATION',
  },
  {
    code: 'CFN',
    callsign: 'CHURCH FENTON',
  },
  {
    code: 'CFR',
    callsign: 'CALFIRE',
  },
  {
    code: 'CFS',
    callsign: 'EMPIRE AIR',
  },
  {
    code: 'CFT',
    callsign: 'CASPER FREIGHT',
  },
  {
    code: 'CFU',
    callsign: 'MINAIR',
  },
  {
    code: 'CFV',
    callsign: 'CALAFIA',
  },
  {
    code: 'CFX',
    callsign: 'CEEFA CHARTER',
  },
  {
    code: 'CFZ',
    callsign: 'ZHONGFEI',
  },
  {
    code: 'CGA',
    callsign: 'CONGRESSIONAL',
  },
  {
    code: 'CGB',
    callsign: 'COMGLOB',
  },
  {
    code: 'CGC',
    callsign: 'CAL-GULF',
  },
  {
    code: 'CGE',
    callsign: 'COLLEGE',
  },
  {
    code: 'CGF',
    callsign: 'CLEVER',
  },
  {
    code: 'CGG',
    callsign: 'CHARGE',
  },
  {
    code: 'CGH',
    callsign: 'WELKIN',
  },
  {
    code: 'CGN',
    callsign: 'CHANG AN',
  },
  {
    code: 'CGO',
    callsign: 'WILD ONION',
  },
  {
    code: 'CGP',
    callsign: 'CAPGROUP',
  },
  {
    code: 'CGR',
    callsign: 'COMPRIP',
  },
  {
    code: 'CGU',
    callsign: 'CHINGUETTI',
  },
  {
    code: 'CGV',
    callsign: 'CLUBE ALGARVE',
  },
  {
    code: 'CGX',
    callsign: 'COASTGUARD AUXAIR',
  },
  {
    code: 'CGZ',
    callsign: 'COLORFUL',
  },
  {
    code: 'CHA',
    callsign: 'CHARTER CENTRAL',
  },
  {
    code: 'CHB',
    callsign: 'WEST CHINA',
  },
  {
    code: 'CHC',
    callsign: 'CHINA HELICOPTER',
  },
  {
    code: 'CHD',
    callsign: 'CHKALOVSK-AVIA',
  },
  {
    code: 'CHE',
    callsign: 'CHECK AIR',
  },
  {
    code: 'CHF',
    callsign: 'CHAO FEI',
  },
  {
    code: 'CHG',
    callsign: 'CHALLENGE',
  },
  {
    code: 'CHH',
    callsign: 'HAINAN',
  },
  {
    code: 'CHI',
    callsign: 'COUGAR',
  },
  {
    code: 'CHJ',
    callsign: 'SHINING',
  },
  {
    code: 'CPO',
    callsign: 'MOKAN',
  },
  {
    code: 'CPP',
    callsign: 'COPTERPLUS',
  },
  {
    code: 'CPR',
    callsign: 'CARIBE-PERU',
  },
  {
    code: 'CPS',
    callsign: 'SWIFT BIRD',
  },
  {
    code: 'CPT',
    callsign: 'AIR SPUR',
  },
  {
    code: 'CPV',
    callsign: 'AIRCORPORATE',
  },
  {
    code: 'CPX',
    callsign: 'CAPAIR',
  },
  {
    code: 'CQH',
    callsign: 'AIR SPRING',
  },
  {
    code: 'CQN',
    callsign: 'CHONG QING',
  },
  {
    code: 'CRA',
    callsign: 'CIMAS',
  },
  {
    code: 'CRB',
    callsign: 'CARIBBEAN COMMUTER',
  },
  {
    code: 'CRC',
    callsign: 'CAMAIRCO',
  },
  {
    code: 'CRD',
    callsign: 'AIR CORRIDOR',
  },
  {
    code: 'CRE',
    callsign: 'CREAM CITY',
  },
  {
    code: 'CRF',
    callsign: 'CROIX ROUGE',
  },
  {
    code: 'CRJ',
    callsign: 'AIR CRUZAL',
  },
  {
    code: 'CRK',
    callsign: 'BAUHINIA',
  },
  {
    code: 'CRL',
    callsign: 'CORSAIR',
  },
  {
    code: 'CRM',
    callsign: 'COMMANDERMEX',
  },
  {
    code: 'CRN',
    callsign: 'CARSON',
  },
  {
    code: 'CRO',
    callsign: 'CROWN AIRWAYS',
  },
  {
    code: 'CRP',
    callsign: 'AEROTRANSCORP',
  },
  {
    code: 'CRQ',
    callsign: 'CREE',
  },
  {
    code: 'CRR',
    callsign: 'RAILCAR',
  },
  {
    code: 'CRS',
    callsign: 'COMERCIAL AEREA',
  },
  {
    code: 'CRT',
    callsign: 'CARIBINTAIR',
  },
  {
    code: 'AAB',
    callsign: 'ABG',
  },
  {
    code: 'AAC',
    callsign: 'ARMYAIR',
  },
  {
    code: 'AAD',
    callsign: 'PANABEAR',
  },
  {
    code: 'AAE',
    callsign: 'EUROVIKING',
  },
  {
    code: 'AAF',
    callsign: 'AIGLE AZUR',
  },
  {
    code: 'AAH',
    callsign: 'ALOHA',
  },
  {
    code: 'AAI',
    callsign: 'BOREALIS',
  },
  {
    code: 'AAJ',
    callsign: 'ALFA SUDAN',
  },
  {
    code: 'AAK',
    callsign: 'ALASKA ISLAND',
  },
  {
    code: 'AAL',
    callsign: 'AMERICAN',
  },
  {
    code: 'AAM',
    callsign: 'AIM AIR',
  },
  {
    code: 'AAP',
    callsign: 'ARABASCO',
  },
  {
    code: 'AAQ',
    callsign: 'ASIA ATLANTIC',
  },
  {
    code: 'AAR',
    callsign: 'ASIANA',
  },
  {
    code: 'AAS',
    callsign: 'AL-AAS',
  },
  {
    code: 'AAV',
    callsign: 'ASTRO-PHIL',
  },
  {
    code: 'AAW',
    callsign: 'AFRIQIYAH',
  },
  {
    code: 'AAX',
    callsign: 'ADVANCE AVIATION',
  },
  {
    code: 'AAY',
    callsign: 'ALLEGIANT',
  },
  {
    code: 'AAZ',
    callsign: 'AEOLUS',
  },
  {
    code: 'ABA',
    callsign: 'AEROBETA',
  },
  {
    code: 'ABB',
    callsign: 'AIR BELGIUM',
  },
  {
    code: 'ABD',
    callsign: 'ATLANTA',
  },
  {
    code: 'ABE',
    callsign: 'ABAN',
  },
  {
    code: 'ABF',
    callsign: 'SKYWINGS',
  },
  {
    code: 'ABG',
    callsign: 'ROYAL FLIGHT',
  },
  {
    code: 'ABI',
    callsign: 'AIRALBACETE',
  },
  {
    code: 'ABJ',
    callsign: 'ABAETE',
  },
  {
    code: 'ABK',
    callsign: 'AIRBUS CANUCK',
  },
  {
    code: 'ABL',
    callsign: 'AIR BUSAN',
  },
  {
    code: 'ABM',
    callsign: 'ALBATROS ESPANA',
  },
  {
    code: 'ABN',
    callsign: 'AIR ALBANIA',
  },
  {
    code: 'ABO',
    callsign: 'AEROEXPRESO',
  },
  {
    code: 'ABP',
    callsign: 'BAIR',
  },
  {
    code: 'ABQ',
    callsign: 'PAKBLUE',
  },
  {
    code: 'ABR',
    callsign: 'CONTRACT',
  },
  {
    code: 'ABT',
    callsign: 'BENWAYS',
  },
  {
    code: 'ABV',
    callsign: 'CARGOBLUE',
  },
  {
    code: 'ABW',
    callsign: 'AIRBRIDGE CARGO',
  },
  {
    code: 'ABX',
    callsign: 'ABEX',
  },
  {
    code: 'AIW',
    callsign: 'AIRWOLF',
  },
  {
    code: 'AIZ',
    callsign: 'ARKIA',
  },
  {
    code: 'AJA',
    callsign: 'AFGHAN JET',
  },
  {
    code: 'AJB',
    callsign: 'AMJET',
  },
  {
    code: 'AJC',
    callsign: 'BAR HARBOR',
  },
  {
    code: 'AJE',
    callsign: 'JET EXPRES',
  },
  {
    code: 'AJH',
    callsign: 'ALJARAFE',
  },
  {
    code: 'AJI',
    callsign: 'AMERISTAR',
  },
  {
    code: 'AJJ',
    callsign: 'AVJET',
  },
  {
    code: 'AJK',
    callsign: 'BAMBI',
  },
  {
    code: 'AJM',
    callsign: 'JAMAICA',
  },
  {
    code: 'AJN',
    callsign: 'BAJANTE',
  },
  {
    code: 'AJO',
    callsign: 'AVIATE JET',
  },
  {
    code: 'AJP',
    callsign: 'ANAPJETS',
  },
  {
    code: 'AJQ',
    callsign: 'PANAJET',
  },
  {
    code: 'AJR',
    callsign: 'JET MONGOLIA',
  },
  {
    code: 'AJS',
    callsign: 'AEROEJECUTIVOS',
  },
  {
    code: 'AJT',
    callsign: 'AMERIJET',
  },
  {
    code: 'AJU',
    callsign: 'AIRJETSUL',
  },
  {
    code: 'AJV',
    callsign: 'LEE JET',
  },
  {
    code: 'AJW',
    callsign: 'ALPHAJET',
  },
  {
    code: 'AJX',
    callsign: 'AIR JAPAN',
  },
  {
    code: 'AJY',
    callsign: 'AJWAA AIRLINES',
  },
  {
    code: 'AJZ',
    callsign: 'ANGLO JET',
  },
  {
    code: 'AKC',
    callsign: 'ASIALINK CARGO',
  },
  {
    code: 'AKG',
    callsign: 'GRIFTER',
  },
  {
    code: 'AKH',
    callsign: 'AKHAL',
  },
  {
    code: 'AKK',
    callsign: 'AKER',
  },
  {
    code: 'AKL',
    callsign: 'AIR KIRIBATI',
  },
  {
    code: 'AKN',
    callsign: 'ALKAN AIR',
  },
  {
    code: 'AKP',
    callsign: 'ASKARI AIR',
  },
  {
    code: 'AKQ',
    callsign: 'MOONLIGHT',
  },
  {
    code: 'AKS',
    callsign: 'AKEL',
  },
  {
    code: 'AKT',
    callsign: 'ARCTIC',
  },
  {
    code: 'AKW',
    callsign: 'ANGKORWAYS',
  },
  {
    code: 'AKX',
    callsign: 'ALFA WING',
  },
  {
    code: 'AKY',
    callsign: 'YAK-SERVICE',
  },
  {
    code: 'ALB',
    callsign: 'ALBATROS',
  },
  {
    code: 'ALC',
    callsign: 'ACOM',
  },
  {
    code: 'ASH',
    callsign: 'AIR SHUTTLE',
  },
  {
    code: 'ASI',
    callsign: 'AEROSUN',
  },
  {
    code: 'ASJ',
    callsign: 'ASTONJET',
  },
  {
    code: 'ASK',
    callsign: 'MULTISKY',
  },
  {
    code: 'ASL',
    callsign: 'AIR SERBIA',
  },
  {
    code: 'ASM',
    callsign: 'AWESOME',
  },
  {
    code: 'ASN',
    callsign: 'AIRSENSING',
  },
  {
    code: 'ASO',
    callsign: 'AEROSUL',
  },
  {
    code: 'ASP',
    callsign: 'AIRSPRINT',
  },
  {
    code: 'ASQ',
    callsign: 'ACEY',
  },
  {
    code: 'ASR',
    callsign: 'SOTRAVIA',
  },
  {
    code: 'AST',
    callsign: 'THAI SUMMER',
  },
  {
    code: 'ASU',
    callsign: 'ASUR',
  },
  {
    code: 'ASV',
    callsign: 'AIR SEOUL',
  },
  {
    code: 'ASW',
    callsign: 'ASIAWAYS',
  },
  {
    code: 'ASX',
    callsign: 'AIRSPEC',
  },
  {
    code: 'ASY',
    callsign: 'AUSSIE',
  },
  {
    code: 'ASZ',
    callsign: 'ASCENSION',
  },
  {
    code: 'ATB',
    callsign: 'TRANS-BURUNDI',
  },
  {
    code: 'ATC',
    callsign: 'TANZANIA',
  },
  {
    code: 'ATF',
    callsign: 'AEROTECNICAS',
  },
  {
    code: 'ATG',
    callsign: 'MOLDCARGO',
  },
  {
    code: 'ATH',
    callsign: 'GLOBESTAR',
  },
  {
    code: 'ATI',
    callsign: 'ART AVIA',
  },
  {
    code: 'ATK',
    callsign: 'AEROTACA',
  },
  {
    code: 'ATL',
    callsign: 'AIR BREMEN',
  },
  {
    code: 'ATM',
    callsign: 'AIRTAS',
  },
  {
    code: 'ATN',
    callsign: 'AIR TRANSPORT',
  },
  {
    code: 'ATO',
    callsign: 'SCHOOL FLIGHT',
  },
  {
    code: 'ATP',
    callsign: 'ALTIPLANO',
  },
  {
    code: 'ATQ',
    callsign: 'COLIBRI',
  },
  {
    code: 'ATR',
    callsign: 'ATRAK AIR',
  },
  {
    code: 'ATS',
    callsign: 'AIR ATLAS',
  },
  {
    code: 'ATU',
    callsign: 'BERLIN SKY',
  },
  {
    code: 'ATV',
    callsign: 'AVANTI AIR',
  },
  {
    code: 'ATW',
    callsign: 'DEVIL',
  },
  {
    code: 'ATX',
    callsign: 'AIRSWIFT',
  },
  {
    code: 'ATY',
    callsign: 'AIRCARE',
  },
  {
    code: 'ATZ',
    callsign: 'ACE TAXI',
  },
  {
    code: 'AUA',
    callsign: 'AUSTRIAN',
  },
  {
    code: 'AZD',
    callsign: 'MAILMAN',
  },
  {
    code: 'AZE',
    callsign: 'ARCUS AIR',
  },
  {
    code: 'AZF',
    callsign: 'AIR ZERMATT',
  },
  {
    code: 'AZG',
    callsign: 'SILK WEST',
  },
  {
    code: 'AZH',
    callsign: 'HELIAZE',
  },
  {
    code: 'AZI',
    callsign: 'GREEK STAR',
  },
  {
    code: 'AZM',
    callsign: 'AZMAN AIR',
  },
  {
    code: 'AZN',
    callsign: 'AMAZONAS',
  },
  {
    code: 'AZO',
    callsign: 'AZIMUTH-DON',
  },
  {
    code: 'AZP',
    callsign: 'GUARANI',
  },
  {
    code: 'AZQ',
    callsign: 'SILK LINE',
  },
  {
    code: 'AZR',
    callsign: 'ZENAIR',
  },
  {
    code: 'AZS',
    callsign: 'ZITOTRANS',
  },
  {
    code: 'AZT',
    callsign: 'AZIMUT',
  },
  {
    code: 'AZU',
    callsign: 'AZUL',
  },
  {
    code: 'AZW',
    callsign: 'AIR ZIMBABWE',
  },
  {
    code: 'AZX',
    callsign: 'AZIMA',
  },
  {
    code: 'AZY',
    callsign: 'AZTEC WORLD',
  },
  {
    code: 'AZZ',
    callsign: 'AZZA TRANSPORT',
  },
  {
    code: 'BAA',
    callsign: 'BALKAN AGRO',
  },
  {
    code: 'BAB',
    callsign: 'BAHRAIN AIR',
  },
  {
    code: 'BAD',
    callsign: 'BADLANDS',
  },
  {
    code: 'BAE',
    callsign: 'FELIX',
  },
  {
    code: 'BAF',
    callsign: 'BELGIAN AIRFORCE',
  },
  {
    code: 'BAH',
    callsign: 'BAHRAIN',
  },
  {
    code: 'BAI',
    callsign: 'BONITO AIR',
  },
  {
    code: 'BAJ',
    callsign: 'BAKER AVIATION',
  },
  {
    code: 'BAK',
    callsign: 'BLACKHAWK',
  },
  {
    code: 'BAL',
    callsign: 'BALMORAL',
  },
  {
    code: 'BAN',
    callsign: 'PENGUIN',
  },
  {
    code: 'BAO',
    callsign: 'AVANGARD',
  },
  {
    code: 'BAP',
    callsign: 'BIG APPLE',
  },
  {
    code: 'BAQ',
    callsign: 'LAT CARGO',
  },
  {
    code: 'BAR',
    callsign: 'BARAKAH',
  },
  {
    code: 'BAS',
    callsign: 'AEROSERV',
  },
  {
    code: 'BAT',
    callsign: 'GHERKIN',
  },
  {
    code: 'BAV',
    callsign: 'BAMBOO',
  },
  {
    code: 'BAW',
    callsign: 'SPEEDBIRD',
  },
  {
    code: 'BBA',
    callsign: 'BOMBARDIER',
  },
  {
    code: 'BBB',
    callsign: 'BLACKBIRD',
  },
  {
    code: 'BLB',
    callsign: 'BLUEBIRD SUDAN',
  },
  {
    code: 'BLC',
    callsign: 'BELLESAVIA',
  },
  {
    code: 'BLD',
    callsign: 'BLACKADDER',
  },
  {
    code: 'BLE',
    callsign: 'BLUEWATER',
  },
  {
    code: 'BLG',
    callsign: 'BELGAVIA',
  },
  {
    code: 'BLH',
    callsign: 'BLUE HORIZON',
  },
  {
    code: 'BLI',
    callsign: 'BLUELINE',
  },
  {
    code: 'BLJ',
    callsign: 'BLUEJET',
  },
  {
    code: 'BLK',
    callsign: 'AERO BLACK',
  },
  {
    code: 'BLL',
    callsign: 'BALTIC AIRLINES',
  },
  {
    code: 'BLM',
    callsign: 'HELI PER',
  },
  {
    code: 'BLN',
    callsign: 'BELTON',
  },
  {
    code: 'BLO',
    callsign: 'BELOGORIE',
  },
  {
    code: 'BLR',
    callsign: 'BLUERIDGE',
  },
  {
    code: 'BLS',
    callsign: 'BEARSKIN',
  },
  {
    code: 'BLT',
    callsign: 'BLUE TIE',
  },
  {
    code: 'BLU',
    callsign: 'BLUEGRASS',
  },
  {
    code: 'BLV',
    callsign: 'BELLVIEW AIRLINES',
  },
  {
    code: 'BLW',
    callsign: 'BLUESTAR',
  },
  {
    code: 'BLX',
    callsign: 'BLUESCAN',
  },
  {
    code: 'BLY',
    callsign: 'BLUE AERO',
  },
  {
    code: 'BLZ',
    callsign: 'AEROLOZ',
  },
  {
    code: 'BMD',
    callsign: 'BRITISH MEDICAL',
  },
  {
    code: 'BME',
    callsign: 'BRIGGS',
  },
  {
    code: 'BMG',
    callsign: 'BOOMERANG',
  },
  {
    code: 'BMJ',
    callsign: 'BEMIDJI',
  },
  {
    code: 'BML',
    callsign: 'BISMILLAH',
  },
  {
    code: 'BMP',
    callsign: 'AIR BAMA',
  },
  {
    code: 'BMR',
    callsign: 'MIDLAND',
  },
  {
    code: 'BMT',
    callsign: 'SYMONETTE',
  },
  {
    code: 'BMW',
    callsign: 'BMW-FLIGHT',
  },
  {
    code: 'BMX',
    callsign: 'BANXICO',
  },
  {
    code: 'BNA',
    callsign: 'BUN AIR',
  },
  {
    code: 'BNB',
    callsign: 'DAZZLE',
  },
  {
    code: 'BNC',
    callsign: 'BARNACLE AIR',
  },
  {
    code: 'BND',
    callsign: 'BOND',
  },
  {
    code: 'BNF',
    callsign: 'BRUNEL',
  },
  {
    code: 'BNG',
    callsign: 'VECTIS',
  },
  {
    code: 'BNH',
    callsign: 'BAONENG JET',
  },
  {
    code: 'BNI',
    callsign: 'BARTOLINI',
  },
  {
    code: 'BNJ',
    callsign: 'JET BELGIUM',
  },
  {
    code: 'BNL',
    callsign: 'MANARA',
  },
  {
    code: 'CAR',
    callsign: 'QUEBEC ROMEO',
  },
  {
    code: 'CAS',
    callsign: 'CHRISTMAN',
  },
  {
    code: 'CAT',
    callsign: 'AIRCAT',
  },
  {
    code: 'CAU',
    callsign: 'CANARIAS LINK',
  },
  {
    code: 'CAV',
    callsign: 'CALM AIR',
  },
  {
    code: 'CAW',
    callsign: 'COMMERCIAL',
  },
  {
    code: 'CAX',
    callsign: 'CONSORCIO',
  },
  {
    code: 'CAY',
    callsign: 'CAYMAN',
  },
  {
    code: 'CAZ',
    callsign: 'EUROCAT',
  },
  {
    code: 'CBA',
    callsign: 'CALIBRA',
  },
  {
    code: 'CBB',
    callsign: 'CARGO-BEE',
  },
  {
    code: 'CBD',
    callsign: 'CATBIRD',
  },
  {
    code: 'CBF',
    callsign: 'BIGFOOT',
  },
  {
    code: 'CBG',
    callsign: 'GREEN CITY',
  },
  {
    code: 'CBH',
    callsign: 'CLUB HOUSE',
  },
  {
    code: 'CBJ',
    callsign: 'CAPITAL JET',
  },
  {
    code: 'CBL',
    callsign: 'COLOMBE LINE',
  },
  {
    code: 'CBM',
    callsign: 'CAMBRIAN',
  },
  {
    code: 'CBN',
    callsign: 'CARBONDALE',
  },
  {
    code: 'CBO',
    callsign: 'TAXI CABO',
  },
  {
    code: 'CBR',
    callsign: 'STRIKE',
  },
  {
    code: 'CBS',
    callsign: 'AIR COLUMBUS',
  },
  {
    code: 'CBT',
    callsign: 'CATALINA AIR',
  },
  {
    code: 'CBU',
    callsign: 'SCANAIR',
  },
  {
    code: 'CBV',
    callsign: 'CABOAEREO',
  },
  {
    code: 'CBW',
    callsign: 'SUPERWINGS',
  },
  {
    code: 'CBX',
    callsign: 'COBREX',
  },
  {
    code: 'CBY',
    callsign: 'TYPHOON',
  },
  {
    code: 'CBZ',
    callsign: 'COOL BREEZE',
  },
  {
    code: 'CCA',
    callsign: 'AIR CHINA',
  },
  {
    code: 'CCB',
    callsign: 'DOLPHIN',
  },
  {
    code: 'CCC',
    callsign: 'GLOBAL CARGO',
  },
  {
    code: 'CCD',
    callsign: 'XIANGJIAN',
  },
  {
    code: 'CCH',
    callsign: 'CHILCHOTA',
  },
  {
    code: 'CCI',
    callsign: 'KUNGFU',
  },
  {
    code: 'CCL',
    callsign: 'ANGKOR WAT',
  },
  {
    code: 'CCM',
    callsign: 'CORSICA',
  },
  {
    code: 'CCO',
    callsign: 'AVIC CARGO',
  },
  {
    code: 'CCP',
    callsign: 'CHAMPION AIR',
  },
  {
    code: 'CCQ',
    callsign: 'CAP CITY',
  },
  {
    code: 'CCS',
    callsign: 'COMAC EXPRESS',
  },
  {
    code: 'CKM',
    callsign: 'RIVAFLECHA',
  },
  {
    code: 'CKR',
    callsign: 'CROWN AIR',
  },
  {
    code: 'CKS',
    callsign: 'CONNIE',
  },
  {
    code: 'CLB',
    callsign: 'CALIBRATOR',
  },
  {
    code: 'CLC',
    callsign: 'MOTHERSHIP',
  },
  {
    code: 'CLD',
    callsign: 'CLOWES',
  },
  {
    code: 'CLE',
    callsign: 'COLEMILL',
  },
  {
    code: 'CLF',
    callsign: 'CLIFTON',
  },
  {
    code: 'CLG',
    callsign: 'CHALLAIR',
  },
  {
    code: 'CLH',
    callsign: 'HANSALINE',
  },
  {
    code: 'CLI',
    callsign: 'CLICKJET',
  },
  {
    code: 'CLJ',
    callsign: 'CELLOJET',
  },
  {
    code: 'CLK',
    callsign: 'CLARKAIR',
  },
  {
    code: 'CLL',
    callsign: 'AEROCASTILLO',
  },
  {
    code: 'CLM',
    callsign: 'CARGO LINK',
  },
  {
    code: 'CLN',
    callsign: 'CLEOPATRA',
  },
  {
    code: 'CLP',
    callsign: 'CLUB PORTUGAL',
  },
  {
    code: 'CLR',
    callsign: 'CLINTON AIRWAYS',
  },
  {
    code: 'CLU',
    callsign: 'FIREBIRD',
  },
  {
    code: 'CLV',
    callsign: 'AEROTRAINING',
  },
  {
    code: 'CLW',
    callsign: 'CENTRALWINGS',
  },
  {
    code: 'CLX',
    callsign: 'CARGOLUX',
  },
  {
    code: 'CLY',
    callsign: 'CLAY-LACY',
  },
  {
    code: 'CLZ',
    callsign: 'CLOUDLINE',
  },
  {
    code: 'CMB',
    callsign: 'CAMBER',
  },
  {
    code: 'CMC',
    callsign: 'ESTERLINE',
  },
  {
    code: 'CMD',
    callsign: 'CALSTAR MED',
  },
  {
    code: 'CME',
    callsign: 'COMMERCE BANK',
  },
  {
    code: 'CMF',
    callsign: 'COMPASSION',
  },
  {
    code: 'CMG',
    callsign: 'SUNSPY',
  },
  {
    code: 'CMH',
    callsign: 'COMMODORE',
  },
  {
    code: 'CMI',
    callsign: 'AIR MIKE',
  },
  {
    code: 'CMN',
    callsign: 'CIMMARON AIRE',
  },
  {
    code: 'CMO',
    callsign: 'CAMELOT',
  },
  {
    code: 'CMP',
    callsign: 'COPA',
  },
  {
    code: 'CMR',
    callsign: 'CAMEO',
  },
  {
    code: 'CMT',
    callsign: 'CASEMENT',
  },
  {
    code: 'CMV',
    callsign: 'CALIMA',
  },
  {
    code: 'CMX',
    callsign: 'EL CAMINANTE',
  },
  {
    code: 'CMZ',
    callsign: 'CEE-EM STAIRS',
  },
  {
    code: 'CNA',
    callsign: 'CANAVIA',
  },
  {
    code: 'CVE',
    callsign: 'KABEX',
  },
  {
    code: 'CVF',
    callsign: 'CLOVERLEAF',
  },
  {
    code: 'CVJ',
    callsign: 'COMEREJECUTIVA',
  },
  {
    code: 'CVK',
    callsign: 'CARGO LINE',
  },
  {
    code: 'CVO',
    callsign: 'CENTERVOL',
  },
  {
    code: 'CVR',
    callsign: 'CHEVRON',
  },
  {
    code: 'CVS',
    callsign: 'CONVERS',
  },
  {
    code: 'CVU',
    callsign: 'CANYON VIEW',
  },
  {
    code: 'CVV',
    callsign: 'COMERAVIA',
  },
  {
    code: 'CVX',
    callsign: 'CIACA',
  },
  {
    code: 'CVZ',
    callsign: 'CARIBE',
  },
  {
    code: 'CWA',
    callsign: 'CAN WEST',
  },
  {
    code: 'CWC',
    callsign: 'CHALLENGE CARGO',
  },
  {
    code: 'CWG',
    callsign: 'CLEAR WING',
  },
  {
    code: 'CWH',
    callsign: 'WARPLANE HERITAGE',
  },
  {
    code: 'CWL',
    callsign: 'CRANWELL',
  },
  {
    code: 'CWM',
    callsign: 'AIR MARSHALLS',
  },
  {
    code: 'CWN',
    callsign: 'CROWN AIRLINES',
  },
  {
    code: 'CWR',
    callsign: 'CITY WORLD',
  },
  {
    code: 'CWS',
    callsign: 'CRETAN',
  },
  {
    code: 'CWX',
    callsign: 'CROW EXPRESS',
  },
  {
    code: 'CWY',
    callsign: 'CAUSEWAY',
  },
  {
    code: 'CWZ',
    callsign: 'CAPWINGS',
  },
  {
    code: 'CXA',
    callsign: 'XIAMEN AIR',
  },
  {
    code: 'CXB',
    callsign: 'STARLUX',
  },
  {
    code: 'AGM',
    callsign: 'ANGEL MED',
  },
  {
    code: 'AGN',
    callsign: 'GOLF NOVEMBER',
  },
  {
    code: 'AGO',
    callsign: 'ANGOLA CHARTER',
  },
  {
    code: 'AGP',
    callsign: 'AIR TARA',
  },
  {
    code: 'AGT',
    callsign: 'AMADEUS',
  },
  {
    code: 'AGU',
    callsign: 'SARMA',
  },
  {
    code: 'AGV',
    callsign: 'AIR GLACIERS',
  },
  {
    code: 'AGY',
    callsign: 'EGYPTIAN BIRD',
  },
  {
    code: 'AHA',
    callsign: 'AIR ALPHA',
  },
  {
    code: 'AHB',
    callsign: 'ALHAMBRA',
  },
  {
    code: 'AHD',
    callsign: 'AIRHANDLING',
  },
  {
    code: 'AHE',
    callsign: 'AIRPORT HELICOPTER',
  },
  {
    code: 'AHF',
    callsign: 'ASPEN',
  },
  {
    code: 'AHH',
    callsign: 'AIRHOLD',
  },
  {
    code: 'AHI',
    callsign: 'AEROCHISA',
  },
  {
    code: 'AHJ',
    callsign: 'AVYCOP',
  },
  {
    code: 'AHK',
    callsign: 'AIR HONG KONG',
  },
  {
    code: 'AHL',
    callsign: 'HIDALGO',
  },
  {
    code: 'AHM',
    callsign: 'HELI ATLANTIS',
  },
  {
    code: 'AHO',
    callsign: 'AIR HAMBURG',
  },
  {
    code: 'AHS',
    callsign: 'HIGH SKY',
  },
  {
    code: 'AHT',
    callsign: 'HELIAPRA',
  },
  {
    code: 'AHU',
    callsign: 'ABC HUNGARY',
  },
  {
    code: 'AHW',
    callsign: 'SKY CAPITAL',
  },
  {
    code: 'AHX',
    callsign: 'AMAKUSA AIR',
  },
  {
    code: 'AHY',
    callsign: 'AZAL',
  },
  {
    code: 'AIA',
    callsign: 'AMELIA',
  },
  {
    code: 'AIB',
    callsign: 'AIRBUS INDUSTRIE',
  },
  {
    code: 'AIC',
    callsign: 'AIRINDIA',
  },
  {
    code: 'AIE',
    callsign: 'INUIT',
  },
  {
    code: 'AIH',
    callsign: 'AIR INCHEON',
  },
  {
    code: 'AIJ',
    callsign: 'ABC AEROLINEAS',
  },
  {
    code: 'AIK',
    callsign: 'AFRICAN AIRLINES',
  },
  {
    code: 'AIM',
    callsign: 'PIJO',
  },
  {
    code: 'AIN',
    callsign: 'FLY CARGO',
  },
  {
    code: 'AIO',
    callsign: 'AIR CHIEF',
  },
  {
    code: 'AIP',
    callsign: 'ALPINE AIR',
  },
  {
    code: 'AIQ',
    callsign: 'THAI ASIA',
  },
  {
    code: 'AIR',
    callsign: 'AIRLIFT',
  },
  {
    code: 'AIS',
    callsign: 'SURESTE',
  },
  {
    code: 'AIT',
    callsign: 'AIR TAURUS',
  },
  {
    code: 'ANA',
    callsign: 'ALL NIPPON',
  },
  {
    code: 'ANB',
    callsign: 'AIR NAV',
  },
  {
    code: 'ANC',
    callsign: 'AVIAN',
  },
  {
    code: 'AND',
    callsign: 'SERVI ANDES',
  },
  {
    code: 'ANE',
    callsign: 'NOSTRU AIR',
  },
  {
    code: 'ANG',
    callsign: 'NIUGINI',
  },
  {
    code: 'ANI',
    callsign: 'NIGALANTIC',
  },
  {
    code: 'ANJ',
    callsign: 'AIR ALLEGIANCE',
  },
  {
    code: 'ANK',
    callsign: 'AERO NOMAD',
  },
  {
    code: 'ANL',
    callsign: 'AIR NACOIA',
  },
  {
    code: 'ANM',
    callsign: 'NORAM',
  },
  {
    code: 'ANO',
    callsign: 'TOPEND',
  },
  {
    code: 'ANP',
    callsign: 'NICON FLIERS',
  },
  {
    code: 'ANQ',
    callsign: 'ANTIOQUIA',
  },
  {
    code: 'ANR',
    callsign: 'YANAIR',
  },
  {
    code: 'ANS',
    callsign: 'AEROANDES',
  },
  {
    code: 'ANT',
    callsign: 'AIR NORTH',
  },
  {
    code: 'ANU',
    callsign: 'ANDALUS',
  },
  {
    code: 'ANV',
    callsign: 'NESTAV',
  },
  {
    code: 'ANW',
    callsign: 'ANGEL WINGS',
  },
  {
    code: 'ANX',
    callsign: 'SECRETARIA DEMARINA',
  },
  {
    code: 'ANZ',
    callsign: 'NEW ZEALAND',
  },
  {
    code: 'AOA',
    callsign: 'AIR OCEAN',
  },
  {
    code: 'AOB',
    callsign: 'CARIBE CORO',
  },
  {
    code: 'AOC',
    callsign: 'ARAMIS',
  },
  {
    code: 'AOD',
    callsign: 'AERO CZECH',
  },
  {
    code: 'AOF',
    callsign: 'ATAIR',
  },
  {
    code: 'AOJ',
    callsign: 'ASTERIX',
  },
  {
    code: 'AON',
    callsign: 'AERO ENTREPRISE',
  },
  {
    code: 'AOR',
    callsign: 'INTER-AFRO',
  },
  {
    code: 'AOS',
    callsign: 'AEROS',
  },
  {
    code: 'AOU',
    callsign: 'AIR TRACTOR',
  },
  {
    code: 'AOV',
    callsign: 'AEROVISION',
  },
  {
    code: 'APA',
    callsign: 'APOYO LOG',
  },
  {
    code: 'APC',
    callsign: 'AIRPAC',
  },
  {
    code: 'APE',
    callsign: 'AIR PARCEL',
  },
  {
    code: 'APF',
    callsign: 'AMAPOLA',
  },
  {
    code: 'APG',
    callsign: 'COOL RED',
  },
  {
    code: 'APH',
    callsign: 'AIRFLIGHT',
  },
  {
    code: 'API',
    callsign: 'ASA PESADA',
  },
  {
    code: 'AWG',
    callsign: 'ANIMA WINGS',
  },
  {
    code: 'AWH',
    callsign: 'AEROWEST',
  },
  {
    code: 'AWI',
    callsign: 'WISCONSIN',
  },
  {
    code: 'AWJ',
    callsign: 'SAHEL AIRLINES',
  },
  {
    code: 'AWK',
    callsign: 'AIRWORK',
  },
  {
    code: 'AWM',
    callsign: 'ASIAN STAR',
  },
  {
    code: 'AWN',
    callsign: 'AIR NIAMEY',
  },
  {
    code: 'AWQ',
    callsign: 'WAGON AIR',
  },
  {
    code: 'AWS',
    callsign: 'ARAB WINGS',
  },
  {
    code: 'AWT',
    callsign: 'ALBAWINGS',
  },
  {
    code: 'AWU',
    callsign: 'SYLT AIR',
  },
  {
    code: 'AWW',
    callsign: 'AWA',
  },
  {
    code: 'AWX',
    callsign: 'ALLWEATHER',
  },
  {
    code: 'AWY',
    callsign: 'RED KITE',
  },
  {
    code: 'AXA',
    callsign: 'AMIRA',
  },
  {
    code: 'AXB',
    callsign: 'EXPRESS INDIA',
  },
  {
    code: 'AXC',
    callsign: 'AIRSPUP',
  },
  {
    code: 'AXD',
    callsign: 'AIR SUDEX',
  },
  {
    code: 'AXE',
    callsign: 'GALILEO',
  },
  {
    code: 'AXG',
    callsign: 'FORTUNE',
  },
  {
    code: 'AXK',
    callsign: 'EXPRESS JET',
  },
  {
    code: 'AXL',
    callsign: 'ANGUILLA',
  },
  {
    code: 'AXM',
    callsign: 'RED CAP',
  },
  {
    code: 'AXP',
    callsign: 'AEROMAX SPAIN',
  },
  {
    code: 'AXQ',
    callsign: 'ACTION AIR',
  },
  {
    code: 'AXT',
    callsign: 'AXTRANSPORTER',
  },
  {
    code: 'AXY',
    callsign: 'LEGEND',
  },
  {
    code: 'AYA',
    callsign: 'ACADEMY AIR',
  },
  {
    code: 'AYE',
    callsign: 'AIR YING AN',
  },
  {
    code: 'AYH',
    callsign: 'ANDES',
  },
  {
    code: 'AYJ',
    callsign: 'ALLEY JET',
  },
  {
    code: 'AYL',
    callsign: 'ALPINE NEPAL',
  },
  {
    code: 'AYM',
    callsign: 'AIRMAN',
  },
  {
    code: 'AYR',
    callsign: 'CYGNET',
  },
  {
    code: 'AYT',
    callsign: 'AYIT',
  },
  {
    code: 'AYV',
    callsign: 'CARGWAY',
  },
  {
    code: 'AYY',
    callsign: 'LUPUS',
  },
  {
    code: 'AZA',
    callsign: 'ALITALIA',
  },
  {
    code: 'AZB',
    callsign: 'AIR ZAMBIA',
  },
  {
    code: 'BHO',
    callsign: 'BHOJA',
  },
  {
    code: 'BHR',
    callsign: 'BIGHORN AIR',
  },
  {
    code: 'BHS',
    callsign: 'BAHAMAS',
  },
  {
    code: 'BHZ',
    callsign: 'ALPINE STAR',
  },
  {
    code: 'BIA',
    callsign: 'BOHLKE',
  },
  {
    code: 'BIC',
    callsign: 'BALESIN',
  },
  {
    code: 'BIG',
    callsign: 'BIG ISLE',
  },
  {
    code: 'BIH',
    callsign: 'BRINTEL',
  },
  {
    code: 'BIL',
    callsign: 'BILAIR',
  },
  {
    code: 'BIN',
    callsign: 'BISON-AIR',
  },
  {
    code: 'BIO',
    callsign: 'BIOFLIGHT',
  },
  {
    code: 'BIR',
    callsign: 'BIRD AIR',
  },
  {
    code: 'BIT',
    callsign: 'RUN BITTY',
  },
  {
    code: 'BIV',
    callsign: 'AVIASERVICE',
  },
  {
    code: 'BIZ',
    callsign: 'BIZMEX',
  },
  {
    code: 'BJA',
    callsign: 'BAJA AIR',
  },
  {
    code: 'BJC',
    callsign: 'BALTIC JET',
  },
  {
    code: 'BJF',
    callsign: 'BAXA',
  },
  {
    code: 'BJK',
    callsign: 'BLACKJACK',
  },
  {
    code: 'BJN',
    callsign: 'AIR JINGHUA',
  },
  {
    code: 'BJS',
    callsign: 'SOLUTION',
  },
  {
    code: 'BJT',
    callsign: 'BAY JET',
  },
  {
    code: 'BJU',
    callsign: 'JET EXPRESS',
  },
  {
    code: 'BJV',
    callsign: 'BEIJING VISTA',
  },
  {
    code: 'BKA',
    callsign: 'BANKAIR',
  },
  {
    code: 'BKB',
    callsign: 'BLACK BEAR',
  },
  {
    code: 'BKE',
    callsign: 'AIR BROCK',
  },
  {
    code: 'BKF',
    callsign: 'BAKERFLIGHT',
  },
  {
    code: 'BKH',
    callsign: 'BARKSTON',
  },
  {
    code: 'BKJ',
    callsign: 'BARKEN JET',
  },
  {
    code: 'BKK',
    callsign: 'BLINKAIR',
  },
  {
    code: 'BKL',
    callsign: 'BARCOL',
  },
  {
    code: 'BKM',
    callsign: 'CARDINAL',
  },
  {
    code: 'BKN',
    callsign: 'BILLIKEN',
  },
  {
    code: 'BKO',
    callsign: 'BRIKO',
  },
  {
    code: 'BKP',
    callsign: 'BANGKOK AIR',
  },
  {
    code: 'BKR',
    callsign: 'BOX KAR',
  },
  {
    code: 'BKS',
    callsign: 'BERKS',
  },
  {
    code: 'BKT',
    callsign: 'BLACKTAIL',
  },
  {
    code: 'BKV',
    callsign: 'BUKOVYNA',
  },
  {
    code: 'BKY',
    callsign: 'BLUE AIRWAYS',
  },
  {
    code: 'BLA',
    callsign: 'BLUE AIR',
  },
  {
    code: 'BWA',
    callsign: 'CARIBBEAN AIRLINES',
  },
  {
    code: 'BWD',
    callsign: 'BLUEWEST',
  },
  {
    code: 'BWI',
    callsign: 'BLUE TAIL',
  },
  {
    code: 'BWJ',
    callsign: 'BELLAWING',
  },
  {
    code: 'BWK',
    callsign: 'ALTERAERO',
  },
  {
    code: 'BWM',
    callsign: 'BEIRUT WINGS',
  },
  {
    code: 'BWS',
    callsign: 'BASS EXEC',
  },
  {
    code: 'BWY',
    callsign: 'BROADWAY',
  },
  {
    code: 'BXA',
    callsign: 'BEXAIR',
  },
  {
    code: 'BXH',
    callsign: 'PALLISER',
  },
  {
    code: 'BXJ',
    callsign: 'BRIXTEL JET',
  },
  {
    code: 'BXR',
    callsign: 'BOXER',
  },
  {
    code: 'BXX',
    callsign: 'STRONGBOX',
  },
  {
    code: 'BYA',
    callsign: 'BERRY',
  },
  {
    code: 'BYC',
    callsign: 'BAYON AIR',
  },
  {
    code: 'BYF',
    callsign: 'BAY FLIGHT',
  },
  {
    code: 'BYG',
    callsign: 'BYGONE',
  },
  {
    code: 'BYS',
    callsign: 'BEL SKY',
  },
  {
    code: 'BZA',
    callsign: 'BONAN AIR',
  },
  {
    code: 'BZE',
    callsign: 'ZENSTAR',
  },
  {
    code: 'BZQ',
    callsign: 'STING',
  },
  {
    code: 'BZS',
    callsign: 'BINIZA',
  },
  {
    code: 'BZY',
    callsign: 'BREEZY',
  },
  {
    code: 'BZZ',
    callsign: 'BUZZARD',
  },
  {
    code: 'CAA',
    callsign: 'INSPECTOR',
  },
  {
    code: 'CAB',
    callsign: 'CHESAPEAKE AIR',
  },
  {
    code: 'CAC',
    callsign: 'CONQUEST AIR',
  },
  {
    code: 'CAD',
    callsign: 'CARDIG AIR',
  },
  {
    code: 'CAE',
    callsign: 'EXPRESS CENTRAF',
  },
  {
    code: 'CAH',
    callsign: 'SMARTAIR',
  },
  {
    code: 'CAI',
    callsign: 'CORENDON',
  },
  {
    code: 'CAJ',
    callsign: 'CAR LINE',
  },
  {
    code: 'CAK',
    callsign: 'CACTUS',
  },
  {
    code: 'CAL',
    callsign: 'DYNASTY',
  },
  {
    code: 'CAM',
    callsign: 'AIR CAMAI',
  },
  {
    code: 'CAN',
    callsign: 'GOBER AIR',
  },
  {
    code: 'CAO',
    callsign: 'AIRCHINA FREIGHT',
  },
  {
    code: 'CAP',
    callsign: 'CAP',
  },
  {
    code: 'CHK',
    callsign: 'CHALKS',
  },
  {
    code: 'CHL',
    callsign: 'ULTRA',
  },
  {
    code: 'CHN',
    callsign: 'CHANNEL',
  },
  {
    code: 'CHO',
    callsign: 'CHROME AIR',
  },
  {
    code: 'CHQ',
    callsign: 'CHAUTAUQUA',
  },
  {
    code: 'CHR',
    callsign: 'CHAIRMAN',
  },
  {
    code: 'CHU',
    callsign: 'CHURCHAIR',
  },
  {
    code: 'CHV',
    callsign: 'CHARTAIR',
  },
  {
    code: 'CHX',
    callsign: 'CHRISTOPH',
  },
  {
    code: 'CIA',
    callsign: 'CALIMERA',
  },
  {
    code: 'CIC',
    callsign: 'CANAIMA',
  },
  {
    code: 'CIE',
    callsign: 'CZECHIA',
  },
  {
    code: 'CIG',
    callsign: 'SIRIUS AERO',
  },
  {
    code: 'CIJ',
    callsign: 'NIKOLAUS',
  },
  {
    code: 'CIL',
    callsign: 'CIAF LEASING',
  },
  {
    code: 'CIM',
    callsign: 'CIMBER',
  },
  {
    code: 'CIN',
    callsign: 'CINNAMON',
  },
  {
    code: 'CIO',
    callsign: 'CIOCCO',
  },
  {
    code: 'CIR',
    callsign: 'AIR ARCTIC',
  },
  {
    code: 'CIS',
    callsign: 'CAT ISLAND',
  },
  {
    code: 'CIT',
    callsign: 'ZANE',
  },
  {
    code: 'CIV',
    callsign: 'CIVAIR',
  },
  {
    code: 'CIX',
    callsign: 'CONNEXION',
  },
  {
    code: 'CJC',
    callsign: 'COLGAN',
  },
  {
    code: 'CJE',
    callsign: 'CUSTOM JET',
  },
  {
    code: 'CJI',
    callsign: 'SEA JET',
  },
  {
    code: 'CJL',
    callsign: 'JETBUS',
  },
  {
    code: 'CJM',
    callsign: 'CHECKMARK',
  },
  {
    code: 'CJR',
    callsign: 'CAVERTON AIR',
  },
  {
    code: 'CJS',
    callsign: 'COMMONWEALTH',
  },
  {
    code: 'CJT',
    callsign: 'CARGOJET',
  },
  {
    code: 'CJX',
    callsign: 'AIR CRANE',
  },
  {
    code: 'CJZ',
    callsign: 'CALIBER JET',
  },
  {
    code: 'CKA',
    callsign: 'COOK-AIR',
  },
  {
    code: 'CKE',
    callsign: 'CHECKMATE',
  },
  {
    code: 'CKK',
    callsign: 'CARGO KING',
  },
  {
    code: 'CKL',
    callsign: 'SPACE',
  },
  {
    code: 'CSL',
    callsign: 'CALIFORNIA SHUTTLE',
  },
  {
    code: 'CSM',
    callsign: 'CSM SERVICIOS',
  },
  {
    code: 'CSN',
    callsign: 'CHINA SOUTHERN',
  },
  {
    code: 'CSO',
    callsign: 'CASAIR',
  },
  {
    code: 'CSP',
    callsign: 'CASPER AIR',
  },
  {
    code: 'CSQ',
    callsign: 'CHASQUI',
  },
  {
    code: 'CSS',
    callsign: 'SHUN FENG',
  },
  {
    code: 'CST',
    callsign: 'SHORELINE',
  },
  {
    code: 'CSV',
    callsign: 'COASTAL TRAVEL',
  },
  {
    code: 'CSX',
    callsign: 'CHOICE AIR',
  },
  {
    code: 'CSY',
    callsign: 'SHUANGYANG',
  },
  {
    code: 'CSZ',
    callsign: 'SHENZHEN AIR',
  },
  {
    code: 'CTA',
    callsign: 'CHAR-TRAN',
  },
  {
    code: 'CTB',
    callsign: 'SUNNYHEART',
  },
  {
    code: 'CTD',
    callsign: 'AEROCORPORATIVOS',
  },
  {
    code: 'CTE',
    callsign: 'CORNERSTONE',
  },
  {
    code: 'CTF',
    callsign: 'CUTTER FLIGHT',
  },
  {
    code: 'CTG',
    callsign: 'CANADIAN COAST GUARD',
  },
  {
    code: 'CTH',
    callsign: 'TONGHANG',
  },
  {
    code: 'CTJ',
    callsign: 'TIANJIN CARGO',
  },
  {
    code: 'CXE',
    callsign: 'CAICOS',
  },
  {
    code: 'CXF',
    callsign: 'CARDS FLIGHT',
  },
  {
    code: 'CXI',
    callsign: 'TOURISTIC',
  },
  {
    code: 'CXN',
    callsign: 'STRONG AIR',
  },
  {
    code: 'CXS',
    callsign: 'CLIPPER CONNECTION',
  },
  {
    code: 'CXT',
    callsign: 'COASTAL',
  },
  {
    code: 'CYA',
    callsign: 'CHEYENNE AIR',
  },
  {
    code: 'CYE',
    callsign: 'SAKIRA',
  },
  {
    code: 'CYF',
    callsign: 'TUS AIR',
  },
  {
    code: 'CYH',
    callsign: 'YUHAO',
  },
  {
    code: 'CYL',
    callsign: 'CITYLINER',
  },
  {
    code: 'CYN',
    callsign: 'COLOR JET',
  },
  {
    code: 'CYO',
    callsign: 'COYOTE',
  },
  {
    code: 'CYP',
    callsign: 'CYPRUS',
  },
  {
    code: 'CYT',
    callsign: 'CRYSTAL AIR',
  },
  {
    code: 'CYZ',
    callsign: 'CHINA POST',
  },
  {
    code: 'DOR',
    callsign: 'DORNIER',
  },
  {
    code: 'DOS',
    callsign: 'DOSAIR',
  },
  {
    code: 'DOV',
    callsign: 'DOVAIR',
  },
  {
    code: 'DOW',
    callsign: 'DOWNTOWN',
  },
  {
    code: 'DPJ',
    callsign: 'JET CARD',
  },
  {
    code: 'DPS',
    callsign: 'BLUE-GRAY',
  },
  {
    code: 'DQA',
    callsign: 'SKY SURFER',
  },
  {
    code: 'DRB',
    callsign: 'DIDIER',
  },
  {
    code: 'DRD',
    callsign: 'AEREO DORADO',
  },
  {
    code: 'DRE',
    callsign: 'MICHIGAN',
  },
  {
    code: 'DRK',
    callsign: 'ROYAL BHUTAN',
  },
  {
    code: 'DRL',
    callsign: 'DRILLER',
  },
  {
    code: 'DRM',
    callsign: 'DARTMOOR',
  },
  {
    code: 'DRO',
    callsign: 'ADRO SERVICIOS',
  },
  {
    code: 'DRS',
    callsign: 'DREAMSHORE',
  },
  {
    code: 'DRU',
    callsign: 'MIRNY',
  },
  {
    code: 'DRY',
    callsign: 'DERAYA',
  },
  {
    code: 'DRZ',
    callsign: 'DERAZONA',
  },
  {
    code: 'DSA',
    callsign: 'DANBURY AIRWAYS',
  },
  {
    code: 'DSM',
    callsign: 'LAN AR',
  },
  {
    code: 'DSO',
    callsign: 'DASSAULT',
  },
  {
    code: 'EFA',
    callsign: 'EXPRESS FREIGHT',
  },
  {
    code: 'EFB',
    callsign: 'EMERALD JETS',
  },
  {
    code: 'EFC',
    callsign: 'AL WASEL',
  },
  {
    code: 'EFD',
    callsign: 'EVER FLIGHT',
  },
  {
    code: 'EFE',
    callsign: 'EFREIGHT SUDAN',
  },
  {
    code: 'EFF',
    callsign: 'EMERALD',
  },
  {
    code: 'EFG',
    callsign: 'ELIFRIULIA',
  },
  {
    code: 'EFS',
    callsign: 'EFAOS',
  },
  {
    code: 'EFT',
    callsign: 'ZIPLINE',
  },
  {
    code: 'EFX',
    callsign: 'EASY EXPRESS',
  },
  {
    code: 'EGA',
    callsign: 'AIR EAGLE',
  },
  {
    code: 'EGC',
    callsign: 'EAGLE CREEK',
  },
  {
    code: 'EGL',
    callsign: 'PRESTIGE',
  },
  {
    code: 'EGM',
    callsign: 'EGAMS',
  },
  {
    code: 'EGO',
    callsign: 'GAUTENG',
  },
  {
    code: 'EGS',
    callsign: 'EVERGLADES',
  },
  {
    code: 'EGT',
    callsign: 'BULGARIAN JET',
  },
  {
    code: 'EGU',
    callsign: 'AFRICAN EAGLE',
  },
  {
    code: 'EGV',
    callsign: 'GLEISNER',
  },
  {
    code: 'EGW',
    callsign: 'EGOL',
  },
  {
    code: 'EHD',
    callsign: 'PLATINUM AIR',
  },
  {
    code: 'EHN',
    callsign: 'EAST HORIZON',
  },
  {
    code: 'EHR',
    callsign: 'ROTOR',
  },
  {
    code: 'EIA',
    callsign: 'EVERGREEN',
  },
  {
    code: 'EIC',
    callsign: 'EXCARGO',
  },
  {
    code: 'EIN',
    callsign: 'SHAMROCK',
  },
  {
    code: 'EIS',
    callsign: 'COOL',
  },
  {
    code: 'EJA',
    callsign: 'EXECJET',
  },
  {
    code: 'EJE',
    callsign: 'VOODOO',
  },
  {
    code: 'EJM',
    callsign: 'JET SPEED',
  },
  {
    code: 'EJO',
    callsign: 'MIDJET',
  },
  {
    code: 'EJP',
    callsign: 'EJECORPORATIVOS',
  },
  {
    code: 'EJS',
    callsign: 'EEJAY SERVICE',
  },
  {
    code: 'EJT',
    callsign: 'ECLIPSE JET',
  },
  {
    code: 'EJU',
    callsign: 'ALPINE',
  },
  {
    code: 'EJV',
    callsign: 'EJECUTIVA',
  },
  {
    code: 'ESF',
    callsign: 'ESTAFETA',
  },
  {
    code: 'ESM',
    callsign: 'EXEC AIRCRAFT',
  },
  {
    code: 'ESQ',
    callsign: 'EUROP STAR',
  },
  {
    code: 'ESR',
    callsign: 'EASTARJET',
  },
  {
    code: 'ESS',
    callsign: 'THAI STAR',
  },
  {
    code: 'EST',
    callsign: 'REVAL',
  },
  {
    code: 'ESW',
    callsign: 'W-BUSINESS',
  },
  {
    code: 'ESX',
    callsign: 'CATFISH',
  },
  {
    code: 'ESY',
    callsign: 'DONOSTI',
  },
  {
    code: 'ETA',
    callsign: 'TIME MACHINE',
  },
  {
    code: 'ETC',
    callsign: 'TRANATTICO',
  },
  {
    code: 'ETD',
    callsign: 'ETIHAD',
  },
  {
    code: 'ETE',
    callsign: 'SAELITE',
  },
  {
    code: 'ETH',
    callsign: 'ETHIOPIAN',
  },
  {
    code: 'ETI',
    callsign: 'JETHAWK',
  },
  {
    code: 'ETJ',
    callsign: 'EAST TAJIK',
  },
  {
    code: 'ETL',
    callsign: 'ENTEL',
  },
  {
    code: 'ETM',
    callsign: 'ETRAM',
  },
  {
    code: 'ETN',
    callsign: 'CHIMNIR',
  },
  {
    code: 'ETP',
    callsign: 'TESTER',
  },
  {
    code: 'ETR',
    callsign: 'ESTEL',
  },
  {
    code: 'ETS',
    callsign: 'ELTSOVKA',
  },
  {
    code: 'ETZ',
    callsign: 'AERO ZODIAC',
  },
  {
    code: 'EUK',
    callsign: 'GREEN FLIGHT',
  },
  {
    code: 'EUL',
    callsign: 'EUROLINK',
  },
  {
    code: 'EUP',
    callsign: 'SAVOY',
  },
  {
    code: 'EUS',
    callsign: 'EURUS',
  },
  {
    code: 'EUU',
    callsign: 'EUROAMERICAN',
  },
  {
    code: 'EUW',
    callsign: 'EUROWEST',
  },
  {
    code: 'EVA',
    callsign: 'EVA',
  },
  {
    code: 'EVE',
    callsign: 'EVELOP',
  },
  {
    code: 'EVJ',
    callsign: 'EVERJETS',
  },
  {
    code: 'EVK',
    callsign: 'EVERETT',
  },
  {
    code: 'EVL',
    callsign: 'EVOLEM',
  },
  {
    code: 'EVM',
    callsign: 'SCIENCE',
  },
  {
    code: 'EVQ',
    callsign: 'EVERETT AIR',
  },
  {
    code: 'EVR',
    callsign: 'DIANA',
  },
  {
    code: 'FLO',
    callsign: 'FLYCOM',
  },
  {
    code: 'FLP',
    callsign: 'PARAPRO',
  },
  {
    code: 'FLR',
    callsign: 'FLEETAIR',
  },
  {
    code: 'FLS',
    callsign: 'RAPIDOS',
  },
  {
    code: 'FLV',
    callsign: 'SKY RED',
  },
  {
    code: 'FLX',
    callsign: 'FLIGHT EXPRESS',
  },
  {
    code: 'FLZ',
    callsign: 'FLIGHTLINK',
  },
  {
    code: 'FMA',
    callsign: 'FAASA',
  },
  {
    code: 'FMF',
    callsign: 'EGYPT ACADEMY',
  },
  {
    code: 'FMG',
    callsign: 'HUSKY',
  },
  {
    code: 'FMI',
    callsign: 'GECKO',
  },
  {
    code: 'FML',
    callsign: 'EAGLE   BIRD',
  },
  {
    code: 'FMR',
    callsign: 'FLAMINGO AIR',
  },
  {
    code: 'FMS',
    callsign: 'HADI',
  },
  {
    code: 'FMX',
    callsign: 'FASTMEX',
  },
  {
    code: 'FMY',
    callsign: 'FRENCH ARMY',
  },
  {
    code: 'FNC',
    callsign: 'FINALAIR CONGO',
  },
  {
    code: 'FNF',
    callsign: 'FINNFORCE',
  },
  {
    code: 'FNG',
    callsign: 'FINNGUARD',
  },
  {
    code: 'FNK',
    callsign: 'FUNKY',
  },
  {
    code: 'FNL',
    callsign: 'FINN FLIGHT',
  },
  {
    code: 'FNO',
    callsign: 'RIAZOR',
  },
  {
    code: 'FNS',
    callsign: 'FINESSE',
  },
  {
    code: 'FNX',
    callsign: 'CRESCENT',
  },
  {
    code: 'FNY',
    callsign: 'FRENCH NAVY',
  },
  {
    code: 'FOA',
    callsign: 'FORTUNE AIR',
  },
  {
    code: 'FOC',
    callsign: 'FOCA',
  },
  {
    code: 'FOE',
    callsign: 'FIRST EAGLE',
  },
  {
    code: 'FOF',
    callsign: 'FOFA',
  },
  {
    code: 'FOH',
    callsign: 'ORION',
  },
  {
    code: 'FON',
    callsign: 'FLYINGTON',
  },
  {
    code: 'FOO',
    callsign: 'AUSTROJET',
  },
  {
    code: 'FOP',
    callsign: 'FOKKER SERVICES',
  },
  {
    code: 'FOR',
    callsign: 'FORMULA',
  },
  {
    code: 'FOT',
    callsign: 'AVIFOTO',
  },
  {
    code: 'FOX',
    callsign: 'GREENSTAR',
  },
  {
    code: 'FOZ',
    callsign: 'STARS AIRWAYS',
  },
  {
    code: 'GCF',
    callsign: 'AEROCARTO',
  },
  {
    code: 'GCI',
    callsign: 'ITALIAN COAST GUARD',
  },
  {
    code: 'GCK',
    callsign: 'GLOCK',
  },
  {
    code: 'GCL',
    callsign: 'SAXONIAN',
  },
  {
    code: 'GCM',
    callsign: 'GLOBECOM',
  },
  {
    code: 'GCN',
    callsign: 'GULF CENTRAL',
  },
  {
    code: 'GCR',
    callsign: 'BO HAI',
  },
  {
    code: 'GCS',
    callsign: 'SKY TEC',
  },
  {
    code: 'GCT',
    callsign: 'GULF COAST',
  },
  {
    code: 'GCW',
    callsign: 'GLOBALCREW',
  },
  {
    code: 'GCY',
    callsign: 'HELIBIRD',
  },
  {
    code: 'GDA',
    callsign: 'GLADIATOR',
  },
  {
    code: 'GDB',
    callsign: 'BELGIAN GENDARMERIE',
  },
  {
    code: 'GDC',
    callsign: 'GRAND CHINA',
  },
  {
    code: 'GDE',
    callsign: 'GREAT DANE',
  },
  {
    code: 'GDG',
    callsign: 'GOLDEN GATE',
  },
  {
    code: 'GDH',
    callsign: 'RISING SUN',
  },
  {
    code: 'GDK',
    callsign: 'GOLDECK FLUG',
  },
  {
    code: 'GDR',
    callsign: 'GADAIR LINES',
  },
  {
    code: 'GDY',
    callsign: 'GUARDIAN',
  },
  {
    code: 'GEC',
    callsign: 'LUFTHANSA CARGO',
  },
  {
    code: 'GEE',
    callsign: 'GEMEX',
  },
  {
    code: 'GEL',
    callsign: 'SKY GEORGIA',
  },
  {
    code: 'GEN',
    callsign: 'GENSA-BRASIL',
  },
  {
    code: 'GEO',
    callsign: 'APOLAX',
  },
  {
    code: 'GER',
    callsign: 'GERMAN EAGLE',
  },
  {
    code: 'GES',
    callsign: 'GESTAIR',
  },
  {
    code: 'GET',
    callsign: 'AIR FLOW',
  },
  {
    code: 'GEX',
    callsign: 'AERO GEORGIA',
  },
  {
    code: 'GFA',
    callsign: 'GULF AIR',
  },
  {
    code: 'GFC',
    callsign: 'GAIL FORCE',
  },
  {
    code: 'GFD',
    callsign: 'KITE',
  },
  {
    code: 'GFM',
    callsign: 'PARROT',
  },
  {
    code: 'GFN',
    callsign: 'GRIFFON',
  },
  {
    code: 'GFS',
    callsign: 'GULFSTAR',
  },
  {
    code: 'GFV',
    callsign: 'GRUPO- AFA',
  },
  {
    code: 'GGA',
    callsign: 'JAWJA',
  },
  {
    code: 'GGC',
    callsign: 'LONG-HAUL',
  },
  {
    code: 'GWY',
    callsign: 'GETAWAY',
  },
  {
    code: 'GWZ',
    callsign: 'SUN BURN',
  },
  {
    code: 'GXA',
    callsign: 'GEMINI',
  },
  {
    code: 'GYG',
    callsign: 'NATAKHTARI',
  },
  {
    code: 'GYP',
    callsign: 'GYPSY',
  },
  {
    code: 'GYR',
    callsign: 'GYROFER',
  },
  {
    code: 'GZP',
    callsign: 'GAZPROM',
  },
  {
    code: 'GZR',
    callsign: 'GEZIRA',
  },
  {
    code: 'HAA',
    callsign: 'AGROFORESTAL',
  },
  {
    code: 'HAC',
    callsign: 'HELI MOUNT',
  },
  {
    code: 'HAD',
    callsign: 'HAITI AVIA',
  },
  {
    code: 'HAE',
    callsign: 'HOLLAND AIR',
  },
  {
    code: 'HAF',
    callsign: 'HELLENIC AIR FORCE',
  },
  {
    code: 'HAG',
    callsign: 'HAGELAND',
  },
  {
    code: 'HAH',
    callsign: 'HANMA',
  },
  {
    code: 'HAJ',
    callsign: 'DINGCHANG',
  },
  {
    code: 'HAK',
    callsign: 'HELIFALCON',
  },
  {
    code: 'HAL',
    callsign: 'HAWAIIAN',
  },
  {
    code: 'HAN',
    callsign: 'KING BEE',
  },
  {
    code: 'HAO',
    callsign: 'HARTY',
  },
  {
    code: 'HAP',
    callsign: 'HELIPERSONAL',
  },
  {
    code: 'HAR',
    callsign: 'HARBOR',
  },
  {
    code: 'HAS',
    callsign: 'HONDURAS AIR',
  },
  {
    code: 'HAT',
    callsign: 'SKY RUNNER',
  },
  {
    code: 'HAU',
    callsign: 'SKYHAUL',
  },
  {
    code: 'HAV',
    callsign: 'HAVILAH',
  },
  {
    code: 'HAW',
    callsign: 'THAI HAWK',
  },
  {
    code: 'HAX',
    callsign: 'SCOOP',
  },
  {
    code: 'HAY',
    callsign: 'HAYA',
  },
  {
    code: 'HBA',
    callsign: 'HARBOR AIR',
  },
  {
    code: 'HBC',
    callsign: 'HALISA',
  },
  {
    code: 'HBH',
    callsign: 'HEBEI AIR',
  },
  {
    code: 'HBN',
    callsign: 'FINCH',
  },
  {
    code: 'HBR',
    callsign: 'HEBRIDEAN',
  },
  {
    code: 'HBT',
    callsign: 'HABICHT',
  },
  {
    code: 'HCA',
    callsign: 'HAVASU',
  },
  {
    code: 'HCB',
    callsign: 'HELEN',
  },
  {
    code: 'HCC',
    callsign: 'HELIANCO',
  },
  {
    code: 'HCF',
    callsign: 'EAGLE',
  },
  {
    code: 'HLL',
    callsign: 'FUSION',
  },
  {
    code: 'HLP',
    callsign: 'HELIPISTAS',
  },
  {
    code: 'HLR',
    callsign: 'HELI BULGARIA',
  },
  {
    code: 'HLT',
    callsign: 'HELITAFE',
  },
  {
    code: 'HLU',
    callsign: 'HELI UNION',
  },
  {
    code: 'HLV',
    callsign: 'SERVI HELI',
  },
  {
    code: 'HLW',
    callsign: 'HELIWORKS',
  },
  {
    code: 'HLY',
    callsign: 'WHISPER',
  },
  {
    code: 'HMB',
    callsign: 'HUMMINGBIRD',
  },
  {
    code: 'HMC',
    callsign: 'HELIAMERICA',
  },
  {
    code: 'HMD',
    callsign: 'HAMMOND',
  },
  {
    code: 'HMF',
    callsign: 'LIFEGUARD SWEDEN',
  },
  {
    code: 'HMJ',
    callsign: 'HARMONY JETS',
  },
  {
    code: 'HML',
    callsign: 'HAMSAL',
  },
  {
    code: 'HMO',
    callsign: 'HELI-CORP',
  },
  {
    code: 'HMP',
    callsign: 'PAPAIR TERMINAL',
  },
  {
    code: 'HNA',
    callsign: 'HELLENIC NAVY',
  },
  {
    code: 'HND',
    callsign: 'HINTERLAND',
  },
  {
    code: 'HNJ',
    callsign: 'SOUTHERN JET',
  },
  {
    code: 'HNL',
    callsign: 'MAPLELEAF',
  },
  {
    code: 'HNR',
    callsign: 'HANAIR',
  },
  {
    code: 'HNW',
    callsign: 'HONEYWELL',
  },
  {
    code: 'HNX',
    callsign: 'SUPERB',
  },
  {
    code: 'HOA',
    callsign: 'HOLA',
  },
  {
    code: 'HOB',
    callsign: 'HOBBY JET',
  },
  {
    code: 'HOD',
    callsign: 'HODHOD',
  },
  {
    code: 'HOG',
    callsign: 'HOGAN AIR',
  },
  {
    code: 'HOL',
    callsign: 'HOLIDAY',
  },
  {
    code: 'HON',
    callsign: 'HONDA TEST',
  },
  {
    code: 'HOP',
    callsign: 'AIR HOP',
  },
  {
    code: 'HOZ',
    callsign: 'OZCA',
  },
  {
    code: 'HPA',
    callsign: 'PEARL AIRWAYS',
  },
  {
    code: 'HPE',
    callsign: 'HOPE AIR',
  },
  {
    code: 'HPJ',
    callsign: 'HOPA-JET',
  },
  {
    code: 'HPK',
    callsign: 'HOPSCOTCH AIR',
  },
  {
    code: 'HPL',
    callsign: 'HELIPORTUGAL',
  },
  {
    code: 'HPN',
    callsign: 'HARPOON',
  },
  {
    code: 'HPO',
    callsign: 'ALMIRON',
  },
  {
    code: 'HPP',
    callsign: 'SKY HOP',
  },
  {
    code: 'HPR',
    callsign: 'HELIPRO',
  },
  {
    code: 'IBG',
    callsign: 'ICE BRIDGE',
  },
  {
    code: 'IBJ',
    callsign: 'IBERTAXI',
  },
  {
    code: 'IBK',
    callsign: 'NORTRANS',
  },
  {
    code: 'IBL',
    callsign: 'CATOVAIR',
  },
  {
    code: 'IBR',
    callsign: 'IBERTOUR',
  },
  {
    code: 'IBS',
    callsign: 'IBEREXPRES',
  },
  {
    code: 'IBX',
    callsign: 'IBEX',
  },
  {
    code: 'ICA',
    callsign: 'TIRYKA',
  },
  {
    code: 'ICC',
    callsign: 'CARTO',
  },
  {
    code: 'ICD',
    callsign: 'ICARO',
  },
  {
    code: 'ICE',
    callsign: 'ICEAIR',
  },
  {
    code: 'ICF',
    callsign: 'INCHARTER',
  },
  {
    code: 'ICG',
    callsign: 'ICELAND COAST',
  },
  {
    code: 'ICH',
    callsign: 'INTERCONTINENTAL',
  },
  {
    code: 'ICL',
    callsign: 'CAL',
  },
  {
    code: 'ICM',
    callsign: 'INTER-CAMEROUN',
  },
  {
    code: 'ICN',
    callsign: 'ICONAIR',
  },
  {
    code: 'ICP',
    callsign: 'CHOPER',
  },
  {
    code: 'ICR',
    callsign: 'ICARUS FLIGHTS',
  },
  {
    code: 'ICT',
    callsign: 'CONTAVIA',
  },
  {
    code: 'ICU',
    callsign: 'ASIA MEDICAL',
  },
  {
    code: 'ICV',
    callsign: 'CARGOLUX ITALIA',
  },
  {
    code: 'ICY',
    callsign: 'INTERCITY',
  },
  {
    code: 'IDA',
    callsign: 'INTRA',
  },
  {
    code: 'IDC',
    callsign: 'ISLAND DEV',
  },
  {
    code: 'CRU',
    callsign: 'VIC-STARLIT',
  },
  {
    code: 'CRV',
    callsign: 'ACROPOLIS',
  },
  {
    code: 'CRW',
    callsign: 'CENTRAL AMERICAN',
  },
  {
    code: 'CRX',
    callsign: 'CROSSAIR',
  },
  {
    code: 'CRY',
    callsign: 'CERNEY',
  },
  {
    code: 'CSA',
    callsign: 'CSA-LINES',
  },
  {
    code: 'CSB',
    callsign: 'CARGO SOUTH',
  },
  {
    code: 'CSC',
    callsign: 'SI CHUAN',
  },
  {
    code: 'CSD',
    callsign: 'DELIVERY',
  },
  {
    code: 'CSF',
    callsign: 'CALEDONIAN',
  },
  {
    code: 'CSG',
    callsign: 'SOUTHERN CARGO',
  },
  {
    code: 'CSH',
    callsign: 'SHANGHAI AIR',
  },
  {
    code: 'CSI',
    callsign: 'SANDIA',
  },
  {
    code: 'CSJ',
    callsign: 'CASTLE',
  },
  {
    code: 'CSK',
    callsign: 'CASCADE',
  },
  {
    code: 'DCS',
    callsign: 'TWIN STAR',
  },
  {
    code: 'DCT',
    callsign: 'AIRTASK',
  },
  {
    code: 'DCV',
    callsign: 'DISCOVERYJET',
  },
  {
    code: 'DCW',
    callsign: 'MALTA STAR',
  },
  {
    code: 'DCY',
    callsign: 'DUCOY',
  },
  {
    code: 'DDA',
    callsign: 'DUSTY',
  },
  {
    code: 'DDY',
    callsign: 'DANDY',
  },
  {
    code: 'DEC',
    callsign: 'DECCAN CARGO',
  },
  {
    code: 'DEE',
    callsign: 'TACAIR',
  },
  {
    code: 'DEF',
    callsign: 'TIRPA',
  },
  {
    code: 'DEK',
    callsign: 'DEK AERO',
  },
  {
    code: 'DEM',
    callsign: 'DESTILA MIELES',
  },
  {
    code: 'DER',
    callsign: 'DEER JET',
  },
  {
    code: 'DES',
    callsign: 'DESTINIA',
  },
  {
    code: 'DEV',
    callsign: 'RED DEVILS',
  },
  {
    code: 'DEX',
    callsign: 'ASIAN AIR',
  },
  {
    code: 'DFA',
    callsign: 'ABLE',
  },
  {
    code: 'DFC',
    callsign: 'DARK BLUE',
  },
  {
    code: 'DFL',
    callsign: 'MEDIFLIGHT',
  },
  {
    code: 'DFS',
    callsign: 'DWYAIR',
  },
  {
    code: 'DGA',
    callsign: 'YELLOW RIVER',
  },
  {
    code: 'DGO',
    callsign: 'DGO JET',
  },
  {
    code: 'DGT',
    callsign: 'DIGITAL',
  },
  {
    code: 'DGX',
    callsign: 'DASNA',
  },
  {
    code: 'DHA',
    callsign: 'YELLOW TAIL',
  },
  {
    code: 'DHC',
    callsign: 'DEHAVILLAND',
  },
  {
    code: 'DHE',
    callsign: 'HELIDAP',
  },
  {
    code: 'DHK',
    callsign: 'WORLD EXPRESS',
  },
  {
    code: 'DHL',
    callsign: 'DAHL',
  },
  {
    code: 'DHR',
    callsign: 'DARK HORSE',
  },
  {
    code: 'DHV',
    callsign: 'WORLDSTAR',
  },
  {
    code: 'DHX',
    callsign: 'DILMUN',
  },
  {
    code: 'DIA',
    callsign: 'BLUE SKY',
  },
  {
    code: 'DIC',
    callsign: 'AEROMEDICA',
  },
  {
    code: 'DIG',
    callsign: 'DAC AIR',
  },
  {
    code: 'DIR',
    callsign: 'DIREKT WINGS',
  },
  {
    code: 'DIS',
    callsign: 'AERODIS',
  },
  {
    code: 'DJA',
    callsign: 'ANTINEA',
  },
  {
    code: 'EAT',
    callsign: 'TRANS EUROPE',
  },
  {
    code: 'EAU',
    callsign: 'ELITE',
  },
  {
    code: 'EAV',
    callsign: 'ELITAVIA',
  },
  {
    code: 'EAW',
    callsign: 'WALYA',
  },
  {
    code: 'EAX',
    callsign: 'AEREAS',
  },
  {
    code: 'EBE',
    callsign: 'MINEBEA',
  },
  {
    code: 'ECA',
    callsign: 'DARK KNIGHT',
  },
  {
    code: 'ECC',
    callsign: 'ECLAIR',
  },
  {
    code: 'ECD',
    callsign: 'ECOTOUR',
  },
  {
    code: 'ECF',
    callsign: 'EUROCOPTER',
  },
  {
    code: 'ECI',
    callsign: 'EASTERN CAROLINA',
  },
  {
    code: 'ECJ',
    callsign: 'EASTCOAST JET',
  },
  {
    code: 'ECL',
    callsign: 'AERO CASTELLANA',
  },
  {
    code: 'ECM',
    callsign: 'AERO COMERCIALES',
  },
  {
    code: 'ECN',
    callsign: 'EURO CONTINENTAL',
  },
  {
    code: 'ECO',
    callsign: 'ECOJET',
  },
  {
    code: 'ECQ',
    callsign: 'SKYBRIDGE',
  },
  {
    code: 'ECR',
    callsign: 'ENERGIA',
  },
  {
    code: 'ECT',
    callsign: 'EASTWAY',
  },
  {
    code: 'ECW',
    callsign: 'ECOTRAVEL',
  },
  {
    code: 'ECX',
    callsign: 'AIR ECOMEX',
  },
  {
    code: 'EDA',
    callsign: 'CARGO LIFT',
  },
  {
    code: 'EDC',
    callsign: 'SALTIRE',
  },
  {
    code: 'EDD',
    callsign: 'LINEAS DURANGO',
  },
  {
    code: 'EDG',
    callsign: 'JET EDGE',
  },
  {
    code: 'EDL',
    callsign: 'POLICE EDELWEISS',
  },
  {
    code: 'EDO',
    callsign: 'EDACOM',
  },
  {
    code: 'EDR',
    callsign: 'BIRDVIEW',
  },
  {
    code: 'EDV',
    callsign: 'ENDEAVOR',
  },
  {
    code: 'EDW',
    callsign: 'EDELWEISS',
  },
  {
    code: 'EDY',
    callsign: 'SOLWAY',
  },
  {
    code: 'EEF',
    callsign: 'ESTONIAN AIRFORCE',
  },
  {
    code: 'EEL',
    callsign: 'SOLACE',
  },
  {
    code: 'EEM',
    callsign: 'EJEAERMEX',
  },
  {
    code: 'EES',
    callsign: 'EAGLE STAR',
  },
  {
    code: 'EEU',
    callsign: 'EUROFLY',
  },
  {
    code: 'EVX',
    callsign: 'GREEN SPIRIT',
  },
  {
    code: 'EWA',
    callsign: 'EWEST',
  },
  {
    code: 'EWG',
    callsign: 'EUROWINGS',
  },
  {
    code: 'EWR',
    callsign: 'MAYOTTE AIR',
  },
  {
    code: 'EWS',
    callsign: 'EAST WEST',
  },
  {
    code: 'EWU',
    callsign: 'UKRAINE EXPRESS',
  },
  {
    code: 'EWZ',
    callsign: 'E-WING',
  },
  {
    code: 'EXA',
    callsign: 'CANADIANEXECAIRE',
  },
  {
    code: 'EXB',
    callsign: 'BRAZILIAN ARMY',
  },
  {
    code: 'EXC',
    callsign: 'ECAIR',
  },
  {
    code: 'EXD',
    callsign: 'PLATINUM EXEC',
  },
  {
    code: 'EXE',
    callsign: 'EXEC',
  },
  {
    code: 'EXF',
    callsign: 'EXIMFLIGHT',
  },
  {
    code: 'EXH',
    callsign: 'EXEC HELI',
  },
  {
    code: 'EXJ',
    callsign: 'VOLANTE',
  },
  {
    code: 'EXM',
    callsign: 'EXAM',
  },
  {
    code: 'EXN',
    callsign: 'EXIN',
  },
  {
    code: 'EXP',
    callsign: 'ISLAND EXPRESS',
  },
  {
    code: 'EXS',
    callsign: 'CHANNEX',
  },
  {
    code: 'EXU',
    callsign: 'SACIR',
  },
  {
    code: 'EXV',
    callsign: 'EXPOAVIA',
  },
  {
    code: 'EXY',
    callsign: 'EXPRESSWAYS',
  },
  {
    code: 'EXZ',
    callsign: 'TWIGA',
  },
  {
    code: 'EYE',
    callsign: 'SOCKEYE',
  },
  {
    code: 'EYT',
    callsign: 'JERNAS',
  },
  {
    code: 'EZA',
    callsign: 'EZNIS',
  },
  {
    code: 'EZE',
    callsign: 'EASTFLIGHT',
  },
  {
    code: 'EZJ',
    callsign: 'GUYANA JET',
  },
  {
    code: 'EZR',
    callsign: 'EASYWAY',
  },
  {
    code: 'EZS',
    callsign: 'TOPSWISS',
  },
  {
    code: 'EZX',
    callsign: 'EAGLEXPRESS AIR',
  },
  {
    code: 'EZY',
    callsign: 'EASY',
  },
  {
    code: 'EZZ',
    callsign: 'ENTERPRIZE',
  },
  {
    code: 'FAC',
    callsign: 'COLOMBIAN AIR FORCE',
  },
  {
    code: 'FAD',
    callsign: 'ADEAL',
  },
  {
    code: 'FAE',
    callsign: 'ECUADORIAN AIRFORCE',
  },
  {
    code: 'FAF',
    callsign: 'FRENCH AIR FORCE',
  },
  {
    code: 'FAG',
    callsign: 'FUAER',
  },
  {
    code: 'FAH',
    callsign: 'BLUE STRIP',
  },
  {
    code: 'FAI',
    callsign: 'MOTOR-CITY',
  },
  {
    code: 'FAJ',
    callsign: 'AMANJET',
  },
  {
    code: 'FPC',
    callsign: 'PRO CONNECT',
  },
  {
    code: 'FPI',
    callsign: 'PERSIA',
  },
  {
    code: 'FPK',
    callsign: 'FEATHER',
  },
  {
    code: 'FPL',
    callsign: 'FEDPOL',
  },
  {
    code: 'FPO',
    callsign: 'FRENCH POST',
  },
  {
    code: 'FPY',
    callsign: 'PLAYER',
  },
  {
    code: 'FQA',
    callsign: 'QUIK LIFT',
  },
  {
    code: 'FRA',
    callsign: 'RUSHTON',
  },
  {
    code: 'FRB',
    callsign: 'RAKWAY',
  },
  {
    code: 'FRD',
    callsign: 'FORD',
  },
  {
    code: 'FRE',
    callsign: 'FREEDOM',
  },
  {
    code: 'FRF',
    callsign: 'FAIRFLEET',
  },
  {
    code: 'FRG',
    callsign: 'FREIGHT RUNNERS',
  },
  {
    code: 'FRI',
    callsign: 'FREEFLIGHT',
  },
  {
    code: 'FRJ',
    callsign: 'AFRIJET',
  },
  {
    code: 'FRK',
    callsign: 'AFRIFAST',
  },
  {
    code: 'FRN',
    callsign: 'FIRST NATION',
  },
  {
    code: 'FRQ',
    callsign: 'CHARTER AFRIQUE',
  },
  {
    code: 'FRR',
    callsign: 'FRESH AIR',
  },
  {
    code: 'FRS',
    callsign: 'COMFORT SERVICIOS',
  },
  {
    code: 'FRU',
    callsign: 'FRENCH RESCUE',
  },
  {
    code: 'FRW',
    callsign: 'FARWEST',
  },
  {
    code: 'FRX',
    callsign: 'FORT AERO',
  },
  {
    code: 'FSA',
    callsign: 'FOSTER-AIR',
  },
  {
    code: 'FSB',
    callsign: 'SEABIRD',
  },
  {
    code: 'FSC',
    callsign: 'FOUR STAR',
  },
  {
    code: 'FSE',
    callsign: 'FALCO',
  },
  {
    code: 'FSF',
    callsign: 'ROCKY',
  },
  {
    code: 'FSJ',
    callsign: 'FREE SKY',
  },
  {
    code: 'FSK',
    callsign: 'AFRICAN SKY',
  },
  {
    code: 'FSO',
    callsign: 'SKY AIR',
  },
  {
    code: 'FSP',
    callsign: 'SNAPSHOT',
  },
  {
    code: 'FSQ',
    callsign: 'KYRGYZ BIRD',
  },
  {
    code: 'FSR',
    callsign: 'FLIGHTSTAR',
  },
  {
    code: 'FSS',
    callsign: 'ABACO',
  },
  {
    code: 'FST',
    callsign: 'FLIGHTRON',
  },
  {
    code: 'FSU',
    callsign: 'UKRAINE BIRD',
  },
  {
    code: 'FSW',
    callsign: 'FASO',
  },
  {
    code: 'FSX',
    callsign: 'FLAG',
  },
  {
    code: 'FSY',
    callsign: 'FROSTY',
  },
  {
    code: 'GGE',
    callsign: 'ECUATO GUINEA',
  },
  {
    code: 'GGG',
    callsign: 'GENESIS',
  },
  {
    code: 'GGL',
    callsign: 'GIRA GLOBO',
  },
  {
    code: 'GGN',
    callsign: 'GREAT NORTH',
  },
  {
    code: 'GGT',
    callsign: 'THUNDERBALL',
  },
  {
    code: 'GHA',
    callsign: 'SUTTER',
  },
  {
    code: 'GHF',
    callsign: 'GHANA AIRFORCE',
  },
  {
    code: 'GHK',
    callsign: 'GOLDEN HAWK',
  },
  {
    code: 'GHL',
    callsign: 'GUILIN ROTOR',
  },
  {
    code: 'GHN',
    callsign: 'AIR GHANA',
  },
  {
    code: 'GHP',
    callsign: 'GRASSHOPPER EX',
  },
  {
    code: 'GIA',
    callsign: 'INDONESIA',
  },
  {
    code: 'GIO',
    callsign: 'GIO-AVIA',
  },
  {
    code: 'GJA',
    callsign: 'GREY JAY',
  },
  {
    code: 'GJB',
    callsign: 'SKY TRUCK',
  },
  {
    code: 'GJE',
    callsign: 'GLOBAL JETS',
  },
  {
    code: 'GJI',
    callsign: 'GAINSTAR',
  },
  {
    code: 'GJM',
    callsign: 'AIRHUB',
  },
  {
    code: 'GJS',
    callsign: 'LINDBERGH',
  },
  {
    code: 'GJT',
    callsign: 'GETJET',
  },
  {
    code: 'GJW',
    callsign: 'PLATA',
  },
  {
    code: 'GKA',
    callsign: 'GOLDEN KNIGHTS',
  },
  {
    code: 'GKS',
    callsign: 'SWARN',
  },
  {
    code: 'GKY',
    callsign: 'GAMA CAYMAN',
  },
  {
    code: 'GLA',
    callsign: 'LAKES AIR',
  },
  {
    code: 'GLB',
    callsign: 'GLOBAL',
  },
  {
    code: 'GLD',
    callsign: 'GOLDEN STAR',
  },
  {
    code: 'GLE',
    callsign: 'GOLIAF AIR',
  },
  {
    code: 'GLF',
    callsign: 'GULFSTREAM TEST',
  },
  {
    code: 'GLG',
    callsign: 'GALAPAGOS',
  },
  {
    code: 'GLH',
    callsign: 'GULFHAWK',
  },
  {
    code: 'GLJ',
    callsign: 'GLOBAL AUSTRIA',
  },
  {
    code: 'GLL',
    callsign: 'TWINS',
  },
  {
    code: 'GLO',
    callsign: 'GOL TRANSPORTE',
  },
  {
    code: 'GLP',
    callsign: 'GLOBUS',
  },
  {
    code: 'GLQ',
    callsign: 'QUILADA',
  },
  {
    code: 'GLR',
    callsign: 'GLACIER',
  },
  {
    code: 'GLS',
    callsign: 'SEDBURGH',
  },
  {
    code: 'GLT',
    callsign: 'GASLIGHT',
  },
  {
    code: 'HCH',
    callsign: 'HELICA',
  },
  {
    code: 'HCK',
    callsign: 'HELI-CHARTER',
  },
  {
    code: 'HCO',
    callsign: 'CONGO LIFT',
  },
  {
    code: 'HCP',
    callsign: 'HELI CZECH',
  },
  {
    code: 'HCS',
    callsign: 'HELIWALES',
  },
  {
    code: 'HCT',
    callsign: 'ROTORCAT',
  },
  {
    code: 'HCV',
    callsign: 'CREOLE',
  },
  {
    code: 'HDA',
    callsign: 'DRAGON',
  },
  {
    code: 'HDC',
    callsign: 'HELICATALUNA',
  },
  {
    code: 'HDI',
    callsign: 'DINAMICOS',
  },
  {
    code: 'HDL',
    callsign: 'HENDELL',
  },
  {
    code: 'HDM',
    callsign: 'HOG',
  },
  {
    code: 'HDR',
    callsign: 'HELITRANS',
  },
  {
    code: 'HEA',
    callsign: 'HELIAVIA',
  },
  {
    code: 'HEB',
    callsign: 'HELIBERNINA',
  },
  {
    code: 'HED',
    callsign: 'FLAPJACK',
  },
  {
    code: 'HEE',
    callsign: 'HELIEJECUTIVO',
  },
  {
    code: 'HEI',
    callsign: 'AEROHEIN',
  },
  {
    code: 'HEL',
    callsign: 'HELICOL',
  },
  {
    code: 'HEN',
    callsign: 'HELINAC',
  },
  {
    code: 'HEP',
    callsign: 'HELIPOLICE',
  },
  {
    code: 'HER',
    callsign: 'HERA',
  },
  {
    code: 'HES',
    callsign: 'HOLIDAY EUROPE',
  },
  {
    code: 'HET',
    callsign: 'HELITAF',
  },
  {
    code: 'HEV',
    callsign: 'HELIJECUTIVO',
  },
  {
    code: 'HEW',
    callsign: 'HELIWATER',
  },
  {
    code: 'HEX',
    callsign: 'HONIARA CARGO',
  },
  {
    code: 'HEZ',
    callsign: 'ARROW',
  },
  {
    code: 'HFD',
    callsign: 'HIGHFIELD',
  },
  {
    code: 'HFJ',
    callsign: 'PANGU',
  },
  {
    code: 'HFL',
    callsign: 'LOADSTAR',
  },
  {
    code: 'HFM',
    callsign: 'MOONRAKER',
  },
  {
    code: 'HFY',
    callsign: 'SKY FLYER',
  },
  {
    code: 'HGB',
    callsign: 'GREATER BAY',
  },
  {
    code: 'HGD',
    callsign: 'HANGDAE',
  },
  {
    code: 'HGE',
    callsign: 'HOAGIE',
  },
  {
    code: 'HGG',
    callsign: 'HI AIR',
  },
  {
    code: 'HGK',
    callsign: 'SALAAMA',
  },
  {
    code: 'HGM',
    callsign: 'MAHSURI',
  },
  {
    code: 'HUC',
    callsign: 'LINEAS TEHUACAN',
  },
  {
    code: 'HUF',
    callsign: 'HUNGARIAN AIRFORCE',
  },
  {
    code: 'HUN',
    callsign: 'HUNGARIAN',
  },
  {
    code: 'HUR',
    callsign: 'HURRICANE CHARTER',
  },
  {
    code: 'HUS',
    callsign: 'HEUSSLER',
  },
  {
    code: 'HUT',
    callsign: 'AEROHUITZILIN',
  },
  {
    code: 'HVK',
    callsign: 'TURKISH AIRFORCE',
  },
  {
    code: 'HVN',
    callsign: 'VIET NAM AIRLINES',
  },
  {
    code: 'HVY',
    callsign: 'LIFT PERU',
  },
  {
    code: 'HWA',
    callsign: 'HILLWOOD',
  },
  {
    code: 'HWD',
    callsign: 'FLITEWISE',
  },
  {
    code: 'HWH',
    callsign: 'HELIWORLD',
  },
  {
    code: 'HWK',
    callsign: 'FIREHAWK',
  },
  {
    code: 'HXA',
    callsign: 'CHINA EXPRESS',
  },
  {
    code: 'HYA',
    callsign: 'ALHAYA',
  },
  {
    code: 'HYC',
    callsign: 'HYDRO CARGO',
  },
  {
    code: 'HYD',
    callsign: 'HYDRO',
  },
  {
    code: 'HYJ',
    callsign: 'HAPPY JET',
  },
  {
    code: 'HYM',
    callsign: 'SKY MOLDOVA',
  },
  {
    code: 'HYN',
    callsign: 'FANTASIA',
  },
  {
    code: 'HYP',
    callsign: 'HYPERION',
  },
  {
    code: 'HYR',
    callsign: 'HIGHFLYER',
  },
  {
    code: 'HYS',
    callsign: 'SKY EUROPE',
  },
  {
    code: 'HYT',
    callsign: 'QUICK AIR',
  },
  {
    code: 'HZM',
    callsign: 'HUZIMA',
  },
  {
    code: 'HZR',
    callsign: 'HUZAR',
  },
  {
    code: 'HZS',
    callsign: 'HORIZON SKY',
  },
  {
    code: 'IAA',
    callsign: 'ANFIBIOS',
  },
  {
    code: 'IAC',
    callsign: 'IBEX AIRLINES',
  },
  {
    code: 'IAD',
    callsign: 'RED KNIGHT',
  },
  {
    code: 'IAE',
    callsign: 'IRAERO',
  },
  {
    code: 'IAG',
    callsign: 'EPAG',
  },
  {
    code: 'IAL',
    callsign: 'IMATONG',
  },
  {
    code: 'IAM',
    callsign: 'ITALIAN AIRFORCE',
  },
  {
    code: 'IAN',
    callsign: 'IBOM',
  },
  {
    code: 'IAR',
    callsign: 'ILIAMNA AIR',
  },
  {
    code: 'IAV',
    callsign: 'ISLAND FLYER',
  },
  {
    code: 'IAW',
    callsign: 'IRAQI',
  },
  {
    code: 'IAX',
    callsign: 'ABBOTSFORD AIR',
  },
  {
    code: 'IBC',
    callsign: 'IBEX CHARTER',
  },
  {
    code: 'IBE',
    callsign: 'IBERIA',
  },
  {
    code: 'ING',
    callsign: 'INAER GALICIA',
  },
  {
    code: 'INI',
    callsign: 'INITIUM',
  },
  {
    code: 'INM',
    callsign: 'INDUCA',
  },
  {
    code: 'INR',
    callsign: 'INAER HELICOPTEROS',
  },
  {
    code: 'INT',
    callsign: 'INTEGRA',
  },
  {
    code: 'INU',
    callsign: 'INSTRUCTOR',
  },
  {
    code: 'IOE',
    callsign: 'ISLAND OIL',
  },
  {
    code: 'IOF',
    callsign: 'INAER OFFSHORE',
  },
  {
    code: 'IOM',
    callsign: 'ISLE AVIA',
  },
  {
    code: 'CTK',
    callsign: 'COSTOCK',
  },
  {
    code: 'CTL',
    callsign: 'CENTRAL COMMUTER',
  },
  {
    code: 'CTM',
    callsign: 'COTAM',
  },
  {
    code: 'CTN',
    callsign: 'CROATIA',
  },
  {
    code: 'CTR',
    callsign: 'CENTAURO',
  },
  {
    code: 'CTS',
    callsign: 'EXECUTIVE',
  },
  {
    code: 'CTT',
    callsign: 'CATT',
  },
  {
    code: 'CTV',
    callsign: 'SUPERGREEN',
  },
  {
    code: 'CTW',
    callsign: 'THIRD CARGO',
  },
  {
    code: 'CTY',
    callsign: 'CENTURY',
  },
  {
    code: 'CUA',
    callsign: 'CHINA UNITED',
  },
  {
    code: 'CUB',
    callsign: 'CUBANA',
  },
  {
    code: 'CUH',
    callsign: 'LOULAN',
  },
  {
    code: 'CUK',
    callsign: 'CHUKKA',
  },
  {
    code: 'CUL',
    callsign: 'COULSON',
  },
  {
    code: 'CUO',
    callsign: 'CUAHONTE',
  },
  {
    code: 'CUS',
    callsign: 'CUSTOM',
  },
  {
    code: 'CUT',
    callsign: 'COURT AIR',
  },
  {
    code: 'CVA',
    callsign: 'CHATHAM',
  },
  {
    code: 'DJH',
    callsign: 'JET FLAG',
  },
  {
    code: 'DJR',
    callsign: 'DESERT FLIGHT',
  },
  {
    code: 'DJT',
    callsign: 'DREAMJET',
  },
  {
    code: 'DKE',
    callsign: 'DUKE',
  },
  {
    code: 'DKH',
    callsign: 'AIR JUNEYAO',
  },
  {
    code: 'DKN',
    callsign: 'AIR DECCAN',
  },
  {
    code: 'DKR',
    callsign: 'DIMA',
  },
  {
    code: 'DKT',
    callsign: 'DAKOTA',
  },
  {
    code: 'DLA',
    callsign: 'DOLOMITI',
  },
  {
    code: 'DLB',
    callsign: 'AIRLINK BAHAMAS',
  },
  {
    code: 'DLC',
    callsign: 'SOARCOPTER',
  },
  {
    code: 'DLF',
    callsign: 'RED WISENT',
  },
  {
    code: 'DLG',
    callsign: 'DELGOLFO',
  },
  {
    code: 'DLH',
    callsign: 'LUFTHANSA',
  },
  {
    code: 'DLJ',
    callsign: 'DING AIR',
  },
  {
    code: 'DLK',
    callsign: 'DEKKANLANKA',
  },
  {
    code: 'DLR',
    callsign: 'DALA AIR',
  },
  {
    code: 'DLX',
    callsign: 'DREAMLINE',
  },
  {
    code: 'DLY',
    callsign: 'DAILY',
  },
  {
    code: 'DMC',
    callsign: 'DINAMICAMONT',
  },
  {
    code: 'DMD',
    callsign: 'DIAMONDJET',
  },
  {
    code: 'DMI',
    callsign: 'AERODINAMICO',
  },
  {
    code: 'DMJ',
    callsign: 'DAMOJH',
  },
  {
    code: 'DML',
    callsign: 'DREAMLAND',
  },
  {
    code: 'DMN',
    callsign: 'DIAMOND ACADEMY',
  },
  {
    code: 'DMQ',
    callsign: 'DALO',
  },
  {
    code: 'DMR',
    callsign: 'HELIDOSA',
  },
  {
    code: 'DMS',
    callsign: 'DIAMOND',
  },
  {
    code: 'DNC',
    callsign: 'FLYINGOLIVE',
  },
  {
    code: 'DND',
    callsign: 'DINDER',
  },
  {
    code: 'DNU',
    callsign: 'DANU',
  },
  {
    code: 'DNY',
    callsign: 'DANISH NAVY',
  },
  {
    code: 'DOC',
    callsign: 'HELIDOC',
  },
  {
    code: 'DOI',
    callsign: 'INTERIOR',
  },
  {
    code: 'DOJ',
    callsign: 'JUSTICE',
  },
  {
    code: 'DOK',
    callsign: 'DOCTOR',
  },
  {
    code: 'DOL',
    callsign: 'DOLLAR SIGN',
  },
  {
    code: 'DOM',
    callsign: 'DOME AIR',
  },
  {
    code: 'DON',
    callsign: 'DONAIR',
  },
  {
    code: 'EKA',
    callsign: 'EQUAFLIGHT',
  },
  {
    code: 'EKC',
    callsign: 'BLUE GOOSE',
  },
  {
    code: 'EKJ',
    callsign: 'EQUAJET',
  },
  {
    code: 'EKO',
    callsign: 'ECOAIR',
  },
  {
    code: 'EKT',
    callsign: 'BLUE KNIGHT',
  },
  {
    code: 'ELB',
    callsign: 'ELLINAIR HELLAS',
  },
  {
    code: 'ELE',
    callsign: 'EUROLINE',
  },
  {
    code: 'ELF',
    callsign: 'ELLASJET',
  },
  {
    code: 'ELG',
    callsign: 'ELANGENI',
  },
  {
    code: 'ELH',
    callsign: 'LARIO',
  },
  {
    code: 'ELJ',
    callsign: 'TOPJET',
  },
  {
    code: 'ELO',
    callsign: 'EUROLOT',
  },
  {
    code: 'ELT',
    callsign: 'ELLIOTT',
  },
  {
    code: 'ELU',
    callsign: 'EGYPTIAN LEISURE',
  },
  {
    code: 'ELV',
    callsign: 'ELVETIA',
  },
  {
    code: 'ELW',
    callsign: 'YELLOW WINGS',
  },
  {
    code: 'ELX',
    callsign: 'ELAN',
  },
  {
    code: 'ELY',
    callsign: 'ELAL',
  },
  {
    code: 'EMB',
    callsign: 'EMBRAER',
  },
  {
    code: 'EMD',
    callsign: 'EAGLEMED',
  },
  {
    code: 'EME',
    callsign: 'EMAIR',
  },
  {
    code: 'EMI',
    callsign: 'BLUE SHUTTLE',
  },
  {
    code: 'EMJ',
    callsign: 'LIGHT HOUSE',
  },
  {
    code: 'EML',
    callsign: 'EMALUSA',
  },
  {
    code: 'EMM',
    callsign: 'RULER',
  },
  {
    code: 'EMP',
    callsign: 'EMPIRE',
  },
  {
    code: 'EMR',
    callsign: 'ZEMMOUR',
  },
  {
    code: 'EMS',
    callsign: 'SERVIEMPRESARIAL',
  },
  {
    code: 'EMT',
    callsign: 'EMETEBE',
  },
  {
    code: 'EMZ',
    callsign: 'EMOZ',
  },
  {
    code: 'ENA',
    callsign: 'ENA',
  },
  {
    code: 'ENC',
    callsign: 'ENDECOTS',
  },
  {
    code: 'END',
    callsign: 'ARRENDADORA',
  },
  {
    code: 'ENE',
    callsign: 'JET-ENER',
  },
  {
    code: 'ENF',
    callsign: 'ENAV CHECK',
  },
  {
    code: 'ENK',
    callsign: 'SUNBIRD',
  },
  {
    code: 'FAK',
    callsign: 'FACTS',
  },
  {
    code: 'FAL',
    callsign: 'FRIENDSHIP',
  },
  {
    code: 'FAM',
    callsign: 'FAMEX',
  },
  {
    code: 'FAN',
    callsign: 'FANBIRD',
  },
  {
    code: 'FAO',
    callsign: 'PANTHER',
  },
  {
    code: 'FAP',
    callsign: 'FAIR SCHOOL',
  },
  {
    code: 'FAR',
    callsign: 'TORRO',
  },
  {
    code: 'FAS',
    callsign: 'FLORIDA CARGO',
  },
  {
    code: 'FAT',
    callsign: 'AIR TAXI',
  },
  {
    code: 'FAU',
    callsign: 'FALCON AIRLINE',
  },
  {
    code: 'FAV',
    callsign: 'FAIRAVIA',
  },
  {
    code: 'FAW',
    callsign: 'FALWELL',
  },
  {
    code: 'FAX',
    callsign: 'FAIRFAX',
  },
  {
    code: 'FAY',
    callsign: 'FAYBAN AIR',
  },
  {
    code: 'FAZ',
    callsign: 'FLINT AIR',
  },
  {
    code: 'FBA',
    callsign: 'IRAQ EXPRESS',
  },
  {
    code: 'FBC',
    callsign: 'BLUE CRANE',
  },
  {
    code: 'FBE',
    callsign: 'AFRIBEE',
  },
  {
    code: 'FBG',
    callsign: 'FLEET BIG',
  },
  {
    code: 'FBJ',
    callsign: 'BABY JET',
  },
  {
    code: 'FBN',
    callsign: 'FLORIDA TRANSPORT',
  },
  {
    code: 'FBS',
    callsign: 'BOSNIA AIR',
  },
  {
    code: 'FBU',
    callsign: 'FRENCH BEE',
  },
  {
    code: 'FBX',
    callsign: 'BUSINESS JET',
  },
  {
    code: 'FBY',
    callsign: 'BAI FLY',
  },
  {
    code: 'FBZ',
    callsign: 'BONDI',
  },
  {
    code: 'FCA',
    callsign: 'COOPAIR',
  },
  {
    code: 'FCB',
    callsign: 'APOLLO',
  },
  {
    code: 'FCH',
    callsign: 'SKY UNION',
  },
  {
    code: 'FCJ',
    callsign: 'FRACJET',
  },
  {
    code: 'FCK',
    callsign: 'NAV CHECKER',
  },
  {
    code: 'FCL',
    callsign: 'FLORIDA COASTAL',
  },
  {
    code: 'FCM',
    callsign: 'FINNCOMM',
  },
  {
    code: 'FCO',
    callsign: 'AEROFRISCO',
  },
  {
    code: 'FCP',
    callsign: 'FLIGHTCORP',
  },
  {
    code: 'FCS',
    callsign: 'NESA',
  },
  {
    code: 'FDA',
    callsign: 'FUJI DREAM',
  },
  {
    code: 'FDB',
    callsign: 'SKY DUBAI',
  },
  {
    code: 'FGG',
    callsign: 'BLUEFINCH',
  },
  {
    code: 'FGN',
    callsign: 'FRANCE GENDARME',
  },
  {
    code: 'FGP',
    callsign: 'FLYING CENTER',
  },
  {
    code: 'FGR',
    callsign: 'FRIGATE',
  },
  {
    code: 'FGS',
    callsign: 'ELITELLINA',
  },
  {
    code: 'FGW',
    callsign: 'GANGWON',
  },
  {
    code: 'FHM',
    callsign: 'EUROBIRD',
  },
  {
    code: 'FHV',
    callsign: 'PATROL',
  },
  {
    code: 'FHY',
    callsign: 'FREEBIRD AIR',
  },
  {
    code: 'FIA',
    callsign: 'FIA AIRLINES',
  },
  {
    code: 'FIC',
    callsign: 'AEROSAFIN',
  },
  {
    code: 'FIH',
    callsign: 'FINNHEMS',
  },
  {
    code: 'FIL',
    callsign: 'FLYLINE',
  },
  {
    code: 'FIN',
    callsign: 'FINNAIR',
  },
  {
    code: 'FIS',
    callsign: 'SHERRY',
  },
  {
    code: 'FIV',
    callsign: 'FIVE STAR',
  },
  {
    code: 'FJA',
    callsign: 'LINK FIJI',
  },
  {
    code: 'FJB',
    callsign: 'FASTBIRD',
  },
  {
    code: 'FJE',
    callsign: 'FANJET',
  },
  {
    code: 'FJI',
    callsign: 'FIJI',
  },
  {
    code: 'FJM',
    callsign: 'GREENHEART',
  },
  {
    code: 'FJO',
    callsign: 'FLEX MALTA',
  },
  {
    code: 'FJR',
    callsign: 'SOLITAIRE AIR',
  },
  {
    code: 'FJS',
    callsign: 'FLORIDAJET',
  },
  {
    code: 'FJT',
    callsign: 'FUTURE AIR',
  },
  {
    code: 'FJW',
    callsign: 'ZIMBIRD',
  },
  {
    code: 'FKS',
    callsign: 'FOCUS',
  },
  {
    code: 'FKZ',
    callsign: 'KUMBA',
  },
  {
    code: 'FLB',
    callsign: 'ROSSIGNOL',
  },
  {
    code: 'FLC',
    callsign: 'FLIGHT CHECK',
  },
  {
    code: 'FLD',
    callsign: 'FLORIDIAN',
  },
  {
    code: 'FLE',
    callsign: 'FLAIR',
  },
  {
    code: 'FLF',
    callsign: 'FRIEND AIR',
  },
  {
    code: 'FLG',
    callsign: 'KRISH',
  },
  {
    code: 'FLH',
    callsign: 'MILE HIGH',
  },
  {
    code: 'FLI',
    callsign: 'FAROELINE',
  },
  {
    code: 'FLJ',
    callsign: 'FLEXAIR',
  },
  {
    code: 'FLK',
    callsign: 'FLYLINK',
  },
  {
    code: 'FLL',
    callsign: 'FEDERAL AIRLINES',
  },
  {
    code: 'FYS',
    callsign: 'EUROPEAN FLYERS',
  },
  {
    code: 'FYW',
    callsign: 'MAISBRASIL',
  },
  {
    code: 'FZA',
    callsign: 'STRAIT AIR',
  },
  {
    code: 'FZW',
    callsign: 'FRESH EXPRESS',
  },
  {
    code: 'GAA',
    callsign: 'GLOBAL AFRICA',
  },
  {
    code: 'GAB',
    callsign: 'CALI',
  },
  {
    code: 'GAC',
    callsign: 'DREAM TEAM',
  },
  {
    code: 'GAD',
    callsign: 'SOUTHCOAST',
  },
  {
    code: 'GAE',
    callsign: 'GULF AVIATION',
  },
  {
    code: 'GAF',
    callsign: 'GERMAN AIR FORCE',
  },
  {
    code: 'GAG',
    callsign: 'GEEBIRD',
  },
  {
    code: 'GAH',
    callsign: 'GAMHELICO',
  },
  {
    code: 'GAJ',
    callsign: 'GAMA JET',
  },
  {
    code: 'GAK',
    callsign: 'AVIAGROUP',
  },
  {
    code: 'GAL',
    callsign: 'ERRANTE',
  },
  {
    code: 'GAM',
    callsign: 'GERMAN ARMY',
  },
  {
    code: 'GAP',
    callsign: 'AIRPHIL',
  },
  {
    code: 'GAR',
    callsign: 'GLORY AIR',
  },
  {
    code: 'GAS',
    callsign: 'GALENA AIR SERVICE',
  },
  {
    code: 'GAT',
    callsign: 'GULF TRANS',
  },
  {
    code: 'GAU',
    callsign: 'AEROGAUCHO',
  },
  {
    code: 'GAW',
    callsign: 'SKYCRAWLER',
  },
  {
    code: 'GAX',
    callsign: 'GRAND AIRE',
  },
  {
    code: 'GBA',
    callsign: 'GULF BAHRAIN',
  },
  {
    code: 'GBB',
    callsign: 'GLOBE',
  },
  {
    code: 'GBC',
    callsign: 'GABON CARGO',
  },
  {
    code: 'GBE',
    callsign: 'GABEX',
  },
  {
    code: 'GBG',
    callsign: 'VAGABOND',
  },
  {
    code: 'GBJ',
    callsign: 'GLOBAL THAI',
  },
  {
    code: 'GBK',
    callsign: 'GABON AIRLINES',
  },
  {
    code: 'GBM',
    callsign: 'BERIEV COMPANY',
  },
  {
    code: 'GBN',
    callsign: 'ATLANTIC GABON',
  },
  {
    code: 'GBQ',
    callsign: 'GAMBIA BIRD',
  },
  {
    code: 'GBR',
    callsign: 'GREENBRIER AIR',
  },
  {
    code: 'GBS',
    callsign: 'GLOBAL SERVE',
  },
  {
    code: 'GBX',
    callsign: 'GEEBEE LINK',
  },
  {
    code: 'GCA',
    callsign: 'AEROGECA',
  },
  {
    code: 'GCB',
    callsign: 'LINACONGO',
  },
  {
    code: 'GCC',
    callsign: 'GECAS',
  },
  {
    code: 'GRD',
    callsign: 'GRID',
  },
  {
    code: 'GRE',
    callsign: 'HELIX',
  },
  {
    code: 'GRF',
    callsign: 'SIMBA',
  },
  {
    code: 'GRL',
    callsign: 'GREENLAND',
  },
  {
    code: 'GRM',
    callsign: 'GERONIMO',
  },
  {
    code: 'GRN',
    callsign: 'GRANDE',
  },
  {
    code: 'GRP',
    callsign: 'AIR FOX',
  },
  {
    code: 'GRR',
    callsign: 'AGROAR',
  },
  {
    code: 'GRV',
    callsign: 'EPSILON',
  },
  {
    code: 'GRX',
    callsign: 'GRODNO',
  },
  {
    code: 'GRY',
    callsign: 'GRAY RIDER',
  },
  {
    code: 'GSA',
    callsign: 'GARDEN STATE',
  },
  {
    code: 'GSC',
    callsign: 'GRANDSTAR CARGO',
  },
  {
    code: 'GSF',
    callsign: 'AERO GESEF',
  },
  {
    code: 'GSH',
    callsign: 'GAMAMENA',
  },
  {
    code: 'GSI',
    callsign: 'SLOVENIA',
  },
  {
    code: 'GSJ',
    callsign: 'GROSSJET',
  },
  {
    code: 'GSK',
    callsign: 'GLOBAL SKY',
  },
  {
    code: 'GSL',
    callsign: 'SURVEY-CANADA',
  },
  {
    code: 'GSP',
    callsign: 'GLOBAL SCHOOL',
  },
  {
    code: 'GST',
    callsign: 'SUPERTANK',
  },
  {
    code: 'GSW',
    callsign: 'EIGER',
  },
  {
    code: 'GSY',
    callsign: 'GRAY SKY',
  },
  {
    code: 'GTA',
    callsign: 'CITY AIRWAYS',
  },
  {
    code: 'GTF',
    callsign: 'GATOR FLIGHT',
  },
  {
    code: 'GTH',
    callsign: 'GOTHAM',
  },
  {
    code: 'GTI',
    callsign: 'GIANT',
  },
  {
    code: 'GTP',
    callsign: 'GRUPOTAMPICO',
  },
  {
    code: 'GTR',
    callsign: 'GALISTAIR',
  },
  {
    code: 'GTS',
    callsign: 'TRANSGROUP',
  },
  {
    code: 'GTV',
    callsign: 'GAVIOTA',
  },
  {
    code: 'GTW',
    callsign: 'GATEWAY',
  },
  {
    code: 'GTX',
    callsign: 'BIG-DEE',
  },
  {
    code: 'GUC',
    callsign: 'LEAF AIR',
  },
  {
    code: 'GUE',
    callsign: 'AERO GUERRERO',
  },
  {
    code: 'GUG',
    callsign: 'AVIATECA',
  },
  {
    code: 'GUJ',
    callsign: 'GUJARATAIR',
  },
  {
    code: 'GUL',
    callsign: 'GULL-AIR',
  },
  {
    code: 'GUM',
    callsign: 'GUM AIR',
  },
  {
    code: 'GUN',
    callsign: 'HOOT',
  },
  {
    code: 'GUY',
    callsign: 'GREEN BIRD',
  },
  {
    code: 'GVA',
    callsign: 'GRUPOVA',
  },
  {
    code: 'GVN',
    callsign: 'GAVINA',
  },
  {
    code: 'GWA',
    callsign: 'G-W AIR',
  },
  {
    code: 'GWC',
    callsign: 'GULF WINGS',
  },
  {
    code: 'GWG',
    callsign: 'GREEN AFRICA',
  },
  {
    code: 'GWI',
    callsign: 'GERMAN WINGS',
  },
  {
    code: 'GWL',
    callsign: 'GREAT WALL',
  },
  {
    code: 'GWR',
    callsign: 'LEMON',
  },
  {
    code: 'GWS',
    callsign: 'GENAIR',
  },
  {
    code: 'HPY',
    callsign: 'HAPPY TRAVEL',
  },
  {
    code: 'HRA',
    callsign: 'ERICA',
  },
  {
    code: 'HRB',
    callsign: 'HAITI AIRLINE',
  },
  {
    code: 'HRC',
    callsign: 'HARCO',
  },
  {
    code: 'HRE',
    callsign: 'HART AIR',
  },
  {
    code: 'HRF',
    callsign: 'HERO-FLIGHT',
  },
  {
    code: 'HRN',
    callsign: 'HERONAIR',
  },
  {
    code: 'HRS',
    callsign: 'HORSEMEN',
  },
  {
    code: 'HRT',
    callsign: 'CHARTRIGHT',
  },
  {
    code: 'HRV',
    callsign: 'SAHARA-SERVICE',
  },
  {
    code: 'HRZ',
    callsign: 'CROATIAN AIRFORCE',
  },
  {
    code: 'HSA',
    callsign: 'DUMA',
  },
  {
    code: 'HSE',
    callsign: 'HELISCAN',
  },
  {
    code: 'HSF',
    callsign: 'HAN-SEO FLIGHT',
  },
  {
    code: 'HSH',
    callsign: 'HISPANICA',
  },
  {
    code: 'HSI',
    callsign: 'HELISWISS',
  },
  {
    code: 'HSJ',
    callsign: 'BAOSHENG',
  },
  {
    code: 'HSK',
    callsign: 'HALLA SKY',
  },
  {
    code: 'HSM',
    callsign: 'HELIMALT',
  },
  {
    code: 'HSN',
    callsign: 'JAGUAR',
  },
  {
    code: 'HSO',
    callsign: 'HELISERVICE',
  },
  {
    code: 'HSR',
    callsign: 'HELISTAR',
  },
  {
    code: 'HSS',
    callsign: 'TAS HELICOPTEROS',
  },
  {
    code: 'HST',
    callsign: 'HESTON',
  },
  {
    code: 'HSV',
    callsign: 'VERTIFLIGHT',
  },
  {
    code: 'HSW',
    callsign: 'HELIBERICA',
  },
  {
    code: 'HSY',
    callsign: 'HELISKY',
  },
  {
    code: 'HTA',
    callsign: 'HELI TRANSAIR',
  },
  {
    code: 'HTB',
    callsign: 'HELIX-CRAFT',
  },
  {
    code: 'HTC',
    callsign: 'HAITI TRANSAIR',
  },
  {
    code: 'HTE',
    callsign: 'HELICOPTERSMEXICO',
  },
  {
    code: 'HTH',
    callsign: 'ALBORAN',
  },
  {
    code: 'HTI',
    callsign: 'HAITI INTERNATIONAL',
  },
  {
    code: 'HTK',
    callsign: 'LINGBIRD',
  },
  {
    code: 'HTM',
    callsign: 'HELITRAVEL',
  },
  {
    code: 'HTP',
    callsign: 'HALA EXPRESS',
  },
  {
    code: 'IDD',
    callsign: 'IDA',
  },
  {
    code: 'IDG',
    callsign: 'INDIGO',
  },
  {
    code: 'IDO',
    callsign: 'INDOKHMER',
  },
  {
    code: 'IDT',
    callsign: 'IDOT',
  },
  {
    code: 'IDX',
    callsign: 'RED PHOENIX',
  },
  {
    code: 'IEI',
    callsign: 'ITALIAN ARMY',
  },
  {
    code: 'IEK',
    callsign: 'INAER EUSKADI',
  },
  {
    code: 'IFA',
    callsign: 'RED ANGEL',
  },
  {
    code: 'IFC',
    callsign: 'INDIAN AIRFORCE',
  },
  {
    code: 'IFF',
    callsign: 'INTERFREIGHTER',
  },
  {
    code: 'IFG',
    callsign: 'INFINITY',
  },
  {
    code: 'IFI',
    callsign: 'HELLAS LIFT',
  },
  {
    code: 'IFJ',
    callsign: 'IFAJET',
  },
  {
    code: 'IFL',
    callsign: 'EIFEL',
  },
  {
    code: 'IFM',
    callsign: 'ICOPTER',
  },
  {
    code: 'JAV',
    callsign: 'JORDAN AVIATION',
  },
  {
    code: 'JAW',
    callsign: 'JASMINE',
  },
  {
    code: 'JAX',
    callsign: 'JANAIR',
  },
  {
    code: 'JAY',
    callsign: 'AFRICA ALLIANCE',
  },
  {
    code: 'JBA',
    callsign: 'HELIJET',
  },
  {
    code: 'JBC',
    callsign: 'JETBEE',
  },
  {
    code: 'JBE',
    callsign: 'SINO BEIJING',
  },
  {
    code: 'JBU',
    callsign: 'JETBLUE',
  },
  {
    code: 'JBW',
    callsign: 'AIRJUB',
  },
  {
    code: 'JCA',
    callsign: 'SPARTAN',
  },
  {
    code: 'JCB',
    callsign: 'JAYSEEBEE',
  },
  {
    code: 'JCC',
    callsign: 'CAMBO',
  },
  {
    code: 'JCH',
    callsign: 'TRADING CARGO',
  },
  {
    code: 'JCL',
    callsign: 'JETCALL',
  },
  {
    code: 'JCM',
    callsign: 'SECUREAIR',
  },
  {
    code: 'JCO',
    callsign: 'CONCIERGE',
  },
  {
    code: 'JCT',
    callsign: 'JET CHARTER',
  },
  {
    code: 'JCY',
    callsign: 'JET CITY',
  },
  {
    code: 'JDA',
    callsign: 'JAY DEE',
  },
  {
    code: 'JDC',
    callsign: 'JOHN DEERE',
  },
  {
    code: 'JDI',
    callsign: 'JEDI',
  },
  {
    code: 'JDL',
    callsign: 'JINGDONG',
  },
  {
    code: 'JEA',
    callsign: 'JETA',
  },
  {
    code: 'JED',
    callsign: 'JET EAST',
  },
  {
    code: 'JEE',
    callsign: 'AMBJEK AIR',
  },
  {
    code: 'JEF',
    callsign: 'JETFLITE',
  },
  {
    code: 'JEI',
    callsign: 'JET EXECUTIVE',
  },
  {
    code: 'JEP',
    callsign: 'JET PERSONALES',
  },
  {
    code: 'JES',
    callsign: 'SMARTBIRD',
  },
  {
    code: 'JET',
    callsign: 'TRADEWIND',
  },
  {
    code: 'JFA',
    callsign: 'MOSQUITO',
  },
  {
    code: 'JFE',
    callsign: 'JAMAICA CARGO',
  },
  {
    code: 'JFL',
    callsign: 'BLUE DANUBE',
  },
  {
    code: 'JFS',
    callsign: 'SERVIJET',
  },
  {
    code: 'JFY',
    callsign: 'YEOMAN',
  },
  {
    code: 'JGB',
    callsign: 'JETGLOBAL',
  },
  {
    code: 'JGJ',
    callsign: 'GLOBAL JINGGONG',
  },
  {
    code: 'JGO',
    callsign: 'JETGO',
  },
  {
    code: 'JHK',
    callsign: 'SHENGLI',
  },
  {
    code: 'KBD',
    callsign: 'JET KEY',
  },
  {
    code: 'KBL',
    callsign: 'KABUL AIR',
  },
  {
    code: 'KBN',
    callsign: 'KABIN',
  },
  {
    code: 'KBR',
    callsign: 'KORAL BLUE',
  },
  {
    code: 'KBV',
    callsign: 'SWECOAST',
  },
  {
    code: 'KBX',
    callsign: 'SKYBEX',
  },
  {
    code: 'KBZ',
    callsign: 'JADE AIR',
  },
  {
    code: 'KCE',
    callsign: 'KACEY',
  },
  {
    code: 'KCH',
    callsign: 'CAM AIR',
  },
  {
    code: 'KCR',
    callsign: 'KOLOB',
  },
  {
    code: 'KCU',
    callsign: 'KOCOGLU',
  },
  {
    code: 'KDR',
    callsign: 'DARLINES',
  },
  {
    code: 'KDS',
    callsign: 'KALEO',
  },
  {
    code: 'KEA',
    callsign: 'KOREA EXPRESS',
  },
  {
    code: 'KEJ',
    callsign: 'KAZAIRJET',
  },
  {
    code: 'KEM',
    callsign: 'CEMAIR',
  },
  {
    code: 'KEN',
    callsign: 'KENMORE',
  },
  {
    code: 'KER',
    callsign: 'KERMAS',
  },
  {
    code: 'KEW',
    callsign: 'BLIZZARD',
  },
  {
    code: 'KEY',
    callsign: 'KEY AIR',
  },
  {
    code: 'KFA',
    callsign: 'FLIGHTCRAFT',
  },
  {
    code: 'KFB',
    callsign: 'MISSION',
  },
  {
    code: 'KFC',
    callsign: 'KREMENCHUK',
  },
  {
    code: 'KFE',
    callsign: 'MORITO',
  },
  {
    code: 'KFK',
    callsign: 'KRIFKA AIR',
  },
  {
    code: 'KFR',
    callsign: 'KINGFISHER',
  },
  {
    code: 'KFS',
    callsign: 'KALITTA',
  },
  {
    code: 'KGB',
    callsign: 'SAPSAN',
  },
  {
    code: 'KGC',
    callsign: 'SALAM AIR',
  },
  {
    code: 'KGK',
    callsign: 'KYRGYZ SKY',
  },
  {
    code: 'KGL',
    callsign: 'KOGALYM',
  },
  {
    code: 'KGS',
    callsign: 'BERKUT',
  },
  {
    code: 'KGT',
    callsign: 'KNIGHT-LINER',
  },
  {
    code: 'KHA',
    callsign: 'AIR KITTYHAWK',
  },
  {
    code: 'KHF',
    callsign: 'KHABAROVSK',
  },
  {
    code: 'KHP',
    callsign: 'PHOTROS AIR',
  },
  {
    code: 'KHR',
    callsign: 'KHAZAR',
  },
  {
    code: 'KHT',
    callsign: 'KHATLON',
  },
  {
    code: 'KHV',
    callsign: 'CAMBODIA AIR',
  },
  {
    code: 'KHY',
    callsign: 'KHYBER',
  },
  {
    code: 'KIC',
    callsign: 'KING COBRA',
  },
  {
    code: 'LCS',
    callsign: 'LEUCHARS',
  },
  {
    code: 'LCT',
    callsign: 'CONEXION LINK',
  },
  {
    code: 'LCV',
    callsign: 'CABO CAT',
  },
  {
    code: 'LCY',
    callsign: 'LONDON CITY',
  },
  {
    code: 'LDA',
    callsign: 'BEAUFORT',
  },
  {
    code: 'LDG',
    callsign: 'EDGE',
  },
  {
    code: 'LDM',
    callsign: 'LAUDA MOTION',
  },
  {
    code: 'LDN',
    callsign: 'ALDONAS AIR',
  },
  {
    code: 'LDR',
    callsign: 'AEROLIDER',
  },
  {
    code: 'LDS',
    callsign: 'LINSUR',
  },
  {
    code: 'LDX',
    callsign: 'LAUDA RUSH',
  },
  {
    code: 'LEA',
    callsign: 'LEADAIR',
  },
  {
    code: 'LEB',
    callsign: 'LEBAP',
  },
  {
    code: 'LEC',
    callsign: 'LECA',
  },
  {
    code: 'LED',
    callsign: 'SWEEPER',
  },
  {
    code: 'LEE',
    callsign: 'JAVELIN',
  },
  {
    code: 'LEG',
    callsign: 'LEGACY',
  },
  {
    code: 'LEL',
    callsign: 'LEONAVIA',
  },
  {
    code: 'LEM',
    callsign: 'LEMPIRAS',
  },
  {
    code: 'LEN',
    callsign: 'LENTINI',
  },
  {
    code: 'LEO',
    callsign: 'SUN-OLE',
  },
  {
    code: 'LEP',
    callsign: 'LECOSTA',
  },
  {
    code: 'LER',
    callsign: 'LASER',
  },
  {
    code: 'LET',
    callsign: 'MEXALE',
  },
  {
    code: 'LEU',
    callsign: 'LIONSAIR',
  },
  {
    code: 'LEX',
    callsign: 'LEXAIR',
  },
  {
    code: 'LFA',
    callsign: 'BUCKY',
  },
  {
    code: 'LFE',
    callsign: 'LIFE AIR',
  },
  {
    code: 'LFI',
    callsign: 'AEROMED',
  },
  {
    code: 'LFL',
    callsign: 'LIFE FLIGHT',
  },
  {
    code: 'LFO',
    callsign: 'LUFO',
  },
  {
    code: 'LFR',
    callsign: 'LANFREIGHT',
  },
  {
    code: 'LGA',
    callsign: 'LOGAIR',
  },
  {
    code: 'LGC',
    callsign: 'LEGACY AIR',
  },
  {
    code: 'LGF',
    callsign: 'LEGACY FREIGHT',
  },
  {
    code: 'LGI',
    callsign: 'ELL-GEE AIR',
  },
  {
    code: 'LGL',
    callsign: 'LUXAIR',
  },
  {
    code: 'LGN',
    callsign: 'AEROLAGUNA',
  },
  {
    code: 'LVS',
    callsign: 'SIGNIA',
  },
  {
    code: 'LVT',
    callsign: 'ELEVATE',
  },
  {
    code: 'LVW',
    callsign: 'LONGVIEW',
  },
  {
    code: 'LWA',
    callsign: 'LIBYAN WINGS',
  },
  {
    code: 'LWC',
    callsign: 'TRANSACA',
  },
  {
    code: 'LWG',
    callsign: 'LUXWING',
  },
  {
    code: 'LWI',
    callsign: 'LUMI',
  },
  {
    code: 'LWL',
    callsign: 'CUB DRIVER',
  },
  {
    code: 'LXA',
    callsign: 'RED LION',
  },
  {
    code: 'LXC',
    callsign: 'LUXVAN',
  },
  {
    code: 'LXF',
    callsign: 'LYNX FLIGHT',
  },
  {
    code: 'LXG',
    callsign: 'LUX AVIATION',
  },
  {
    code: 'LXJ',
    callsign: 'FLEXJET',
  },
  {
    code: 'LXM',
    callsign: 'LUQA',
  },
  {
    code: 'LXP',
    callsign: 'LANEX',
  },
  {
    code: 'LXR',
    callsign: 'SKY LUXURY',
  },
  {
    code: 'LXS',
    callsign: 'LYNX SERVICIOS',
  },
  {
    code: 'LXX',
    callsign: 'LIBYANEXPRESS',
  },
  {
    code: 'LYB',
    callsign: 'HIGHLANDS',
  },
  {
    code: 'LYC',
    callsign: 'LYNDEN',
  },
  {
    code: 'LYD',
    callsign: 'LYDDAIR',
  },
  {
    code: 'LYF',
    callsign: 'LITHUANIAN AIRFORCE',
  },
  {
    code: 'LYH',
    callsign: 'HELIGUYANE',
  },
  {
    code: 'LYM',
    callsign: 'KEY LIME',
  },
  {
    code: 'LYN',
    callsign: 'ALTYN AVIA',
  },
  {
    code: 'LYX',
    callsign: 'MALTA CAT',
  },
  {
    code: 'LZA',
    callsign: 'AEROLANZA',
  },
  {
    code: 'LZB',
    callsign: 'FLYING BULGARIA',
  },
  {
    code: 'LZF',
    callsign: 'SKYLEASE',
  },
  {
    code: 'MAA',
    callsign: 'MAS CARGA',
  },
  {
    code: 'MAB',
    callsign: 'MAPPING',
  },
  {
    code: 'MAC',
    callsign: 'ARABIA MAROC',
  },
  {
    code: 'MAD',
    callsign: 'MALONE',
  },
  {
    code: 'MAE',
    callsign: 'MALI AIREXPRESS',
  },
  {
    code: 'MAF',
    callsign: 'AEROMAS',
  },
  {
    code: 'MAG',
    callsign: 'MAGMA',
  },
  {
    code: 'MAJ',
    callsign: 'ME TACK',
  },
  {
    code: 'MAK',
    callsign: 'MAKLEE',
  },
  {
    code: 'MAL',
    callsign: 'MORNINGSTAR',
  },
  {
    code: 'MAN',
    callsign: 'MANNION',
  },
  {
    code: 'MAO',
    callsign: 'MAYO',
  },
  {
    code: 'MAP',
    callsign: 'ALTJETS',
  },
  {
    code: 'MAQ',
    callsign: 'MAC AVIATION',
  },
  {
    code: 'MKG',
    callsign: 'MEKONG',
  },
  {
    code: 'MKL',
    callsign: 'MCCALL',
  },
  {
    code: 'MKR',
    callsign: 'AIR LANME',
  },
  {
    code: 'MKU',
    callsign: 'MOKU',
  },
  {
    code: 'MLA',
    callsign: 'MILE-AIR',
  },
  {
    code: 'MLB',
    callsign: 'MANAF',
  },
  {
    code: 'MLD',
    callsign: 'AIR MOLDOVA',
  },
  {
    code: 'MLE',
    callsign: 'MOLDAERO',
  },
  {
    code: 'MLH',
    callsign: 'SOUTH WIND',
  },
  {
    code: 'MLI',
    callsign: 'DEMAIS',
  },
  {
    code: 'MLK',
    callsign: 'NIGERJET',
  },
  {
    code: 'MLL',
    callsign: 'MALLORCA',
  },
  {
    code: 'MLM',
    callsign: 'LUXMALTA',
  },
  {
    code: 'MLN',
    callsign: 'MILLENNIUM',
  },
  {
    code: 'MLO',
    callsign: 'MILENIO',
  },
  {
    code: 'MLS',
    callsign: 'MALL-AIRWAYS',
  },
  {
    code: 'MLT',
    callsign: 'MALETH',
  },
  {
    code: 'MLU',
    callsign: 'MALI LOSINJ',
  },
  {
    code: 'MLV',
    callsign: 'MULTI VALLE',
  },
  {
    code: 'MLX',
    callsign: 'MALAWI EXPRESS',
  },
  {
    code: 'MMA',
    callsign: 'MYANMAR',
  },
  {
    code: 'MMD',
    callsign: 'MERMAID',
  },
  {
    code: 'MME',
    callsign: 'MAJESTIC AIR',
  },
  {
    code: 'MMF',
    callsign: 'MULTI',
  },
  {
    code: 'MMG',
    callsign: 'RUTA MAYA',
  },
  {
    code: 'MMH',
    callsign: 'NIGHT RIDER',
  },
  {
    code: 'MMI',
    callsign: 'ITALIAN NAVY',
  },
  {
    code: 'MMJ',
    callsign: 'MACAUJET',
  },
  {
    code: 'MML',
    callsign: 'TRANS MONGOLIA',
  },
  {
    code: 'MMM',
    callsign: 'AVIAMERIDIAN',
  },
  {
    code: 'MMN',
    callsign: 'MINUTEMAN',
  },
  {
    code: 'MMO',
    callsign: 'MALIT',
  },
  {
    code: 'MMU',
    callsign: 'SKY TOUR',
  },
  {
    code: 'MMY',
    callsign: 'MARMOT',
  },
  {
    code: 'MMZ',
    callsign: 'EUROATLANTIC',
  },
  {
    code: 'MNB',
    callsign: 'BLACK SEA',
  },
  {
    code: 'MND',
    callsign: 'CUGAT',
  },
  {
    code: 'MNE',
    callsign: 'MOUNT EAGLE',
  },
  {
    code: 'MNG',
    callsign: 'AERO MONGOLIA',
  },
  {
    code: 'MNH',
    callsign: 'MONARCH AIR',
  },
  {
    code: 'MNJ',
    callsign: 'MENAJET',
  },
  {
    code: 'MYU',
    callsign: 'INDO',
  },
  {
    code: 'MYW',
    callsign: 'MY SKY',
  },
  {
    code: 'MYX',
    callsign: 'TALLINN CAT',
  },
  {
    code: 'MZN',
    callsign: 'AMAZON',
  },
  {
    code: 'MZS',
    callsign: 'MAHFOOZ',
  },
  {
    code: 'MZT',
    callsign: 'ZHONG TAI',
  },
  {
    code: 'NAA',
    callsign: 'NORUEGA',
  },
  {
    code: 'NAB',
    callsign: 'NIGER CARGO',
  },
  {
    code: 'NAC',
    callsign: 'YUKON',
  },
  {
    code: 'NAE',
    callsign: 'NATIONAL',
  },
  {
    code: 'NAF',
    callsign: 'NETHERLANDS  AIR FORCE',
  },
  {
    code: 'NAG',
    callsign: 'NATO',
  },
  {
    code: 'NAI',
    callsign: 'NOVAIR',
  },
  {
    code: 'NAK',
    callsign: 'ENACSCHOOL',
  },
  {
    code: 'NAL',
    callsign: 'NORTHWAY',
  },
  {
    code: 'NAM',
    callsign: 'NAM',
  },
  {
    code: 'NAN',
    callsign: 'NORSHIP',
  },
  {
    code: 'NAO',
    callsign: 'NORTH AMERICAN',
  },
  {
    code: 'NAS',
    callsign: 'NASAIRWAYS',
  },
  {
    code: 'NAT',
    callsign: 'MASS AIR',
  },
  {
    code: 'NAV',
    callsign: 'NAV DISPATCH',
  },
  {
    code: 'NAX',
    callsign: 'NOR SHUTTLE',
  },
  {
    code: 'NAY',
    callsign: 'NAYSA',
  },
  {
    code: 'NBK',
    callsign: 'AL-AIR',
  },
  {
    code: 'NBR',
    callsign: 'NORBROOK',
  },
  {
    code: 'NBT',
    callsign: 'LONGSHIP',
  },
  {
    code: 'NCA',
    callsign: 'NIPPON CARGO',
  },
  {
    code: 'NCB',
    callsign: 'NORTH CARIBOU',
  },
  {
    code: 'NCC',
    callsign: 'STARFLEET',
  },
  {
    code: 'NCG',
    callsign: 'NETHERLANDS COASTGUARD',
  },
  {
    code: 'NCH',
    callsign: 'CHANCHANGI',
  },
  {
    code: 'NCJ',
    callsign: 'NORTH CENTRAL',
  },
  {
    code: 'NCL',
    callsign: 'ANCARGO AIR',
  },
  {
    code: 'NCN',
    callsign: 'NICON AIRWAYS',
  },
  {
    code: 'NCO',
    callsign: 'NATALCO',
  },
  {
    code: 'NCP',
    callsign: 'CAPITAL SHUTTLE',
  },
  {
    code: 'NCR',
    callsign: 'NATIONAL CARGO',
  },
  {
    code: 'NCT',
    callsign: 'BIG BIRD',
  },
  {
    code: 'NDA',
    callsign: 'NORDICA AIR',
  },
  {
    code: 'NDD',
    callsign: 'NORDEND',
  },
  {
    code: 'NSR',
    callsign: 'AIR STAR',
  },
  {
    code: 'NSS',
    callsign: 'NESMA CONNECT',
  },
  {
    code: 'NST',
    callsign: 'NISHAT',
  },
  {
    code: 'NSW',
    callsign: 'NORDIC',
  },
  {
    code: 'NSY',
    callsign: 'QUNYING',
  },
  {
    code: 'NSZ',
    callsign: 'NORLIGHT',
  },
  {
    code: 'NTA',
    callsign: 'THUNDERBIRD',
  },
  {
    code: 'NTC',
    callsign: 'NIGHT CHASE',
  },
  {
    code: 'NTF',
    callsign: 'NETFLIGHT',
  },
  {
    code: 'NTG',
    callsign: 'INTEGRALES',
  },
  {
    code: 'NTH',
    callsign: 'NORTH AIR',
  },
  {
    code: 'NTK',
    callsign: 'NATCA',
  },
  {
    code: 'NTN',
    callsign: 'NATCHAIR',
  },
  {
    code: 'NTS',
    callsign: 'NITE STAR',
  },
  {
    code: 'NTW',
    callsign: 'NORTHWOODS',
  },
  {
    code: 'NTX',
    callsign: 'TIFLIS',
  },
  {
    code: 'NUA',
    callsign: 'UNITED NIGERIA',
  },
  {
    code: 'NUB',
    callsign: 'VALLETTA',
  },
  {
    code: 'NUK',
    callsign: 'NANUCK',
  },
  {
    code: 'NUL',
    callsign: 'SERVICIOS NUEVOLEON',
  },
  {
    code: 'NUM',
    callsign: 'BULLY',
  },
  {
    code: 'NUS',
    callsign: 'ENJET',
  },
  {
    code: 'NVC',
    callsign: 'NAV CAN',
  },
  {
    code: 'NVD',
    callsign: 'NORDVIND',
  },
  {
    code: 'NVJ',
    callsign: 'NOUVINTER',
  },
  {
    code: 'NVK',
    callsign: 'VARTOVSKAVIA',
  },
  {
    code: 'NVM',
    callsign: 'NAVIERA',
  },
  {
    code: 'NVO',
    callsign: 'ENVUELO',
  },
  {
    code: 'NVQ',
    callsign: 'NOVO AIR',
  },
  {
    code: 'NVR',
    callsign: 'NAVIGATOR',
  },
  {
    code: 'NVS',
    callsign: 'NOUVELLE AFFAIRES',
  },
  {
    code: 'NVT',
    callsign: 'NATIVE',
  },
  {
    code: 'NVY',
    callsign: 'NAVY',
  },
  {
    code: 'NWA',
    callsign: 'SOLEIL NIGER',
  },
  {
    code: 'NWC',
    callsign: 'WEST WAY',
  },
  {
    code: 'NWD',
    callsign: 'NEW WORLD',
  },
  {
    code: 'PBN',
    callsign: 'BLUEBIRD',
  },
  {
    code: 'PBR',
    callsign: 'POLAR BEAR',
  },
  {
    code: 'PBU',
    callsign: 'AIR-BURUNDI',
  },
  {
    code: 'ION',
    callsign: 'GORRION',
  },
  {
    code: 'IOS',
    callsign: 'SCILLONIA',
  },
  {
    code: 'IPF',
    callsign: 'IRAQI PRESIDENTIAL FLIGHT',
  },
  {
    code: 'IPL',
    callsign: 'IPULL',
  },
  {
    code: 'IPM',
    callsign: 'AEROITA',
  },
  {
    code: 'IPT',
    callsign: 'INTERPORT',
  },
  {
    code: 'IQP',
    callsign: 'IQRA PAKISTAN',
  },
  {
    code: 'IQQ',
    callsign: 'CARIBJET',
  },
  {
    code: 'IRA',
    callsign: 'IRANAIR',
  },
  {
    code: 'IRB',
    callsign: 'AIRTOUR',
  },
  {
    code: 'IRC',
    callsign: 'ASEMAN',
  },
  {
    code: 'IRD',
    callsign: 'ARVAND',
  },
  {
    code: 'IRE',
    callsign: 'PARIZAIR',
  },
  {
    code: 'IRF',
    callsign: 'TA-AIR',
  },
  {
    code: 'IRH',
    callsign: 'ATLAS AVIA',
  },
  {
    code: 'IRI',
    callsign: 'NAVID',
  },
  {
    code: 'IRJ',
    callsign: 'BONYAD AIR',
  },
  {
    code: 'IRL',
    callsign: 'IRISH',
  },
  {
    code: 'IRM',
    callsign: 'MAHAN AIR',
  },
  {
    code: 'IRO',
    callsign: 'IRON AIR',
  },
  {
    code: 'IRP',
    callsign: 'PAYAMAIR',
  },
  {
    code: 'IRR',
    callsign: 'TARAIR',
  },
  {
    code: 'IRS',
    callsign: 'SAHAND AIR',
  },
  {
    code: 'IRU',
    callsign: 'CHABAHAR',
  },
  {
    code: 'IRV',
    callsign: 'SAFAT AIR',
  },
  {
    code: 'IRW',
    callsign: 'ARAM',
  },
  {
    code: 'IRX',
    callsign: 'ARIA',
  },
  {
    code: 'IRY',
    callsign: 'ERAM AIR',
  },
  {
    code: 'IRZ',
    callsign: 'SAHA',
  },
  {
    code: 'ISA',
    callsign: 'ISLAND',
  },
  {
    code: 'JNS',
    callsign: 'AFRICAN QUEEN',
  },
  {
    code: 'JNV',
    callsign: 'JETNOVA',
  },
  {
    code: 'JNY',
    callsign: 'ROCK BAND',
  },
  {
    code: 'JOB',
    callsign: 'JOBENI',
  },
  {
    code: 'JOC',
    callsign: 'JUST US',
  },
  {
    code: 'JOE',
    callsign: 'JET-EUROPE',
  },
  {
    code: 'JON',
    callsign: 'JOON',
  },
  {
    code: 'JOY',
    callsign: 'JOY AIR',
  },
  {
    code: 'JPA',
    callsign: 'J-PAT',
  },
  {
    code: 'JPB',
    callsign: 'PALMBEACH JET',
  },
  {
    code: 'JPJ',
    callsign: 'JUPITERJET',
  },
  {
    code: 'JPL',
    callsign: 'SEQUOIA',
  },
  {
    code: 'JPN',
    callsign: 'JETPLAN',
  },
  {
    code: 'JPO',
    callsign: 'JETPRO',
  },
  {
    code: 'JPR',
    callsign: 'JUMPER',
  },
  {
    code: 'JPS',
    callsign: 'JETPLUS',
  },
  {
    code: 'JPT',
    callsign: 'PRIMETIME',
  },
  {
    code: 'JPV',
    callsign: 'JETPOOL',
  },
  {
    code: 'JRC',
    callsign: 'JETAIR',
  },
  {
    code: 'JRH',
    callsign: 'BLUETIP',
  },
  {
    code: 'JRT',
    callsign: 'JETRIGHT',
  },
  {
    code: 'JRY',
    callsign: 'JET READY',
  },
  {
    code: 'JSA',
    callsign: 'JETSTAR ASIA',
  },
  {
    code: 'JSC',
    callsign: 'CONGO RIVER',
  },
  {
    code: 'JSH',
    callsign: 'STREAM AIR',
  },
  {
    code: 'JSI',
    callsign: 'SISTEMA',
  },
  {
    code: 'JSJ',
    callsign: 'JS CHARTER',
  },
  {
    code: 'JSL',
    callsign: 'SELECTJET',
  },
  {
    code: 'JSN',
    callsign: 'JETSUN',
  },
  {
    code: 'JSP',
    callsign: 'SPOOLER',
  },
  {
    code: 'JST',
    callsign: 'JETSTAR',
  },
  {
    code: 'JSU',
    callsign: 'SU JET',
  },
  {
    code: 'JSW',
    callsign: 'JIGSAW',
  },
  {
    code: 'JSX',
    callsign: 'BIGSTRIPE',
  },
  {
    code: 'JSY',
    callsign: 'JUNG SKY',
  },
  {
    code: 'JTA',
    callsign: 'JAI OCEAN',
  },
  {
    code: 'KPO',
    callsign: 'KRYPTO',
  },
  {
    code: 'KPR',
    callsign: 'SKIPPER',
  },
  {
    code: 'KPT',
    callsign: 'BALADO',
  },
  {
    code: 'KQA',
    callsign: 'KENYA',
  },
  {
    code: 'KRC',
    callsign: 'KIWI RESCUE',
  },
  {
    code: 'KRE',
    callsign: 'AEROSUCRE',
  },
  {
    code: 'KRF',
    callsign: 'KITTYHAWK',
  },
  {
    code: 'KRH',
    callsign: 'SPARROWHAWK',
  },
  {
    code: 'KYY',
    callsign: 'KAMOZ',
  },
  {
    code: 'KZH',
    callsign: 'ULUTAU',
  },
  {
    code: 'KZN',
    callsign: 'KAZANAIR',
  },
  {
    code: 'KZR',
    callsign: 'ASTANALINE',
  },
  {
    code: 'KZU',
    callsign: 'UNIVERSAL CARGO',
  },
  {
    code: 'KZW',
    callsign: 'KHALIFA AIR',
  },
  {
    code: 'LAA',
    callsign: 'LIBAIR',
  },
  {
    code: 'LAB',
    callsign: 'LAB',
  },
  {
    code: 'LAC',
    callsign: 'LOCKHEED',
  },
  {
    code: 'LAD',
    callsign: 'LADCO-AIR',
  },
  {
    code: 'LAF',
    callsign: 'LATVIAN AIRFORCE',
  },
  {
    code: 'LAG',
    callsign: 'AVILEG',
  },
  {
    code: 'LAK',
    callsign: 'REDLAKE',
  },
  {
    code: 'LAM',
    callsign: 'MOZAMBIQUE',
  },
  {
    code: 'LAN',
    callsign: 'LAN CHILE',
  },
  {
    code: 'LAO',
    callsign: 'LAO',
  },
  {
    code: 'LAP',
    callsign: 'PARAGUAYA',
  },
  {
    code: 'LAQ',
    callsign: 'LAT',
  },
  {
    code: 'LAR',
    callsign: 'LAWRENCE',
  },
  {
    code: 'LAS',
    callsign: 'LIONS HELICOPTER',
  },
  {
    code: 'LAT',
    callsign: 'LEBANESE AIR',
  },
  {
    code: 'LAU',
    callsign: 'SURAMERICANO',
  },
  {
    code: 'LAV',
    callsign: 'ALBASTAR',
  },
  {
    code: 'LAY',
    callsign: 'LAYANG',
  },
  {
    code: 'LBA',
    callsign: 'LOBAYE',
  },
  {
    code: 'LBB',
    callsign: 'LIBYAN BLUE BIRD',
  },
  {
    code: 'LBD',
    callsign: 'LIGHTBIRD',
  },
  {
    code: 'LBF',
    callsign: 'LIBYAN AIRFORCE',
  },
  {
    code: 'LBO',
    callsign: 'ALLIBO FREIGHT',
  },
  {
    code: 'LBQ',
    callsign: 'LABQUEST',
  },
  {
    code: 'LBR',
    callsign: 'NORTHLIGHT',
  },
  {
    code: 'LBT',
    callsign: 'NOUVELAIR',
  },
  {
    code: 'LCA',
    callsign: 'LECONTE',
  },
  {
    code: 'LCB',
    callsign: 'BUSRE',
  },
  {
    code: 'LCC',
    callsign: 'LANCAIR',
  },
  {
    code: 'LCH',
    callsign: 'LYNCH AIR',
  },
  {
    code: 'LCM',
    callsign: 'LINEAS COMERCIALES',
  },
  {
    code: 'LCN',
    callsign: 'CANEDO',
  },
  {
    code: 'LCO',
    callsign: 'LAN CARGO',
  },
  {
    code: 'LPR',
    callsign: 'RATOVNIK',
  },
  {
    code: 'LRB',
    callsign: 'LADY RACINE',
  },
  {
    code: 'LRC',
    callsign: 'LACSA',
  },
  {
    code: 'LRD',
    callsign: 'LAREDO AIR',
  },
  {
    code: 'LRG',
    callsign: 'SOLAER LAGUNA',
  },
  {
    code: 'LRK',
    callsign: 'SKYLARK',
  },
  {
    code: 'LRQ',
    callsign: 'LUX RESCUE',
  },
  {
    code: 'LRR',
    callsign: 'LORRAINE',
  },
  {
    code: 'LRT',
    callsign: 'LIBERTY JET',
  },
  {
    code: 'LRW',
    callsign: 'AL RIDA',
  },
  {
    code: 'LSC',
    callsign: 'CEDROS',
  },
  {
    code: 'LSI',
    callsign: 'ALIS',
  },
  {
    code: 'LSJ',
    callsign: 'LIAISON',
  },
  {
    code: 'LSK',
    callsign: 'SKYLIGHT',
  },
  {
    code: 'LSL',
    callsign: 'LEE AIR',
  },
  {
    code: 'LSM',
    callsign: 'TITAN',
  },
  {
    code: 'LSN',
    callsign: 'SERVI NAL',
  },
  {
    code: 'LSO',
    callsign: 'STARCOM',
  },
  {
    code: 'LSP',
    callsign: 'AIR TONY',
  },
  {
    code: 'LST',
    callsign: 'LESORTEC',
  },
  {
    code: 'LSV',
    callsign: 'SLOVENIAN AIRFORCE',
  },
  {
    code: 'LSX',
    callsign: 'ATLAS EXEC',
  },
  {
    code: 'LSY',
    callsign: 'LEBANON SKY',
  },
  {
    code: 'LTA',
    callsign: 'LIFT',
  },
  {
    code: 'LTC',
    callsign: 'CHARTER JETS',
  },
  {
    code: 'LTD',
    callsign: 'LIGHTSPEED',
  },
  {
    code: 'LTE',
    callsign: 'FUN JET',
  },
  {
    code: 'LTF',
    callsign: 'LETS FLY',
  },
  {
    code: 'LTG',
    callsign: 'TAMCARGO',
  },
  {
    code: 'LTR',
    callsign: 'LUFT TRANSPORT',
  },
  {
    code: 'LTS',
    callsign: 'SPECAIR',
  },
  {
    code: 'LTU',
    callsign: 'GERMANAIR',
  },
  {
    code: 'LTZ',
    callsign: 'LAMINAR',
  },
  {
    code: 'LUC',
    callsign: 'ALBINATI',
  },
  {
    code: 'LUK',
    callsign: 'LUKOIL',
  },
  {
    code: 'LUT',
    callsign: 'LUGO',
  },
  {
    code: 'LVB',
    callsign: 'SILVERBIRD',
  },
  {
    code: 'MGT',
    callsign: 'MIGHTYVAN',
  },
  {
    code: 'MGW',
    callsign: 'GREAT MONGOLIAN',
  },
  {
    code: 'MGX',
    callsign: 'MONTAIR',
  },
  {
    code: 'MHA',
    callsign: 'MOUNTAIN HIGH',
  },
  {
    code: 'MHC',
    callsign: 'AERO JOMACHA',
  },
  {
    code: 'MHD',
    callsign: 'YAS AIR',
  },
  {
    code: 'MHF',
    callsign: 'AIR MARITIME',
  },
  {
    code: 'MHK',
    callsign: 'AL-NASER',
  },
  {
    code: 'MHL',
    callsign: 'HASSIMAIR',
  },
  {
    code: 'MHN',
    callsign: 'MANHATTAN',
  },
  {
    code: 'MHO',
    callsign: 'MAHALO',
  },
  {
    code: 'MHQ',
    callsign: 'HELICARE',
  },
  {
    code: 'MHS',
    callsign: 'AIR MEMPHIS',
  },
  {
    code: 'MHU',
    callsign: 'MEMPHIS UGANDA',
  },
  {
    code: 'MHV',
    callsign: 'SNOWCAP',
  },
  {
    code: 'MIA',
    callsign: 'MAURIA',
  },
  {
    code: 'MIC',
    callsign: 'MINT AIRWAYS',
  },
  {
    code: 'MID',
    callsign: 'MIDCOAST',
  },
  {
    code: 'MIE',
    callsign: 'AEROPREMIER',
  },
  {
    code: 'MIG',
    callsign: 'MIG AVIA',
  },
  {
    code: 'MIH',
    callsign: 'MASSYLINE',
  },
  {
    code: 'MIM',
    callsign: 'MILLESIME',
  },
  {
    code: 'MIR',
    callsign: 'AEROMIR',
  },
  {
    code: 'MIS',
    callsign: 'ARTEMIS',
  },
  {
    code: 'MIT',
    callsign: 'MATCO',
  },
  {
    code: 'MIZ',
    callsign: 'MILAZ',
  },
  {
    code: 'MJA',
    callsign: 'MAJARA',
  },
  {
    code: 'MJC',
    callsign: 'AIR MANDA',
  },
  {
    code: 'MJE',
    callsign: 'EMJET',
  },
  {
    code: 'MJF',
    callsign: 'EM-EXPRESS',
  },
  {
    code: 'MJI',
    callsign: 'MAJESTIC JET',
  },
  {
    code: 'MJN',
    callsign: 'MAJAN',
  },
  {
    code: 'MJP',
    callsign: 'AIR MAJORO',
  },
  {
    code: 'MJR',
    callsign: 'MAJOR',
  },
  {
    code: 'MJS',
    callsign: 'JET SAVER',
  },
  {
    code: 'MJT',
    callsign: 'MADJET',
  },
  {
    code: 'MKA',
    callsign: 'MOKA AIR',
  },
  {
    code: 'MKB',
    callsign: 'GREENTAIL',
  },
  {
    code: 'MUC',
    callsign: 'AIR MUNICH',
  },
  {
    code: 'MUI',
    callsign: 'TRANSAIR',
  },
  {
    code: 'MUN',
    callsign: 'AEROMUNDO',
  },
  {
    code: 'MUT',
    callsign: 'IGAVIA',
  },
  {
    code: 'MVA',
    callsign: 'VALAIR',
  },
  {
    code: 'MVE',
    callsign: 'MOVE FLIGHTS',
  },
  {
    code: 'MVJ',
    callsign: 'MIRA VISTA',
  },
  {
    code: 'MVK',
    callsign: 'MINN STATE',
  },
  {
    code: 'MVP',
    callsign: 'ALLSTAR',
  },
  {
    code: 'MVR',
    callsign: 'MAV-AIR',
  },
  {
    code: 'MVS',
    callsign: 'AIR SENOK',
  },
  {
    code: 'MWG',
    callsign: 'MASWINGS',
  },
  {
    code: 'MWI',
    callsign: 'MALAWIAN',
  },
  {
    code: 'MWL',
    callsign: 'MIDWEST AIRLINK',
  },
  {
    code: 'MWM',
    callsign: 'MODERNAIR',
  },
  {
    code: 'MWN',
    callsign: 'MAX NIGER',
  },
  {
    code: 'MWS',
    callsign: 'MALTA WINGS',
  },
  {
    code: 'MWT',
    callsign: 'MIDWEST',
  },
  {
    code: 'MWY',
    callsign: 'MAURITANIENNE',
  },
  {
    code: 'MXD',
    callsign: 'MALINDO',
  },
  {
    code: 'MXE',
    callsign: 'MOZAMBIQUE EXPRESS',
  },
  {
    code: 'MXF',
    callsign: 'MAXFLIGHT',
  },
  {
    code: 'MXG',
    callsign: 'PRESIDENCIA DE MEXICO',
  },
  {
    code: 'MXM',
    callsign: 'MAXLINES',
  },
  {
    code: 'MXO',
    callsign: 'MEXAERO',
  },
  {
    code: 'MXP',
    callsign: 'BEECHNUT',
  },
  {
    code: 'MXQ',
    callsign: 'MEXIQUENSES',
  },
  {
    code: 'MXS',
    callsign: 'MILLON EXPRESS',
  },
  {
    code: 'MXT',
    callsign: 'TRANSMEX',
  },
  {
    code: 'MXX',
    callsign: 'MERCHANT',
  },
  {
    code: 'MXY',
    callsign: 'MOXY',
  },
  {
    code: 'MYA',
    callsign: 'MYFLUG',
  },
  {
    code: 'MYD',
    callsign: 'MYLAND',
  },
  {
    code: 'MYF',
    callsign: 'MY FLIGHT',
  },
  {
    code: 'MYI',
    callsign: 'MAYAIR',
  },
  {
    code: 'MYK',
    callsign: 'MYLIN',
  },
  {
    code: 'MYO',
    callsign: 'MAYORAL',
  },
  {
    code: 'MYP',
    callsign: 'MANN ROYAL',
  },
  {
    code: 'MYS',
    callsign: 'AERO YAQUI',
  },
  {
    code: 'NHX',
    callsign: 'EVO',
  },
  {
    code: 'NHZ',
    callsign: 'GOLD',
  },
  {
    code: 'NIA',
    callsign: 'NILE BIRD',
  },
  {
    code: 'NIG',
    callsign: 'AEROLINE',
  },
  {
    code: 'NIM',
    callsign: 'NIMJET',
  },
  {
    code: 'NIN',
    callsign: 'NIGER AIRLINES',
  },
  {
    code: 'NIT',
    callsign: 'NIGHTTRAIN',
  },
  {
    code: 'NJA',
    callsign: 'SHIN NIHON',
  },
  {
    code: 'NJC',
    callsign: 'NASHVILLE JET',
  },
  {
    code: 'NJE',
    callsign: 'FRACTION',
  },
  {
    code: 'NJM',
    callsign: 'NORTHERN JET',
  },
  {
    code: 'NJS',
    callsign: 'NATIONAL JET',
  },
  {
    code: 'NJT',
    callsign: 'NETJET',
  },
  {
    code: 'NJU',
    callsign: 'ARTISAN',
  },
  {
    code: 'NKA',
    callsign: 'ANKA CARGO',
  },
  {
    code: 'NKP',
    callsign: 'ABAKAN AIR',
  },
  {
    code: 'NKS',
    callsign: 'SPIRIT WINGS',
  },
  {
    code: 'NKT',
    callsign: 'NORTH COUNTRY',
  },
  {
    code: 'NKV',
    callsign: 'AIR NIKOLAEV',
  },
  {
    code: 'NKY',
    callsign: 'AEROMON',
  },
  {
    code: 'NKZ',
    callsign: 'NOVOKUZNETSK',
  },
  {
    code: 'NLA',
    callsign: 'PENINSULA',
  },
  {
    code: 'NLE',
    callsign: 'NELLIE',
  },
  {
    code: 'NLF',
    callsign: 'NORTHERN LIFE',
  },
  {
    code: 'NLI',
    callsign: 'NORTH LINK',
  },
  {
    code: 'NLW',
    callsign: 'NILE WINGS',
  },
  {
    code: 'NMA',
    callsign: 'NESMA',
  },
  {
    code: 'NMB',
    callsign: 'NAMIBIA',
  },
  {
    code: 'NMD',
    callsign: 'NOMAD AIR',
  },
  {
    code: 'NME',
    callsign: 'NAMASTE AIR',
  },
  {
    code: 'NMG',
    callsign: 'TIANJIAO AIR',
  },
  {
    code: 'NMI',
    callsign: 'TSUNAMI',
  },
  {
    code: 'NML',
    callsign: 'NEWMILL',
  },
  {
    code: 'NMR',
    callsign: 'E-MED',
  },
  {
    code: 'OEW',
    callsign: 'DREAMER',
  },
  {
    code: 'OEY',
    callsign: 'RIMBUN AIR',
  },
  {
    code: 'OFL',
    callsign: 'OPEN FLIGHT',
  },
  {
    code: 'OFY',
    callsign: 'AEROSOF',
  },
  {
    code: 'OGA',
    callsign: 'OASIS AIR',
  },
  {
    code: 'OGJ',
    callsign: 'BAKO AIR',
  },
  {
    code: 'OHC',
    callsign: 'OHIO-CHARTER',
  },
  {
    code: 'OHS',
    callsign: 'ODENGENE',
  },
  {
    code: 'OHY',
    callsign: 'ONUR AIR',
  },
  {
    code: 'OIX',
    callsign: 'ORIONIX',
  },
  {
    code: 'OJO',
    callsign: 'OHJOH',
  },
  {
    code: 'OJT',
    callsign: 'ORYSTAR',
  },
  {
    code: 'OKA',
    callsign: 'OKAYJET',
  },
  {
    code: 'OKC',
    callsign: 'THUNDER WINGS',
  },
  {
    code: 'OKJ',
    callsign: 'OKADA AIR',
  },
  {
    code: 'OKL',
    callsign: 'OKLAHOMA',
  },
  {
    code: 'OKS',
    callsign: 'SLOK GAMBIA',
  },
  {
    code: 'OKT',
    callsign: 'SOKO AIR',
  },
  {
    code: 'OLA',
    callsign: 'OVERLAND',
  },
  {
    code: 'OLC',
    callsign: 'SOLARCARGO',
  },
  {
    code: 'OLD',
    callsign: 'AIR ORENDA',
  },
  {
    code: 'OLE',
    callsign: 'OPERADORA',
  },
  {
    code: 'OLL',
    callsign: 'MINMEX',
  },
  {
    code: 'OLR',
    callsign: 'COLAEREOS',
  },
  {
    code: 'OLS',
    callsign: 'FLIGHT SOL',
  },
  {
    code: 'OLV',
    callsign: 'OLIVIA',
  },
  {
    code: 'OLY',
    callsign: 'OLYAIR',
  },
  {
    code: 'OMA',
    callsign: 'OMAN AIR',
  },
  {
    code: 'HTR',
    callsign: 'HOLSTEN',
  },
  {
    code: 'HTS',
    callsign: 'HELISAS',
  },
  {
    code: 'HTY',
    callsign: 'HELITY',
  },
  {
    code: 'HUB',
    callsign: 'HUB',
  },
  {
    code: 'IFX',
    callsign: 'IFTA',
  },
  {
    code: 'IGA',
    callsign: 'IGUANA',
  },
  {
    code: 'IGL',
    callsign: 'ATISA',
  },
  {
    code: 'IGN',
    callsign: 'DIVINE AIR',
  },
  {
    code: 'IGO',
    callsign: 'IFLY',
  },
  {
    code: 'IGS',
    callsign: 'ISLA GRANDE',
  },
  {
    code: 'IGY',
    callsign: 'INGENUITY',
  },
  {
    code: 'IHC',
    callsign: 'HELIRAN',
  },
  {
    code: 'IHO',
    callsign: 'SEFEAS',
  },
  {
    code: 'IIA',
    callsign: 'INTER ILES',
  },
  {
    code: 'IIC',
    callsign: 'INTER AIR',
  },
  {
    code: 'IIL',
    callsign: 'INDIA INTER',
  },
  {
    code: 'IJA',
    callsign: 'I-JET',
  },
  {
    code: 'IJM',
    callsign: 'JET MANAGEMENT',
  },
  {
    code: 'IJW',
    callsign: 'JET WEST',
  },
  {
    code: 'IKK',
    callsign: 'IKIAIR',
  },
  {
    code: 'IKM',
    callsign: 'EASY SHUTTLE',
  },
  {
    code: 'IKR',
    callsign: 'IKAROS',
  },
  {
    code: 'ILA',
    callsign: 'ILAN ARAD',
  },
  {
    code: 'ILF',
    callsign: 'ISLAND FLIGHT',
  },
  {
    code: 'ILK',
    callsign: 'ILEK',
  },
  {
    code: 'ILM',
    callsign: 'BIRDMAN',
  },
  {
    code: 'ILN',
    callsign: 'INLINE',
  },
  {
    code: 'ILS',
    callsign: 'SERVICIOS ILSA',
  },
  {
    code: 'ILU',
    callsign: 'ILLINI',
  },
  {
    code: 'IMA',
    callsign: 'INTER-MOUNTAIN',
  },
  {
    code: 'IMC',
    callsign: 'MY CHARTER',
  },
  {
    code: 'IMD',
    callsign: 'JETBULL',
  },
  {
    code: 'IMG',
    callsign: 'IMAGINE',
  },
  {
    code: 'IMI',
    callsign: 'IMPERIALAEREO',
  },
  {
    code: 'IML',
    callsign: 'MAL AIR',
  },
  {
    code: 'IMO',
    callsign: 'INVERMOCA',
  },
  {
    code: 'IMS',
    callsign: 'IMAGES',
  },
  {
    code: 'IMT',
    callsign: 'IMTREC',
  },
  {
    code: 'IMX',
    callsign: 'ZIMEX',
  },
  {
    code: 'INB',
    callsign: 'INTERBUILD',
  },
  {
    code: 'INC',
    callsign: 'INSELAIR',
  },
  {
    code: 'JHN',
    callsign: 'AIR JOHNSON',
  },
  {
    code: 'JIA',
    callsign: 'BLUE STREAK',
  },
  {
    code: 'JIM',
    callsign: 'SARK',
  },
  {
    code: 'JIS',
    callsign: 'JUPAIR',
  },
  {
    code: 'JIT',
    callsign: 'JET IT',
  },
  {
    code: 'JIV',
    callsign: 'JIV AIR',
  },
  {
    code: 'JJA',
    callsign: 'JEJU AIR',
  },
  {
    code: 'JJM',
    callsign: 'GEODATA',
  },
  {
    code: 'JJP',
    callsign: 'ORANGE LINER',
  },
  {
    code: 'JKA',
    callsign: 'JACKET',
  },
  {
    code: 'JKH',
    callsign: 'JETKONTOR',
  },
  {
    code: 'JKK',
    callsign: 'SPANAIR',
  },
  {
    code: 'JKN',
    callsign: 'JUNKANOO AIR',
  },
  {
    code: 'JKR',
    callsign: 'JOKER',
  },
  {
    code: 'JKT',
    callsign: 'KAITAK',
  },
  {
    code: 'JKY',
    callsign: 'JOCKEY',
  },
  {
    code: 'JLA',
    callsign: 'AERO JOE',
  },
  {
    code: 'JLB',
    callsign: 'JHONLIN',
  },
  {
    code: 'JLC',
    callsign: 'ANGELIC',
  },
  {
    code: 'JLF',
    callsign: 'JETLYFT',
  },
  {
    code: 'JLG',
    callsign: 'LOGISTICS',
  },
  {
    code: 'JLH',
    callsign: 'CESA',
  },
  {
    code: 'JLJ',
    callsign: 'HIGHBORN',
  },
  {
    code: 'JLL',
    callsign: 'LITE JET',
  },
  {
    code: 'JLM',
    callsign: 'ATLANTIC GAMBIA',
  },
  {
    code: 'JLN',
    callsign: 'JETLINE',
  },
  {
    code: 'JLX',
    callsign: 'KEN JET',
  },
  {
    code: 'JMA',
    callsign: 'CHUI',
  },
  {
    code: 'JMC',
    callsign: 'JAYEMMSEE',
  },
  {
    code: 'JME',
    callsign: 'JETMAN',
  },
  {
    code: 'JMG',
    callsign: 'TRE TORRE',
  },
  {
    code: 'JMK',
    callsign: 'JET MAGIC',
  },
  {
    code: 'JML',
    callsign: 'MALTA JET',
  },
  {
    code: 'JMM',
    callsign: 'JOICOMAIR',
  },
  {
    code: 'JMP',
    callsign: 'JUMP RUN',
  },
  {
    code: 'JMR',
    callsign: 'LAHWINGS',
  },
  {
    code: 'JMS',
    callsign: 'SKY AVIATION',
  },
  {
    code: 'JMX',
    callsign: 'JAMAICA EXPRESS',
  },
  {
    code: 'JNA',
    callsign: 'JIN AIR',
  },
  {
    code: 'JNH',
    callsign: 'JONAH',
  },
  {
    code: 'JNK',
    callsign: 'JONIKA',
  },
  {
    code: 'JNL',
    callsign: 'JETNETHERLANDS',
  },
  {
    code: 'KIE',
    callsign: 'TWEETY',
  },
  {
    code: 'KII',
    callsign: 'DRAGSTER',
  },
  {
    code: 'KIN',
    callsign: 'KINLOSS',
  },
  {
    code: 'KIP',
    callsign: 'KINNARPS',
  },
  {
    code: 'KIS',
    callsign: 'KISH AIR',
  },
  {
    code: 'KIW',
    callsign: 'KIWI',
  },
  {
    code: 'KJD',
    callsign: 'ALGERIAN AIRFORCE',
  },
  {
    code: 'KJE',
    callsign: 'KAPAJET',
  },
  {
    code: 'KJT',
    callsign: 'SHANGHAI KINGJET',
  },
  {
    code: 'KKK',
    callsign: 'ATLASJET',
  },
  {
    code: 'KLA',
    callsign: 'SKYLIGHT AIR',
  },
  {
    code: 'KLC',
    callsign: 'CITY',
  },
  {
    code: 'KLD',
    callsign: 'AIR KLAIPEDA',
  },
  {
    code: 'KLG',
    callsign: 'KARLOG',
  },
  {
    code: 'KLJ',
    callsign: 'CLASS LINE',
  },
  {
    code: 'KLM',
    callsign: 'KLM',
  },
  {
    code: 'KLR',
    callsign: 'KAY-LER',
  },
  {
    code: 'KLX',
    callsign: 'KELIX',
  },
  {
    code: 'KLZ',
    callsign: 'AEROKALUZ',
  },
  {
    code: 'KMA',
    callsign: 'KOMI AVIA',
  },
  {
    code: 'KME',
    callsign: 'GIANT IBIS',
  },
  {
    code: 'KMF',
    callsign: 'KAMGAR',
  },
  {
    code: 'KMI',
    callsign: 'KAY-MILE AIR',
  },
  {
    code: 'KMK',
    callsign: 'KAMAKA',
  },
  {
    code: 'KMW',
    callsign: 'KOSTROMA',
  },
  {
    code: 'KMZ',
    callsign: 'COMORES AVIATION',
  },
  {
    code: 'KNA',
    callsign: 'KUNMING AIR',
  },
  {
    code: 'KND',
    callsign: 'KANNITHI AIR',
  },
  {
    code: 'KNE',
    callsign: 'NAS EXPRESS',
  },
  {
    code: 'KNM',
    callsign: 'KINGDOM',
  },
  {
    code: 'KNT',
    callsign: 'KINETIC',
  },
  {
    code: 'KNY',
    callsign: 'KENLEY',
  },
  {
    code: 'KNZ',
    callsign: 'KINZE',
  },
  {
    code: 'KOA',
    callsign: 'KOANDA',
  },
  {
    code: 'KOC',
    callsign: 'SETAIR',
  },
  {
    code: 'KOK',
    callsign: 'KOKO',
  },
  {
    code: 'KOM',
    callsign: 'BLACKEAGLE',
  },
  {
    code: 'KOR',
    callsign: 'AIR KORYO',
  },
  {
    code: 'KOW',
    callsign: 'RODEO',
  },
  {
    code: 'KPA',
    callsign: 'KUN PENG',
  },
  {
    code: 'LGT',
    callsign: 'LONGTAIL',
  },
  {
    code: 'LGU',
    callsign: 'LAGUNA',
  },
  {
    code: 'LHA',
    callsign: 'AIR CANTON',
  },
  {
    code: 'LHB',
    callsign: 'FAMILY',
  },
  {
    code: 'LHC',
    callsign: 'LONDON EYE',
  },
  {
    code: 'LHG',
    callsign: 'AIR LEADER',
  },
  {
    code: 'LHK',
    callsign: 'LAHAK',
  },
  {
    code: 'LHN',
    callsign: 'LONGHORN',
  },
  {
    code: 'LHT',
    callsign: 'LUFTHANSA TECHNIK',
  },
  {
    code: 'LIA',
    callsign: 'LIAT',
  },
  {
    code: 'LIB',
    callsign: 'LIBELLE',
  },
  {
    code: 'LIC',
    callsign: 'ASIASLINE',
  },
  {
    code: 'LID',
    callsign: 'ALIDA',
  },
  {
    code: 'LIE',
    callsign: 'AL-DAWOOD AIR',
  },
  {
    code: 'LIF',
    callsign: 'LIFECARE',
  },
  {
    code: 'LIL',
    callsign: 'LILIES',
  },
  {
    code: 'LIN',
    callsign: 'AEROLIMOUSINE',
  },
  {
    code: 'LIO',
    callsign: 'LIONEL',
  },
  {
    code: 'LIP',
    callsign: 'LIPIZAN',
  },
  {
    code: 'LIR',
    callsign: 'LISLINE',
  },
  {
    code: 'LIT',
    callsign: 'LEONE',
  },
  {
    code: 'LIX',
    callsign: 'RAPIDLINX',
  },
  {
    code: 'LJB',
    callsign: 'AL JABER',
  },
  {
    code: 'LJC',
    callsign: 'LITTLE JET',
  },
  {
    code: 'LJM',
    callsign: 'COOL RUNNINGS',
  },
  {
    code: 'LJT',
    callsign: 'LIFEJET',
  },
  {
    code: 'LJY',
    callsign: 'ELJAY',
  },
  {
    code: 'LKA',
    callsign: 'LUKE AIR',
  },
  {
    code: 'LKE',
    callsign: 'LUCKY AIR',
  },
  {
    code: 'LKF',
    callsign: 'LAKEFRONT',
  },
  {
    code: 'LKH',
    callsign: 'SKYPLANET',
  },
  {
    code: 'LKN',
    callsign: 'NAMAIR',
  },
  {
    code: 'LKS',
    callsign: 'AIRLIN',
  },
  {
    code: 'LLB',
    callsign: 'LLOYDAEREO',
  },
  {
    code: 'LLC',
    callsign: 'SMALL PLANET',
  },
  {
    code: 'LLD',
    callsign: 'AVIA LLOYD',
  },
  {
    code: 'LLE',
    callsign: 'CONVALLES',
  },
  {
    code: 'LLJ',
    callsign: 'MAYBOUNE JET',
  },
  {
    code: 'LLK',
    callsign: 'AIR LIFE',
  },
  {
    code: 'LLL',
    callsign: 'LAVIE',
  },
  {
    code: 'LLM',
    callsign: 'YAMAL',
  },
  {
    code: 'MAR',
    callsign: 'HELLASMED',
  },
  {
    code: 'MAS',
    callsign: 'MALAYSIAN',
  },
  {
    code: 'MAT',
    callsign: 'MAINE-AV',
  },
  {
    code: 'MAU',
    callsign: 'AIRMAURITIUS',
  },
  {
    code: 'MAV',
    callsign: 'SEA WING',
  },
  {
    code: 'MAW',
    callsign: 'MUSTIQUE',
  },
  {
    code: 'MAX',
    callsign: 'MAX AVIATION',
  },
  {
    code: 'MAY',
    callsign: 'BLUE MED',
  },
  {
    code: 'MBA',
    callsign: 'AVAG AIR',
  },
  {
    code: 'MBB',
    callsign: 'AIR MANAS',
  },
  {
    code: 'MBC',
    callsign: 'MABECO',
  },
  {
    code: 'MBE',
    callsign: 'MARTIN',
  },
  {
    code: 'MBF',
    callsign: 'MABE FLIGHTS',
  },
  {
    code: 'MBG',
    callsign: 'CHALGROVE',
  },
  {
    code: 'MBK',
    callsign: 'MATBLACK',
  },
  {
    code: 'MBO',
    callsign: 'AEROBEL',
  },
  {
    code: 'MBR',
    callsign: 'BRAZILIAN NAVY',
  },
  {
    code: 'MBS',
    callsign: 'MBACHI AIR',
  },
  {
    code: 'MBT',
    callsign: 'MANITOBA',
  },
  {
    code: 'MCA',
    callsign: 'COASTAL CLIPPER',
  },
  {
    code: 'MCC',
    callsign: 'DISCOVERY',
  },
  {
    code: 'MCD',
    callsign: 'AIR MED',
  },
  {
    code: 'MCE',
    callsign: 'MARSHALL',
  },
  {
    code: 'MCK',
    callsign: 'MAVERICK',
  },
  {
    code: 'MCL',
    callsign: 'MEDIC',
  },
  {
    code: 'MCM',
    callsign: 'HELI AIR',
  },
  {
    code: 'MCO',
    callsign: 'MARCOS',
  },
  {
    code: 'MCP',
    callsign: 'MARCOPOLO',
  },
  {
    code: 'MCS',
    callsign: 'CARMEX',
  },
  {
    code: 'MCT',
    callsign: 'TRANS CORTES',
  },
  {
    code: 'MCV',
    callsign: 'MTC AVIACION',
  },
  {
    code: 'MCW',
    callsign: 'MACHINE WORKS',
  },
  {
    code: 'MCX',
    callsign: 'MAURICARGO',
  },
  {
    code: 'MCY',
    callsign: 'MERCY',
  },
  {
    code: 'MDA',
    callsign: 'MANDARIN',
  },
  {
    code: 'MDB',
    callsign: 'MARIANAS',
  },
  {
    code: 'MDE',
    callsign: 'MAURI-TRANS',
  },
  {
    code: 'MDF',
    callsign: 'MED-FREIGHT',
  },
  {
    code: 'MDG',
    callsign: 'AIR MADAGASCAR',
  },
  {
    code: 'MDI',
    callsign: 'MEDOPS',
  },
  {
    code: 'MDK',
    callsign: 'MADIKWE',
  },
  {
    code: 'MDM',
    callsign: 'MEDAVIA',
  },
  {
    code: 'MNM',
    callsign: 'MANASIK',
  },
  {
    code: 'MNO',
    callsign: 'TULCA',
  },
  {
    code: 'MNT',
    callsign: 'MONTSERRAT',
  },
  {
    code: 'MNU',
    callsign: 'MAINER',
  },
  {
    code: 'MNV',
    callsign: 'NAVALE',
  },
  {
    code: 'MNX',
    callsign: 'MANX',
  },
  {
    code: 'MNY',
    callsign: 'MOONEY FLIGHT',
  },
  {
    code: 'MOD',
    callsign: 'MONDEGO',
  },
  {
    code: 'MOL',
    callsign: 'TAXI MOREL',
  },
  {
    code: 'MON',
    callsign: 'MONARCH',
  },
  {
    code: 'MOP',
    callsign: 'PUBLICITARIA',
  },
  {
    code: 'MOR',
    callsign: 'AEROMORELIA',
  },
  {
    code: 'MOT',
    callsign: 'MOTAHIDA',
  },
  {
    code: 'MOV',
    callsign: 'MOV AIR',
  },
  {
    code: 'MOW',
    callsign: 'MOHAWK AIR',
  },
  {
    code: 'MOZ',
    callsign: 'MOZART',
  },
  {
    code: 'MPA',
    callsign: 'MID PAC',
  },
  {
    code: 'MPD',
    callsign: 'RED COMET',
  },
  {
    code: 'MPE',
    callsign: 'EMPRESS',
  },
  {
    code: 'MPH',
    callsign: 'MARTINAIR',
  },
  {
    code: 'MPO',
    callsign: 'AMPARO',
  },
  {
    code: 'MPR',
    callsign: 'HONEY BEE',
  },
  {
    code: 'MQT',
    callsign: 'MUSKETEER',
  },
  {
    code: 'MRA',
    callsign: 'MARTEX',
  },
  {
    code: 'MRC',
    callsign: 'MARTRANS',
  },
  {
    code: 'MRD',
    callsign: 'MERIDIAN',
  },
  {
    code: 'MRE',
    callsign: 'MED RESCUE',
  },
  {
    code: 'MRF',
    callsign: 'MAUR-FRET',
  },
  {
    code: 'MRH',
    callsign: 'MARHAM',
  },
  {
    code: 'MRJ',
    callsign: 'MERAJ AIRLINE',
  },
  {
    code: 'MRK',
    callsign: 'MARKAIR',
  },
  {
    code: 'MRN',
    callsign: 'MARIANNE',
  },
  {
    code: 'MRO',
    callsign: 'MORRISON',
  },
  {
    code: 'MRP',
    callsign: 'ABAS',
  },
  {
    code: 'MRR',
    callsign: 'MARINER',
  },
  {
    code: 'MRT',
    callsign: 'MIKE ROMEO',
  },
  {
    code: 'MRV',
    callsign: 'XENA',
  },
  {
    code: 'NDF',
    callsign: 'NAMIBIAN AIRFORCE',
  },
  {
    code: 'NDJ',
    callsign: 'BASSAMAT',
  },
  {
    code: 'NDL',
    callsign: 'NEEDLE',
  },
  {
    code: 'NDT',
    callsign: 'ND AEROTAXIS',
  },
  {
    code: 'NDU',
    callsign: 'SIOUX',
  },
  {
    code: 'NEA',
    callsign: 'NEW ENGLAND',
  },
  {
    code: 'NEC',
    callsign: 'NECON AIR',
  },
  {
    code: 'NEF',
    callsign: 'NORDEX',
  },
  {
    code: 'NEG',
    callsign: 'AGUAS NEGRAS',
  },
  {
    code: 'NEJ',
    callsign: 'NET BUSINESS',
  },
  {
    code: 'NEL',
    callsign: 'NELLA',
  },
  {
    code: 'NEN',
    callsign: 'NORTHEAST SWAN',
  },
  {
    code: 'NEP',
    callsign: 'WARISAN',
  },
  {
    code: 'NES',
    callsign: 'NEWS',
  },
  {
    code: 'NET',
    callsign: 'NETWORK',
  },
  {
    code: 'NEW',
    callsign: 'NORTHEASTERN',
  },
  {
    code: 'NEX',
    callsign: 'NEATAX',
  },
  {
    code: 'NFA',
    callsign: 'NORTH FLYING',
  },
  {
    code: 'NFE',
    callsign: 'NORDIC BLUE',
  },
  {
    code: 'NFL',
    callsign: 'GREAT LAKES',
  },
  {
    code: 'NFX',
    callsign: 'SUNSET',
  },
  {
    code: 'NGA',
    callsign: 'NIGERIA',
  },
  {
    code: 'NGC',
    callsign: 'ANGOSERVICE',
  },
  {
    code: 'NGF',
    callsign: 'ANGEL FLIGHT',
  },
  {
    code: 'NGL',
    callsign: 'MAXAIR NIGERIA',
  },
  {
    code: 'NGO',
    callsign: 'AIR ANGOL',
  },
  {
    code: 'NGP',
    callsign: 'REGAL EAGLE',
  },
  {
    code: 'NGR',
    callsign: 'NIGERIAN AIRFORCE',
  },
  {
    code: 'NGT',
    callsign: 'NIKA',
  },
  {
    code: 'NGV',
    callsign: 'ANGOAVIA',
  },
  {
    code: 'NGX',
    callsign: 'AIR GLOBAL',
  },
  {
    code: 'NHC',
    callsign: 'NORTHERN',
  },
  {
    code: 'NHD',
    callsign: 'NEMO',
  },
  {
    code: 'NHE',
    callsign: 'NORD',
  },
  {
    code: 'NHG',
    callsign: 'HELGA',
  },
  {
    code: 'NHK',
    callsign: 'NIGHTHAWK',
  },
  {
    code: 'NHL',
    callsign: 'NORTHUMBRIA',
  },
  {
    code: 'NHT',
    callsign: 'NEWHEIGHTS',
  },
  {
    code: 'NOA',
    callsign: 'NOAA',
  },
  {
    code: 'NOF',
    callsign: 'FONNA',
  },
  {
    code: 'NOH',
    callsign: 'NORTHOLT',
  },
  {
    code: 'NOJ',
    callsign: 'NOVAJET',
  },
  {
    code: 'NOK',
    callsign: 'NOK AIR',
  },
  {
    code: 'NOL',
    callsign: 'NAT AIRLINE',
  },
  {
    code: 'NOR',
    callsign: 'NORSKE',
  },
  {
    code: 'NOS',
    callsign: 'MOONFLOWER',
  },
  {
    code: 'NOT',
    callsign: 'COSTA NORTE',
  },
  {
    code: 'NOV',
    callsign: 'NOVANILE',
  },
  {
    code: 'NOW',
    callsign: 'NORWEGIAN',
  },
  {
    code: 'NOZ',
    callsign: 'NORSEMAN',
  },
  {
    code: 'NPL',
    callsign: 'AIR NEPAL',
  },
  {
    code: 'NPR',
    callsign: 'AIR NAPIER',
  },
  {
    code: 'NPT',
    callsign: 'NEPTUNE',
  },
  {
    code: 'NQN',
    callsign: 'LASA',
  },
  {
    code: 'NRA',
    callsign: 'AIRNOAR',
  },
  {
    code: 'NRK',
    callsign: 'NATURELINK',
  },
  {
    code: 'NRL',
    callsign: 'NOLINOR',
  },
  {
    code: 'PBW',
    callsign: 'BUSSARD',
  },
  {
    code: 'PBY',
    callsign: 'PEARL SERVICES',
  },
  {
    code: 'PCE',
    callsign: 'PACE',
  },
  {
    code: 'PCF',
    callsign: 'PACIFIC',
  },
  {
    code: 'PCH',
    callsign: 'PILATUS WINGS',
  },
  {
    code: 'PCI',
    callsign: 'PROTEA COIN',
  },
  {
    code: 'PCJ',
    callsign: 'PACIFIC JET',
  },
  {
    code: 'PCK',
    callsign: 'AIRPACK EXPRESS',
  },
  {
    code: 'PCL',
    callsign: 'PINNACLE GROUP',
  },
  {
    code: 'PCM',
    callsign: 'PAC VALLEY',
  },
  {
    code: 'PCN',
    callsign: 'PRINCETON',
  },
  {
    code: 'PCO',
    callsign: 'PASCO',
  },
  {
    code: 'PCP',
    callsign: 'PRINCIPAL',
  },
  {
    code: 'PCR',
    callsign: 'PACAIR',
  },
  {
    code: 'PCS',
    callsign: 'AIR PALACE',
  },
  {
    code: 'PCT',
    callsign: 'POLLUTION PATROL',
  },
  {
    code: 'PDD',
    callsign: 'PADA',
  },
  {
    code: 'PDG',
    callsign: 'OSPREY',
  },
  {
    code: 'PDI',
    callsign: 'COKI',
  },
  {
    code: 'PDK',
    callsign: 'PREDANNACK',
  },
  {
    code: 'PDQ',
    callsign: 'DISPATCH',
  },
  {
    code: 'PDT',
    callsign: 'PIEDMONT',
  },
  {
    code: 'PDU',
    callsign: 'PURDUE',
  },
  {
    code: 'PDX',
    callsign: 'PACKAGE EXPRESS',
  },
  {
    code: 'PDY',
    callsign: 'PENDLEY',
  },
  {
    code: 'PEC',
    callsign: 'PASS AVIATION',
  },
  {
    code: 'PEF',
    callsign: 'PERFECT AVIATION',
  },
  {
    code: 'PEG',
    callsign: 'PEGJET',
  },
  {
    code: 'PEI',
    callsign: 'PANAMEDIA',
  },
  {
    code: 'PEL',
    callsign: 'AIRPEN',
  },
  {
    code: 'PEO',
    callsign: 'PETRO AIR',
  },
  {
    code: 'PER',
    callsign: 'PAS AIR',
  },
  {
    code: 'PET',
    callsign: 'AEROPETRO',
  },
  {
    code: 'PEV',
    callsign: 'PEOPLES',
  },
  {
    code: 'PSU',
    callsign: 'PREMIUM',
  },
  {
    code: 'PSV',
    callsign: 'PROSERVICIOS',
  },
  {
    code: 'PSW',
    callsign: 'PSKOVAVIA',
  },
  {
    code: 'PSX',
    callsign: 'SMILEY',
  },
  {
    code: 'PSY',
    callsign: 'PLYSA',
  },
  {
    code: 'PSZ',
    callsign: 'POP-AIR',
  },
  {
    code: 'PTA',
    callsign: 'PONTAIR',
  },
  {
    code: 'PTB',
    callsign: 'PASSAREDO',
  },
  {
    code: 'PTC',
    callsign: 'MENTOR',
  },
  {
    code: 'PTD',
    callsign: 'PITY',
  },
  {
    code: 'PTE',
    callsign: 'AERO-COP',
  },
  {
    code: 'PTH',
    callsign: 'PROTEUS',
  },
  {
    code: 'PTJ',
    callsign: 'PAT-JETS',
  },
  {
    code: 'PTK',
    callsign: 'PETROKAM',
  },
  {
    code: 'PTL',
    callsign: 'PLANTATION',
  },
  {
    code: 'PTM',
    callsign: 'POSTMAN',
  },
  {
    code: 'PTN',
    callsign: 'PANTANAL',
  },
  {
    code: 'PTO',
    callsign: 'ROOKIE',
  },
  {
    code: 'PTP',
    callsign: 'TRANS PACIFIC',
  },
  {
    code: 'PTQ',
    callsign: 'TOWNSEND',
  },
  {
    code: 'PTS',
    callsign: 'PENTRA',
  },
  {
    code: 'PTY',
    callsign: 'PETTY',
  },
  {
    code: 'PUA',
    callsign: 'PLUNA',
  },
  {
    code: 'PUE',
    callsign: 'SPANISH',
  },
  {
    code: 'PUL',
    callsign: 'PULSE',
  },
  {
    code: 'PUN',
    callsign: 'PRATISE',
  },
  {
    code: 'PUR',
    callsign: 'SPURWING',
  },
  {
    code: 'PUV',
    callsign: 'PUBLIVOO',
  },
  {
    code: 'PVA',
    callsign: 'PRIVATE AIR',
  },
  {
    code: 'PVD',
    callsign: 'PAD AVIATION',
  },
  {
    code: 'PVG',
    callsign: 'PRIVILEGE',
  },
  {
    code: 'PVL',
    callsign: 'PROVINCIAL',
  },
  {
    code: 'PVN',
    callsign: 'PERUVIAN',
  },
  {
    code: 'PVO',
    callsign: 'PRIVATE ORANGE',
  },
  {
    code: 'PVS',
    callsign: 'PASA',
  },
  {
    code: 'PVU',
    callsign: 'PEAU',
  },
  {
    code: 'PVV',
    callsign: 'SUNDAY',
  },
  {
    code: 'PWA',
    callsign: 'PRIESTER',
  },
  {
    code: 'REY',
    callsign: 'AEROREY',
  },
  {
    code: 'RFD',
    callsign: 'RAFILHER',
  },
  {
    code: 'RFE',
    callsign: 'RUSSIAN EXPRESS',
  },
  {
    code: 'RFF',
    callsign: 'RUSSIAN AIRFORCE',
  },
  {
    code: 'RFH',
    callsign: 'NUT',
  },
  {
    code: 'RFI',
    callsign: 'SHERLOCK',
  },
  {
    code: 'RFR',
    callsign: 'RAFAIR',
  },
  {
    code: 'RFT',
    callsign: 'ROMANIAN ACADEMY',
  },
  {
    code: 'RFX',
    callsign: 'REFLEX',
  },
  {
    code: 'RFY',
    callsign: 'AEROFIL',
  },
  {
    code: 'RGA',
    callsign: 'REGA',
  },
  {
    code: 'RGB',
    callsign: 'REGIONAL AIR',
  },
  {
    code: 'RGC',
    callsign: 'REGIOMONTANO',
  },
  {
    code: 'RGD',
    callsign: 'KUWAIT CARGO',
  },
  {
    code: 'RGE',
    callsign: 'REGENT',
  },
  {
    code: 'RGK',
    callsign: 'GRANCA',
  },
  {
    code: 'RGL',
    callsign: 'RIDGELINE',
  },
  {
    code: 'RGN',
    callsign: 'CYGNUS AIR',
  },
  {
    code: 'RGO',
    callsign: 'PASSARO',
  },
  {
    code: 'RGP',
    callsign: 'GARDEN CITY',
  },
  {
    code: 'RGR',
    callsign: 'AVIOR REGIONAL',
  },
  {
    code: 'RGS',
    callsign: 'RENOWN',
  },
  {
    code: 'RGV',
    callsign: 'VOLON',
  },
  {
    code: 'RGY',
    callsign: 'REGENCY',
  },
  {
    code: 'RHC',
    callsign: 'REDAIR',
  },
  {
    code: 'RHD',
    callsign: 'RED HEAD',
  },
  {
    code: 'RHH',
    callsign: 'STAR CRESCENT',
  },
  {
    code: 'RHK',
    callsign: 'REDHAWK',
  },
  {
    code: 'RHL',
    callsign: 'ARCHIPELS',
  },
  {
    code: 'RIA',
    callsign: 'RICHAIR',
  },
  {
    code: 'RIC',
    callsign: 'RICHARDSON',
  },
  {
    code: 'RIE',
    callsign: 'ARIEL',
  },
  {
    code: 'RIF',
    callsign: 'INTERMIN AVIA',
  },
  {
    code: 'RIH',
    callsign: 'RAHILA',
  },
  {
    code: 'RII',
    callsign: 'TICA',
  },
  {
    code: 'RIM',
    callsign: 'RIMROCK',
  },
  {
    code: 'RIN',
    callsign: 'RISSINGTON',
  },
  {
    code: 'RIO',
    callsign: 'RIO LINHAS',
  },
  {
    code: 'RIS',
    callsign: 'AERIS',
  },
  {
    code: 'RSS',
    callsign: 'ROSS CHARTER',
  },
  {
    code: 'RST',
    callsign: 'RESORT AIR',
  },
  {
    code: 'RSU',
    callsign: 'AEROSUR',
  },
  {
    code: 'RSV',
    callsign: 'RED SKY',
  },
  {
    code: 'RSW',
    callsign: 'ROYAL SKY',
  },
  {
    code: 'RSX',
    callsign: 'RED SEA',
  },
  {
    code: 'RSY',
    callsign: 'RUSSIAN SKY',
  },
  {
    code: 'RTD',
    callsign: 'RIPTIDE',
  },
  {
    code: 'RTE',
    callsign: 'LA MONTANA',
  },
  {
    code: 'RTG',
    callsign: 'REITAN',
  },
  {
    code: 'RTI',
    callsign: 'ROTORITALIA',
  },
  {
    code: 'RTL',
    callsign: 'ROYAL BIRD',
  },
  {
    code: 'RTM',
    callsign: 'AERO TRANSAM',
  },
  {
    code: 'RTN',
    callsign: 'RAYTHEON',
  },
  {
    code: 'RTO',
    callsign: 'RACCOON',
  },
  {
    code: 'RTS',
    callsign: 'RELIEF',
  },
  {
    code: 'RTU',
    callsign: 'AEROTUCAN',
  },
  {
    code: 'RTV',
    callsign: 'TIC-TAC',
  },
  {
    code: 'RTY',
    callsign: 'AIMS COLLEGE',
  },
  {
    code: 'RTZ',
    callsign: 'AEROTUZLA',
  },
  {
    code: 'RUC',
    callsign: 'RUTACA',
  },
  {
    code: 'RUF',
    callsign: 'ROUGH RIDER',
  },
  {
    code: 'RUK',
    callsign: 'BLUE MAX',
  },
  {
    code: 'RUN',
    callsign: 'CARGO TURK',
  },
  {
    code: 'RUR',
    callsign: 'RUSSTAR',
  },
  {
    code: 'RUT',
    callsign: 'YADID',
  },
  {
    code: 'RVA',
    callsign: 'RAVEN AIR',
  },
  {
    code: 'RVC',
    callsign: 'RIVER CITY',
  },
  {
    code: 'RVE',
    callsign: 'AIRVENTURE',
  },
  {
    code: 'RVF',
    callsign: 'RAVEN FLIGHT',
  },
  {
    code: 'RVG',
    callsign: 'AERO VINA',
  },
  {
    code: 'RVJ',
    callsign: 'RIVERS JET',
  },
  {
    code: 'RVM',
    callsign: 'RIVER',
  },
  {
    code: 'RVN',
    callsign: 'RAVEN U-S',
  },
  {
    code: 'RVP',
    callsign: 'SEVAIR',
  },
  {
    code: 'SED',
    callsign: 'SEDONA AIR',
  },
  {
    code: 'SEE',
    callsign: 'SEEKER',
  },
  {
    code: 'SEH',
    callsign: 'AIR CRETE',
  },
  {
    code: 'SEI',
    callsign: 'TRANSPORTE SIERRA',
  },
  {
    code: 'SEJ',
    callsign: 'SPICEJET',
  },
  {
    code: 'SEK',
    callsign: 'EAST RIDER',
  },
  {
    code: 'SEL',
    callsign: 'SENTEL',
  },
  {
    code: 'SEM',
    callsign: 'SEMO',
  },
  {
    code: 'SEN',
    callsign: 'SENIOR AIR',
  },
  {
    code: 'SEO',
    callsign: 'SELCON AIR',
  },
  {
    code: 'SEP',
    callsign: 'SERENE',
  },
  {
    code: 'SES',
    callsign: 'SERVISAL',
  },
  {
    code: 'SET',
    callsign: 'SOLENTA',
  },
  {
    code: 'SEV',
    callsign: 'CARGOPRESS',
  },
  {
    code: 'SEW',
    callsign: 'SKYWARD EXPRESS',
  },
  {
    code: 'SEY',
    callsign: 'SEYCHELLES',
  },
  {
    code: 'SFD',
    callsign: 'DRACO',
  },
  {
    code: 'SFE',
    callsign: 'STAR FLIGHT',
  },
  {
    code: 'SFF',
    callsign: 'SWIFTWING',
  },
  {
    code: 'SFH',
    callsign: 'STARFISH',
  },
  {
    code: 'SFI',
    callsign: 'SPACE STAR',
  },
  {
    code: 'SFJ',
    callsign: 'STARFLYER',
  },
  {
    code: 'SFL',
    callsign: 'SWIFTLINE',
  },
  {
    code: 'SFM',
    callsign: 'AIR SAFAR',
  },
  {
    code: 'SFN',
    callsign: 'SAFIRAN',
  },
  {
    code: 'SFR',
    callsign: 'CARGO',
  },
  {
    code: 'SFS',
    callsign: 'TOPO',
  },
  {
    code: 'SFT',
    callsign: 'SKYFREIGHT',
  },
  {
    code: 'SFU',
    callsign: 'SAINTS',
  },
  {
    code: 'SFV',
    callsign: 'SAFE SERVICIOS',
  },
  {
    code: 'SFW',
    callsign: 'SAFI AIRWAYS',
  },
  {
    code: 'SFX',
    callsign: 'SWAMP FOX',
  },
  {
    code: 'SFY',
    callsign: 'SKY FLITE',
  },
  {
    code: 'SGA',
    callsign: 'SOL URUGUAY',
  },
  {
    code: 'SGB',
    callsign: 'SONGBIRD',
  },
  {
    code: 'SGC',
    callsign: 'SPEC WINGS',
  },
  {
    code: 'SGD',
    callsign: 'AIR BLUE',
  },
  {
    code: 'SGH',
    callsign: 'SERVISAIR',
  },
  {
    code: 'SGO',
    callsign: 'SPEEDGOAT',
  },
  {
    code: 'SGP',
    callsign: 'SAGOLAIR',
  },
  {
    code: 'SGQ',
    callsign: 'SAUDI GOLD',
  },
  {
    code: 'SGT',
    callsign: 'STARGATE',
  },
  {
    code: 'SMZ',
    callsign: 'SCAMPTON',
  },
  {
    code: 'SNA',
    callsign: 'CANA',
  },
  {
    code: 'SNB',
    callsign: 'STERLING',
  },
  {
    code: 'SNC',
    callsign: 'NIGHT CARGO',
  },
  {
    code: 'SND',
    callsign: 'SNOWBIRD',
  },
  {
    code: 'SNF',
    callsign: 'MATZADA',
  },
  {
    code: 'SNG',
    callsign: 'SNOW EAGLE',
  },
  {
    code: 'SNI',
    callsign: 'SAVANAHLINE',
  },
  {
    code: 'SNJ',
    callsign: 'NEWSKY',
  },
  {
    code: 'SNK',
    callsign: 'SUN KING',
  },
  {
    code: 'SNL',
    callsign: 'SOONAIR',
  },
  {
    code: 'SNM',
    callsign: 'SERVIZI AEREI',
  },
  {
    code: 'SNO',
    callsign: 'SONAVI',
  },
  {
    code: 'SNP',
    callsign: 'SUN PACIFIC',
  },
  {
    code: 'SNQ',
    callsign: 'EXECU-QUEST',
  },
  {
    code: 'SNR',
    callsign: 'SUN GROUP',
  },
  {
    code: 'SNT',
    callsign: 'SUNCOAST',
  },
  {
    code: 'SNV',
    callsign: 'SUDANESE',
  },
  {
    code: 'SNW',
    callsign: 'SUN WEST',
  },
  {
    code: 'SOA',
    callsign: 'SOUTH AIRCHARTER',
  },
  {
    code: 'SOB',
    callsign: 'STABO',
  },
  {
    code: 'SOC',
    callsign: 'SERVIAEREO',
  },
  {
    code: 'SOD',
    callsign: 'ALSOL',
  },
  {
    code: 'SOE',
    callsign: 'AIR SOLEIL',
  },
  {
    code: 'SOF',
    callsign: 'SKY COMFORT',
  },
  {
    code: 'SOH',
    callsign: 'SOUTHERN OHIO',
  },
  {
    code: 'SOK',
    callsign: 'SNOW SKI',
  },
  {
    code: 'SOL',
    callsign: 'SOLOMON',
  },
  {
    code: 'SOM',
    callsign: 'AERO-SAL',
  },
  {
    code: 'SON',
    callsign: 'SONAS',
  },
  {
    code: 'SOO',
    callsign: 'SOUTHERN AIR',
  },
  {
    code: 'SOP',
    callsign: 'SOLINAIR',
  },
  {
    code: 'SOR',
    callsign: 'SONAIR',
  },
  {
    code: 'SOT',
    callsign: 'SOUTH COURIER',
  },
  {
    code: 'SOU',
    callsign: 'PARADISE',
  },
  {
    code: 'SOW',
    callsign: 'SPARROW',
  },
  {
    code: 'SOY',
    callsign: 'SORIANO',
  },
  {
    code: 'SPA',
    callsign: 'SIERRA PACIFIC',
  },
  {
    code: 'SVF',
    callsign: 'SWEDEFORCE',
  },
  {
    code: 'SVG',
    callsign: 'SOLAVIA',
  },
  {
    code: 'SVL',
    callsign: 'SUN DEVIL',
  },
  {
    code: 'SVN',
    callsign: 'SAVANAIR',
  },
  {
    code: 'SVP',
    callsign: 'STAR INDIA',
  },
  {
    code: 'SVR',
    callsign: 'SVERDLOVSK AIR',
  },
  {
    code: 'SVV',
    callsign: 'SALTAVIATION',
  },
  {
    code: 'SVW',
    callsign: 'SILVER ARROWS',
  },
  {
    code: 'SVX',
    callsign: 'SECURITY AIR',
  },
  {
    code: 'SVZ',
    callsign: 'SERVICIOS VIZ',
  },
  {
    code: 'SWA',
    callsign: 'SOUTHWEST',
  },
  {
    code: 'SWC',
    callsign: 'SWEDCOPTER',
  },
  {
    code: 'SWD',
    callsign: 'SAWBLADE',
  },
  {
    code: 'SWE',
    callsign: 'SWEDECARE',
  },
  {
    code: 'SWF',
    callsign: 'GALAIR',
  },
  {
    code: 'SWG',
    callsign: 'SUNWING',
  },
  {
    code: 'SWH',
    callsign: 'SHOCKWAVE',
  },
  {
    code: 'SWI',
    callsign: 'SUNWORLD',
  },
  {
    code: 'SWK',
    callsign: 'ZAGEL',
  },
  {
    code: 'SWM',
    callsign: 'SKY ANGKOR',
  },
  {
    code: 'SWN',
    callsign: 'AIR SWEDEN',
  },
  {
    code: 'SWO',
    callsign: 'SIVA',
  },
  {
    code: 'SWP',
    callsign: 'STAR WORK',
  },
  {
    code: 'SWQ',
    callsign: 'SWIFTFLIGHT',
  },
  {
    code: 'SWR',
    callsign: 'SWISS',
  },
  {
    code: 'SWS',
    callsign: 'SUNNY WEST',
  },
  {
    code: 'SWT',
    callsign: 'SWIFT',
  },
  {
    code: 'SWV',
    callsign: 'SWEDEMED',
  },
  {
    code: 'SWW',
    callsign: 'LAKE',
  },
  {
    code: 'SWZ',
    callsign: 'SKYWISE',
  },
  {
    code: 'SXA',
    callsign: 'FERRY',
  },
  {
    code: 'SXC',
    callsign: 'SKY EXEC',
  },
  {
    code: 'SXD',
    callsign: 'SUNRISE',
  },
  {
    code: 'SXF',
    callsign: 'SOUTHERN FLIGHT',
  },
  {
    code: 'SXI',
    callsign: 'SOUTHERN CROSS',
  },
  {
    code: 'SXJ',
    callsign: 'BIG WING',
  },
  {
    code: 'SXL',
    callsign: 'SONIC',
  },
  {
    code: 'SXM',
    callsign: 'SAINT MAARTEN',
  },
  {
    code: 'TGC',
    callsign: 'THANET',
  },
  {
    code: 'TGE',
    callsign: 'TASA',
  },
  {
    code: 'TGG',
    callsign: 'TIGGOZ',
  },
  {
    code: 'TGM',
    callsign: 'TAG ESPANA',
  },
  {
    code: 'TGN',
    callsign: 'TRIGANA',
  },
  {
    code: 'TGO',
    callsign: 'TRANSPORT',
  },
  {
    code: 'TGP',
    callsign: 'TROPICANA',
  },
  {
    code: 'TGT',
    callsign: 'TARGET',
  },
  {
    code: 'TGU',
    callsign: 'CHAPIN',
  },
  {
    code: 'TGW',
    callsign: 'SCOOTER',
  },
  {
    code: 'TGX',
    callsign: 'TRANSGABON',
  },
  {
    code: 'TGY',
    callsign: 'TRANS GUYANA',
  },
  {
    code: 'TGZ',
    callsign: 'TAMAZI',
  },
  {
    code: 'THA',
    callsign: 'THAI',
  },
  {
    code: 'THC',
    callsign: 'TRANAVEN',
  },
  {
    code: 'THD',
    callsign: 'THAI SMILE',
  },
  {
    code: 'THF',
    callsign: 'TOURAINE HELICO',
  },
  {
    code: 'THK',
    callsign: 'HUR KUS',
  },
  {
    code: 'THL',
    callsign: 'TERNHILL',
  },
  {
    code: 'THR',
    callsign: 'TEHRAN AIR',
  },
  {
    code: 'THS',
    callsign: 'TUSAS',
  },
  {
    code: 'THT',
    callsign: 'TAHITI AIRLINES',
  },
  {
    code: 'THU',
    callsign: 'AIR THUNDER',
  },
  {
    code: 'THY',
    callsign: 'TURKISH',
  },
  {
    code: 'TIA',
    callsign: 'TRANS INTERNATIONAL',
  },
  {
    code: 'TIB',
    callsign: 'TRIP',
  },
  {
    code: 'TIC',
    callsign: 'TAC AIRLINES',
  },
  {
    code: 'TIE',
    callsign: 'TIME AIR',
  },
  {
    code: 'TIG',
    callsign: 'TIGRIS',
  },
  {
    code: 'OMB',
    callsign: 'OMNIBLU',
  },
  {
    code: 'OMD',
    callsign: 'NOMADIC',
  },
  {
    code: 'OME',
    callsign: 'OMEGA TANKER',
  },
  {
    code: 'OMF',
    callsign: 'OMNIFLYS',
  },
  {
    code: 'OMG',
    callsign: 'OMEGA',
  },
  {
    code: 'OMI',
    callsign: 'OMNI AIR',
  },
  {
    code: 'OML',
    callsign: 'MAMBRA',
  },
  {
    code: 'OMR',
    callsign: 'ORMINE',
  },
  {
    code: 'OMS',
    callsign: 'MAZOON',
  },
  {
    code: 'ONE',
    callsign: 'OCEANAIR',
  },
  {
    code: 'ONI',
    callsign: 'OMNI TRAINING',
  },
  {
    code: 'ONO',
    callsign: 'NORAERO',
  },
  {
    code: 'ONS',
    callsign: 'AIR DREAMS',
  },
  {
    code: 'ONT',
    callsign: 'ONTARIO GOVERNMENT',
  },
  {
    code: 'PFC',
    callsign: 'PERFORMANCE AIR',
  },
  {
    code: 'PFI',
    callsign: 'PACIFICO CHIHUAHUA',
  },
  {
    code: 'PFL',
    callsign: 'PACIFICFLYER',
  },
  {
    code: 'PFP',
    callsign: 'POLICIA FEDERAL',
  },
  {
    code: 'PFR',
    callsign: 'PACIFIC WEST',
  },
  {
    code: 'PFS',
    callsign: 'JAY BIRD',
  },
  {
    code: 'PFT',
    callsign: 'PARAGON FLIGHT',
  },
  {
    code: 'PFU',
    callsign: 'PFUNWA',
  },
  {
    code: 'PFY',
    callsign: 'PELFLIGHT',
  },
  {
    code: 'PFZ',
    callsign: 'PROFLIGHT-ZAMBIA',
  },
  {
    code: 'PGA',
    callsign: 'PORTUGALIA',
  },
  {
    code: 'PGJ',
    callsign: 'UBER',
  },
  {
    code: 'PGL',
    callsign: 'PREMIERE',
  },
  {
    code: 'PGN',
    callsign: 'PEREGRINE',
  },
  {
    code: 'PGT',
    callsign: 'SUNTURK',
  },
  {
    code: 'PGV',
    callsign: 'FLANA',
  },
  {
    code: 'PGX',
    callsign: 'PARAGON EXPRESS',
  },
  {
    code: 'PHB',
    callsign: 'PHOEBUS',
  },
  {
    code: 'PHC',
    callsign: 'HELICOPTERS',
  },
  {
    code: 'PHD',
    callsign: 'PANHANDLE',
  },
  {
    code: 'PHE',
    callsign: 'PAWAN HANS',
  },
  {
    code: 'PHF',
    callsign: 'SHAN FENG',
  },
  {
    code: 'PHG',
    callsign: 'PENNINE',
  },
  {
    code: 'PHH',
    callsign: 'IBIS',
  },
  {
    code: 'PHJ',
    callsign: 'PEACHJET',
  },
  {
    code: 'PHL',
    callsign: 'PHILLIPS',
  },
  {
    code: 'PHM',
    callsign: 'PETROLEUM',
  },
  {
    code: 'PHN',
    callsign: 'JADESTAR',
  },
  {
    code: 'PHP',
    callsign: 'SKY POWER',
  },
  {
    code: 'PHS',
    callsign: 'PASSAT',
  },
  {
    code: 'PHT',
    callsign: 'PANANK',
  },
  {
    code: 'PHU',
    callsign: 'PANNON',
  },
  {
    code: 'PHV',
    callsign: 'NEW BIRD',
  },
  {
    code: 'PIA',
    callsign: 'PAKISTAN',
  },
  {
    code: 'PIC',
    callsign: 'PACIFIC AIRLINES',
  },
  {
    code: 'PIK',
    callsign: 'POLICE IKARUS',
  },
  {
    code: 'PIM',
    callsign: 'PINFRAMAT',
  },
  {
    code: 'PPR',
    callsign: 'PROPSTAR',
  },
  {
    code: 'PPS',
    callsign: 'PIPESTONE',
  },
  {
    code: 'PPU',
    callsign: 'PAPUGA',
  },
  {
    code: 'PPV',
    callsign: 'PAVLOVSK',
  },
  {
    code: 'PQA',
    callsign: 'SAGE BRUSH',
  },
  {
    code: 'PRA',
    callsign: 'PARSAVIA',
  },
  {
    code: 'PRB',
    callsign: 'PRAMS AIR',
  },
  {
    code: 'PRD',
    callsign: 'PRESIDENTIAL',
  },
  {
    code: 'PRE',
    callsign: 'PRECISION',
  },
  {
    code: 'PRF',
    callsign: 'PRECISION AIR',
  },
  {
    code: 'PRG',
    callsign: 'AIR PRAGUE',
  },
  {
    code: 'PRH',
    callsign: 'PROHAWK',
  },
  {
    code: 'PRI',
    callsign: 'PRIMERA',
  },
  {
    code: 'PRJ',
    callsign: 'PRONAIR',
  },
  {
    code: 'PRK',
    callsign: 'PARACA',
  },
  {
    code: 'PRM',
    callsign: 'PRIME AIR',
  },
  {
    code: 'PRN',
    callsign: 'PIRINAIR EXPRESS',
  },
  {
    code: 'PRO',
    callsign: 'PROPAIR',
  },
  {
    code: 'PRP',
    callsign: 'PRONTO',
  },
  {
    code: 'PRS',
    callsign: 'PARS SKY',
  },
  {
    code: 'PRT',
    callsign: 'PRIME ITALIA',
  },
  {
    code: 'PRU',
    callsign: 'COLORBIRD',
  },
  {
    code: 'PRW',
    callsign: 'JETBIRD',
  },
  {
    code: 'PRY',
    callsign: 'PRIORITY AIR',
  },
  {
    code: 'PRZ',
    callsign: 'AERONAUTIC',
  },
  {
    code: 'PSA',
    callsign: 'PACIFIC ISLE',
  },
  {
    code: 'PSC',
    callsign: 'PASCAN',
  },
  {
    code: 'PSE',
    callsign: 'SIPSE',
  },
  {
    code: 'PSF',
    callsign: 'LIZARD',
  },
  {
    code: 'PSG',
    callsign: 'PIER JET',
  },
  {
    code: 'PSH',
    callsign: 'PASSION',
  },
  {
    code: 'PSI',
    callsign: 'PONT',
  },
  {
    code: 'PSK',
    callsign: 'SUPPORT',
  },
  {
    code: 'PSN',
    callsign: 'POTOSINA',
  },
  {
    code: 'PSO',
    callsign: 'AEROPEGASO',
  },
  {
    code: 'PSP',
    callsign: 'PUBLISERVICIOS',
  },
  {
    code: 'PSR',
    callsign: 'POLESTAR',
  },
  {
    code: 'PSS',
    callsign: 'PROGRESS',
  },
  {
    code: 'PST',
    callsign: 'TURISMO REGIONAL',
  },
  {
    code: 'RCB',
    callsign: 'BALEARES',
  },
  {
    code: 'RCD',
    callsign: 'AEROCLUB',
  },
  {
    code: 'RCG',
    callsign: 'ROYAL CARGO',
  },
  {
    code: 'RCH',
    callsign: 'REACH',
  },
  {
    code: 'RCI',
    callsign: 'AIR CASSAI',
  },
  {
    code: 'RCJ',
    callsign: 'NEWPIN',
  },
  {
    code: 'RCM',
    callsign: 'CANNES AZUR',
  },
  {
    code: 'RCN',
    callsign: 'SPEED CARGO',
  },
  {
    code: 'RCO',
    callsign: 'AEROCOAHUILA',
  },
  {
    code: 'RCR',
    callsign: 'ROMCARGO',
  },
  {
    code: 'RCT',
    callsign: 'GREEN SKY',
  },
  {
    code: 'RCX',
    callsign: 'SERVICE CENTER',
  },
  {
    code: 'RDA',
    callsign: 'RADA',
  },
  {
    code: 'RDC',
    callsign: 'LINEAS DELCENTRO',
  },
  {
    code: 'RDF',
    callsign: 'RED FOX',
  },
  {
    code: 'RDJ',
    callsign: 'RED JET',
  },
  {
    code: 'RDK',
    callsign: 'RED DUKE',
  },
  {
    code: 'RDL',
    callsign: 'RED LIFT',
  },
  {
    code: 'RDM',
    callsign: 'AIR ADA',
  },
  {
    code: 'RDN',
    callsign: 'RED BARN',
  },
  {
    code: 'RDO',
    callsign: 'AIRDOM',
  },
  {
    code: 'RDR',
    callsign: 'RED STAR',
  },
  {
    code: 'RDS',
    callsign: 'RHOADES EXPRESS',
  },
  {
    code: 'RDY',
    callsign: 'RODNEY',
  },
  {
    code: 'RDZ',
    callsign: 'RODZE AIR',
  },
  {
    code: 'REA',
    callsign: 'RED DOMINICANA',
  },
  {
    code: 'REB',
    callsign: 'REBUS',
  },
  {
    code: 'REC',
    callsign: 'TRANS-RECO',
  },
  {
    code: 'RED',
    callsign: 'RED CROSS',
  },
  {
    code: 'REF',
    callsign: 'REEF AIR',
  },
  {
    code: 'REG',
    callsign: 'REGIONAL SERVICES',
  },
  {
    code: 'REH',
    callsign: 'REACHMED',
  },
  {
    code: 'REJ',
    callsign: 'REGIONAL LINK',
  },
  {
    code: 'REL',
    callsign: 'RELIANCE AIR',
  },
  {
    code: 'REM',
    callsign: 'ROSE',
  },
  {
    code: 'REN',
    callsign: 'ARENA',
  },
  {
    code: 'REO',
    callsign: 'RIO',
  },
  {
    code: 'RER',
    callsign: 'REGAIR',
  },
  {
    code: 'REU',
    callsign: 'REUNION',
  },
  {
    code: 'REV',
    callsign: 'ENDURANCE',
  },
  {
    code: 'REX',
    callsign: 'RAM EXPRESS',
  },
  {
    code: 'ROW',
    callsign: 'ROTORWING',
  },
  {
    code: 'ROX',
    callsign: 'ROBLEX',
  },
  {
    code: 'ROY',
    callsign: 'SKY DIVE',
  },
  {
    code: 'RPA',
    callsign: 'BRICKYARD',
  },
  {
    code: 'RPB',
    callsign: 'AEROREPUBLICA',
  },
  {
    code: 'RPC',
    callsign: 'AEROPACSA',
  },
  {
    code: 'RPM',
    callsign: 'COBALT',
  },
  {
    code: 'RPN',
    callsign: 'REPATRIATE',
  },
  {
    code: 'RPP',
    callsign: 'PILIPINAS',
  },
  {
    code: 'RPR',
    callsign: 'AEROPROFESIONAL',
  },
  {
    code: 'RPS',
    callsign: 'RESPONSE',
  },
  {
    code: 'RPX',
    callsign: 'RAPEX',
  },
  {
    code: 'RQT',
    callsign: 'AIR QUEST',
  },
  {
    code: 'RRA',
    callsign: 'ROYAL RWANDA',
  },
  {
    code: 'RRC',
    callsign: 'AEROROCA',
  },
  {
    code: 'RRE',
    callsign: 'AERO TORREON',
  },
  {
    code: 'RRF',
    callsign: 'KITTY',
  },
  {
    code: 'RRL',
    callsign: 'MERLIN',
  },
  {
    code: 'RRR',
    callsign: 'ASCOT',
  },
  {
    code: 'RRS',
    callsign: 'BLACKBOX',
  },
  {
    code: 'RRT',
    callsign: 'SIERRA ALTA',
  },
  {
    code: 'RRV',
    callsign: 'SKYROVER',
  },
  {
    code: 'RRZ',
    callsign: 'ROLLRIGHT',
  },
  {
    code: 'RSA',
    callsign: 'ESRA',
  },
  {
    code: 'RSB',
    callsign: 'RUBYSTAR',
  },
  {
    code: 'RSD',
    callsign: 'STATE AERO',
  },
  {
    code: 'RSF',
    callsign: 'ARSAF',
  },
  {
    code: 'RSH',
    callsign: 'SAHARA',
  },
  {
    code: 'RSI',
    callsign: 'AIR SUNSHINE',
  },
  {
    code: 'RSJ',
    callsign: 'RUSJET',
  },
  {
    code: 'RSK',
    callsign: 'REDSKIN',
  },
  {
    code: 'RSL',
    callsign: 'PANAMA RENTAL',
  },
  {
    code: 'RSM',
    callsign: 'ROYAL STAR',
  },
  {
    code: 'RSO',
    callsign: 'AERO ASIA',
  },
  {
    code: 'RSP',
    callsign: 'RED STRIPE',
  },
  {
    code: 'RSQ',
    callsign: 'SKYMEDIC',
  },
  {
    code: 'RSR',
    callsign: 'CONGOSERV',
  },
  {
    code: 'SCE',
    callsign: 'SCENIC',
  },
  {
    code: 'SCF',
    callsign: 'SOCOFER',
  },
  {
    code: 'SCG',
    callsign: 'COSTA CALIDA',
  },
  {
    code: 'SCH',
    callsign: 'OCEAN BIRD',
  },
  {
    code: 'SCI',
    callsign: 'SAN CRISTOBAL',
  },
  {
    code: 'SCK',
    callsign: 'SKYCAM',
  },
  {
    code: 'SCL',
    callsign: 'SHELL CANADA',
  },
  {
    code: 'SCM',
    callsign: 'SCREAMER',
  },
  {
    code: 'SCN',
    callsign: 'SILICON',
  },
  {
    code: 'SCO',
    callsign: 'BRAVEHEART',
  },
  {
    code: 'SCP',
    callsign: 'SKY COPTER',
  },
  {
    code: 'SCQ',
    callsign: 'SCAVAC',
  },
  {
    code: 'SCR',
    callsign: 'SILVER CLOUD',
  },
  {
    code: 'SCS',
    callsign: 'SOUTHERN CHARTERS',
  },
  {
    code: 'SCT',
    callsign: 'SAAB-CRAFT',
  },
  {
    code: 'SCU',
    callsign: 'SKELLY',
  },
  {
    code: 'SCX',
    callsign: 'SUN COUNTRY',
  },
  {
    code: 'SDB',
    callsign: 'SHORE AIR',
  },
  {
    code: 'SDC',
    callsign: 'SUNDANCE',
  },
  {
    code: 'SDD',
    callsign: 'SKY DANCE',
  },
  {
    code: 'SDE',
    callsign: 'STAMPEDE',
  },
  {
    code: 'SDG',
    callsign: 'HI STAR',
  },
  {
    code: 'SDI',
    callsign: 'SERVI DINAMIC',
  },
  {
    code: 'SDK',
    callsign: 'SADELCA',
  },
  {
    code: 'SDL',
    callsign: 'SKYDRIFT',
  },
  {
    code: 'SDM',
    callsign: 'ROSSIYA',
  },
  {
    code: 'SDN',
    callsign: 'BLUE NILE',
  },
  {
    code: 'SDO',
    callsign: 'AERO DOMINGO',
  },
  {
    code: 'SDQ',
    callsign: 'MEXTECNICO',
  },
  {
    code: 'SDR',
    callsign: 'SUNDAIR',
  },
  {
    code: 'SDS',
    callsign: 'STANDARDS',
  },
  {
    code: 'SDT',
    callsign: 'SADAT',
  },
  {
    code: 'SDU',
    callsign: 'SANTOS',
  },
  {
    code: 'SDV',
    callsign: 'SELVA',
  },
  {
    code: 'SDX',
    callsign: 'SERVICIO TECNICO',
  },
  {
    code: 'SDY',
    callsign: 'SANDY ISLE',
  },
  {
    code: 'SDZ',
    callsign: 'SUDANA',
  },
  {
    code: 'SEA',
    callsign: 'SOUTHEAST AIR',
  },
  {
    code: 'SEC',
    callsign: 'SECUREX',
  },
  {
    code: 'SLC',
    callsign: 'SALSA',
  },
  {
    code: 'SLD',
    callsign: 'SILVERLINE',
  },
  {
    code: 'SLE',
    callsign: 'SLIPSTREAM',
  },
  {
    code: 'SLG',
    callsign: 'LIFEGUARD',
  },
  {
    code: 'SLH',
    callsign: 'SILVERHAWK',
  },
  {
    code: 'SLI',
    callsign: 'COSTERA',
  },
  {
    code: 'SLJ',
    callsign: 'SLAMAIR',
  },
  {
    code: 'SLK',
    callsign: 'SILKAIR',
  },
  {
    code: 'SLM',
    callsign: 'SURINAM',
  },
  {
    code: 'SLN',
    callsign: 'SLOANE',
  },
  {
    code: 'SLO',
    callsign: 'SLOW',
  },
  {
    code: 'SLP',
    callsign: 'SALPA',
  },
  {
    code: 'SLQ',
    callsign: 'SKYLINK',
  },
  {
    code: 'SLR',
    callsign: 'SILVERSTONE',
  },
  {
    code: 'SLS',
    callsign: 'POWER BLADE',
  },
  {
    code: 'SLT',
    callsign: 'STARLIGHT CARGO',
  },
  {
    code: 'SLU',
    callsign: 'AVIO SLUZBA',
  },
  {
    code: 'SLV',
    callsign: 'AVISTELLA',
  },
  {
    code: 'SLW',
    callsign: 'SALMA AIR',
  },
  {
    code: 'SLX',
    callsign: 'SETE',
  },
  {
    code: 'SLY',
    callsign: 'SKYCO',
  },
  {
    code: 'SLZ',
    callsign: 'LUZA',
  },
  {
    code: 'SMA',
    callsign: 'SESAME',
  },
  {
    code: 'SMC',
    callsign: 'COMLUX',
  },
  {
    code: 'SMD',
    callsign: 'SKYMAP',
  },
  {
    code: 'SMF',
    callsign: 'SMEA',
  },
  {
    code: 'SMG',
    callsign: 'SMARTLINE',
  },
  {
    code: 'SMH',
    callsign: 'SMITHAIR',
  },
  {
    code: 'SMI',
    callsign: 'SAMI',
  },
  {
    code: 'SMJ',
    callsign: 'AVAVIA',
  },
  {
    code: 'SML',
    callsign: 'SKYLINE',
  },
  {
    code: 'SMM',
    callsign: 'SUMMIT-AIR',
  },
  {
    code: 'SMN',
    callsign: 'SIMAVIA',
  },
  {
    code: 'SMO',
    callsign: 'SERVIAUTOMOTRIZ',
  },
  {
    code: 'SMP',
    callsign: 'PRIMA AVIATION',
  },
  {
    code: 'SMQ',
    callsign: 'SAMAR AIR',
  },
  {
    code: 'SMR',
    callsign: 'SOMON AIR',
  },
  {
    code: 'SMS',
    callsign: 'SANTOMENSES',
  },
  {
    code: 'SMT',
    callsign: 'SKYLIMIT',
  },
  {
    code: 'SMU',
    callsign: 'SPRINGER',
  },
  {
    code: 'SMX',
    callsign: 'SWANTON',
  },
  {
    code: 'SMY',
    callsign: 'CABO CACTI',
  },
  {
    code: 'STB',
    callsign: 'SEA BARTH',
  },
  {
    code: 'STE',
    callsign: 'STEAMLINE',
  },
  {
    code: 'STG',
    callsign: 'STAGE',
  },
  {
    code: 'STH',
    callsign: 'ZELTRON',
  },
  {
    code: 'STI',
    callsign: 'SONTAIR',
  },
  {
    code: 'STJ',
    callsign: 'KINGSMAN',
  },
  {
    code: 'STK',
    callsign: 'STOBART AIR',
  },
  {
    code: 'STL',
    callsign: 'STAPLEFORD',
  },
  {
    code: 'STN',
    callsign: 'SAINT ATHAN',
  },
  {
    code: 'STO',
    callsign: 'SLOPS',
  },
  {
    code: 'STP',
    callsign: 'SAOTOME AIRWAYS',
  },
  {
    code: 'STQ',
    callsign: 'STARTREK',
  },
  {
    code: 'STR',
    callsign: 'STERNA',
  },
  {
    code: 'STT',
    callsign: 'SAWTOOTH',
  },
  {
    code: 'STV',
    callsign: 'SATURN',
  },
  {
    code: 'STX',
    callsign: 'STARSAWAY',
  },
  {
    code: 'STY',
    callsign: 'ALTAIR',
  },
  {
    code: 'STZ',
    callsign: 'STUDIO',
  },
  {
    code: 'SUA',
    callsign: 'AIR SILESIA',
  },
  {
    code: 'SUB',
    callsign: 'SUB AIR',
  },
  {
    code: 'SUD',
    callsign: 'SUDANAIR',
  },
  {
    code: 'SUF',
    callsign: 'SUNFLOWER',
  },
  {
    code: 'SUI',
    callsign: 'SWISS AIR FORCE',
  },
  {
    code: 'SUK',
    callsign: 'SKYCARGO',
  },
  {
    code: 'SUL',
    callsign: 'ASTAIR',
  },
  {
    code: 'SUM',
    callsign: 'SUMES',
  },
  {
    code: 'SUP',
    callsign: 'SUN SPEED',
  },
  {
    code: 'SUQ',
    callsign: 'SUPERIOR',
  },
  {
    code: 'SUS',
    callsign: 'SUNSCAN',
  },
  {
    code: 'SUT',
    callsign: 'SUMMIT',
  },
  {
    code: 'SUU',
    callsign: 'SUNSTAR',
  },
  {
    code: 'SUV',
    callsign: 'DANCEAIR',
  },
  {
    code: 'SUY',
    callsign: 'SURVEY',
  },
  {
    code: 'SVA',
    callsign: 'SAUDIA',
  },
  {
    code: 'SVB',
    callsign: 'SIAVIA',
  },
  {
    code: 'SVD',
    callsign: 'GRENADINES',
  },
  {
    code: 'SVE',
    callsign: 'AEROESPECIAL',
  },
  {
    code: 'TCY',
    callsign: 'TWIN CITY',
  },
  {
    code: 'TDA',
    callsign: 'TREND AIR',
  },
  {
    code: 'TDB',
    callsign: 'THUNDER BAY',
  },
  {
    code: 'TDC',
    callsign: 'TADAIR',
  },
  {
    code: 'TDE',
    callsign: 'TELLURIDE',
  },
  {
    code: 'TDG',
    callsign: 'TURBO DOG',
  },
  {
    code: 'TDR',
    callsign: 'TRADEAIR',
  },
  {
    code: 'TDS',
    callsign: 'TSARADIA',
  },
  {
    code: 'NRN',
    callsign: 'NETHERLANDS NAVY',
  },
  {
    code: 'NRR',
    callsign: 'NATUREAIR',
  },
  {
    code: 'NRS',
    callsign: 'REDNOSE',
  },
  {
    code: 'NRT',
    callsign: 'NORESTAIR',
  },
  {
    code: 'NRW',
    callsign: 'HUMMEL',
  },
  {
    code: 'NRX',
    callsign: 'NORSE AIR',
  },
  {
    code: 'NSA',
    callsign: 'NILE SAFARIS',
  },
  {
    code: 'NSC',
    callsign: 'TRANS-SOCIETE',
  },
  {
    code: 'NSE',
    callsign: 'SATENA',
  },
  {
    code: 'NSF',
    callsign: 'NORTON',
  },
  {
    code: 'NSH',
    callsign: 'NORTH-SHORE',
  },
  {
    code: 'NSI',
    callsign: 'NORTHERN SKIES',
  },
  {
    code: 'NSJ',
    callsign: 'NANSHAN',
  },
  {
    code: 'NSK',
    callsign: 'INTERSALONIKA',
  },
  {
    code: 'NSL',
    callsign: 'NERICAIR',
  },
  {
    code: 'NSM',
    callsign: 'THUNDERCLOUD',
  },
  {
    code: 'NSO',
    callsign: 'SOSA',
  },
  {
    code: 'NSP',
    callsign: 'SAM PURSE',
  },
  {
    code: 'OVA',
    callsign: 'AERONOVA',
  },
  {
    code: 'OVD',
    callsign: 'RUSSIA CORP',
  },
  {
    code: 'OWE',
    callsign: 'OWENAIR',
  },
  {
    code: 'OWI',
    callsign: 'AEROWIN',
  },
  {
    code: 'OWL',
    callsign: 'NIGHT OWL',
  },
  {
    code: 'OWN',
    callsign: 'AERO OWEN',
  },
  {
    code: 'OXF',
    callsign: 'OXFORD',
  },
  {
    code: 'OXS',
    callsign: 'OXUS AIRWAYS',
  },
  {
    code: 'OYA',
    callsign: 'OYAA',
  },
  {
    code: 'OYE',
    callsign: 'KODA AIR',
  },
  {
    code: 'OZB',
    callsign: 'AIR PRIMUS',
  },
  {
    code: 'OZT',
    callsign: 'OZARK TECH',
  },
  {
    code: 'PAA',
    callsign: 'CLIPPER',
  },
  {
    code: 'PAB',
    callsign: 'PARAIR',
  },
  {
    code: 'PAC',
    callsign: 'POLAR',
  },
  {
    code: 'PAD',
    callsign: 'AIR PROFESSIONAL',
  },
  {
    code: 'PAE',
    callsign: 'PAISAJES',
  },
  {
    code: 'PAF',
    callsign: 'PATHFINDER',
  },
  {
    code: 'PAG',
    callsign: 'PERIMETER',
  },
  {
    code: 'PAH',
    callsign: 'LANI',
  },
  {
    code: 'PAI',
    callsign: 'SEA RAY',
  },
  {
    code: 'PAJ',
    callsign: 'ALIPARMA',
  },
  {
    code: 'PAK',
    callsign: 'PACIFIC ALASKA',
  },
  {
    code: 'PAL',
    callsign: 'PHILIPPINE',
  },
  {
    code: 'PAM',
    callsign: 'MAP AIR',
  },
  {
    code: 'PAO',
    callsign: 'POLYNESIAN',
  },
  {
    code: 'PAQ',
    callsign: 'SOLPAC',
  },
  {
    code: 'PAS',
    callsign: 'PELITA',
  },
  {
    code: 'PAT',
    callsign: 'PAT',
  },
  {
    code: 'PAU',
    callsign: 'PALAIRWAYS',
  },
  {
    code: 'PAV',
    callsign: 'BRILLIANT',
  },
  {
    code: 'PAW',
    callsign: 'POINTAIR BURKINA',
  },
  {
    code: 'PAX',
    callsign: 'PANNEX',
  },
  {
    code: 'PAZ',
    callsign: 'POINTAIR NIGER',
  },
  {
    code: 'PBA',
    callsign: 'PILBARA',
  },
  {
    code: 'PBB',
    callsign: 'ADEBAR',
  },
  {
    code: 'PBD',
    callsign: 'POBEDA',
  },
  {
    code: 'PBF',
    callsign: 'PENN-FOREST',
  },
  {
    code: 'PMO',
    callsign: 'PETER AIR',
  },
  {
    code: 'PMS',
    callsign: 'PLANEMASTER',
  },
  {
    code: 'PMT',
    callsign: 'MULTITRADE',
  },
  {
    code: 'PMV',
    callsign: 'POLICE MERLIN',
  },
  {
    code: 'PMW',
    callsign: 'PARAWAY',
  },
  {
    code: 'PMX',
    callsign: 'PAMBELE',
  },
  {
    code: 'PNA',
    callsign: 'TROPIC BIRD',
  },
  {
    code: 'PNC',
    callsign: 'PRINCE AVIATION',
  },
  {
    code: 'PNH',
    callsign: 'KUBAN LIK',
  },
  {
    code: 'PNJ',
    callsign: 'PAC JET',
  },
  {
    code: 'PNK',
    callsign: 'AIR PINK',
  },
  {
    code: 'PNL',
    callsign: 'AEROPERSONAL',
  },
  {
    code: 'PNM',
    callsign: 'PANAMERICANO',
  },
  {
    code: 'PNO',
    callsign: 'PANO',
  },
  {
    code: 'PNP',
    callsign: 'PINEAPPLE AIR',
  },
  {
    code: 'PNR',
    callsign: 'SKYJET',
  },
  {
    code: 'PNU',
    callsign: 'AERO PLATINUM',
  },
  {
    code: 'PNW',
    callsign: 'PALESTINIAN',
  },
  {
    code: 'PNX',
    callsign: 'SPINNER',
  },
  {
    code: 'PNY',
    callsign: 'POLISH NAVY',
  },
  {
    code: 'POA',
    callsign: 'PORTUGUESE ARMY',
  },
  {
    code: 'POB',
    callsign: 'POBLANOS',
  },
  {
    code: 'POC',
    callsign: 'POCONO',
  },
  {
    code: 'POE',
    callsign: 'PORTER AIR',
  },
  {
    code: 'POF',
    callsign: 'AIRPOL',
  },
  {
    code: 'POM',
    callsign: 'JETKIC',
  },
  {
    code: 'PON',
    callsign: 'PORTUGUESE NAVY',
  },
  {
    code: 'POR',
    callsign: 'PORTEADORA',
  },
  {
    code: 'POS',
    callsign: 'PORSCHE',
  },
  {
    code: 'POW',
    callsign: 'AIRNET',
  },
  {
    code: 'POY',
    callsign: 'APOYO AEREO',
  },
  {
    code: 'PPA',
    callsign: 'AIR PROP',
  },
  {
    code: 'PPC',
    callsign: 'PALAU ASIAPAC',
  },
  {
    code: 'PPG',
    callsign: 'PAPAGO',
  },
  {
    code: 'PPH',
    callsign: 'POLICE PHOENIX',
  },
  {
    code: 'PPJ',
    callsign: 'PREMIER JETS',
  },
  {
    code: 'PPK',
    callsign: 'PELICAN',
  },
  {
    code: 'PPL',
    callsign: 'PEGASUS',
  },
  {
    code: 'PPQ',
    callsign: 'PERSONASPAQ',
  },
  {
    code: 'QSC',
    callsign: 'ZEBRA',
  },
  {
    code: 'QSM',
    callsign: 'QESHM AIR',
  },
  {
    code: 'QSR',
    callsign: 'SPARKLE ROLL',
  },
  {
    code: 'QTM',
    callsign: 'GANSUM',
  },
  {
    code: 'QTR',
    callsign: 'QATARI',
  },
  {
    code: 'QUE',
    callsign: 'QUEBEC',
  },
  {
    code: 'QUI',
    callsign: 'QUIMMCO',
  },
  {
    code: 'QXE',
    callsign: 'HORIZON AIR',
  },
  {
    code: 'QZR',
    callsign: 'ROYAL ZAM',
  },
  {
    code: 'RAA',
    callsign: 'RYNES AVIATION',
  },
  {
    code: 'RAB',
    callsign: 'RAYYAN INTERNATIONAL',
  },
  {
    code: 'RAC',
    callsign: 'TUZLA AIR',
  },
  {
    code: 'RAD',
    callsign: 'AIR ALADA',
  },
  {
    code: 'RAF',
    callsign: 'FARNAS',
  },
  {
    code: 'RAG',
    callsign: 'RAGLAN',
  },
  {
    code: 'RAK',
    callsign: 'SPORT CLUB',
  },
  {
    code: 'RAL',
    callsign: 'ROSWELL',
  },
  {
    code: 'RAM',
    callsign: 'ROYALAIR MAROC',
  },
  {
    code: 'RAP',
    callsign: 'RAPTOR',
  },
  {
    code: 'RAS',
    callsign: 'SHANHIL',
  },
  {
    code: 'RAT',
    callsign: 'RAINDROP',
  },
  {
    code: 'RAU',
    callsign: 'UGANDA ROYAL',
  },
  {
    code: 'RAV',
    callsign: 'ROLLINS',
  },
  {
    code: 'RAW',
    callsign: 'BLUERAY',
  },
  {
    code: 'RAX',
    callsign: 'AIR ROYAL',
  },
  {
    code: 'RBA',
    callsign: 'BRUNEI',
  },
  {
    code: 'RBB',
    callsign: 'RABBIT',
  },
  {
    code: 'RBD',
    callsign: 'REDBIRD',
  },
  {
    code: 'RBG',
    callsign: 'ARABIA EGYPT',
  },
  {
    code: 'RBK',
    callsign: 'RIOBAKER',
  },
  {
    code: 'RBL',
    callsign: 'ROYAL BELAU',
  },
  {
    code: 'RBN',
    callsign: 'RED BARON',
  },
  {
    code: 'RBR',
    callsign: 'SIAM AIRNET',
  },
  {
    code: 'RBU',
    callsign: 'CORVETTE',
  },
  {
    code: 'RBW',
    callsign: 'CAI HONG',
  },
  {
    code: 'RBY',
    callsign: 'RUBY',
  },
  {
    code: 'RCA',
    callsign: 'RICHLAND',
  },
  {
    code: 'RMS',
    callsign: 'TASS AIR',
  },
  {
    code: 'RMT',
    callsign: 'RAM FLIGHT',
  },
  {
    code: 'RMU',
    callsign: 'AIR-MAUR',
  },
  {
    code: 'RMY',
    callsign: 'RAYA EXPRESS',
  },
  {
    code: 'RNA',
    callsign: 'ROYAL NEPAL',
  },
  {
    code: 'RNG',
    callsign: 'RENEGADE',
  },
  {
    code: 'RNR',
    callsign: 'RUNNER',
  },
  {
    code: 'RNX',
    callsign: 'NEXTIME',
  },
  {
    code: 'ROD',
    callsign: 'AERODAN',
  },
  {
    code: 'ROE',
    callsign: 'ESTE-BOLIVIA',
  },
  {
    code: 'ROF',
    callsign: 'ROMAF',
  },
  {
    code: 'ROG',
    callsign: 'REGO',
  },
  {
    code: 'ROI',
    callsign: 'AVIOR',
  },
  {
    code: 'ROJ',
    callsign: 'ROYALJET',
  },
  {
    code: 'ROK',
    callsign: 'RED ROCK',
  },
  {
    code: 'ROM',
    callsign: 'ROME',
  },
  {
    code: 'RON',
    callsign: 'AIR NAURU',
  },
  {
    code: 'ROO',
    callsign: 'AERO ROA',
  },
  {
    code: 'ROR',
    callsign: 'RORAIMA',
  },
  {
    code: 'ROT',
    callsign: 'TAROM',
  },
  {
    code: 'ROU',
    callsign: 'ROUGE',
  },
  {
    code: 'ROV',
    callsign: 'ROVERAIR',
  },
  {
    code: 'RVQ',
    callsign: 'REVA AIR',
  },
  {
    code: 'RVR',
    callsign: 'RAVEN',
  },
  {
    code: 'RVT',
    callsign: 'ROSTVERTOL',
  },
  {
    code: 'RVU',
    callsign: 'GEORGIAN BALLOON',
  },
  {
    code: 'RVV',
    callsign: 'REEVE',
  },
  {
    code: 'RWD',
    callsign: 'RWANDAIR',
  },
  {
    code: 'RWE',
    callsign: 'ROYAL WEST',
  },
  {
    code: 'RWG',
    callsign: 'RED WING',
  },
  {
    code: 'RWK',
    callsign: 'AIRAWAK',
  },
  {
    code: 'RWL',
    callsign: 'RHEINTRAINER',
  },
  {
    code: 'RWN',
    callsign: 'ROJAS',
  },
  {
    code: 'RWZ',
    callsign: 'AIR RED',
  },
  {
    code: 'RXF',
    callsign: 'AIDEN',
  },
  {
    code: 'RXP',
    callsign: 'EXPRESS MAROC',
  },
  {
    code: 'RXT',
    callsign: 'AERO-EXTRA',
  },
  {
    code: 'RYA',
    callsign: 'RYAN AIR',
  },
  {
    code: 'RYB',
    callsign: 'ROYAL BAHRAIN',
  },
  {
    code: 'RYJ',
    callsign: 'RYJET',
  },
  {
    code: 'RYL',
    callsign: 'DOUBLE GOLD',
  },
  {
    code: 'RYN',
    callsign: 'RYAN INTERNATIONAL',
  },
  {
    code: 'RYR',
    callsign: 'RYANAIR',
  },
  {
    code: 'RYS',
    callsign: 'MAGIC SUN',
  },
  {
    code: 'RYW',
    callsign: 'ROYAL WINGS',
  },
  {
    code: 'RZB',
    callsign: 'AERO REGIONAL',
  },
  {
    code: 'RZO',
    callsign: 'AIR AZORES',
  },
  {
    code: 'RZR',
    callsign: 'RECOVERY',
  },
  {
    code: 'RZT',
    callsign: 'RITZ JET',
  },
  {
    code: 'SAA',
    callsign: 'SPRINGBOK',
  },
  {
    code: 'SAC',
    callsign: 'SASCO',
  },
  {
    code: 'SAE',
    callsign: 'SAMPOERNA',
  },
  {
    code: 'SAF',
    callsign: 'SINGA',
  },
  {
    code: 'SAG',
    callsign: 'AERO EAGLE',
  },
  {
    code: 'SAH',
    callsign: 'SMART JET',
  },
  {
    code: 'SAI',
    callsign: 'SHAHEEN AIR',
  },
  {
    code: 'SAJ',
    callsign: 'SAINT JET',
  },
  {
    code: 'SJA',
    callsign: 'SAWYER',
  },
  {
    code: 'SJB',
    callsign: 'SOUTHERN TIGER',
  },
  {
    code: 'SJC',
    callsign: 'SERVIEJECUTIVO',
  },
  {
    code: 'SJE',
    callsign: 'SUN JOURNEY',
  },
  {
    code: 'SJF',
    callsign: 'HARMATTAN',
  },
  {
    code: 'SJJ',
    callsign: 'SPIRIT JET',
  },
  {
    code: 'SJK',
    callsign: 'NUSANTARA',
  },
  {
    code: 'SJM',
    callsign: 'SINO SKY',
  },
  {
    code: 'SJN',
    callsign: 'SAN JUAN',
  },
  {
    code: 'SJO',
    callsign: 'JEY SPRING',
  },
  {
    code: 'SJS',
    callsign: 'JET PORT',
  },
  {
    code: 'SJV',
    callsign: 'PROSPER',
  },
  {
    code: 'SJX',
    callsign: 'STARWALKER',
  },
  {
    code: 'SJY',
    callsign: 'SRIWIJAYA',
  },
  {
    code: 'SKA',
    callsign: 'SKYLAN AIRWAYS',
  },
  {
    code: 'SKB',
    callsign: 'SKYBUS',
  },
  {
    code: 'SKC',
    callsign: 'SKYMASTER AIR',
  },
  {
    code: 'SKD',
    callsign: 'SKY DAWG',
  },
  {
    code: 'SKE',
    callsign: 'SKYISLE',
  },
  {
    code: 'SKF',
    callsign: 'SKYCRAFT',
  },
  {
    code: 'SKG',
    callsign: 'SKY GABON',
  },
  {
    code: 'SKH',
    callsign: 'SKYNEWS',
  },
  {
    code: 'SKI',
    callsign: 'SKYKING',
  },
  {
    code: 'SKJ',
    callsign: 'SKYNET AIR',
  },
  {
    code: 'SKK',
    callsign: 'ASKY AIRLINE',
  },
  {
    code: 'SKL',
    callsign: 'SKYCHARTER',
  },
  {
    code: 'SKM',
    callsign: 'SKYTEM',
  },
  {
    code: 'SKN',
    callsign: 'SKYLINER',
  },
  {
    code: 'SKO',
    callsign: 'SKYWORK',
  },
  {
    code: 'SKP',
    callsign: 'SKYTRANS',
  },
  {
    code: 'SKQ',
    callsign: 'SKYLAB',
  },
  {
    code: 'SKR',
    callsign: 'SKYSCAPES',
  },
  {
    code: 'SKT',
    callsign: 'SKYCAT',
  },
  {
    code: 'SKU',
    callsign: 'AEROSKY',
  },
  {
    code: 'SKW',
    callsign: 'SKYWEST',
  },
  {
    code: 'SKX',
    callsign: 'SKY PERU',
  },
  {
    code: 'SKY',
    callsign: 'SKYMARK',
  },
  {
    code: 'SKZ',
    callsign: 'SKYWAY-INC',
  },
  {
    code: 'SLB',
    callsign: 'SLOK AIR',
  },
  {
    code: 'SRK',
    callsign: 'SHARK JET',
  },
  {
    code: 'SRL',
    callsign: 'SERVICIOS PERSONAL',
  },
  {
    code: 'SRM',
    callsign: 'TARMEX',
  },
  {
    code: 'SRN',
    callsign: 'SPRINTAIR',
  },
  {
    code: 'SRO',
    callsign: 'SAEREO',
  },
  {
    code: 'SRP',
    callsign: 'SPERBER',
  },
  {
    code: 'SRQ',
    callsign: 'BLUE JAY',
  },
  {
    code: 'SRR',
    callsign: 'WHITESTAR',
  },
  {
    code: 'SRS',
    callsign: 'SKY SOUTH',
  },
  {
    code: 'SRT',
    callsign: 'SURTEP',
  },
  {
    code: 'SRU',
    callsign: 'STAR-UP',
  },
  {
    code: 'SRV',
    callsign: 'SERVICORP',
  },
  {
    code: 'SRW',
    callsign: 'SARIA',
  },
  {
    code: 'SRX',
    callsign: 'SIERRA EX',
  },
  {
    code: 'SRY',
    callsign: 'STINGRAY',
  },
  {
    code: 'SRZ',
    callsign: 'STRATO',
  },
  {
    code: 'SSA',
    callsign: 'STAR-JET',
  },
  {
    code: 'SSB',
    callsign: 'SUASA AIR',
  },
  {
    code: 'SSC',
    callsign: 'SOUTHERN SKIES',
  },
  {
    code: 'SSF',
    callsign: 'SEVERSTAL',
  },
  {
    code: 'SSG',
    callsign: 'SLOVAK GOVERNMENT',
  },
  {
    code: 'SSH',
    callsign: 'SNOWSHOE',
  },
  {
    code: 'SSI',
    callsign: 'SUPER JET',
  },
  {
    code: 'SSJ',
    callsign: 'SIBERIAN SKY',
  },
  {
    code: 'SSK',
    callsign: 'SKYSTAR',
  },
  {
    code: 'SSM',
    callsign: 'SASEMAR',
  },
  {
    code: 'SSN',
    callsign: 'SETOUCHI',
  },
  {
    code: 'SSO',
    callsign: 'ORINOCO',
  },
  {
    code: 'SSP',
    callsign: 'STARSPEED',
  },
  {
    code: 'SSQ',
    callsign: 'SUNSTATE',
  },
  {
    code: 'SSR',
    callsign: 'SARDINIAN',
  },
  {
    code: 'SSS',
    callsign: 'SAESA',
  },
  {
    code: 'SST',
    callsign: 'STETSON',
  },
  {
    code: 'SSU',
    callsign: 'SASCA',
  },
  {
    code: 'SSX',
    callsign: 'SHASTA',
  },
  {
    code: 'SSZ',
    callsign: 'SPECSAVERS',
  },
  {
    code: 'STA',
    callsign: 'STAR',
  },
  {
    code: 'TAJ',
    callsign: 'TUNISAVIA',
  },
  {
    code: 'TAK',
    callsign: 'TRANSAFRICAN',
  },
  {
    code: 'TAL',
    callsign: 'TALAIR',
  },
  {
    code: 'TAM',
    callsign: 'TAM',
  },
  {
    code: 'TAN',
    callsign: 'ZANAIR',
  },
  {
    code: 'TAO',
    callsign: 'TRANS-AEROMAR',
  },
  {
    code: 'TAP',
    callsign: 'AIR PORTUGAL',
  },
  {
    code: 'TAR',
    callsign: 'TUNAIR',
  },
  {
    code: 'TAS',
    callsign: 'LOTUS FLOWER',
  },
  {
    code: 'TAU',
    callsign: 'TRANSTAURO',
  },
  {
    code: 'TAV',
    callsign: 'TAVISA',
  },
  {
    code: 'TAX',
    callsign: 'EXPRESS WING',
  },
  {
    code: 'TAY',
    callsign: 'QUALITY',
  },
  {
    code: 'TBA',
    callsign: 'TIBET',
  },
  {
    code: 'TBI',
    callsign: 'TAB INTERNATIONAL',
  },
  {
    code: 'TBJ',
    callsign: 'TAG JET',
  },
  {
    code: 'TBN',
    callsign: 'TABAN',
  },
  {
    code: 'TBO',
    callsign: 'CARTILLION',
  },
  {
    code: 'TBS',
    callsign: 'TIMBIS',
  },
  {
    code: 'TBZ',
    callsign: 'ATALAR AIR',
  },
  {
    code: 'TIH',
    callsign: 'TIRIAC AIR',
  },
  {
    code: 'TIL',
    callsign: 'TIL',
  },
  {
    code: 'TIM',
    callsign: 'TEAM BRASIL',
  },
  {
    code: 'TIN',
    callsign: 'TINDI',
  },
  {
    code: 'TIP',
    callsign: 'TRANSPAC',
  },
  {
    code: 'TIR',
    callsign: 'ANTAIR',
  },
  {
    code: 'TIS',
    callsign: 'AVIATIS',
  },
  {
    code: 'TPM',
    callsign: 'TRANSPAIS',
  },
  {
    code: 'TPN',
    callsign: 'AEREA DELNORTE',
  },
  {
    code: 'TPO',
    callsign: 'TAXI-POTOSI',
  },
  {
    code: 'TPS',
    callsign: 'TAPSA',
  },
  {
    code: 'TPU',
    callsign: 'TRANS PERU',
  },
  {
    code: 'TPZ',
    callsign: 'TRANSPAZ',
  },
  {
    code: 'TQE',
    callsign: 'TAXAIR',
  },
  {
    code: 'TQF',
    callsign: 'RAINBOW',
  },
  {
    code: 'TQN',
    callsign: 'TAQUAN',
  },
  {
    code: 'TQQ',
    callsign: 'AVIATION TARCO',
  },
  {
    code: 'TRA',
    callsign: 'TRANSAVIA',
  },
  {
    code: 'TRB',
    callsign: 'THAI REGIONAL',
  },
  {
    code: 'TRC',
    callsign: 'TRACKER',
  },
  {
    code: 'TRD',
    callsign: 'TRANS ISLAND',
  },
  {
    code: 'TRF',
    callsign: 'THRUST',
  },
  {
    code: 'TRG',
    callsign: 'TRANSCARGO',
  },
  {
    code: 'TRH',
    callsign: 'TURUKHAN',
  },
  {
    code: 'TRJ',
    callsign: 'TRUJET',
  },
  {
    code: 'TRK',
    callsign: 'TURKISH REPUBLIC',
  },
  {
    code: 'TRL',
    callsign: 'STARSTREAM',
  },
  {
    code: 'TRM',
    callsign: 'SOTRANS',
  },
  {
    code: 'TRN',
    callsign: 'AEROTRON',
  },
  {
    code: 'TRO',
    callsign: 'MOLOKAI',
  },
  {
    code: 'TRP',
    callsign: 'TROOPER',
  },
  {
    code: 'TRR',
    callsign: 'TRAMSON',
  },
  {
    code: 'TRS',
    callsign: 'CITRUS',
  },
  {
    code: 'TRT',
    callsign: 'TRANS ARABIAN',
  },
  {
    code: 'TRU',
    callsign: 'TRI AIR',
  },
  {
    code: 'TRW',
    callsign: 'TRANS-WEST',
  },
  {
    code: 'TRX',
    callsign: 'TRANS-TERRESTRES',
  },
  {
    code: 'TRY',
    callsign: 'TRISTAR AIR',
  },
  {
    code: 'TRZ',
    callsign: 'TRANS-MERIDIAN',
  },
  {
    code: 'TSA',
    callsign: 'AIRTRAF',
  },
  {
    code: 'TSC',
    callsign: 'AIR TRANSAT',
  },
  {
    code: 'TSD',
    callsign: 'TAFI',
  },
  {
    code: 'TSG',
    callsign: 'TRANS-AIR-CONGO',
  },
  {
    code: 'TSH',
    callsign: 'TRANSCANADA',
  },
  {
    code: 'TSJ',
    callsign: 'TARSIER',
  },
  {
    code: 'UFA',
    callsign: 'FLIGHT ACADEMY',
  },
  {
    code: 'UFS',
    callsign: 'FEEDER FLIGHT',
  },
  {
    code: 'UGA',
    callsign: 'UGANDA',
  },
  {
    code: 'UGB',
    callsign: 'UGAWINGS',
  },
  {
    code: 'UGC',
    callsign: 'URGEMER',
  },
  {
    code: 'UGD',
    callsign: 'CRESTED',
  },
  {
    code: 'UGJ',
    callsign: 'UNIJET',
  },
  {
    code: 'UGL',
    callsign: 'UGLY VAN',
  },
  {
    code: 'UGP',
    callsign: 'SHARINK',
  },
  {
    code: 'UGS',
    callsign: 'SKY UGANDA',
  },
  {
    code: 'UGX',
    callsign: 'CRANE',
  },
  {
    code: 'UHC',
    callsign: 'HANDLING COMPANY',
  },
  {
    code: 'UHL',
    callsign: 'UKRAINE COPTERS',
  },
  {
    code: 'UHO',
    callsign: 'HELIOPS',
  },
  {
    code: 'UIT',
    callsign: 'ARTIC',
  },
  {
    code: 'UJC',
    callsign: 'ULTIMATE',
  },
  {
    code: 'UJR',
    callsign: 'UNIVERSAL JET',
  },
  {
    code: 'UJX',
    callsign: 'ATLAS UKRAINE',
  },
  {
    code: 'UKI',
    callsign: 'MAGENTA',
  },
  {
    code: 'UKL',
    callsign: 'UKRAINE ALLIANCE',
  },
  {
    code: 'UKN',
    callsign: 'ENTERPRISE UKRAINE',
  },
  {
    code: 'UKP',
    callsign: 'POLICE',
  },
  {
    code: 'UKT',
    callsign: 'UKTUS',
  },
  {
    code: 'ULA',
    callsign: 'ULTRAJET',
  },
  {
    code: 'ULC',
    callsign: 'ALBI',
  },
  {
    code: 'ULH',
    callsign: 'ULTIMATE HELI',
  },
  {
    code: 'ULR',
    callsign: 'VIPER',
  },
  {
    code: 'ULT',
    callsign: 'ULTRAIR',
  },
  {
    code: 'UMK',
    callsign: 'YUZMASH',
  },
  {
    code: 'UNA',
    callsign: 'UNITED ASIA',
  },
  {
    code: 'UNC',
    callsign: 'UNICOPTER',
  },
  {
    code: 'UND',
    callsign: 'ATUNEROS UNIDOS',
  },
  {
    code: 'UNF',
    callsign: 'UNION FLIGHTS',
  },
  {
    code: 'UNO',
    callsign: 'UNITED NATIONS',
  },
  {
    code: 'UNQ',
    callsign: 'UNIQUE',
  },
  {
    code: 'UNT',
    callsign: 'UNIVERSITARIO',
  },
  {
    code: 'UNY',
    callsign: 'UNIVERSITY',
  },
  {
    code: 'UPL',
    callsign: 'PILOT SCHOOL',
  },
  {
    code: 'UPS',
    callsign: 'UPS',
  },
  {
    code: 'VLT',
    callsign: 'VERTICAL',
  },
  {
    code: 'VLV',
    callsign: 'VLADLIFT',
  },
  {
    code: 'VLX',
    callsign: 'AVOLAR',
  },
  {
    code: 'VLZ',
    callsign: 'VOLARE',
  },
  {
    code: 'VMA',
    callsign: 'VERO MONMOUTH',
  },
  {
    code: 'VME',
    callsign: 'AVIAMERICA',
  },
  {
    code: 'VMP',
    callsign: 'VAMPIRE',
  },
  {
    code: 'VMS',
    callsign: 'VICTOR MIKE',
  },
  {
    code: 'VNA',
    callsign: 'EBBA',
  },
  {
    code: 'VND',
    callsign: 'AVINORD',
  },
  {
    code: 'VNE',
    callsign: 'VENEZOLANA',
  },
  {
    code: 'VNR',
    callsign: 'AVANTAIR',
  },
  {
    code: 'VNS',
    callsign: 'AEROVENA',
  },
  {
    code: 'VNX',
    callsign: 'VANCE',
  },
  {
    code: 'VOC',
    callsign: 'COSTA RICAN',
  },
  {
    code: 'VOE',
    callsign: 'VOLOTEA',
  },
  {
    code: 'VOI',
    callsign: 'VOLARIS',
  },
  {
    code: 'VOR',
    callsign: 'FLIGHT CAL',
  },
  {
    code: 'VOS',
    callsign: 'JETSAL',
  },
  {
    code: 'VOZ',
    callsign: 'VELOCITY',
  },
  {
    code: 'VPC',
    callsign: 'SOLAR',
  },
  {
    code: 'VPE',
    callsign: 'VIVA PERU',
  },
  {
    code: 'VPP',
    callsign: 'VINTAGE',
  },
  {
    code: 'VPT',
    callsign: 'POTOK',
  },
  {
    code: 'VQI',
    callsign: 'VILLA AIR',
  },
  {
    code: 'VRB',
    callsign: 'SILVERBACK',
  },
  {
    code: 'VRC',
    callsign: 'VERACRUZ',
  },
  {
    code: 'VRD',
    callsign: 'REDWOOD',
  },
  {
    code: 'VRE',
    callsign: "COTE D'IVOIRE",
  },
  {
    code: 'VRG',
    callsign: 'VARNA LINES',
  },
  {
    code: 'VRH',
    callsign: 'SKY VICTOR',
  },
  {
    code: 'VRI',
    callsign: 'VILLARICA',
  },
  {
    code: 'VRL',
    callsign: 'VOAR LINHAS',
  },
  {
    code: 'VRO',
    callsign: 'AEROVITRO',
  },
  {
    code: 'VRS',
    callsign: 'VAIRSA',
  },
  {
    code: 'VSA',
    callsign: 'STARBIRD',
  },
  {
    code: 'VSB',
    callsign: 'VICKERS',
  },
  {
    code: 'WYM',
    callsign: 'WORLD CLASS',
  },
  {
    code: 'WYT',
    callsign: 'WYTON',
  },
  {
    code: 'WZM',
    callsign: 'MATILDA',
  },
  {
    code: 'WZZ',
    callsign: 'WIZZAIR',
  },
  {
    code: 'XAA',
    callsign: 'ROCKFISH',
  },
  {
    code: 'XAB',
    callsign: 'AERO XABRE',
  },
  {
    code: 'XAE',
    callsign: 'AURA',
  },
  {
    code: 'XAI',
    callsign: 'PEX AIR',
  },
  {
    code: 'XAK',
    callsign: 'SUNEXPRESS',
  },
  {
    code: 'XAL',
    callsign: 'BAJANSTAR',
  },
  {
    code: 'XAM',
    callsign: 'ALLIANCE',
  },
  {
    code: 'XAP',
    callsign: 'MID-TOWN',
  },
  {
    code: 'XAR',
    callsign: 'TRAVEL EXPRESS',
  },
  {
    code: 'XAU',
    callsign: 'PEARL',
  },
  {
    code: 'XAV',
    callsign: 'AVIAPROM',
  },
  {
    code: 'XAX',
    callsign: 'XANADU',
  },
  {
    code: 'XCA',
    callsign: 'COLT',
  },
  {
    code: 'XCC',
    callsign: 'XCALAK',
  },
  {
    code: 'XCH',
    callsign: 'EXCHANGE',
  },
  {
    code: 'XCN',
    callsign: 'FIRE SCAN',
  },
  {
    code: 'XEL',
    callsign: 'HELI EXCEL',
  },
  {
    code: 'XEN',
    callsign: 'ZEN JET',
  },
  {
    code: 'XER',
    callsign: 'XEROX',
  },
  {
    code: 'TCA',
    callsign: 'TRADECRAFT',
  },
  {
    code: 'TCB',
    callsign: 'AEREO COLOMBIA',
  },
  {
    code: 'TCC',
    callsign: 'TRANSCAL',
  },
  {
    code: 'TCE',
    callsign: 'TRANS-COLORADO',
  },
  {
    code: 'TCG',
    callsign: 'AFRICARGO',
  },
  {
    code: 'TCH',
    callsign: 'TRANS GULF',
  },
  {
    code: 'TCI',
    callsign: 'AMBER',
  },
  {
    code: 'TCK',
    callsign: 'TRANS CARAVAN',
  },
  {
    code: 'TCL',
    callsign: 'TRANS COASTAL',
  },
  {
    code: 'TCM',
    callsign: 'TELEDYN',
  },
  {
    code: 'TCO',
    callsign: 'TRANSCOLOMBIA',
  },
  {
    code: 'TCP',
    callsign: 'TRANSCORP',
  },
  {
    code: 'TCR',
    callsign: 'TICOS',
  },
  {
    code: 'TCS',
    callsign: 'TECHSERVICE',
  },
  {
    code: 'TCT',
    callsign: 'TRANS-CONT',
  },
  {
    code: 'TCV',
    callsign: 'CABOVERDE',
  },
  {
    code: 'TCW',
    callsign: 'PHOENIX',
  },
  {
    code: 'TCX',
    callsign: 'THOMAS COOK',
  },
  {
    code: 'TMM',
    callsign: 'WILLOW RUN',
  },
  {
    code: 'TMN',
    callsign: 'TASMAN',
  },
  {
    code: 'TMQ',
    callsign: 'TRAM AIR',
  },
  {
    code: 'TMR',
    callsign: 'TAMARA',
  },
  {
    code: 'TMS',
    callsign: 'TEMSCO',
  },
  {
    code: 'TMT',
    callsign: 'KAPOW',
  },
  {
    code: 'TMW',
    callsign: 'TRANS MALDIVIAN',
  },
  {
    code: 'TMX',
    callsign: 'TRAMON',
  },
  {
    code: 'TMZ',
    callsign: 'TRANS AMAZON',
  },
  {
    code: 'TNE',
    callsign: 'TAXINOROESTE',
  },
  {
    code: 'TNF',
    callsign: 'TRANSFAS',
  },
  {
    code: 'TNI',
    callsign: 'INCA',
  },
  {
    code: 'TNK',
    callsign: 'TONKA AIR',
  },
  {
    code: 'TNL',
    callsign: 'SKY HORSE',
  },
  {
    code: 'TNO',
    callsign: 'AEROUNION',
  },
  {
    code: 'TNR',
    callsign: 'TAN AIR',
  },
  {
    code: 'TNU',
    callsign: 'TRANSNUSA',
  },
  {
    code: 'TNV',
    callsign: 'TRANSNORTHERN',
  },
  {
    code: 'TNW',
    callsign: 'TRANS-NATION',
  },
  {
    code: 'TNX',
    callsign: 'TRAINER',
  },
  {
    code: 'TNY',
    callsign: 'TWINCAL',
  },
  {
    code: 'TOA',
    callsign: 'TOPCAT',
  },
  {
    code: 'TOF',
    callsign: 'TOPCLIFFE',
  },
  {
    code: 'TOJ',
    callsign: 'TOJ AIRLINE',
  },
  {
    code: 'TOK',
    callsign: 'BALUS',
  },
  {
    code: 'TOM',
    callsign: 'TOMJET',
  },
  {
    code: 'TON',
    callsign: 'TONGA',
  },
  {
    code: 'TOP',
    callsign: 'AIR TOP',
  },
  {
    code: 'TOR',
    callsign: 'HOMERUN',
  },
  {
    code: 'TOS',
    callsign: 'TROPISER',
  },
  {
    code: 'TOW',
    callsign: 'TOWLINE',
  },
  {
    code: 'TOY',
    callsign: 'TOYO AVIATION',
  },
  {
    code: 'TPA',
    callsign: 'TAMPA',
  },
  {
    code: 'TPB',
    callsign: 'AERO TROPICAL',
  },
  {
    code: 'TPC',
    callsign: 'AIRCAL',
  },
  {
    code: 'TPD',
    callsign: 'TROPICAL',
  },
  {
    code: 'TPG',
    callsign: 'TRANSPEGASO',
  },
  {
    code: 'TPJ',
    callsign: 'TEMPUS JETS',
  },
  {
    code: 'TPL',
    callsign: 'INTERPILOT',
  },
  {
    code: 'TZK',
    callsign: 'TAJIKISTAN',
  },
  {
    code: 'TZP',
    callsign: 'ZIPPY',
  },
  {
    code: 'TZR',
    callsign: 'RAIN-BEE',
  },
  {
    code: 'TZS',
    callsign: 'TOKA',
  },
  {
    code: 'TZT',
    callsign: 'ZAMBEZI',
  },
  {
    code: 'UAB',
    callsign: 'UNITED ARABIAN',
  },
  {
    code: 'UAC',
    callsign: 'UNITAIR',
  },
  {
    code: 'UAE',
    callsign: 'EMIRATES',
  },
  {
    code: 'UAF',
    callsign: 'UNIFORCE',
  },
  {
    code: 'UAG',
    callsign: 'SAKTA',
  },
  {
    code: 'UAL',
    callsign: 'UNITED',
  },
  {
    code: 'UAP',
    callsign: 'AIRWING',
  },
  {
    code: 'UAR',
    callsign: 'AEROSTAR',
  },
  {
    code: 'UAT',
    callsign: 'UZTECH',
  },
  {
    code: 'UBA',
    callsign: 'UNIONAIR',
  },
  {
    code: 'UBD',
    callsign: 'URAIR',
  },
  {
    code: 'UBE',
    callsign: 'FLOWER BEE',
  },
  {
    code: 'UBG',
    callsign: 'BANGLASTAR',
  },
  {
    code: 'UCA',
    callsign: 'COMMUTAIR',
  },
  {
    code: 'UCB',
    callsign: 'EAGLE EYE',
  },
  {
    code: 'UCC',
    callsign: 'UGANDA CARGO',
  },
  {
    code: 'UCG',
    callsign: 'UNIWORLD',
  },
  {
    code: 'UCH',
    callsign: 'US CHARTER',
  },
  {
    code: 'UCK',
    callsign: 'GALETA',
  },
  {
    code: 'UCM',
    callsign: 'MULE-FLIGHT',
  },
  {
    code: 'UCO',
    callsign: 'UCOAVIACION',
  },
  {
    code: 'UCS',
    callsign: 'UNITED CARRIERS',
  },
  {
    code: 'UDG',
    callsign: 'UNDERDOG',
  },
  {
    code: 'UEA',
    callsign: 'UNITED EAGLE',
  },
  {
    code: 'UEE',
    callsign: 'EAGLE SLOVENIA',
  },
  {
    code: 'UEJ',
    callsign: 'JETCORP',
  },
  {
    code: 'VGL',
    callsign: 'PREFECT',
  },
  {
    code: 'VGN',
    callsign: 'VIRGIN NIGERIA',
  },
  {
    code: 'VGO',
    callsign: 'VIRGO',
  },
  {
    code: 'VGS',
    callsign: 'SMART',
  },
  {
    code: 'VGV',
    callsign: 'VOLOGDA AIR',
  },
  {
    code: 'VGX',
    callsign: 'GALACTIC',
  },
  {
    code: 'VHA',
    callsign: 'AIR V-H',
  },
  {
    code: 'VHC',
    callsign: 'WILDCAT',
  },
  {
    code: 'VHS',
    callsign: 'VALAIRHELI',
  },
  {
    code: 'VHT',
    callsign: 'VEGAS HEAT',
  },
  {
    code: 'VIC',
    callsign: 'VIP-EJECUTIVO',
  },
  {
    code: 'VIE',
    callsign: 'VIP EMPRESARIAL',
  },
  {
    code: 'VIG',
    callsign: 'VEGA AVIATION',
  },
  {
    code: 'VIH',
    callsign: 'VICHI',
  },
  {
    code: 'VIJ',
    callsign: 'AERO VIGO',
  },
  {
    code: 'VIL',
    callsign: 'TURTLE DOVE',
  },
  {
    code: 'VIN',
    callsign: 'VINAIR',
  },
  {
    code: 'VIP',
    callsign: 'SOVEREIGN',
  },
  {
    code: 'VIR',
    callsign: 'VIRGIN',
  },
  {
    code: 'VIS',
    callsign: 'DREAM BIRD',
  },
  {
    code: 'VIT',
    callsign: 'AVIASTAR',
  },
  {
    code: 'VIV',
    callsign: 'AEROENLACES',
  },
  {
    code: 'VIZ',
    callsign: 'AEROVIZ',
  },
  {
    code: 'VJC',
    callsign: 'VIETJETAIR',
  },
  {
    code: 'VJM',
    callsign: 'VIAJES MEXICANOS',
  },
  {
    code: 'VJS',
    callsign: 'CARBON',
  },
  {
    code: 'VJT',
    callsign: 'VISTA JET',
  },
  {
    code: 'VKA',
    callsign: 'VULKAN AIR',
  },
  {
    code: 'VKG',
    callsign: 'VIKING',
  },
  {
    code: 'VKN',
    callsign: 'TRUE NORTH',
  },
  {
    code: 'VKY',
    callsign: 'VALKYRIE',
  },
  {
    code: 'VLA',
    callsign: 'NALAU',
  },
  {
    code: 'VLB',
    callsign: 'VOLTA',
  },
  {
    code: 'VLC',
    callsign: 'AVALANCHE',
  },
  {
    code: 'VLG',
    callsign: 'VUELING',
  },
  {
    code: 'VLJ',
    callsign: 'VALJET',
  },
  {
    code: 'VLM',
    callsign: 'RUBENS',
  },
  {
    code: 'VLN',
    callsign: 'VALAN',
  },
  {
    code: 'VLO',
    callsign: 'VELOG',
  },
  {
    code: 'VLR',
    callsign: 'VOLAX',
  },
  {
    code: 'VLS',
    callsign: 'VIREL',
  },
  {
    code: 'WFD',
    callsign: 'AVRO',
  },
  {
    code: 'WFH',
    callsign: 'WANFENG',
  },
  {
    code: 'WFL',
    callsign: 'BLUE WORLD',
  },
  {
    code: 'WGN',
    callsign: 'WESTERN GLOBAL',
  },
  {
    code: 'WGP',
    callsign: 'GRAND PRIX',
  },
  {
    code: 'WHB',
    callsign: 'HOSHAM AIR',
  },
  {
    code: 'WHE',
    callsign: 'WESTLAND',
  },
  {
    code: 'WHG',
    callsign: 'CHEER',
  },
  {
    code: 'WHR',
    callsign: 'TANGI',
  },
  {
    code: 'WHS',
    callsign: 'WEEKING',
  },
  {
    code: 'WHT',
    callsign: 'WHITEJET',
  },
  {
    code: 'WIA',
    callsign: 'WINDWARD',
  },
  {
    code: 'WID',
    callsign: 'WIDGEON',
  },
  {
    code: 'WIF',
    callsign: 'WIDEROE',
  },
  {
    code: 'WIG',
    callsign: 'WIGGINS AIRWAYS',
  },
  {
    code: 'WIL',
    callsign: 'WILLAMETTE',
  },
  {
    code: 'WIS',
    callsign: 'WISCAIR',
  },
  {
    code: 'WIT',
    callsign: 'STRIKER',
  },
  {
    code: 'WIW',
    callsign: 'VEE-AVIA',
  },
  {
    code: 'WIZ',
    callsign: 'WIZARD',
  },
  {
    code: 'WJA',
    callsign: 'WESTJET',
  },
  {
    code: 'WJM',
    callsign: 'WINDSOR JET',
  },
  {
    code: 'WJT',
    callsign: 'WIJET',
  },
  {
    code: 'WKT',
    callsign: 'WHITE KNIGHT',
  },
  {
    code: 'WLB',
    callsign: 'WINGS LEBANON',
  },
  {
    code: 'WLC',
    callsign: 'WELCOMEAIR',
  },
  {
    code: 'WLD',
    callsign: 'WILDERNESS',
  },
  {
    code: 'WLF',
    callsign: 'SKY BLUE',
  },
  {
    code: 'WLK',
    callsign: 'SKYWATCH',
  },
  {
    code: 'WLM',
    callsign: 'RIBICA',
  },
  {
    code: 'WLN',
    callsign: 'WEST LINK',
  },
  {
    code: 'WLS',
    callsign: 'WILLIAMS AIR',
  },
  {
    code: 'WLT',
    callsign: 'WINGLET',
  },
  {
    code: 'WLV',
    callsign: 'WOLVERINE',
  },
  {
    code: 'WMA',
    callsign: 'WATERMAKERS',
  },
  {
    code: 'WML',
    callsign: 'MARLIN',
  },
  {
    code: 'WMN',
    callsign: 'WATERMAN',
  },
  {
    code: 'WMU',
    callsign: 'SKYBRONCO',
  },
  {
    code: 'WNA',
    callsign: 'WINAIR',
  },
  {
    code: 'WNE',
    callsign: 'CRUSH',
  },
  {
    code: 'XTA',
    callsign: 'TEXTRA',
  },
  {
    code: 'XTH',
    callsign: 'BRIGHT PEARL',
  },
  {
    code: 'XTO',
    callsign: 'EXPRESS TOURS',
  },
  {
    code: 'XYZ',
    callsign: 'RAINBIRD',
  },
  {
    code: 'YAK',
    callsign: 'YAK AVIA',
  },
  {
    code: 'YAP',
    callsign: 'WHITEKEKO',
  },
  {
    code: 'YEL',
    callsign: 'YELLOWSTONE',
  },
  {
    code: 'YOG',
    callsign: 'YOGAN AIR',
  },
  {
    code: 'TDT',
    callsign: 'TRIDENT',
  },
  {
    code: 'TEA',
    callsign: 'TRAVELMAX',
  },
  {
    code: 'TEC',
    callsign: 'TECHJET',
  },
  {
    code: 'TED',
    callsign: 'AEROAZTECA',
  },
  {
    code: 'TEE',
    callsign: 'TEEBIRD',
  },
  {
    code: 'TEF',
    callsign: 'TECFOTO',
  },
  {
    code: 'TEG',
    callsign: 'SERVITLAL',
  },
  {
    code: 'TEK',
    callsign: 'ATECH',
  },
  {
    code: 'TEL',
    callsign: 'TELFORD',
  },
  {
    code: 'TEN',
    callsign: 'TENNESSEE',
  },
  {
    code: 'TEO',
    callsign: 'TRANSAEREO',
  },
  {
    code: 'TEP',
    callsign: 'TRANSPORTES PIZA',
  },
  {
    code: 'TER',
    callsign: 'TERRI-AIRE',
  },
  {
    code: 'TES',
    callsign: 'TESABAN',
  },
  {
    code: 'TEU',
    callsign: 'TAG MALTA',
  },
  {
    code: 'TEW',
    callsign: 'TEAMWORK',
  },
  {
    code: 'TEZ',
    callsign: 'TEZJET',
  },
  {
    code: 'TFA',
    callsign: 'TRANS FLORIDA',
  },
  {
    code: 'TFF',
    callsign: 'TALON FLIGHT',
  },
  {
    code: 'TFG',
    callsign: 'TRAFALGAR',
  },
  {
    code: 'TFL',
    callsign: 'ORANGE',
  },
  {
    code: 'TFR',
    callsign: 'TOLL FREIGHT',
  },
  {
    code: 'TFS',
    callsign: 'CAMPUS',
  },
  {
    code: 'TFT',
    callsign: 'THAI FLYING',
  },
  {
    code: 'TFX',
    callsign: 'TOLL EXPRESS',
  },
  {
    code: 'TFY',
    callsign: 'TAYSIDE',
  },
  {
    code: 'TGA',
    callsign: 'TAIGA',
  },
  {
    code: 'TSL',
    callsign: 'THAI AVIATION',
  },
  {
    code: 'TSM',
    callsign: 'SKYLOUNGE',
  },
  {
    code: 'TSN',
    callsign: 'AIR TRANS',
  },
  {
    code: 'TSP',
    callsign: 'TONLESAP AIR',
  },
  {
    code: 'TSQ',
    callsign: 'AIRTRA',
  },
  {
    code: 'TSR',
    callsign: 'SAN MARINO',
  },
  {
    code: 'TSS',
    callsign: 'TRI-STATE',
  },
  {
    code: 'TSU',
    callsign: 'TRANSAUTO',
  },
  {
    code: 'TSY',
    callsign: 'TRIPLE STAR',
  },
  {
    code: 'TTA',
    callsign: 'KANIMANBO',
  },
  {
    code: 'TTC',
    callsign: 'TRANSTECO',
  },
  {
    code: 'TTD',
    callsign: 'INTERESTATALES',
  },
  {
    code: 'TTE',
    callsign: 'TETON',
  },
  {
    code: 'TTF',
    callsign: 'CARGO UNIT',
  },
  {
    code: 'TTG',
    callsign: 'VIETNAM CARGO',
  },
  {
    code: 'TTJ',
    callsign: 'TATRA',
  },
  {
    code: 'TTL',
    callsign: 'TOTAL',
  },
  {
    code: 'TTM',
    callsign: 'TOUT-AIR',
  },
  {
    code: 'TTN',
    callsign: 'TITANIUM',
  },
  {
    code: 'TTO',
    callsign: 'TRANSPORT TRANSIT',
  },
  {
    code: 'TTP',
    callsign: 'MIGHTY WING',
  },
  {
    code: 'TTS',
    callsign: 'TECNICO',
  },
  {
    code: 'TTU',
    callsign: 'TANTALUS',
  },
  {
    code: 'TTV',
    callsign: 'WINSKY',
  },
  {
    code: 'TTW',
    callsign: 'SMART CAT',
  },
  {
    code: 'TTX',
    callsign: 'TWISTER',
  },
  {
    code: 'TUA',
    callsign: 'TURKMENISTAN',
  },
  {
    code: 'TUB',
    callsign: 'TRUE AVIATION',
  },
  {
    code: 'TUD',
    callsign: 'TUNDRA',
  },
  {
    code: 'TUG',
    callsign: 'TURKMENISTAN GOVERNMENT',
  },
  {
    code: 'TUI',
    callsign: 'TUI JET',
  },
  {
    code: 'TUJ',
    callsign: 'TAJA',
  },
  {
    code: 'TUL',
    callsign: 'URSAL',
  },
  {
    code: 'TUN',
    callsign: 'TUNISIAN AIRFORCE',
  },
  {
    code: 'TUP',
    callsign: 'TUPOLEVAIR',
  },
  {
    code: 'TUS',
    callsign: 'TRUSTCO',
  },
  {
    code: 'TUX',
    callsign: 'TUNEXPRESS',
  },
  {
    code: 'TUY',
    callsign: 'AEREOTUY',
  },
  {
    code: 'TUZ',
    callsign: 'UTAT',
  },
  {
    code: 'TVA',
    callsign: 'TRANS-AMERICA',
  },
  {
    code: 'UPV',
    callsign: 'UPAVON',
  },
  {
    code: 'URA',
    callsign: 'ROSAVIA',
  },
  {
    code: 'URF',
    callsign: 'SURFAIR',
  },
  {
    code: 'URG',
    callsign: 'URGA',
  },
  {
    code: 'URI',
    callsign: 'URI AIR',
  },
  {
    code: 'URJ',
    callsign: 'STARAV',
  },
  {
    code: 'URO',
    callsign: 'EURO',
  },
  {
    code: 'USB',
    callsign: 'TUSHETI',
  },
  {
    code: 'USC',
    callsign: 'STAR CHECK',
  },
  {
    code: 'USH',
    callsign: 'US-HELI',
  },
  {
    code: 'USJ',
    callsign: 'USJET',
  },
  {
    code: 'USR',
    callsign: 'US ARMY',
  },
  {
    code: 'USW',
    callsign: 'AKSAR',
  },
  {
    code: 'USX',
    callsign: 'TERRAKEA',
  },
  {
    code: 'UTA',
    callsign: 'UTAIR',
  },
  {
    code: 'UTG',
    callsign: 'UTAGE',
  },
  {
    code: 'UTH',
    callsign: 'UTAIR HELICOPTERS',
  },
  {
    code: 'UTN',
    callsign: 'UT-UKRAINE',
  },
  {
    code: 'UTP',
    callsign: 'UNI-TOP',
  },
  {
    code: 'UTR',
    callsign: 'UTAIR LIMITED',
  },
  {
    code: 'UTS',
    callsign: 'AIRRUH',
  },
  {
    code: 'UTT',
    callsign: 'ARABIAN TRANSPORT',
  },
  {
    code: 'UTY',
    callsign: 'UNITY',
  },
  {
    code: 'UVA',
    callsign: 'UNIVERSAL',
  },
  {
    code: 'UVM',
    callsign: 'UVAVEMEX',
  },
  {
    code: 'UVN',
    callsign: 'SENYARI',
  },
  {
    code: 'UVT',
    callsign: 'TAT-JET',
  },
  {
    code: 'UWA',
    callsign: 'GRUMPY',
  },
  {
    code: 'UWD',
    callsign: 'UPWARD',
  },
  {
    code: 'UWJ',
    callsign: 'UKRAINIAN WINGS',
  },
  {
    code: 'UZA',
    callsign: 'CONSTANTA',
  },
  {
    code: 'UZB',
    callsign: 'UZBEK',
  },
  {
    code: 'VAA',
    callsign: 'EUROVAN',
  },
  {
    code: 'VAC',
    callsign: 'VALORAVIA',
  },
  {
    code: 'VAD',
    callsign: 'VALLES',
  },
  {
    code: 'VAE',
    callsign: 'AIR-EVANS',
  },
  {
    code: 'VAG',
    callsign: 'VIETRAVEL AIR',
  },
  {
    code: 'VAJ',
    callsign: 'TRUBADIX',
  },
  {
    code: 'VAL',
    callsign: 'VOYAGEUR',
  },
  {
    code: 'VAM',
    callsign: 'AMERAVIA',
  },
  {
    code: 'VAN',
    callsign: 'CAMEL',
  },
  {
    code: 'VSM',
    callsign: 'VIETSTAR',
  },
  {
    code: 'VSR',
    callsign: 'AVIOSTART',
  },
  {
    code: 'VSS',
    callsign: 'WATERBIRD',
  },
  {
    code: 'VSV',
    callsign: 'VLASTA',
  },
  {
    code: 'VTA',
    callsign: 'AIR TAHITI',
  },
  {
    code: 'VTE',
    callsign: 'VOLUNTEER',
  },
  {
    code: 'VTG',
    callsign: 'ATACARGO',
  },
  {
    code: 'VTH',
    callsign: 'VUELOS TEHUACAN',
  },
  {
    code: 'VTI',
    callsign: 'VISTARA',
  },
  {
    code: 'VTK',
    callsign: 'VOSTOK',
  },
  {
    code: 'VTM',
    callsign: 'AERONAVES TSM',
  },
  {
    code: 'VTS',
    callsign: 'EVERTS',
  },
  {
    code: 'VTT',
    callsign: 'VIATRANSPORT',
  },
  {
    code: 'VTU',
    callsign: 'TURPIAL',
  },
  {
    code: 'VTY',
    callsign: 'VICTORY',
  },
  {
    code: 'VUE',
    callsign: 'FLIGHTVUE',
  },
  {
    code: 'VUK',
    callsign: 'VUKANI',
  },
  {
    code: 'VUN',
    callsign: 'AIRIVOIRE',
  },
  {
    code: 'VUO',
    callsign: 'AEROVUELOX',
  },
  {
    code: 'VUR',
    callsign: 'VIPEC',
  },
  {
    code: 'VVA',
    callsign: 'LOAD SHARK',
  },
  {
    code: 'VVB',
    callsign: 'VEE-TRANS',
  },
  {
    code: 'VVC',
    callsign: 'VIVA COLOMBIA',
  },
  {
    code: 'VVD',
    callsign: 'LIVERBIRD',
  },
  {
    code: 'VVG',
    callsign: 'AEROVILLA',
  },
  {
    code: 'VVM',
    callsign: 'JACKPOT',
  },
  {
    code: 'VVV',
    callsign: 'VALAIRJET',
  },
  {
    code: 'VXG',
    callsign: 'AVIREX-GABON',
  },
  {
    code: 'VXP',
    callsign: 'AVELO',
  },
  {
    code: 'VXS',
    callsign: 'VOLUXIS',
  },
  {
    code: 'VYE',
    callsign: 'JET SOLUTIONS',
  },
  {
    code: 'VYR',
    callsign: 'VEYRON',
  },
  {
    code: 'VYT',
    callsign: 'ANGLESEY',
  },
  {
    code: 'VZA',
    callsign: 'AVEAIR',
  },
  {
    code: 'VZL',
    callsign: 'VZLYET',
  },
  {
    code: 'WAA',
    callsign: 'WESTAIR WINGS',
  },
  {
    code: 'WAB',
    callsign: 'WABASH',
  },
  {
    code: 'WAC',
    callsign: 'WESTAF CARGO',
  },
  {
    code: 'WAD',
    callsign: 'VULCAN',
  },
  {
    code: 'WNR',
    callsign: 'WONDAIR',
  },
  {
    code: 'WOA',
    callsign: 'WORLD',
  },
  {
    code: 'WOF',
    callsign: 'FIREWISE',
  },
  {
    code: 'WOK',
    callsign: 'WOKAIR',
  },
  {
    code: 'WOL',
    callsign: 'WINGJET',
  },
  {
    code: 'WON',
    callsign: 'WINGS ABADI',
  },
  {
    code: 'WOW',
    callsign: 'WOW AIR',
  },
  {
    code: 'WPA',
    callsign: 'WESTPAC',
  },
  {
    code: 'WPC',
    callsign: 'COMMAND AIR',
  },
  {
    code: 'WPH',
    callsign: 'WAT PHNOM',
  },
  {
    code: 'WPR',
    callsign: 'WESTPAC RESCUE',
  },
  {
    code: 'WPT',
    callsign: 'NEW BLUE',
  },
  {
    code: 'WRC',
    callsign: 'WIND ROSE',
  },
  {
    code: 'WRF',
    callsign: 'WRIGHT FLYER',
  },
  {
    code: 'WRR',
    callsign: 'WRAP AIR',
  },
  {
    code: 'WRT',
    callsign: 'WRIGHT-AIR',
  },
  {
    code: 'WSG',
    callsign: 'WASAYA',
  },
  {
    code: 'WSI',
    callsign: 'WIND SPIRIT',
  },
  {
    code: 'WSM',
    callsign: 'WISMAN',
  },
  {
    code: 'WSN',
    callsign: 'WINGSPAN',
  },
  {
    code: 'WST',
    callsign: 'WESTERN AIR',
  },
  {
    code: 'WSW',
    callsign: 'SWOOP',
  },
  {
    code: 'WTJ',
    callsign: 'BRASILJETS',
  },
  {
    code: 'WTN',
    callsign: 'TARNISH',
  },
  {
    code: 'WTV',
    callsign: 'WESTAVIA',
  },
  {
    code: 'WUA',
    callsign: 'YAJIE AIR',
  },
  {
    code: 'WUE',
    callsign: 'FASTY',
  },
  {
    code: 'WUK',
    callsign: 'WIZZ GO',
  },
  {
    code: 'WVA',
    callsign: 'WABASH VALLEY',
  },
  {
    code: 'WVE',
    callsign: 'CLEAR WAVE',
  },
  {
    code: 'WVU',
    callsign: 'COUNTRY ROADS',
  },
  {
    code: 'WWA',
    callsign: 'WILKEN AIR',
  },
  {
    code: 'WWI',
    callsign: 'WORLDWIDE',
  },
  {
    code: 'WWP',
    callsign: 'WINGO PANAMA',
  },
  {
    code: 'WYC',
    callsign: 'WYCOMBE',
  },
  {
    code: 'WYF',
    callsign: 'WAYFARER',
  },
  {
    code: 'WYG',
    callsign: 'WYOMING',
  },
  {
    code: 'YSY',
    callsign: 'RADIO',
  },
  {
    code: 'YZR',
    callsign: 'YANGTZE RIVER',
  },
  {
    code: 'ZAK',
    callsign: 'ZAMBIA SKIES',
  },
  {
    code: 'ZAV',
    callsign: 'ZETAVIA',
  },
  {
    code: 'ZAZ',
    callsign: 'APPROACH',
  },
  {
    code: 'ZBA',
    callsign: 'BOSKY',
  },
  {
    code: 'ZIZ',
    callsign: 'INFORMACION',
  },
  {
    code: 'ZOM',
    callsign: 'ZOOM',
  },
  {
    code: 'ZOZ',
    callsign: 'OCEANA',
  },
  {
    code: 'ZRZ',
    callsign: 'CONTROLE',
  },
]

export default airlines
