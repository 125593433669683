import { useEffect } from 'react'

import { CwpContextProvider } from './contexts/CwpContext'

import './App.css'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { ProtectedRoute } from './auth/ProtectedRoute'
import HomePage from './pages/HomePage/HomePage'
import CWPPage from './pages/CWPPage/CWPPage'

import { store } from './app/store'
import { Provider } from 'react-redux'

function App() {
  const disableContextMenu = (e) => {
    e.preventDefault()
  }
  const disableWheelClick = (e) => {
    if (e.buttons === 4) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.addEventListener('contextmenu', disableContextMenu, false)
    document.body.addEventListener('mousedown', disableWheelClick, true)

    return () => {
      document.body.removeEventListener('contextmenu', disableContextMenu)
      document.body.removeEventListener('mousedown', disableWheelClick)
    }
  }, [])

  return (
    <Router>
      <Provider store={store}>
        <CwpContextProvider>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <ProtectedRoute path="/epp">
              <CWPPage isEpp />
            </ProtectedRoute>
            <ProtectedRoute path="/plt">
              <CWPPage isPlt />
            </ProtectedRoute>
            <ProtectedRoute path="/con">
              <CWPPage isCon />
            </ProtectedRoute>
            <ProtectedRoute path="/demo">
              <CWPPage isDemo />
            </ProtectedRoute>
            <Redirect to="/" />
          </Switch>
        </CwpContextProvider>
      </Provider>
    </Router>
  )
}

export default App
