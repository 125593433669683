import { createSlice } from '@reduxjs/toolkit'

const initialState = { speaking: false }

export const commSlice = createSlice({
  name: 'comm',
  initialState,
  reducers: {
    toggleADF: (state, action) => {
      // console.log(state, action)
      state.speaking = action.payload.speaking
    },
    setCP: (state, action) => {
      state.cP = action.payload.cP
    },
  },
})

export const { toggleADF, setCP } = commSlice.actions

export default commSlice.reducer
