import { distanceLatLon } from '../calc/calc'
import * as geolib from 'geolib'

const endConflict = (conflictInfo, flightsI, flightsJ, ind1, ind2, tv) => {
  conflictInfo.flight1EndIndex = ind1
  conflictInfo.flight2EndIndex = ind2
  conflictInfo.flight1EndAltitude = flightsI.flight[ind1].a
  conflictInfo.flight2EndAltitude = flightsJ.flight[ind2].a
  conflictInfo.endTickValue = tv
  return conflictInfo
}

export const calcStcaAll = (flights, tickValue, isCon) => {
  if (!flights) return []
  let stcas = []
  for (let i = 0; i < flights.length; i++) {
    let tSh1 = isCon ? 0 : tickValue + flights[i].timeShift
    if (
      flights[i] &&
      flights[i].flight.length > tSh1 &&
      tSh1 >= 0 &&
      flights[i].flight[tSh1]
    ) {
      let flt1Pos = flights[i].flight[tSh1].cP
      if (
        flt1Pos.latitude > 47.47 &&
        flt1Pos.longitude > 22 &&
        flt1Pos.latitude < 52.2 &&
        flt1Pos.longitude < 28
      ) {
        for (let j = i + 1; j < flights.length; j++) {
          let tSh2 = isCon ? 0 : tickValue + flights[j].timeShift
          if (
            flights[j].flight.length > tSh2 &&
            tSh2 >= 0 &&
            flights[j].flight[tSh2]
          ) {
            let flt2Pos = flights[j].flight[tSh2].cP
            let alt1 = flights[i].flight[tSh1].a
            let alt2 = flights[j].flight[tSh2].a
            if (Math.abs(alt1 - alt2) < 950) {
              if (
                flt2Pos.latitude > 47.47 &&
                flt2Pos.longitude > 22 &&
                flt2Pos.latitude < 52.2 &&
                flt2Pos.longitude < 28
              ) {
                let dist = distanceLatLon(
                  flt1Pos.latitude,
                  flt1Pos.longitude,
                  flt2Pos.latitude,
                  flt2Pos.longitude,
                )
                let separation = 5
                if (flights[i].isLvt && flights[j].isLvt) separation = 5
                if (dist < separation) {
                  stcas.push({
                    id1: flights[i].id,
                    id2: flights[j].id,
                    callsign1: flights[i].flight[tSh1].c,
                    callsign2: flights[j].flight[tSh2].c,
                    distance: dist,
                  })
                }
              }
            }
          }
        }
      }
    }
  }
  return stcas
}

export const calcWorkloadAndMtcd = (flights, conflictNumberList) => {
  const workloadArr = []
  const resultConflictNumberList = [] // [...conflictNumberList]
  const conflictsTable = []
  const conflictInfoList = new Set()
  for (let i = 0; i < flights.length - 1; i++) {
    for (let j = i + 1; j < flights.length; j++) {
      let tvInterval = 5
      let tickValue = 0
      let tv = tickValue
      let isConflict = false
      const lookAheadTime = 900
      const flightConflict = []
      let conflictInfo = {}

      while (tv < tickValue + lookAheadTime && tv < 900) {
        if (
          !flights[i].flight[0].isAdvanced &&
          !flights[j].flight[0].isAdvanced
        )
          break
        const ind1 = tv + flights[i].timeShift
        const ind2 = tv + flights[j].timeShift
        if (flights[i].flight[ind1] && flights[j].flight[ind2]) {
          let dist = geolib.convertDistance(
            geolib.getDistance(
              flights[i].flight[ind1].cP,
              flights[j].flight[ind2].cP,
            ),
            'sm',
          )
          if (
            dist <= 7 &&
            Math.abs(flights[i].flight[ind1].a - flights[j].flight[ind2].a) <
              950
          ) {
            if (!isConflict) {
              conflictInfo.flight1 = flights[i]
              conflictInfo.flight2 = flights[j]
              conflictInfo.flight1StartIndex = ind1
              conflictInfo.flight2StartIndex = ind2
              conflictInfo.flight1StartAltitude = flights[i].flight[ind1].a
              conflictInfo.flight2StartAltitude = flights[j].flight[ind2].a
              conflictInfo.startTickValue = tv
              conflictInfo.minDist = dist
            }
            conflictInfo.minDist =
              conflictInfo.minDist < dist ? conflictInfo.minDist : dist
            if (
              flights[i].flight[ind1].isAssumed ||
              flights[j].flight[ind2].isAssumed
            ) {
              flightConflict[parseInt(tv / 15)] = 1
            }
            tvInterval = 1
            isConflict = true
          } else {
            if (isConflict) {
              conflictInfoList.add(
                endConflict(
                  conflictInfo,
                  flights[i],
                  flights[j],
                  ind1,
                  ind2,
                  tv,
                ),
              )
              conflictInfo = {}
            }
            tvInterval = 5
            isConflict = false
          }
        } else if (isConflict) {
          // console.log(conflictInfo)
          // console.log(flights[i])
          // console.log(flights[j])
          // console.log(ind1, ind2)
          // conflictInfoList.add(
          //   endConflict(conflictInfo, flights[i], flights[j], ind1, ind2, tv),
          // )
          conflictInfo = {}
          isConflict = false
        }
        tv += tvInterval
      }
      if (isConflict) {
        conflictInfoList.add(
          endConflict(
            conflictInfo,
            flights[i],
            flights[j],
            899 + flights[i].timeShift,
            899 + flights[j].timeShift,
            899,
          ),
        )
        conflictInfo = {}
        isConflict = false
      }
      if (flightConflict.length > 0) {
        conflictsTable.push(flightConflict)
      }
    }
  }
  for (let tickValue = 0; tickValue < 900; tickValue += 15) {
    if (tickValue % 15 === 0) {
      resultConflictNumberList[tickValue / 15] = 0
      let assumedNumber = flights.reduce(
        ([acc, wl], curVal) => {
          let tvi = tickValue + curVal.timeShift
          if (curVal.flight[tvi]) {
            if (curVal.flight[tvi].isAssumed) {
              acc += 1
              if (curVal.workload[tickValue / 5]) {
                wl += curVal.workload[tickValue / 5]
              }
            }
          }
          return [acc, wl]
        },
        [0, 0],
      )

      if (conflictsTable) {
        // console.log('conflictsTable', conflictsTable)
        conflictsTable.forEach((val) => {
          if (val[tickValue / 15] === 1) {
            resultConflictNumberList[tickValue / 15] += 1
          }
        })
        assumedNumber[1] += resultConflictNumberList[tickValue / 15] * 10
      }
      workloadArr.length = tickValue / 15
      workloadArr.push(assumedNumber)
    }
  }
  return [conflictInfoList, workloadArr, resultConflictNumberList]
}
