/*
    node_js
    2/1/2021 10:24 PM
    by Oleksandr
*/
export const ASSUMED_COLOR = 'rgb(238,238,200)'
export const ADVANCED_COLOR = 'rgb(236,177,177)'
export const SSR_COLOR = 'rgb(105,170,170)'
export const CONCERNED_COLOR = 'rgb(183,156,71)'
export const SELECTED_COLOR = 'rgb(255,255,230)'
export const FIELD_HOVERED_COLOR = 'rgb(38,132,0)'
export const UNCONCERNED_COLOR = 'rgb(120,120,120)'
export const ALERT_COLOR = 'rgb(255,26,26)'
export const WARNING_COLOR = 'rgb(255,230,31)'
export const DFLASSUMED_COLOR = 'rgb(117,224,79)'
