import React, { useEffect, useRef, useState } from 'react'
import '../css/fpActionWindow.css'
import { useCwpContext } from '../../../../../contexts/CwpContext'
import Window from '../Window'
import { airports } from '../../../../../config/adap/airports'
import { navfix } from '../../../../../config/adap/navfix'
import {
  calcFlight,
  calcPointsDistance,
  calcPassedPoints,
} from '../../../../../calc/calc'
import { lastKeyOf } from 'draft-js/lib/DefaultDraftBlockRenderMap'

function FpAction(props) {
  const {
    pttWindows,
    setPttWindows,
    flights,
    tickValue,
    startDate,
    setFlights,
    initialFlightUpdate,
  } = useCwpContext()
  const [fpActionFlight, setFpActionFlight] = useState({
    radiobutton: 'fpaction_view',
  })
  const [editFields, setEditFields] = useState({})
  const [errorField, setErrorField] = useState({})
  const { flightId } = pttWindows.options.fpActionWindow

  const fpaction_view = useRef()

  useEffect(() => {
    if (fpActionFlight.radiobutton === 'fpaction_create_current') return
    updateFlight()
    document
      .querySelectorAll('.fpactionwindow_grid input')
      .forEach((input) => (input.value = ''))
    document.querySelector('.fpactionwindow_grid input')?.focus()
  }, [flightId])

  const createFlight = () => {
    let flight = flights.filter((flt) => flt.id === flightId)[0]
    let timeConstrainPoint = {}
    const parsedPoints = fpActionFlight.firRoute.split(' ').map((p, index) => {
      const splitPoint = p.toUpperCase().split('/H')
      if (splitPoint[1])
        timeConstrainPoint = {
          name: splitPoint[0],
          time: {
            hh: splitPoint[1].substr(0, 2),
            mm: splitPoint[1].substr(2, 2),
          },
          index: index,
        }
      return splitPoint[0]
    })
    const fplRoute = []

    for (let index = 0; index < parsedPoints.length; index++) {
      try {
        const fix = {
          ...navfix.filter((p) => p.name === parsedPoints[index])[0],
        }
        if (fix.name) {
          if (timeConstrainPoint.index === index)
            fix.timeConstrain =
              timeConstrainPoint.time.hh + '' + timeConstrainPoint.time.mm
          fplRoute.push(fix)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const rfl = fpActionFlight.rfl || '350'
    let newFlight = flight
      ? flight
      : {
          adep: fpActionFlight.adep.toUpperCase() || '',
          ades: fpActionFlight.ades.toUpperCase() || '',
          id: 'JS' + new Date().getTime(),
          c: fpActionFlight.c || 'KYM1715',
          cas: 250,
          flight: [
            {
              c: fpActionFlight.c || 'KYM1715',
              cP: fplRoute[0],
              a: parseInt(rfl) * 100,
              h: 270,
              sH: 270,
              M: 0.75,
              s: 250,
            },
          ],
          fpl: [
            '0',
            '1',
            '2',
            'B738/M',
            '4',
            '5',
            '6',
            '7',
            'REG/URKYM CODE/K1715',
            '9',
            fplRoute,
          ],
          passedPoints: [],
          timeShift: 0,
        }

    newFlight.rfl = rfl
    if (flight) {
      newFlight.ades = fpActionFlight.ades.toUpperCase()
      newFlight.fpl[10] = fplRoute
      newFlight.flight[0].cP = fplRoute[0]
    }
    if (newFlight.ades) {
      const airport = airports.filter((p) => p.name === newFlight.ades)[0]
      if (airport) {
        newFlight.fpl[10].push({ ...airport, isAdes: true })
      }
    }
    if (newFlight.adep) {
      const airport = airports.filter((p) => p.name === newFlight.adep)[0]
      if (airport) {
        newFlight.fpl[10].unshift({ ...airport, isAdep: true })
        newFlight.flight[0].cP = newFlight.fpl[10][0]
        newFlight.flight[0].a = 1000
      }
    }
    calcPointsDistance(newFlight)
    newFlight.flight[0].h = newFlight.fpl[10][0].heading
    newFlight = initialFlightUpdate(
      newFlight,
      newFlight.rfl,
      null,
      0,
      false,
      true,
      false,
    )
    if (timeConstrainPoint.name) {
      const entryPointIndex = newFlight.passedPoints
        .map((e) => e.pp.pP)
        .indexOf(timeConstrainPoint.name)
      const t1 = new Date(startDate.getTime()).setHours(
        timeConstrainPoint.time.hh,
        timeConstrainPoint.time.mm,
      )
      if (entryPointIndex >= 0) {
        const newShift =
          newFlight.passedPoints[entryPointIndex].index -
          (t1 - startDate.getTime()) / 4000
        newFlight.timeShift = parseInt(newShift)
        calcPassedPoints(newFlight, startDate.getTime())
      }
    }
    console.log(newFlight.timeShift)
    if (flight) {
      flight = newFlight
    } else {
      flights.push(newFlight)
    }
  }

  const updateFlight = () => {
    const flight = flights.filter((flt) => flt.id === flightId)[0]
    if (flight) {
      const tempFlight = JSON.parse(JSON.stringify(flight))
      tempFlight.firRoute = tempFlight.fpl[10]
        .map((p) => {
          if (p.timeConstrain) {
            return p.name + '/H' + p.timeConstrain
          }
          return p.name
        })
        .join(' ')
      setFpActionFlight((prev) => ({ ...prev, ...tempFlight }))
    }
    !flight &&
      setFpActionFlight((prev) => ({ radiobutton: prev.radiobutton, c: '' }))
  }

  const searchFlightId = () => {
    const flight = flights.filter((flt) => flt.c === fpActionFlight.c)[0]
    flight &&
      setPttWindows((prev) => ({
        ...prev,
        options: { ...prev.options, fpActionWindow: { flightId: flight.id } },
      }))
    !flight && setErrorField({ flightId: true })
  }
  const termFlight = () => {
    setFlights((prev) => {
      return prev.filter((flight) => flight.id !== flightId)
    })
    document.querySelector('#fpaction_view').checked = true
  }

  const handleChange = (e) => {
    if (e.target.id === 'fpaction_view') {
      setEditFields({})
      setFpActionFlight((prev) => ({ ...prev, radiobutton: e.target.id }))
    } else if (e.target.id === 'fpaction_modify') {
      setEditFields({
        flightId: true,
        // adep: true,
        ades: true,
        firRoute: true,
        rfl: true,
      })
      setFpActionFlight((prev) => ({ ...prev, radiobutton: e.target.id }))
    } else if (e.target.id === 'fpaction_create_current') {
      setEditFields({
        flightId: true,
        adep: true,
        ades: true,
        firRoute: true,
        rfl: true,
      })
      setFpActionFlight((prev) => ({ ...prev, radiobutton: e.target.id }))
      setPttWindows((prev) => {
        return {
          ...prev,
          options: {
            ...prev.options,
            fpActionWindow: {
              ...prev.options.fpActionWindow,
              flightId: null,
            },
          },
        }
      })
    } else if (e.target.id === 'fpaction_term') {
      setEditFields({})
      setFpActionFlight((prev) => ({ ...prev, radiobutton: e.target.id }))
    }
  }

  const handleInputChange = (e) => {
    setFpActionFlight((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const handleUpdate = (event) => {
    event.preventDefault()
    switch (fpActionFlight.radiobutton) {
      case 'fpaction_view':
        flightId && updateFlight()
        searchFlightId()
        break
      case 'fpaction_create_current':
        createFlight()
        setEditFields({})
        fpaction_view.current.click()
        searchFlightId()
        break
      case 'fpaction_modify':
        createFlight()
        setEditFields({})
        fpaction_view.current.click()
        updateFlight()
        break
      case 'fpaction_term':
        termFlight()
        break

      default:
        console.log('Unknown radio button')
    }
  }
  return (
    <Window
      label="FP Action"
      toggleWindow={() =>
        setPttWindows((prev) => ({
          ...prev,
          fpActionWindow: false,
          options: { ...prev.options, fpActionWindow: { flightId: null } },
        }))
      }
      maxContentHeight={300}
    >
      <form onSubmit={handleUpdate}>
        <div className="fpactionwindow_grid fpactionwindow_grid_firstline">
          <div>
            <label>FLIGHT ID</label>
            <input
              disabled={flightId && !editFields.flightId}
              autoFocus
              className={errorField.flightId ? 'error-field' : ''}
              value={fpActionFlight?.c}
              onChange={(e) => {
                setFpActionFlight((prev) => ({
                  ...prev,
                  c: e.target.value.toUpperCase(),
                }))
                setErrorField((prev) => {
                  return { ...prev, flightId: false }
                })
              }}
            ></input>
          </div>
          <div>
            <label>RADIO CALLSIGN</label>
            <input disabled></input>
          </div>
          <div>
            <label>NO</label>
            <input disabled></input>
          </div>
          <div>
            <label>A/C TYPE</label>
            <input
              disabled
              value={fpActionFlight?.fpl?.[3].split('/')[0]}
            ></input>
          </div>
          <div>
            <label>W</label>
            <input
              disabled
              value={fpActionFlight?.fpl?.[3].split('/')[1]}
            ></input>
          </div>
          <div>
            <label>DEP</label>
            <input
              disabled={!editFields.adep}
              name="adep"
              onChange={(e) => handleInputChange(e)}
              value={fpActionFlight?.adep}
            ></input>
          </div>
          <div>
            <label>DEST</label>
            <input
              disabled={!editFields.ades}
              name="ades"
              onChange={(e) => handleInputChange(e)}
              value={fpActionFlight?.ades}
            ></input>
          </div>
          <div>
            <label>NAV/COM</label>
            <input
              disabled
              value={fpActionFlight?.fpl?.[4].split('/')[0]}
            ></input>
          </div>
          <div>
            <label>RVSM</label>
            <input disabled></input>
          </div>
          <div>
            <label>SURVEILLANCE EQUEPMENT</label>
            <input
              disabled
              value={fpActionFlight?.fpl?.[4].split('/')[1]}
            ></input>
          </div>
          <div>
            <label>CSSR</label>
            <input disabled value={fpActionFlight?.squawk}></input>
          </div>
          <div>
            <label>R</label>
            <input disabled></input>
          </div>
          <div>
            <label>FT</label>
            <input disabled></input>
          </div>
        </div>
        <div className="fpactionwindow_grid fpactionwindow_grid_secondline">
          <div>
            <label>SID</label>
            <input disabled></input>
          </div>
          <div>
            <label>FIR ROUTE</label>
            <input
              disabled={!editFields.firRoute}
              name="firRoute"
              onChange={(e) => handleInputChange(e)}
              value={fpActionFlight?.firRoute}
            ></input>
          </div>
          <div>
            <label>STAR</label>
            <input disabled></input>
          </div>
        </div>
        <div className="fpactionwindow_grid fpactionwindow_grid_thirdline">
          <div>
            <label>EOBD</label>
            <input disabled></input>
          </div>
          <div>
            <label>EOBT</label>
            <input disabled></input>
          </div>
          <div>
            <label>MSG</label>
            <input disabled></input>
          </div>
          <div>
            <label>CTOT</label>
            <input disabled></input>
          </div>
          <div>
            <label>ATFCM</label>
            <input disabled></input>
          </div>
          <div>
            <label>ATD</label>
            <input disabled></input>
          </div>
          <div>
            <label>ETA</label>
            <input disabled></input>
          </div>
          <div>
            <label>SPEED</label>
            <input disabled></input>
          </div>
          <div>
            <label>LEVEL</label>
            <input
              disabled={!editFields.rfl}
              name="rfl"
              value={fpActionFlight.rfl}
              onChange={(e) => handleInputChange(e)}
            ></input>
          </div>
          <div>
            <label>FIX</label>
            <input disabled></input>
          </div>
          <div>
            <label>ESTIMATE TIME</label>
            <input disabled></input>
          </div>
          <div>
            <label>LEVEL</label>
            <input disabled></input>
          </div>
          <div>
            <label>RCOORD</label>
            <input disabled></input>
          </div>
          <div>
            <label>SCOORD</label>
            <input disabled></input>
          </div>
          <div>
            <label>ALT AD(S)</label>
            <input disabled></input>
          </div>
        </div>
        <div className="fpactionwindow_grid fpactionwindow_grid_fourthline">
          <div>
            <label>FIELD18</label>
          </div>
          <div>
            <input
              disabled
              value={fpActionFlight?.fpl?.[8]
                .replace(new RegExp('REG\\/(.\\S*)', 'gm'), '')
                .replace(new RegExp('CODE\\/(.\\S*)', 'gm'), '')}
            ></input>
          </div>
        </div>
        <div className="fpactionwindow_grid fpactionwindow_grid_fifthline">
          <div>
            <label>FREE TEXT</label>
            <input disabled></input>
          </div>
          <div>
            <label>CFL</label>
            <input disabled></input>
          </div>
          <div>
            <label>ECL</label>
            <input disabled></input>
          </div>
          <div></div>
          <div>
            <label>REG</label>
            <input
              disabled
              value={fpActionFlight?.fpl?.[8]?.match('REG\\/(.\\S*)')[1] || ''}
            ></input>
          </div>
          <div>
            <label>STS</label>
            <input disabled></input>
          </div>
          <div>
            <label>MODE S</label>
            <input
              disabled
              value={
                fpActionFlight?.fpl?.[8]?.match('CODE\\/(.\\S*)')?.[1] || ''
              }
            ></input>
          </div>
        </div>
        <div className="fpactionwindow_grid fpactionwindow_grid_sixthline">
          <div>
            <label>ORIGINAL ROUTE</label>
          </div>
          <div>
            <input disabled value={fpActionFlight?.fpl?.[6]}></input>
          </div>
        </div>
        <div className="fpactionwindow_grid_seventhline">
          <div>
            <label>
              <input
                id="fpaction_view"
                type="radio"
                name="fpaction"
                ref={fpaction_view}
                onChange={(e) => handleChange(e)}
                defaultChecked
              />
              VIEV
            </label>
            <br />
            <label>
              <input type="radio" disabled />
              HISTORY
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              CREATE
            </label>
            <br />
            <label>
              <input
                id="fpaction_create_current"
                type="radio"
                name="fpaction"
                onChange={(e) => handleChange(e)}
              />
              CREATE CURRENT
            </label>
          </div>
          <div>
            <label>
              <input
                id="fpaction_modify"
                type="radio"
                name="fpaction"
                onChange={(e) => handleChange(e)}
              />
              MODIFY
            </label>
            <br />
            <label>
              <input
                type="radio"
                id="fpaction_term"
                name="fpaction"
                onChange={(e) => handleChange(e)}
              />
              TERM
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              NOTIF
            </label>
            <br />
            <label>
              <input type="radio" name="fpaction" disabled />
              C.NOTIF
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              ATD
            </label>
            <br />
            <label>
              <input type="radio" name="fpaction" disabled />
              C.ATD
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              ATA
            </label>
            <br />
            <label>
              <input type="radio" name="fpaction" disabled />
              C.ATA
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              EST
            </label>
            <br />
            <label>
              <input type="radio" name="fpaction" disabled />
              REA
            </label>
          </div>
          <div>
            <label>
              <input type="radio" name="fpaction" disabled />
              POS
            </label>
            <br />
            <label>
              <input type="radio" name="fpaction" disabled />
              AFTN SEND
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" disabled />
              AFP
            </label>
          </div>
          <div style={{ flex: 1 }}></div>
          <button
            type="submit"
            style={{ width: 'auto', margin: '10px 3px' }}
            className={'buttonSDD'}
            // onClick={() => updateRoute()}
          >
            UPDATE
          </button>
          <span
            style={{ width: 'auto', margin: '10px 3px' }}
            className={'buttonSDD'}
          >
            CANCEL
          </span>
          <span
            style={{ width: 'auto', margin: '10px 3px' }}
            className={'buttonSDD'}
            onClick={() => {
              setPttWindows((prev) => {
                return {
                  ...prev,
                  options: {
                    ...prev.options,
                    fpActionWindow: {
                      ...prev.options.fpActionWindow,
                      flightId: null,
                    },
                  },
                }
              })
              setErrorField({})
            }}
          >
            CLEAR
          </span>
          <button
            disabled
            style={{ width: 'auto', margin: '10px 8px 10px 3px' }}
            className={'buttonSDD'}
          >
            PRINT
          </button>
        </div>
      </form>
    </Window>
  )
}

export default FpAction
