import { configureStore } from '@reduxjs/toolkit'
import mapReducer from '../reducers/mapSlice'
import commReducer from '../reducers/commSlice'

export const store = configureStore({
  reducer: {
    map: mapReducer,
    comm: commReducer,
  },
})
