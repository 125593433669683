/*
    node_js
    2/1/2021 7:27 PM
    by Oleksandr
*/
import '../../../CWP.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  getFlightColor,
  getIsInSector,
  getPolygonPoints,
  calcFlight,
  calcPointsDistance,
} from '../../../../../calc/calc'
import Rbls from '../../rbl/Rbls'
import {
  ALERT_COLOR,
  ASSUMED_COLOR,
  CONCERNED_COLOR,
  DFLASSUMED_COLOR,
} from '../../colors/colors'
import * as geolib from 'geolib'
import { lvt, uklv } from '../../../../../config/adap/map'
import Target from '../../target/Target'
import ConnectingLine from '../../target/ConnectingLine'
import AccLabel from '../../label/AccLabel'
import TargetHistory from '../../target/TargetHistory'
import Window from '../../windows/Window'
import WorkloadWindow from '../../windows/WorkloadWindow'
import { useCwpContext } from '../../../../../contexts/CwpContext'
import {
  calcStcaAll,
  calcWorkloadAndMtcd,
} from '../../../../../utils/calcSafetyNets'
import SystemTimeWindow from '../../windows/SystemTimeWindow'
import EditRoutePath from '../../route/editRoutePath'
import SvgSubLayer from './SvgSubLayer'
import RadarTarget from '../../target/RadarTarget'

const TrackLayer = ({ isEpp, isPlt, isCon }) => {
  const {
    flights,
    tickValue,
    zoom,
    startDate,
    setSelectedFlight,
    selectedFlight,
    timeOfUpdate,
    setZoom,
    lonLatCenter,
    setLonLatCenter,
    assumeFlight,
    conAssumeFlight,
    showCallsignMenu,
    setTypeofFlightMenu,
    typeOfFlightMenu,
    projection,
    updateFlightsTime,
    showFlightMenu,
    pttWindows,
    speedVector,
    sectors,
    conflictInfoList,
    trackPlanSet,
    setTrackPlanSet,
    editRouteValues,
    setEditRouteValues,
    labelSettings,
    stcas,
    setStcas,
    setConflictInfoList,
    conflictNumberList,
    setConflictNumberList,
    setWorkloadArray,
    setUpdateFlightsTime,
    setPttWindows,
    fAlt,
  } = useCwpContext()

  const [, setMarkerDot] = useState('')
  const [trackPlan, setTrackPlan] = useState([])
  const [trackPlanLines, setTrackPlanLines] = useState([])
  const [trackPlanMtcdLines, setTrackPlanMtcdLines] = useState([])
  const [radarTargets, setRadarTargets] = useState([])

  const [isMouseMoveOnDiv, setIsMouseMoveOnDiv] = useState(false)
  const [isMouseMoveOnSvg, setIsMouseMoveOnSvg] = useState(false)

  const [rblsArray, setRblsArray] = useState([])

  const [stcasText, setStcasText] = useState([])
  const [mtcdIds, setMtcdIds] = useState(new Set())
  const [stcasIds, setStcasIds] = useState(new Set())

  const trackLayerDiv = useRef()
  const trackLayerSvg = useRef()

  const updateRoute = ([lon, lat]) => {
    if (lat && lon) {
      setEditRouteValues((prev) => {
        prev[3].push([lon, lat])
        return prev
      })
      return
    }
    if (!editRouteValues[4]) return
    const [flight] = flights.filter((flt) => flt.id === editRouteValues[0])
    const index = tickValue + flight.timeShift

    const nextPointIndex = parseInt(editRouteValues[4])

    if (editRouteValues[1]) {
      if (nextPointIndex - parseInt(editRouteValues[1]) > 1) {
        flight.fpl[10].splice(
          1 + parseInt(editRouteValues[1]),
          -1 + parseInt(nextPointIndex) - parseInt(editRouteValues[1]),
        )
      }
    } else {
      flight.fpl[10].splice(
        flight.flight[index].nP,
        nextPointIndex - flight.flight[index].nP,
      )
      flight.fpl[10].splice(flight.flight[index].nP, 0, {
        dist: 0,
        latitude: flight.flight[index].cP['latitude'],
        longitude: flight.flight[index].cP['longitude'],
        name: `${parseInt(flight.flight[index].cP['latitude'])}N0${parseInt(
          flight.flight[index].cP['longitude'],
        )}E`,
      })
      flight.flight[index].nP++
    }
    editRouteValues[3].pop()
    const newPoints = editRouteValues[3].map(([lon, lat]) => {
      return {
        dist: 0,
        latitude: lat,
        longitude: lon,
        name: `${lat.toFixed(2)}N0${lon.toFixed(2)}E`,
      }
    })
    if (editRouteValues[1] && newPoints[0]) {
      flight.fpl[10].splice(parseInt(editRouteValues[1]) + 1, 0, ...newPoints)
    } else if (newPoints[0]) {
      flight.fpl[10].splice(flight.flight[index].nP, 0, ...newPoints)
    }
    calcPointsDistance(flight)
    calcFlight(flight, index, startDate.getTime())
    setEditRouteValues([null])
    setIsMouseMoveOnDiv(false)
    setUpdateFlightsTime(new Date())
  }

  const handleTrackPlanPointClick = (e) => {
    setEditRouteValues((prev) => {
      if (!prev[0]) {
        setIsMouseMoveOnDiv(true)
        return [e.target.dataset.flightid, e.target.dataset.tppointid, [], []]
      } else {
        prev[4] = e.target.dataset.tppointid
        return prev
      }
    })
    updateRoute([])
  }

  const getEditRoutePath = () => {
    if (!editRouteValues[0]) return []
    const [flight] = flights.filter((flt) => flt.id === editRouteValues[0])
    const index = tickValue + flight.timeShift
    const lat1 = flight.flight[index].cP.latitude
    const lon1 = flight.flight[index].cP.longitude
    let pos
    if (editRouteValues[1]) {
      pos = projection([
        flight.fpl[10][editRouteValues[1]].longitude,
        flight.fpl[10][editRouteValues[1]].latitude,
      ])
    } else {
      pos = projection([lon1, lat1])
    }

    const [posX2, posY2] = editRouteValues[2][0] ? editRouteValues[2] : pos
    const tmpArr = [[pos[0], pos[1]]]
    editRouteValues[3].reduce((prev, routePoint) => {
      prev.push(projection(routePoint))
      return prev
    }, tmpArr)
    tmpArr.push([posX2, posY2])
    return tmpArr
  }

  const calculateTrackPlan = (flt, trackPlanMtcdLines) => {
    const tempTrackPlanLine = []
    const tempTrackPlan = []
    const { flight, timeShift, id } = flt
    const index = isCon ? 0 : tickValue + timeShift
    if (flight[index] && flight.length > index) {
      let pos = projection([
        flight[index].cP.longitude,
        flight[index].cP.latitude,
      ])
      const top = pos[1] + 'px'
      const left = pos[0] + 'px'
      const tempNextPoint = flight[index].nP
      let nextPointIndex = -1
      if (flt.fpl[10][tempNextPoint])
        nextPointIndex = flt.passedPoints
          .map((e) => e.pp.pP)
          .indexOf(flt.fpl[10][tempNextPoint].name)
      let nextPointTime = ''
      const dflColor = isCon ? getDflColor(flt) : getDflColor(flight[index])

      if (nextPointIndex >= 0) {
        nextPointTime = new Date(
          startDate.getTime() +
            (flt.passedPoints[nextPointIndex].index - timeShift) * 4000,
        ).toLocaleTimeString('es-ES')

        pos = projection([
          flt.fpl[10][tempNextPoint].longitude,
          flt.fpl[10][tempNextPoint].latitude,
        ])
        const top3 = pos[1] + 'px'
        const left3 = pos[0] + 'px'
        tempTrackPlanLine.push(
          <line
            x1={left}
            y1={top}
            x2={left3}
            y2={top3}
            stroke={dflColor}
            pointerEvents="none"
            key={'trackPlan_line0' + flt.id}
          />,
          <polygon
            points={getPolygonPoints(pos)}
            stroke={dflColor}
            fill={dflColor}
            pointerEvents="none"
            key={'trackPlan_polygon0' + flt.id}
          />,
          <circle
            cursor="crosshair"
            cx={pos[0]}
            cy={pos[1]}
            r={4}
            fillOpacity="0"
            data-tppointid={flight[index].nP}
            data-flightid={flt.id}
            pointerEvents="visiblePainted"
            key={'trackPlan_circle0' + flt.id}
            onClick={handleTrackPlanPointClick}
            //   (e) => {
            //   setEditRouteValues((prev) => {
            //     if (prev[0]) {
            //       prev[1] = e.target.dataset.tppointid
            //       return prev
            //     } else {
            //       setIsMouseMoveOnDiv(true)
            //       return [e.target.dataset.flightid, null, [], []]
            //     }
            //   })
            //   updateRoute([])
            // }}
          />,
        )
      }
      for (let i = flight[index].nP; i < flt.fpl[10].length - 1; i++) {
        const nextPointIndex = flt.passedPoints
          .map((e) => e.pp.pP)
          .indexOf(flt.fpl[10][i].name)
        let nextPointTime = ''
        if (nextPointIndex >= 0) {
          nextPointTime = new Date(
            startDate.getTime() +
              (flt.passedPoints[nextPointIndex].index - timeShift) * 4000,
          ).toLocaleTimeString('es-ES')

          pos = projection([flt.fpl[10][i].longitude, flt.fpl[10][i].latitude])
          const top1 = pos[1] + 'px'
          const left1 = pos[0] + 'px'
          pos = projection([
            flt.fpl[10][i + 1].longitude,
            flt.fpl[10][i + 1].latitude,
          ])
          const top2 = pos[1] + 'px'
          const left2 = pos[0] + 'px'
          let dflPointTextL1, dflPointTextL2
          if (flt.fpl[10][i].name.substr(0, 2) === 'LL') {
            dflPointTextL1 = flt.fpl[10][i].name
          } else {
            dflPointTextL1 =
              nextPointTime.substr(0, 5) + ' ' + flt.fpl[10][i].name
            dflPointTextL2 = Math.round(
              flt.passedPoints[nextPointIndex].pp.a / 100,
            )
          }
          tempTrackPlanLine.push(
            <line
              x1={left1}
              y1={top1}
              x2={left2}
              y2={top2}
              stroke={dflColor}
              key={'trackPlan_line' + i + flt.id}
              pointerEvents="none"
            />,
            <polygon
              points={getPolygonPoints(pos)}
              stroke={dflColor}
              fill={dflColor}
              pointerEvents="none"
              key={'trackPlan_polygon' + i + flt.id}
            />,
            <circle
              cursor="crosshair"
              cx={pos[0]}
              cy={pos[1]}
              r={3}
              fillOpacity="0"
              data-tppointid={i + 1}
              data-flightid={flt.id}
              pointerEvents="visiblePainted"
              key={'trackPlan_circle' + i + flt.id}
              onClick={handleTrackPlanPointClick}
            />,
          )
          tempTrackPlan.push(
            <div
              key={
                'trackPlan_' +
                id +
                '_' +
                flt.fpl[10][i].latitude +
                Math.random()
              }
              className="trackPlan"
              style={{
                position: 'absolute',
                top: top1,
                left: left1,
                color: dflColor,
                pointerEvents: 'none',
              }}
            >
              {dflPointTextL1}
              <br />
              {dflPointTextL2}
            </div>,
          )
        }
      }
    }
    if (trackPlanMtcdLines[id]) {
      trackPlanMtcdLines[id].forEach((tpml, tInd) => {
        const pos1 = projection(tpml.pos1)
        const pos2 = projection(tpml.pos2)
        tempTrackPlanLine.push(
          <line
            x1={pos1[0]}
            y1={pos1[1]}
            x2={pos2[0]}
            y2={pos2[1]}
            stroke="red"
            key={'trackPlan_lineMtcd' + flt.id + '_' + tInd + '_' + pos1[0]}
          />,
        )
      })
    }
    return [tempTrackPlan, tempTrackPlanLine]
  }

  const updateAllTrackPlans = () => {
    const tempTrackPlan = []
    const tempTrackPlanLine = []
    trackPlanSet.forEach((trPl) => {
      let flight1Index = flights.map((e) => e.id).indexOf(trPl)
      if (flight1Index < 0) return
      const [returnedTempTrackPlan, returnedTempTrackPlanLine] =
        calculateTrackPlan(flights[flight1Index], trackPlanMtcdLines)
      tempTrackPlan.push(...returnedTempTrackPlan)
      tempTrackPlanLine.push(...returnedTempTrackPlanLine)
    })
    setTrackPlan(tempTrackPlan)
    setTrackPlanLines(tempTrackPlanLine)
  }

  const addTrackPlan = (id) => {
    setTrackPlanSet((prev) => {
      const result = new Set(prev)
      if (result.has(id)) {
        result.delete(id)
      } else {
        result.add(id)
      }
      return result
    })

    updateAllTrackPlans()
  }

  useEffect(() => {
    updateAllTrackPlans()
  }, [trackPlanSet])

  const addRbl = (id, pCoord) => {
    setRblsArray((prev) => {
      const result = [...prev]
      if (result.length === 0 || result[result.length - 1].p2Id) {
        result.push({
          p1isFlight: id !== 'point',
          p1Id: id,
          p1Coord: pCoord,
          p1isMoving: false,
          rblId: 'rbl' + new Date().getTime(),
          p2isMoving: true,
          isMinSep: false,
        })
        setIsMouseMoveOnDiv(true)
        return result
      } else {
        setIsMouseMoveOnDiv(false)

        return result.map((rbl, index) => {
          if (index === result.length - 1) {
            rbl = {
              ...rbl,
              p2isFlight: id !== 'point',
              p2Id: id,
              p2isMoving: false,
              p2Coord: pCoord,
              p2CoordXY: null,
            }
            return rbl
          } else {
            return rbl
          }
        })
      }
    })
  }

  const mouseMoveOnDiv = useCallback(
    (e) => {
      if (editRouteValues[0]) {
        setEditRouteValues((prev) => {
          prev[2] = [e.clientX, e.clientY]
          return prev
        })
      } else if (
        rblsArray.length === 0 ||
        (rblsArray[rblsArray.length - 1] === undefined &&
          !rblsArray[rblsArray.length - 1].p2isMoving)
      ) {
        return
      }

      setRblsArray((prev) =>
        prev.map((rbl, index) => {
          if (index === prev.length - 1) {
            rbl.p2CoordXY = {
              clientX: e.clientX,
              clientY: e.clientY,
            }
            return rbl
          } else {
            return rbl
          }
        }),
      )
    },
    [rblsArray, editRouteValues],
  )

  useEffect(() => {
    if (isMouseMoveOnDiv)
      trackLayerDiv.current.addEventListener('mousemove', mouseMoveOnDiv)
    else trackLayerDiv.current.removeEventListener('mousemove', mouseMoveOnDiv)
    return () => {
      if (trackLayerDiv.current)
        return trackLayerDiv.current.removeEventListener(
          'mousemove',
          mouseMoveOnDiv,
        )
    }
  }, [isMouseMoveOnDiv, mouseMoveOnDiv])

  const mouseUpOnDiv = useCallback(
    (e) => {
      if (editRouteValues[0]) {
        if (e.button === 0) {
          updateRoute(projection.invert([e.clientX, e.clientY]))
        } else if (e.button === 2) {
          setEditRouteValues((prev) => {
            if (prev[3].length === 0) {
              return []
            } else {
              prev[3].pop()
              return prev
            }
          })
        }
      } else if (!isMouseMoveOnSvg && e.button === 1) {
        const coord = projection.invert([e.clientX, e.clientY])
        addRbl('point', { lat: coord[1], lon: coord[0] })
      } else if (isMouseMoveOnDiv && e.button === 2) {
        setIsMouseMoveOnDiv(false)
        setRblsArray((prev) => {
          const result = [...prev]
          result.pop()
          return result
        })
      }
    },
    [isMouseMoveOnDiv, isMouseMoveOnSvg, editRouteValues, updateRoute],
  )

  useEffect(() => {
    trackLayerDiv.current.addEventListener('mouseup', mouseUpOnDiv, false)
    return () => {
      if (trackLayerDiv.current)
        return trackLayerDiv.current.removeEventListener(
          'mouseup',
          mouseUpOnDiv,
          false,
        )
    }
  }, [isMouseMoveOnDiv, mouseUpOnDiv])

  const deleteRbl = (e) => {
    const rblId = e.target.dataset.rblid
    setRblsArray((prev) => prev.filter((rbl) => rbl.rblId !== rblId))
  }

  const minSepRbl = (e) => {
    const rblId = e.target.dataset.rblid
    setRblsArray((prev) =>
      prev.map((rbl) => {
        if (rbl.rblId === rblId) {
          rbl.isMinSep = true
          return rbl
        } else {
          return rbl
        }
      }),
    )
  }

  ///TODO переробити updateFlights
  const updateFlights = () => {
    drawTrackLayer()
  }

  useEffect(() => {
    updateFlights()
  }, [updateFlightsTime, labelSettings, fAlt])

  useEffect(() => {
    trackLayerSvg.current.addEventListener('mousedown', mouseDownOnSVG, false)
  }, [])

  const mouseUpOnSVG = useCallback(() => {
    if (typeOfFlightMenu !== 'dct' || !editRouteValues[0]) {
      setTypeofFlightMenu(null)
      showCallsignMenu(null)
      trackLayerSvg.current?.removeEventListener('mousemove', mouseMoveOnSVG)
    }
  }, [typeOfFlightMenu, editRouteValues])

  useEffect(() => {
    trackLayerSvg.current?.addEventListener('mouseup', mouseUpOnSVG)
    return () => {
      return trackLayerSvg.current?.removeEventListener('mouseup', mouseUpOnSVG)
    }
  }, [mouseUpOnSVG])

  const mouseDownOnSVG = () => {
    setIsMouseMoveOnSvg(false)
    trackLayerSvg.current?.addEventListener('mousemove', mouseMoveOnSVG, false)
  }

  const mouseMoveOnSVG = (e) => {
    if (e.buttons === 2) {
      setZoom((prevZoom) => {
        prevZoom += e.movementY * 30
        if (prevZoom < 1000) {
          prevZoom = 1000
        }
        return prevZoom
      })
    }

    if (e.buttons === 4) {
      setIsMouseMoveOnSvg(true)

      setLonLatCenter((prev) => [
        prev[0] - e.movementX / 100,
        prev[1] + e.movementY / 100,
      ])
    }
  }

  const addStcas = useCallback(() => {
    //TODO STCA prediction
    setStcas(calcStcaAll(flights, tickValue + 1, isCon))
  }, [tickValue, flights, isCon])

  useEffect(() => {
    addStcas()
  }, [addStcas])

  const addMtcd = useCallback(() => {
    const result = calcWorkloadAndMtcd(flights)
    setConflictInfoList(result[0])
    setWorkloadArray(result[1])
    setConflictNumberList(result[2])
  }, [flights, updateFlightsTime])

  useEffect(() => {
    addMtcd()
  }, [addMtcd])

  const drawStcas = useCallback(() => {
    const tempStcasIds = new Set()
    const tempStcaText = []
    stcas.forEach((a) => {
      tempStcasIds.add(a.id1)
      tempStcasIds.add(a.id2)

      tempStcaText.push(
        <tr
          key={'stca_' + a.id1 + '_' + a.id2}
          className="sddTableTr"
          style={{ color: 'red' }}
        >
          <td style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {a.callsign1}
          </td>
          <td style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {a.callsign2}
          </td>
          <td style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {a.distance.toFixed(2) + 'NM'}
          </td>
        </tr>,
      )
    })
    setStcasIds(tempStcasIds)
    setStcasText(tempStcaText)
  }, [stcas])

  const drawMTCDs = useCallback(() => {
    const tempConflictsIds = new Set()

    const tMtcdLines = []
    const tempTrackPlanMtcdLine = []
    Array.from(conflictInfoList).forEach((conflict) => {
      const tf1ei = conflict.flight1EndIndex - conflict.flight1.timeShift
      const tf2ei = conflict.flight2EndIndex - conflict.flight2.timeShift
      const tf1si = conflict.flight1StartIndex - conflict.flight1.timeShift
      const tf2si = conflict.flight2StartIndex - conflict.flight2.timeShift
      const arr = [
        [conflict.flight1, tf1si, tf1ei],
        [conflict.flight2, tf2si, tf2ei],
      ]
      arr.forEach(([flight, start, end]) => {
        if (end > tickValue) {
          tempConflictsIds.add(flight.id)
          tMtcdLines[flight.id] = []
          for (
            let i =
              start > tickValue
                ? start + flight.timeShift
                : tickValue + flight.timeShift;
            i <= end + flight.timeShift;
            i++
          ) {
            if (flight.flight[i]) {
              const pos = [
                flight.flight[i].cP.longitude,
                flight.flight[i].cP.latitude,
              ]
              tMtcdLines[flight.id].push(pos)
            }
          }
          tempTrackPlanMtcdLine[flight.id] =
            tempTrackPlanMtcdLine[flight.id] || []
          tMtcdLines[flight.id].forEach((pos, index) => {
            if (tMtcdLines[flight.id][index + 1]) {
              tempTrackPlanMtcdLine[flight.id].push({
                pos1: pos,
                pos2: tMtcdLines[flight.id][index + 1],
              })
            }
          })
        }
      })
    })
    setTrackPlanMtcdLines(tempTrackPlanMtcdLine)
    setMtcdIds(tempConflictsIds)
  }, [tickValue, conflictInfoList])

  useEffect(() => {
    drawStcas()
    drawMTCDs()
  }, [drawStcas, drawMTCDs])

  useEffect(() => {
    drawTrackLayer()
    updateAllTrackPlans()
  }, [
    tickValue,
    startDate,
    zoom,
    selectedFlight,
    timeOfUpdate,
    lonLatCenter,
    flights,
    stcasIds,
    speedVector,
  ])

  const getCallsignColor = (flight, color, cP, a) => {
    const isInSector = getIsInSector(cP.latitude, cP.longitude, a, sectors)
    return flight.isAssumed
      ? ASSUMED_COLOR
      : flight.isAdvanced
      ? ASSUMED_COLOR
      : isInSector
      ? CONCERNED_COLOR
      : color
  }

  const getDflColor = (flight) => {
    return flight.isAssumed ? DFLASSUMED_COLOR : getFlightColor(flight)
  }

  const drawTrackLayer = () => {
    let tempMarkerDot = []

    const tempRadarTargets = []

    flights.forEach((flt) => {
      let { flight, timeShift, id } = flt

      let index = isCon ? 0 : tickValue + timeShift
      let color, callsignColor

      let top, left
      if (flight[index] !== null && flight.length > index && index >= 0) {
        color = isCon ? getFlightColor(flt) : getFlightColor(flight[index])
        flight.isUklv = geolib.isPointInPolygon(
          {
            latitude: flight[index].cP.latitude,
            longitude: flight[index].cP.longitude,
          },
          uklv,
        )
        flt.isLvt =
          geolib.isPointInPolygon(
            {
              latitude: flight[index].cP.latitude,
              longitude: flight[index].cP.longitude,
            },
            lvt,
          ) && flight[index].a <= 16500
        callsignColor = isCon
          ? getCallsignColor(flt, color, flight[index].cP, flight[index].a)
          : getCallsignColor(
              flight[index],
              color,
              flight[index].cP,
              flight[index].a,
            )
        let pos = projection([
          flight[index].cP.longitude,
          flight[index].cP.latitude,
        ])
        top = pos[1] + 'px'
        left = pos[0] + 'px'
        let targetColor = stcasIds.has(id) ? ALERT_COLOR : color
        let isMtcd = mtcdIds.has(id)

        tempRadarTargets.push(
          <RadarTarget
            id={id}
            flight={flight}
            left={left}
            top={top}
            targetColor={targetColor}
            trackLayerSvg={trackLayerSvg}
            projection={projection}
            index={index}
            speedVector={speedVector}
            labelSettings={labelSettings}
            selectedFlight={selectedFlight}
            setSelectedFlight={setSelectedFlight}
            flt={flt}
            updateAllTrackPlans={updateAllTrackPlans}
            addTrackPlan={addTrackPlan}
            color={color}
            callsignColor={callsignColor}
            updateFlights={updateFlights}
            timeOfUpdate={timeOfUpdate}
            stcasIds={stcasIds}
            tickValue={tickValue}
            isPlt={isPlt}
            isCon={isCon}
            isEpp={isEpp}
            addRbl={addRbl}
            isMtcd={isMtcd}
            assumeFlight={assumeFlight}
            conAssumeFlight={conAssumeFlight}
            showCallsignMenu={showCallsignMenu}
            showFlightMenu={showFlightMenu}
            startDate={startDate}
            setPttWindows={setPttWindows}
            fAlt={fAlt}
          />,
        )
        ///Calculating Vector
        // if (speedVector.isVisible) {
        //   let previousPosition = flight[index].cP
        //   for (let i = 1; i <= speedVector.time; i++) {
        //     let nextPosition = geolib.computeDestinationPoint(
        //       flight[index].cP,
        //       ((flight[index].gs || flight[index].t) * 1.852 * 1000 * i) / 60,
        //       flight[index].h + 6,
        //     )
        //     let pos0 = projection([
        //       previousPosition.longitude,
        //       previousPosition.latitude,
        //     ])
        //     pos = projection([nextPosition.longitude, nextPosition.latitude])
        //     targetHistory.push(
        //       <g key={i + Math.random()}>
        //         <defs>
        //           <marker
        //             id={'dot' + id}
        //             viewBox="0 0 6 6"
        //             refX="3"
        //             refY="3"
        //             markerWidth="3"
        //             markerHeight="3"
        //           >
        //             <circle
        //               cx="3"
        //               cy="3"
        //               r="3"
        //               fill={targetColor}
        //               pointerEvents="none"
        //             />
        //           </marker>
        //         </defs>
        //         <line
        //           x1={pos0[0]}
        //           y1={pos0[1]}
        //           x2={pos[0]}
        //           y2={pos[1]}
        //           stroke={targetColor}
        //           pointerEvents="none"
        //           markerEnd={'url(#dot' + id + ')'}
        //         />
        //       </g>,
        //     )
        //     previousPosition = nextPosition
        //   }
        // }

        ///Calculating History
        // const testTargetHistory = []
        // for (let i = index - 10; i < index; i++) {
        //   if (i > 0) {
        //     pos = projection([flight[i].cP.longitude, flight[i].cP.latitude])
        //     let hTop = pos[1]
        //     let hLeft = pos[0]
        //     testTargetHistory.push(
        //       <TargetHistory
        //         trackLayerSvg={trackLayerSvg}
        //         color={targetColor}
        //         track={{ X: parseInt(hLeft), Y: parseInt(hTop) }}
        //         key={'target_history_' + id + flight[i].cP.latitude}
        //       />,
        //     )
        //   }
        // }
        ///Pushing AccLabel
        // let accLabel = (
        //   <AccLabel
        //     color={color}
        //     callsignColor={callsignColor}
        //     index={index}
        //     left={left}
        //     top={top}
        //     setSelectedFlight={setSelectedFlight}
        //     fltId={id}
        //     selectedFlight={selectedFlight}
        //     key={'acclabel_' + id}
        //     flight={flt}
        //     id={'acclabel_' + id}
        //     updateFlights={updateFlights}
        //     timeOfUpdate={timeOfUpdate}
        //     isStca={stcasIds.has(id)}
        //     tickValue={tickValue}
        //     isPlt={isPlt}
        //     isCon={isCon}
        //     isEpp={isEpp}
        //     addRbl={addRbl}
        //     addTrackPlan={addTrackPlan}
        //     isMtcd={isMtcd}
        //     assumeFlight={assumeFlight}
        //     conAssumeFlight={conAssumeFlight}
        //     showCallsignMenu={showCallsignMenu}
        //     showFlightMenu={showFlightMenu}
        //     startDate={startDate}
        //     labelSettings={labelSettings}
        //     setPttWindows={setPttWindows}
        //   />
        // )
        // flightSpan2.push(accLabel)
      }
    })

    setMarkerDot(tempMarkerDot)
    setRadarTargets(tempRadarTargets)
  }

  return (
    <div className="TrackLayer" ref={trackLayerDiv}>
      {trackPlan}
      <SvgSubLayer
        trackPlanLines={trackPlanLines}
        rblsArray={rblsArray}
        projection={projection}
        tickValue={tickValue}
        flights={flights}
        deleteRbl={deleteRbl}
        minSepRbl={minSepRbl}
        getEditRoutePath={getEditRoutePath}
        ref={trackLayerSvg}
      ></SvgSubLayer>
      {radarTargets}
      {pttWindows.systemTimeWindow && (
        <div
          style={{ position: 'absolute', left: '0px', top: '0px', zIndex: 100 }}
        >
          <SystemTimeWindow showRadios={!isCon} />
        </div>
      )}

      {stcasText.length > 0 && (
        <div
          style={{
            position: 'fixed',
            right: '0px',
            top: '0px',
            fontSize: '15px',
            width: '300px',
            zIndex: 100,
          }}
        >
          <Window label="ST CONFLICT" width={'auto'} hideCloseButton={true}>
            <table>
              <thead>
                <tr>
                  <th className="headerTh">C/S</th>
                  <th className="headerTh">C/S</th>
                  <th className="headerTh">CDIS</th>
                </tr>
              </thead>
              <tbody>{stcasText}</tbody>
            </table>
          </Window>
        </div>
      )}
      {(isEpp || isPlt) && pttWindows.workloadWindow && <WorkloadWindow />}
    </div>
  )
}

export default TrackLayer
