import React, { useEffect, useMemo } from 'react'
import VideoWithVolume from './VideoWithVolume'

const VideoChat = (props) => {
  const { setLocalVideoRef, toggleAudio, localStream, isLocalAudioMuted } =
    props

  useEffect(() => {
    if (localStream) {
      console.log(localStream.getAudioTracks()[0].enabled)
    }
  }, [localStream])

  const handleToggleAudio = (e) => {
    if (
      e.nativeEvent.type === 'mousedown' ||
      e.nativeEvent.type === 'touchstart'
    )
      toggleAudio(true)
    if (e.nativeEvent.type === 'mouseup' || e.nativeEvent.type === 'touchend')
      toggleAudio(false)
  }
  return (
    <section id="container" style={{ background: 'black' }}>
      {!isLocalAudioMuted && (
        <span style={{ position: 'absolute', zIndex: '15', left: '0px' }}>
          🔇
        </span>
      )}
      <VideoWithVolume
        videoStream={localStream}
        video={
          <video
            style={{ width: '200px' }}
            ref={setLocalVideoRef}
            autoPlay
            playsInline
            muted="muted"
            onTouchStart={handleToggleAudio}
            onTouchEnd={handleToggleAudio}
          />
        }
      />
    </section>
  )
}

export default VideoChat
