/*
    node_js
    1/31/2021 7:24 PM
    by Oleksandr
*/
// import { uklv } from '../config/adap/map'

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import {
  calcApproach,
  calcDeparture,
  calcExerciseWorkload,
  calcFlight,
  calcPassedPoints,
  getProjection,
} from '../calc/calc'
import { convertFromRaw, EditorState } from 'draft-js'
import flightClearanceReducer from '../reducers/flightClearanceReducer'
import { getHistoricalFPLs } from '../services/historicalService'
import { exercises } from '../services/exerciseService'

let squawks = []
let assignedSquawks = -1
for (let i = 1; i < 7; i++) {
  for (let j = 1; j < 7; j++) {
    for (let x = 1; x < 8; x++) {
      for (let z = 1; z < 8; z++) {
        squawks.push(i + '' + j + '' + x + '' + z)
      }
    }
  }
}
const getSquawk = () => {
  assignedSquawks++
  if (assignedSquawks > squawks.length - 1) assignedSquawks = 0
  return squawks[assignedSquawks]
}
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}
shuffle(squawks)

const getPitch = () => {
  return Math.random() * (1.15 - 0.6) + 0.6
}

export function CwpContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true)
  const [zoom, setZoom] = useState(6000)
  const [tickValue, setTickValue] = useState(0)
  const [timeValue, setTimeValue] = useState(0)
  const [tickSpeed, setTickSpeed] = useState(1000)
  const [tickPause, setTickPause] = useState(true)
  const [sectors, setSectors] = useState({
    ops: {
      name: 'uklv',
      sector: 'ALVC',
      basicSectors: ['E345', 'W345', 'C355', 'C365', 'C375', 'C385', 'C660'],
      freq: '135.600',
      vLimit: { lower: 32500, upper: 66000 },
      maxTV: 35,
    },
  })

  const [flights, setFlights] = useState([])
  const [historicalFlights, setHistoricalFlights] = useState([])
  const [trackNum, setTrackNum] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [selectedFlight, setSelectedFlight] = useState(null)
  const [timeOfUpdate, setTimeOfUpdate] = useState(null)
  const [exerciseName, setExerciseName] = useState('new_exercise')
  const [files, setFiles] = useState([])
  const [lonLatCenter, setLonLatCenter] = useState([23, 50.7])
  const [addTrackPlan, setAddTrackPlan] = useState()
  const [isCallsignMenuVisible, setIsCallsignMenuVisible] = useState(false)
  const [callsignMenuFlight, setCallsignMenuFlight] = useState(null)
  const [typeOfFlightMenu, setTypeofFlightMenu] = useState(null)
  const [updateFlights, setUpdateFlights] = useState(() => {})
  const [stcas, setStcas] = useState([])
  const [fAlt, setFAlt] = useState({ lower: 0, upper: 660 })

  const projection = useMemo(
    () => getProjection(zoom, lonLatCenter),
    [zoom, lonLatCenter],
  )

  const [updateFlightsTime, setUpdateFlightsTime] = useState(
    new Date().getTime(),
  )
  const [map, setMap] = useState({
    borders: true,
    ukll_app_rw31ils: false,
    ukll_app_rw31star: false,
    ukll_app_rw13ils: false,
    ukll_app_rw13star: false,
    ukln_app_rw15star: false,
    ukln_app_rw33star: false,
    points: true,
    extFreq: true,
  })
  const [pttWindows, setPttWindows] = useState({
    options: { fpActionWindow: { flightId: null } },
    exerciseWindow: false,
    activeWindow: false,
    historicalWindow: false,
    aswToolbox1Window: false,
    systemTimeWindow: false,
    workloadWindow: false,
    exerciseDescriptionWindow: false,
    rwyWindow: false,
    speedVectorWindow: false,
    mapWindow: false,
    plannerWindow: false,
    sectorWindow: false,
    mtcdWindow: true,
    mainMenu: true,
    fpActionWindow: false,
    communicationWindow: true,
  })
  const [labelSettings, setLabelSettings] = useState({ fontSize: 15 })
  const [trackPlanSet, setTrackPlanSet] = useState(new Set())

  const [isConnected, setIsConnected] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [exerciseDescription, setExerciseDescription] = useState('')
  //Todo: make single object, not array
  const [runways, setRunways] = useState([
    { ICAOcode: 'UKLL', Runways: '31' },
    { ICAOcode: 'UKLN', Runways: '15' },
    { ICAOcode: 'UKBB', Runways: '36R' },
    { ICAOcode: 'UKKK', Runways: '26' },
  ])
  const [speedVector, setSpeedVector] = useState({ isVisible: true, time: 3 })
  const [exerciseData, setExerciseData] = useState()
  const [conflictNumberList, setConflictNumberList] = useState([])
  const [conflictInfoList, setConflictInfoList] = useState(new Set())
  const [workloadArray, setWorkloadArray] = useState([])
  const [exerciseWorkload, setExerciseWorkload] = useState({})
  const [inSectorFlights, setInSectorFights] = useState([])
  const [editRouteValues, setEditRouteValues] = useState([null])

  const [flightClearances, dispatchFlightClearance] = useReducer(
    flightClearanceReducer,
    {},
  )

  const getData = async () => {
    try {
      const response = await getHistoricalFPLs()
      setHistoricalFlights(
        response.map((flt) => {
          const { flight } = flt
          flt.timeShift = 0
          flt.type = flt.fpl[3].split('/')[0]
          flt.passedPoints = flight.reduce((pp, point) => {
            if (point.pP != null) {
              pp.push({ index: flight.indexOf(point), pp: point })
            }
            return pp
          }, [])
          flt.isAdvanced = true
          flt.isCorrelated = true

          return flt
        }),
      )
      await getAllSim()
    } catch (e) {
      console.log(e)
    }
  }

  const updateFlight = (id, value) => {
    if (id == null) return
    let flight = flights.filter((flight) => {
      return flight.id === id
    })[0]
    flight.timeShift += value
    calcPassedPoints(flight, startDate.getTime())

    setTimeOfUpdate(new Date())
  }

  const updateAllFlightsForEpp = (isEpp) => {
    setFlights((prev) => {
      prev.forEach((flight) => {
        flight.flight[0].isAdvanced = false
        flight.flight[0].isCorrelated = true
        updateFlightForEpp(flight, isEpp)
      })
      return prev
    })
    setIsLoading(false)
    setUpdateFlightsTime(new Date().getTime())
  }

  const updateFlightForEpp = (flight, isEpp) => {
    flight.flight[0].isAdvanced = false
    calcFlight(flight, 0, startDate.getTime(), 120)
    // let ind1 = flight.timeShift

    let tempSetOfBasicSetors = flight.sectors
    // for (let ii = 0; ii < flight.flight.length; ii += 1) {
    //   if (flight.flight[ii] && flight.flight[ii].iBSN)
    //     tempSetOfBasicSetors.add(flight.flight[ii].iBSN)
    // }
    let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors)
    arrayOfBasicSetors.forEach((a) => {
      if (sectors.ops.basicSectors.includes(a)) {
        flight.flight[0].isAdvanced = true
        return
      }
    })
    calcFlight(flight, 0, startDate.getTime())
    if (!isEpp) return
    for (let tickValue = 0; tickValue < 900; tickValue += 5) {
      let ind1 = tickValue + flight.timeShift
      if (flight.flight[ind1]) {
        if (
          flight.flight[ind1].iBSN &&
          sectors.ops.basicSectors.includes(flight.flight[ind1].iBSN) &&
          !flight.flight[ind1].isAssumed
        ) {
          if (flight.flight[ind1].isAssumed) return
          flight.flight[ind1].isAssumed = true
          flight.flight[ind1].isAdvanced = false
          calcFlight(flight, ind1, startDate.getTime())
        } else if (
          (!flight.flight[ind1].iBSN ||
            !sectors.ops.basicSectors.includes(flight.flight[ind1].iBSN)) &&
          flight.flight[ind1].isAssumed
        ) {
          flight.flight[ind1].isAssumed = false
          calcFlight(flight, ind1, startDate.getTime())

          let tempSetOfBasicSetors = new Set()
          for (let ii = ind1 + 5; ii < flight.flight.length; ii += 5) {
            flight.flight[ii].iBSN &&
              tempSetOfBasicSetors.add(flight.flight[ii].iBSN)
          }
          let arrayOfBasicSetors = Array.from(tempSetOfBasicSetors)
          arrayOfBasicSetors.forEach((a) => {
            if (sectors.ops.basicSectors.includes(a))
              flight.flight[ind1].isAdvanced = true
          })

          calcFlight(flight, ind1, startDate.getTime())
        }
        if (
          (flight.flight[ind1].tod || flight.flight[ind1].toc) &&
          flight.flight[ind1].isAssumed
        ) {
          flight.flight[ind1].sA = flight.flight[ind1].xfl * 100
          calcFlight(flight, ind1, startDate.getTime())
        }
      }
    }
    setUpdateFlightsTime(new Date().getTime())
  }

  const saveExercise = async (exerciseJson, flights) => {
    try {
      let atm_code = localStorage.getItem('atm_code')
      atm_code = !atm_code ? 'CON' : atm_code

      const promptName = window.prompt(
        'Exercise name: ' + atm_code + '_',
        exerciseName.replace(atm_code + '_', ''),
      )
      if (!promptName) {
        return
      }

      const searchParams = new URLSearchParams()

      const calExerciseData = calcExerciseWorkload(flights, sectors)

      const exerciseWorkload = {
        ops: sectors.ops,
        numberOfFlights: calExerciseData.uniqueFlights.size,
        trafficVolume: calExerciseData.inSectorFlights,
      }

      searchParams.set('action', 'update')
      searchParams.set('exercise', atm_code + '_' + promptName)
      // searchParams.set(
      //   'exerciseDescription',
      //   JSON.stringify(convertToRaw(exerciseDescription.getCurrentContent())),
      // )
      searchParams.set('flights', JSON.stringify(exerciseJson))
      searchParams.set('exerciseWorkload', JSON.stringify(exerciseWorkload))

      await exercises(searchParams)
      await getAllSim()
      setExerciseName(atm_code + '_' + promptName)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const loadExercise = async (file, isPlt, isEpp, isDemo) => {
    if (!file) {
      return
    }

    try {
      setExerciseName(file)
      setIsLoading(true)
      const flightsClone = []
      setTickValue(0)

      const searchParams = new URLSearchParams()
      searchParams.set('action', 'load')
      searchParams.set('exercise', file)
      const data = await exercises(searchParams)

      //TODO: refactor here
      if (data[0]) {
        setExerciseData(data[0])

        let tempDescr
        try {
          tempDescr = convertFromRaw(JSON.parse(data[0].exerciseDescription))
          setExerciseDescription(EditorState.createWithContent(tempDescr))
        } catch (e) {
          setExerciseDescription(EditorState.createEmpty())
        }

        JSON.parse(data[0].flights).forEach((flight) => {
          const index = historicalFlights.map((e) => e.id).indexOf(flight.id)
          if (index >= 0) {
            historicalFlights[index].timeShift = flight.timeShift
            let tempFlight
            if (isPlt) {
              tempFlight = JSON.parse(JSON.stringify(historicalFlights[index]))
            } else {
              tempFlight = historicalFlights[index]
            }

            tempFlight = initialFlightUpdate(
              tempFlight,
              flight.rfl,
              flight.xfl,
              flight.timeShift,
              isEpp,
              isPlt,
              isDemo,
            )

            // calcPassedPoints(tempFlight, startDate.getTime());
            flightsClone.push(tempFlight)
          }
        })
        setFlights(flightsClone)
      } else {
        setExerciseDescription(EditorState.createWithText(''))
      }
      //TODO: ---------------------
      setTickPause(false)
      dispatchFlightClearance({ type: 'clear' })
      setIsLoading(false)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const initialFlightUpdate = (
    tempFlight,
    rfl,
    xfl,
    timeShift,
    isEpp,
    isPlt,
    isDemo,
  ) => {
    if (rfl) {
      tempFlight.rfl = rfl
      tempFlight.flight[0].sA = parseInt(rfl) * 100
      tempFlight.flight[0].isAdvanced = false
      tempFlight.flight[0].isCorrelated = true
    }
    tempFlight.squawk = getSquawk()
    tempFlight.pitch = getPitch()
    tempFlight.comm = {}
    tempFlight.xfl = xfl || null
    calcDeparture(tempFlight, runways)
    calcApproach(tempFlight, runways)

    updateFlightForEpp(tempFlight, isEpp)
    tempFlight.dx = 0
    tempFlight.dy = 0
    tempFlight.isTrackPlan = false
    if ((isDemo || isPlt) && tempFlight.timeShift > 0) {
      tempFlight.flight.splice(0, timeShift)
      tempFlight.timeShift = 0
    }
    return tempFlight
  }

  const renameExercise = async (atm_code, exerciseName) => {
    console.log(atm_code, exerciseName)
    try {
      atm_code = !atm_code ? 'CON' : atm_code
      const promptName = window.prompt(
        'Exercise name: ' + atm_code + '_',
        exerciseName.replace(atm_code + '_', ''),
      )
      if (!promptName) {
        return
      }

      const searchParams = new URLSearchParams()
      searchParams.set('action', 'rename')
      searchParams.set('old_name', exerciseName)
      searchParams.set('new_name', atm_code + '_' + promptName)

      await exercises(searchParams)
      // getAllSim()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const deleteExercise = async (exerciseName) => {
    if (!window.confirm('Are you sure want to delete ' + exerciseName)) {
      return
    }
    try {
      const searchParams = new URLSearchParams()
      searchParams.set('action', 'delete')
      searchParams.set('exercise', exerciseName)
      await exercises(searchParams)
      await getAllSim()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getAllSim = async () => {
    try {
      const searchParams = new URLSearchParams()
      searchParams.set('action', 'getAllSim')
      const data = await exercises(searchParams)
      setFiles(data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const assumeFlight = (flight, index) => {
    if (flight.flight[index].isAdvanced) {
      flight.flight[index].isAssumed = true
      flight.flight[index].isAdvanced = false
    } else if (flight.flight[index].isAssumed) {
      flight.flight[index].isAssumed = false
    } else if (flight.flight[index].isCorrelated) {
      flight.flight[index].isAssumed = true
      flight.flight[index].isAdvanced = false
    }
    calcFlight(flight, index, startDate.getTime())
    dispatchFlightClearance({
      type: 'assume',
      flight,
      index,
      startDate,
    })
    setUpdateFlightsTime(new Date().getTime())
  }

  const conAssumeFlight = (flight) => {
    if (flight.isAdvanced) {
      flight.isAssumed = true
      flight.isAdvanced = false
    } else if (flight.isAssumed) conDecontrolFlight(flight)
    else {
      flight.isAssumed = true
      flight.isAdvanced = false
    }
    setUpdateFlightsTime(new Date().getTime())
  }

  const conDecontrolFlight = (flight) => {
    flight.isAssumed = false
  }

  const showCallsignMenu = (flight, clientX, clientY) => {
    if (flight === null) {
      setIsCallsignMenuVisible(false)
      setTypeofFlightMenu(null)
      setCallsignMenuFlight(null)
    } else {
      setIsCallsignMenuVisible(true)
      setCallsignMenuFlight({ flight, clientX, clientY })
    }
  }

  const showFlightMenu = (flight, clientX, clientY, type) => {
    if (clientY + 200 > document.body.clientHeight)
      clientY = document.body.clientHeight - 210
    if (flight === null) {
      setCallsignMenuFlight(null)
    } else {
      setTypeofFlightMenu(type)
      setCallsignMenuFlight({ flight, clientX, clientY })
    }
  }

  const state = {
    isLoading,
    setIsLoading,
    zoom,
    setZoom,
    tickValue,
    setTickValue,
    timeValue,
    setTimeValue,
    tickPause,
    setTickPause,
    flights,
    setFlights,
    trackNum,
    setTrackNum,
    startDate,
    setStartDate,
    tickSpeed,
    setTickSpeed,
    selectedFlight,
    setSelectedFlight,
    updateFlight,
    timeOfUpdate,
    historicalFlights,
    setHistoricalFlights,
    saveExercise,
    exerciseName,
    setExerciseName,
    getSquawk,
    getAllSim,
    files,
    getData,
    lonLatCenter,
    setLonLatCenter,
    addTrackPlan,
    setAddTrackPlan,
    assumeFlight,
    conAssumeFlight,
    showCallsignMenu,
    isCallsignMenuVisible,
    callsignMenuFlight,
    updateFlights,
    setUpdateFlights,
    updateFlightsTime,
    setUpdateFlightsTime,
    showFlightMenu,
    typeOfFlightMenu,
    setTypeofFlightMenu,
    map,
    setMap,
    pttWindows,
    setPttWindows,
    isConnected,
    setIsConnected,
    sessionId,
    setSessionId,
    exerciseDescription,
    setExerciseDescription,
    runways,
    setRunways,
    speedVector,
    setSpeedVector,
    sectors,
    setSectors,
    flightClearances,
    dispatchFlightClearance,
    setExerciseData,
    conflictNumberList,
    setConflictNumberList,
    conflictInfoList,
    setConflictInfoList,
    workloadArray,
    setWorkloadArray,
    exerciseWorkload,
    setExerciseWorkload,
    inSectorFlights,
    setInSectorFights,
    updateFlightForEpp,
    updateAllFlightsForEpp,
    trackPlanSet,
    setTrackPlanSet,
    labelSettings,
    setLabelSettings,
    projection,
    stcas,
    setStcas,
    loadExercise,
    renameExercise,
    deleteExercise,
    editRouteValues,
    setEditRouteValues,
    initialFlightUpdate,
    fAlt,
    setFAlt,
  }

  useEffect(() => {
    let date = new Date()
    date.setHours(12, 0, 0)
    setStartDate(date)
  }, [setStartDate])

  return <CwpContext.Provider value={state}>{children}</CwpContext.Provider>
}

const CwpContext = createContext({
  zoom: 6000,
  setZoom: () => {},
  tickValue: 0,
  setTickValue: () => {},
  tickSpeed: 1000,
  setTickSpeed: () => {},
  timeValue: 0,
  setTimeValue: () => {},
  flights: [],
  setFlights: () => {},
  startDate: new Date(),
  setStartDate: () => {},
})

export const useCwpContext = () => useContext(CwpContext)
