import * as React from 'react'
import { useEffect, useState } from 'react'

import auth from '../../auth/Auth'

import Login from '../../components/Login/Login'
import Home from '../../components/Home/Home'
import Loading from '../../components/Loader/Loading'

import { useCwpContext } from '../../contexts/CwpContext'

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { isLoading, setIsLoading } = useCwpContext()

  useEffect(() => {
    setIsLoading(true)
    auth.validateToken((response) => {
      if (response.success) {
        setIsLoggedIn(true)
      }
      setIsLoading(false)
    })
  }, [])

  return isLoading ? (
    <Loading />
  ) : isLoggedIn ? (
    <Home setLogin={setIsLoggedIn} />
  ) : (
    <Login auth={auth} setLogin={setIsLoggedIn} />
  )
}

export default HomePage
