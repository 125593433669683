/*
    node_js
    2/27/2021 7:50 PM
    by Oleksandr
*/

export const cops = [
  'PEVOT',
  'USTIL',
  'ROLKA',
  'GOTIX',
  'DIBED',
  'POBUV',
  'TEPNA',
  'DIDUR',
  'INROG',
  'UNDOL',
  'RUMUK',
  'ROMOL',
  'BUKOV',
  'LUGOL',
  'EROMO',
  'TUREC',
  'BAMUD',
  'LONLA',
  'GEMTO',
  'LADOB',
  'MALBE',
  'LASOT',
  'BURAK',
  'DIBON',
  'AMIRI',
  'TOLPA',
  'IVNER',
  'SOMAT',
  'TADUN',
  'OKROT',
  'SORON',
  'SITBA',
  'KOROP',
  'TAKET',
  'DORER',
]
