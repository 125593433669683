/*
    node_js
    3/1/2021 9:17 AM
    by Oleksandr
*/
export const ukll_app_rw31ils = [
  { lat: '#' },
  { lat: 49.68101, lon: 24.40077 },
  { lat: 49.65787, lon: 24.36374 },
  { lat: 49.70404, lon: 24.11642 },
  { lat: 49.54242, lon: 24.17852 },
  { lat: 49.51928, lon: 24.14161 },
  { lat: '#' },
  { lat: 49.7999, lon: 23.97318 },
  { lat: 49.56026, lon: 24.33075 },
]

export const ukll_app_rw31star = [
  { first: '#', lat: 49.504722, lon: 22.656111 },
  { lat: 49.504722, lon: 22.656111 },
  { lat: 49.528333, lon: 23.655 },
  { lat: 49.632, lon: 23.864611 },
  { lat: 49.6905, lon: 23.957028 },
  { lat: 49.642722, lon: 24.028667 },
  { lat: 49.594889, lon: 24.100167 },
  { lat: 49.547, lon: 24.171528 },
  { lat: 49.605278, lon: 24.264167 },
  { lat: 49.665389, lon: 24.174444 },
  { lat: 49.731389, lon: 24.075833 },
  { lat: '#' },
  { first: '#', lat: 49.888333, lon: 23.058333 },
  { lat: 49.888333, lon: 23.058333 },
  { lat: 49.528333, lon: 23.655 },
  { lat: '#' },
  { first: '#', lat: 49.802222, lon: 25.391111 },
  { lat: 49.802222, lon: 25.391111 },
  { lat: 49.803889, lon: 24.903056 },
  { lat: 49.817611, lon: 24.307306 },
  { lat: 49.865611, lon: 24.235639 },
  { lat: 49.807306, lon: 24.142583 },
  { lat: 49.759389, lon: 24.214222 },
  { lat: 49.711444, lon: 24.285694 },
  { lat: 49.663444, lon: 24.357056 },
  { lat: 49.605278, lon: 24.264167 },
  { lat: '#' },
  { first: '#', lat: 49.605278, lon: 25.072222 },
  { lat: 49.605278, lon: 25.072222 },
  { lat: 49.817611, lon: 24.307306 },
  { lat: '#' },
  { first: '#', lat: 50.404167, lon: 23.825556 },
  { lat: 50.404167, lon: 23.825556 },
  { lat: 50.142222, lon: 24.241389 },
  { lat: 49.932278, lon: 24.237028 },
  { lat: 49.865611, lon: 24.235639 },
  { lat: '#' },
  { first: '#', lat: 49.345, lon: 24.501667 },
  { lat: 49.345, lon: 24.501667 },
  { lat: 49.584222, lon: 23.936306 },
  { lat: 49.632, lon: 23.864611 },
]
export const ukll_app_rw13ils = [
  { lat: '#' },
  { lat: 50.0743777777778, lon: 23.8131833333333 },
  { lat: 50.0512444444444, lon: 23.7758444444444 },
  { lat: 49.8896555555556, lon: 23.8386 },
  { lat: 49.9352972222222, lon: 23.5898583333333 },
  { lat: 49.9121555555556, lon: 23.5526472222222 },
  { lat: '#' },
  { lat: 49.8177805555556, lon: 23.9462805555556 },
  { lat: 50.0573722222222, lon: 23.5867138888889 },
]

export const ukll_app_rw13star = [
  { first: '#', lat: 49.802222, lon: 25.391111 },
  { lat: 49.802222, lon: 25.391111 },
  { lat: 49.803889, lon: 24.903056 },
  { lat: 49.927667, lon: 24.218111 },
  { lat: 49.988944, lon: 23.869083 },
  { lat: 49.930361, lon: 23.776194 },
  { lat: 49.868056, lon: 23.870833 },
  { lat: '#' },
  { first: '#', lat: 49.888333, lon: 23.058333 },
  { lat: 49.888333, lon: 23.058333 },
  { lat: 49.737639, lon: 23.672333 },
  { lat: 49.871722, lon: 23.6835 },
  { lat: 49.930361, lon: 23.776194 },
  { lat: '#' },
  { first: '#', lat: 49.605278, lon: 25.072222 },
  { lat: 49.605278, lon: 25.072222 },
  { lat: 49.803889, lon: 24.903056 },
  { lat: '#' },
  { first: '#', lat: 49.528333, lon: 23.655 },
  { lat: 49.528333, lon: 23.655 },
  { lat: 49.737639, lon: 23.672333 },
  { lat: '#' },
  { first: '#', lat: 50.142222, lon: 24.241389 },
  { lat: 50.142222, lon: 24.241389 },
  { lat: 49.988944, lon: 23.869083 },
  { lat: '#' },
  { first: '#', lat: 49.345, lon: 24.501667 },
  { lat: 49.345, lon: 24.501667 },
  { lat: 49.528333, lon: 23.655 },
  { lat: '#' },
  { first: '#', lat: 50.404167, lon: 23.825556 },
  { lat: 50.404167, lon: 23.825556 },
  { lat: 50.142222, lon: 24.241389 },
  { lat: '#' },
  { first: '#', lat: 49.504722, lon: 22.656111 },
  { lat: 49.504722, lon: 22.656111 },
  { lat: 49.528333, lon: 23.655 },
  { lat: '#' },
]

export const ukln_app_rw15star = [
  { first: '#', lat: 47.951667, lon: 25.958333 },
  { lat: 47.951667, lon: 25.958333 },
  { lat: 48.226417, lon: 26.146472 },
  { lat: 48.446306, lon: 25.978083 },
  { lat: 48.408278, lon: 25.866056 },
  { lat: 48.268389, lon: 25.973303 },
  { lat: '#' },
  { first: '#', lat: 49.074167, lon: 26.681389 },
  { lat: 49.074167, lon: 26.681389 },
  { lat: 48.526556, lon: 26.2145 },
  { lat: 48.446306, lon: 25.978083 },
  { lat: '#' },
  { first: '#', lat: 48.502778, lon: 26.602222 },
  { lat: 48.502778, lon: 26.602222 },
  { lat: 48.526556, lon: 26.2145 },
  { lat: '#' },
  { first: '#', lat: 48.758333, lon: 25.98 },
  { lat: 48.758333, lon: 25.98 },
  { lat: 48.583528, lon: 25.978972 },
  { lat: 48.446306, lon: 25.978083 },
  { lat: '#' },
  { first: '#', lat: 48.720556, lon: 25.457222 },
  { lat: 48.720556, lon: 25.457222 },
  { lat: 48.467, lon: 25.672222 },
  { lat: 48.370333, lon: 25.754 },
  { lat: 48.408278, lon: 25.866056 },
  { lat: '#' },
]

export const ukln_app_rw33star = [
  { first: '#', lat: 47.951667, lon: 25.958333 },
  { lat: 47.951667, lon: 25.958333 },
  { lat: 48.109306, lon: 26.094111 },
  { lat: 48.250617, lon: 25.986811 },
  { lat: '#' },
  { first: '#', lat: 48.502778, lon: 26.602222 },
  { lat: 48.502778, lon: 26.602222 },
  { lat: 48.314417, lon: 26.392083 },
  { lat: 48.147, lon: 26.205778 },
  { lat: 48.109306, lon: 26.094111 },
  { lat: '#' },
  { first: '#', lat: 49.074167, lon: 26.681389 },
  { lat: 49.074167, lon: 26.681389 },
  { lat: 48.371028, lon: 26.03775 },
  { lat: 48.147, lon: 26.205778 },
  { lat: '#' },
  { first: '#', lat: 48.758333, lon: 25.98 },
  { lat: 48.758333, lon: 25.98 },
  { lat: 48.371028, lon: 26.03775 },
  { lat: '#' },
  { first: '#', lat: 48.720556, lon: 25.457222 },
  { lat: 48.720556, lon: 25.457222 },
  { lat: 48.371028, lon: 26.03775 },
  { lat: '#' },
]
