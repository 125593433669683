import React from 'react'
import * as geolib from 'geolib'
import ConnectingLine from './ConnectingLine'
import TargetHistory from './TargetHistory'
import SpeedVector from './SpeedVector'
import Target from './Target'
import AccLabel from '../label/AccLabel'

function RadarTarget(props) {
  const {
    id,
    flight,
    left,
    top,
    targetColor,
    trackLayerSvg,
    projection,
    index,
    speedVector,
    labelSettings,
    selectedFlight,
    setSelectedFlight,
    flt,
    updateAllTrackPlans,
    addTrackPlan,
    color,
    callsignColor,
    updateFlights,
    timeOfUpdate,
    stcasIds,
    tickValue,
    isPlt,
    isCon,
    isEpp,
    addRbl,
    isMtcd,
    assumeFlight,
    conAssumeFlight,
    showCallsignMenu,
    showFlightMenu,
    startDate,
    setPttWindows,
    fAlt,
  } = props
  const getTargetHistory = () => {
    const testTargetHistory = []
    for (let i = index - 10; i < index; i++) {
      if (i > 0) {
        let pos = projection([flight[i].cP.longitude, flight[i].cP.latitude])
        let hTop = pos[1]
        let hLeft = pos[0]
        testTargetHistory.push(
          <TargetHistory
            trackLayerSvg={trackLayerSvg}
            color={targetColor}
            track={{ X: parseInt(hLeft), Y: parseInt(hTop) }}
            key={'target_history_' + id + flight[i].cP.latitude}
          />,
        )
      }
    }
    return testTargetHistory
  }

  const getSpeedVector = () => {
    const tempSpeedVectors = []
    if (speedVector.isVisible) {
      let previousPosition = flight[index].cP
      for (let i = 1; i <= speedVector.time; i++) {
        let nextPosition = geolib.computeDestinationPoint(
          flight[index].cP,
          ((flight[index].gs || flight[index].t) * 1.852 * 1000 * i) / 60,
          flight[index].h + 6,
        )
        let pos0 = projection([
          previousPosition.longitude,
          previousPosition.latitude,
        ])
        let pos = projection([nextPosition.longitude, nextPosition.latitude])
        tempSpeedVectors.push(
          <g key={i + Math.random()}>
            <defs>
              <marker
                id={'dot' + id}
                viewBox="0 0 6 6"
                refX="3"
                refY="3"
                markerWidth="3"
                markerHeight="3"
              >
                <circle
                  cx="3"
                  cy="3"
                  r="3"
                  fill={targetColor}
                  pointerEvents="none"
                />
              </marker>
            </defs>
            <line
              x1={pos0[0]}
              y1={pos0[1]}
              x2={pos[0]}
              y2={pos[1]}
              stroke={targetColor}
              pointerEvents="none"
              markerEnd={'url(#dot' + id + ')'}
            />
          </g>,
        )
        previousPosition = nextPosition
      }
    }
    return tempSpeedVectors
  }

  const isNotFilltered = () => {
    return (
      (flight[index] &&
        (flight[index].isAssumed || flight[index].isAdvanced)) ||
      (flight[index].a > fAlt.lower * 100 && flight[index].a < fAlt.upper * 100)
    )
  }
  return (
    <>
      {getTargetHistory()}
      <SpeedVector trackLayerSvg={trackLayerSvg}>
        {getSpeedVector()}
      </SpeedVector>
      <Target
        color={targetColor}
        track={{ X: parseInt(left), Y: parseInt(top) }}
        ssrSize={1}
        labelSettings={labelSettings}
        key={'target_' + id}
        id={id}
        selectedFlight={selectedFlight}
        setSelectedFlight={setSelectedFlight}
        flight={flt}
        updateAllTrackPlans={updateAllTrackPlans}
        addTrackPlan={addTrackPlan}
      />
      {isNotFilltered() && (
        <>
          <ConnectingLine
            key={'connecting_line_' + id}
            track={flight}
            fltId={id}
            left={left}
            top={top}
            color={targetColor}
            trackLayerSvg={trackLayerSvg}
          />
          <AccLabel
            color={color}
            callsignColor={callsignColor}
            index={index}
            left={left}
            top={top}
            setSelectedFlight={setSelectedFlight}
            fltId={id}
            selectedFlight={selectedFlight}
            key={'acclabel_' + id}
            flight={flt}
            id={'acclabel_' + id}
            updateFlights={updateFlights}
            timeOfUpdate={timeOfUpdate}
            isStca={stcasIds.has(id)}
            tickValue={tickValue}
            isPlt={isPlt}
            isCon={isCon}
            isEpp={isEpp}
            addRbl={addRbl}
            addTrackPlan={addTrackPlan}
            isMtcd={isMtcd}
            assumeFlight={assumeFlight}
            conAssumeFlight={conAssumeFlight}
            showCallsignMenu={showCallsignMenu}
            showFlightMenu={showFlightMenu}
            startDate={startDate}
            labelSettings={labelSettings}
            setPttWindows={setPttWindows}
          />
        </>
      )}
      ,
    </>
  )
}

export default RadarTarget
