/*
    node_js
    2/17/2021 12:43 AM
    by Oleksandr
*/
import React, { useEffect, useRef, useState } from 'react'
import socketIOClient from 'socket.io-client'
import { createPeerConnection } from './RTCModule'
import { useCwpContext } from '../../../contexts/CwpContext'

const ClientSocket = (props) => {
  const {
    isCon,
    isPlt,
    answerRes,
    candidates,
    getLocalStream,
    localStream,
    setPeerConnections,
    peerConnections,
    remoteVideoRef,
    setRemoteStreams,
    remoteStreams,
    setLabel,
    setUsers,
    username,
    room,
  } = props

  const {
    flights,
    tickValue,
    timeValue,
    setTimeValue,
    setFlights,
    setUpdateFlightsTime,
    sectors,
    setSectors,
    setIsConnected,
    flightClearances,
  } = useCwpContext()

  const socketRef = useRef()
  const [status, setStatus] = useState('Waiting for connection')

  useEffect(() => {
    if (answerRes === null) return
    socketRef.current.emit('answer', { answer: answerRes })
  }, [answerRes])

  useEffect(() => {
    socketRef.current = socketIOClient.connect('https://lvivacc.online:443')

    socketRef.current.emit(
      'join',
      { username: username, room: room },
      (error) => {
        console.log('join', room)
        if (error) {
          console.log(error)
        }
      },
    )
    socketRef.current.on('connection-success', async (data) => {
      await getLocalStream()
      socketRef.current.emit('onlinePeers', {
        socketID: { local: socketRef.current.id },
      })

      const status =
        data.peerCount > 1
          ? `Connected to ${room}`
          : `Connected to ${room}\nWaiting for other users...`
      setLabel(status)
      setStatus(status)
    })
    socketRef.current.on('joined-peers', (data) => {
      const status =
        data.peerCount > 1
          ? `Connected to ${room}`
          : `Connected to ${room}\nWaiting for other users...`
      setLabel(status)
      setStatus(status)
    })
    socketRef.current.on('roomData', (data) => {
      setUsers(data.users)
      console.log(data.users)
    })

    socketRef.current.on('timeValue', (data) => {
      if (isCon) {
        setTimeValue(data)
      }
    })
    socketRef.current.on('connect', (data) => {
      console.log('connect', socketRef.current.maxHttpBufferSize)
    })

    socketRef.current.on('disconnect', (data) => {
      window.localVideo &&
        window.localVideo.getTracks().forEach((track) => {
          track.stop()
        })
      setIsConnected(false)
      console.log('disconnect', data)
    })

    return () => {
      console.log('disconnectManual')
      window.localVideo &&
        window.localVideo.getTracks().forEach((track) => {
          track.stop()
        })
      socketRef.current.emit('disconnectManual')
    }
  }, [username, room, candidates])

  useEffect(() => {
    const handleFlights = (data) => {
      if (isCon) {
        data.flights.map((flt) => {
          let indexOfFlight = flights.map((e) => e.id).indexOf(flt.id)
          if (indexOfFlight >= 0) {
            flights[indexOfFlight].flight = flt.flight
            flights[indexOfFlight].entryTime = flt.entryTime
            flights[indexOfFlight].exitTime = flt.exitTime
          } else {
            flt.cfl = Math.round(flt.flight[0].sA / 100)
            flights.push(flt)
          }
          return flt
        })
        flights.forEach((flt, index) => {
          let indexOfFlight = data.flights.map((e) => e.id).indexOf(flt.id)
          if (indexOfFlight === -1) {
            flights.splice(index, 1)
          }
        })
        setFlights(flights)
        setUpdateFlightsTime(new Date())

        if (data.OpsSector.sector !== sectors.ops.sector) {
          setSectors({ ...sectors, ops: data.OpsSector })
        }
      }
    }
    socketRef.current.on('flights', handleFlights)

    return () => {
      socketRef.current.off('flights', handleFlights)
    }
  }, [flights, sectors])

  useEffect(() => {
    console.log('peerConnections', peerConnections)
    const sdpConstraints = {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
    }
    const onlinePeerHandler = (socketID) => {
      console.log('connected peers ...', socketID)

      createPeerConnection(
        socketID,
        setPeerConnections,
        localStream,
        socketRef,
        remoteVideoRef,
        setRemoteStreams,
        remoteStreams,
        (pc) => {
          // 2. Create Offer
          if (pc)
            pc.createOffer(sdpConstraints).then((sdp) => {
              pc.setLocalDescription(sdp)

              socketRef.current.emit('offer', {
                payload: sdp,
                socketID: {
                  local: socketRef.current.id,
                  remote: socketID,
                },
              })
            })
        },
      )
    }

    const offerHandler = (data) => {
      createPeerConnection(
        data.socketID,
        setPeerConnections,
        localStream,
        socketRef,
        remoteVideoRef,
        setRemoteStreams,
        remoteStreams,
        (pc) => {
          // pc.addStream(localStream)

          pc.setRemoteDescription(new RTCSessionDescription(data.sdp)).then(
            () => {
              // 2. Create Answer
              pc.createAnswer(sdpConstraints).then((sdp) => {
                pc.setLocalDescription(sdp)

                socketRef.current.emit('answer', {
                  payload: sdp,
                  socketID: {
                    local: socketRef.current.id,
                    remote: data.socketID,
                  },
                })
              })
            },
          )
        },
      )
    }

    const answerHandler = async (data) => {
      // get remote's peerConnection
      // console.log(peerConnections,peerConnections[data.socketID], data.socketID, data)
      const pc = peerConnections[data.socketID]
      // console.log(pc)
      pc.setRemoteDescription(await new RTCSessionDescription(data.sdp))
    }

    const candidateHandler = (data) => {
      // get remote's peerConnection
      const pc = peerConnections[data.socketID]

      if (pc) pc.addIceCandidate(new RTCIceCandidate(data.candidate))
    }

    const disconnectHandler = (data) => {
      const status =
        data.peerCount > 1
          ? `Connected to ${room}`
          : `Connected to ${room}\nWaiting for other users...`
      const remoteStreamsNew = remoteStreams.filter(
        (stream) => stream.id !== data.socketID,
      )
      setRemoteStreams(remoteStreamsNew)
      setLabel(status)
      setStatus(status)
    }

    socketRef.current.on('offer', offerHandler)
    socketRef.current.on('online-peer', onlinePeerHandler)
    socketRef.current.on('answer', answerHandler)
    socketRef.current.on('candidate', candidateHandler)
    socketRef.current.on('peer-disconnected', disconnectHandler)
    return () => {
      socketRef.current.off('online-peer', onlinePeerHandler)
      socketRef.current.off('offer', offerHandler)
      socketRef.current.off('answer', answerHandler)
      socketRef.current.off('candidate', candidateHandler)
      socketRef.current.off('peer-disconnected', disconnectHandler)
    }
  }, [localStream, peerConnections, remoteVideoRef, remoteStreams])

  useEffect(() => {
    if (flights.length === 0 || isCon) return
    ;(async () => {
      let flTest = JSON.parse(JSON.stringify(flights))
      const flightsObj = {
        flights: flTest
          .filter((flt) => tickValue + flt.timeShift >= 0)
          .map((flt) => {
            let index = tickValue + flt.timeShift
            flt.flight = [flt.flight[index]]
            flt.adesRunwayDetails = null
            return flt
          }),
        OpsSector: sectors.ops,
      }
      socketRef.current.emit('flights', flightsObj, (error) => {
        if (error) {
          console.log(error)
        }
      })
    })()
  }, [tickValue])

  useEffect(() => {
    console.log('flightClearances', flightClearances)
    socketRef.current.emit('clearance', flightClearances?.lastClearance)
  }, [flightClearances])

  useEffect(() => {
    if (isPlt) {
      console.log(isPlt, timeValue)
      socketRef.current.emit('timeValue', { timeValue }, (error) => {
        if (error) {
          console.log(error)
        }
      })
    }
  }, [timeValue])
  return <></>
}

export default ClientSocket
