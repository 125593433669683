/*
    node_js
    3/5/2021 7:05 PM
    by Oleksandr
*/

import CallsignMenu from '../menu/CallsignMenu'
import DctMenu from '../menu/DctMenu'
import { useCwpContext } from '../../../../contexts/CwpContext'
import DataMenu from '../menu/DataMenu'

const AswWindowLayer = ({ isCon, isPlt }) => {
  const { isCallsignMenuVisible, typeOfFlightMenu } = useCwpContext()

  return (
    <div style={{ position: 'absolute', left: '0px', top: '0px', zIndex: 100 }}>
      {isCallsignMenuVisible ? (
        <CallsignMenu isCon={isCon} isPlt={isPlt} />
      ) : (
        typeOfFlightMenu &&
        (typeOfFlightMenu === 'dct' ? (
          <DctMenu isCon={isCon} />
        ) : (
          <DataMenu isCon={isCon} />
        ))
      )}
    </div>
  )
}

export default AswWindowLayer
