export const rte = [
  {
    type: 'Feature',
    name: 'A100',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.646667', '45.350000'],
        ['37.365000', '45.749722'],
        ['38.031667', '46.174722'],
        ['38.715833', '46.596667'],
        ['39.825000', '47.197778'],
        ['39.933556', '47.500889'],
        ['40.100000', '47.885278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A102',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.808333', '47.950000'],
        ['39.970556', '47.911389'],
        ['40.100000', '47.885278'],
        ['40.376111', '47.805556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A137',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.825556', '50.404167'],
        ['27.207778', '50.465833'],
        ['28.692500', '50.456389'],
        ['30.451667', '50.401667'],
        ['30.900972', '50.285694'],
        ['31.820000', '50.255000'],
        ['32.353056', '50.255278'],
        ['33.885278', '50.212778'],
        ['34.633889', '50.083056'],
        ['35.545278', '49.917222'],
        ['36.290444', '49.928917'],
        ['37.640000', '50.301667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A142',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.209444', '49.040833'],
        ['32.283333', '48.533333'],
        ['32.673056', '48.357778'],
        ['32.966667', '48.191667'],
        ['33.212139', '48.051000'],
        ['33.261667', '47.698333'],
        ['33.495833', '46.807500'],
        ['34.745833', '47.351667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A16',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.216111', '42.065556'],
        ['28.357778', '41.286389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A17',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['28.766111', '42.082222']] },
  },
  {
    type: 'Feature',
    name: 'A225',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.943333', '48.195000'],
        ['40.158889', '48.085000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A245',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.646667', '45.350000'],
        ['37.347222', '45.002222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A277',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.591667', '45.000000'],
        ['37.258333', '44.573056'],
        ['37.403611', '44.479722'],
        ['37.820000', '44.208056'],
        ['38.091667', '44.026389'],
        ['38.281667', '43.898056'],
        ['39.088611', '43.349722'],
        ['39.983333', '42.990000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A28',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.385889', '41.291917'],
        ['33.254556', '41.017250'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A4',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.167844', '48.853200'],
        ['17.386111', '48.715833'],
        ['17.541111', '48.617500'],
        ['18.050556', '48.290556'],
        ['26.568333', '41.871667'],
        ['27.309167', '41.577222'],
        ['28.384167', '41.136389'],
        ['29.142889', '41.126972'],
        ['29.630000', '41.045833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A42',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.050556', '48.290556'],
        ['18.511667', '48.362222'],
        ['19.115861', '48.453250'],
        ['19.571944', '48.505833'],
        ['20.266667', '48.582500'],
        ['21.091111', '48.667500'],
        ['21.248167', '48.683083'],
        ['21.722222', '48.743889'],
        ['22.375000', '48.823889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A48',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.065833', '41.058611'],
        ['18.096944', '41.276944'],
        ['18.129167', '41.502778'],
        ['18.194444', '41.932778'],
        ['18.239333', '42.270500'],
        ['18.277442', '42.553844'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A482',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.049167', '41.912778'],
        ['17.011111', '41.728611'],
        ['17.563889', '41.618611'],
        ['18.129167', '41.502778'],
        ['18.339444', '41.495000'],
        ['18.685833', '41.481111'],
        ['19.100000', '41.458333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A712',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.218333', '47.856667'],
        ['39.798611', '47.752778'],
        ['39.970556', '47.911389'],
        ['40.158889', '48.085000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A716',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.698333', '54.368333'],
        ['20.650000', '54.620000'],
        ['20.599611', '54.893278'],
        ['20.581389', '55.078333'],
        ['20.560000', '55.311667'],
        ['20.553333', '55.400000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A805',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.591667', '45.000000'],
        ['37.861944', '43.929444'],
        ['38.036667', '43.778056'],
        ['38.571667', '43.308056'],
        ['39.270278', '42.681389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A808',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.825000', '47.197778'],
        ['40.849722', '47.801667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A813',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.755000', '46.879722'],
        ['39.213333', '47.228611'],
        ['40.100000', '47.885278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A83',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.692500', '50.456389'],
        ['30.833333', '50.051944'],
        ['31.852500', '49.826111'],
        ['33.616667', '49.313333'],
        ['34.900556', '49.023056'],
        ['36.441667', '48.650000'],
        ['37.037222', '48.544444'],
        ['38.940556', '48.181944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A87',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.218333', '47.856667'],
        ['40.723056', '47.368611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A914',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.359444', '47.321111']] },
  },
  {
    type: 'Feature',
    name: 'A915',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.093333', '46.532778'],
        ['40.600278', '47.008611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A926',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.599611', '54.893278'],
        ['21.214167', '54.826667'],
        ['21.643333', '54.778333'],
        ['22.755000', '54.651667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A944',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.383333', '43.250000'],
        ['37.785000', '43.478056'],
        ['38.510000', '43.588056'],
        ['39.913611', '43.780556'],
        ['40.710278', '43.881389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A945',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.701667', '55.931667'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A961',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.801111', '44.774444'],
        ['39.673056', '45.218611'],
        ['39.917500', '45.286667'],
        ['40.218611', '45.355278'],
        ['40.565000', '45.449722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'A962',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.565000', '45.449722']] },
  },
  {
    type: 'Feature',
    name: 'A97',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.921944', '48.996111'],
        ['36.208333', '49.368056'],
        ['36.290444', '49.928917'],
        ['36.485000', '50.313333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B120',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.958333', '54.912778'],
        ['19.600000', '54.900000'],
        ['20.309167', '54.895833'],
        ['20.599611', '54.893278'],
        ['21.256389', '54.904722'],
        ['22.734722', '54.941389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B143',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.383333', '43.250000'],
        ['36.988333', '43.138056'],
        ['37.810556', '42.980556'],
        ['39.270278', '42.681389'],
        ['39.501944', '42.631389'],
        ['40.145833', '42.490278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B145',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.673056', '45.218611'],
        ['39.845000', '45.481944'],
        ['40.090000', '45.851944'],
        ['40.465000', '46.349722'],
        ['40.659444', '46.585278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B147',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.491667', '43.741667'],
        ['37.080000', '43.623056'],
        ['37.623333', '43.511389'],
        ['37.785000', '43.478056'],
        ['38.105000', '43.410000'],
        ['38.300000', '43.368056'],
        ['38.571667', '43.308056'],
        ['38.780000', '43.263056'],
        ['39.983333', '42.990000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B238',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.689444', '47.926111']] },
  },
  {
    type: 'Feature',
    name: 'B246',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.261667', '51.476389'],
        ['30.641944', '50.970000'],
        ['30.892222', '50.628611'],
        ['31.392222', '50.068333'],
        ['31.443333', '49.745000'],
        ['31.438889', '49.324444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B367',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.820000', '44.208056'],
        ['38.405000', '44.038056'],
        ['38.735000', '43.939722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B45',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.447300', '49.393553'],
        ['18.654444', '49.106389'],
        ['18.745319', '48.979400'],
        ['18.820833', '48.873333'],
        ['18.883889', '48.783889'],
        ['19.115861', '48.453250'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B450',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.448333', '47.124722']] },
  },
  {
    type: 'Feature',
    name: 'B489',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.167222', '55.448611'],
        ['20.321667', '55.150000'],
        ['21.216389', '55.145833'],
        ['21.716667', '55.140833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B490',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.011667', '49.264722'],
        ['24.669167', '49.537500'],
        ['25.391111', '49.802222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B491',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.545000', '45.153333'],
        ['37.347222', '45.002222'],
        ['37.813333', '44.933056'],
        ['38.631667', '44.809722'],
        ['38.801111', '44.774444'],
        ['40.288333', '44.419722'],
        ['40.945278', '44.253056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B492',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.669722', '46.426389'],
        ['30.759444', '47.431667'],
        ['30.975278', '48.043889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B493',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.888333', '48.118333'],
        ['33.212139', '48.051000'],
        ['34.136667', '47.971667'],
        ['34.644167', '47.928056'],
        ['35.316667', '47.866667'],
        ['36.283333', '47.810000'],
        ['37.225278', '47.748889'],
        ['38.313333', '47.555000'],
        ['40.448333', '47.124722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B494',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.421667', '46.658333'],
        ['38.965556', '46.201944'],
        ['40.090000', '45.851944'],
        ['40.736667', '45.613333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B5',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.991489', '41.098714']] },
  },
  {
    type: 'Feature',
    name: 'B54',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.727500', '44.999167'],
        ['19.095556', '45.004167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B549',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.558333', '44.036667'],
        ['37.165000', '44.121389'],
        ['37.820000', '44.208056'],
        ['38.315000', '44.269722'],
        ['38.703889', '44.310556'],
        ['39.026667', '44.139444'],
        ['39.337778', '43.910278'],
        ['39.913611', '43.780556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B65',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.167222', '55.448611'],
        ['18.729444', '55.502778'],
        ['18.921111', '55.521944'],
        ['19.050000', '55.533889'],
        ['19.838333', '55.603611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B67',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.599611', '54.893278'],
        ['20.916667', '55.300000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B7',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.010278', '49.336389'],
        ['21.090278', '49.118056'],
        ['21.142500', '48.975000'],
        ['21.248167', '48.683083'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B704',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.654167', '54.447778'],
        ['20.309167', '54.895833'],
        ['20.581389', '55.078333'],
        ['20.916667', '55.300000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B709',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.448056', '55.258611'],
        ['19.838333', '55.603611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B73',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.958333', '54.912778'],
        ['19.838333', '55.603611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B74',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.355556', '54.637778'],
        ['19.634167', '55.201389'],
        ['19.838333', '55.603611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B810',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.448056', '55.258611'],
        ['18.921111', '55.521944'],
        ['19.329167', '55.744722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B820',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.164056', '45.020917'],
        ['39.576389', '44.962222'],
        ['39.917500', '45.286667'],
        ['40.015000', '45.433056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B947',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.659444', '46.585278'],
        ['40.272778', '47.067500'],
        ['39.933556', '47.500889'],
        ['39.218333', '47.856667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B949',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.735000', '43.939722'],
        ['38.703889', '44.310556'],
        ['38.801111', '44.774444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B950',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.801111', '44.774444'],
        ['38.965000', '44.333056'],
        ['39.026667', '44.139444'],
        ['39.043611', '43.925000'],
        ['39.088611', '43.349722'],
        ['39.270278', '42.681389'],
        ['39.403056', '42.183889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'B967',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.165000', '44.121389'],
        ['38.368333', '44.136944'],
        ['39.026667', '44.139444'],
        ['40.867778', '44.129167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G1',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['28.779281', '41.746164'],
        ['28.789722', '41.478333'],
        ['28.799444', '41.228056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G118',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.659444', '46.585278'],
        ['40.448333', '47.124722'],
        ['40.359444', '47.321111'],
        ['40.100000', '47.885278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G123',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['29.146667', '41.461389'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G128',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.659444', '46.585278'],
        ['40.785000', '46.866389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G18',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.758333', '41.247222'],
        ['22.991489', '41.098714'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G247',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.801111', '44.774444'],
        ['39.318333', '45.653056'],
        ['40.448333', '47.124722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G33',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['26.321389', '41.125278']] },
  },
  {
    type: 'Feature',
    name: 'G352',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.247222', '43.089444'],
        ['38.123056', '43.322222'],
        ['38.300000', '43.368056'],
        ['39.088611', '43.349722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G354',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.654167', '54.447778'],
        ['20.650000', '54.620000'],
        ['21.145000', '54.700000'],
        ['21.643333', '54.778333'],
        ['22.734722', '54.941389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G364',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.868611', '45.735278'],
        ['37.386667', '46.099722'],
        ['38.755000', '46.879722'],
        ['39.291667', '47.071667'],
        ['39.825000', '47.197778'],
        ['40.359444', '47.321111'],
        ['40.723056', '47.368611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G476',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.914722', '42.708889'],
        ['36.988333', '43.138056'],
        ['37.080000', '43.623056'],
        ['37.148333', '43.978056'],
        ['37.165000', '44.121389'],
        ['37.222500', '44.400000'],
        ['37.258333', '44.573056'],
        ['37.347222', '45.002222'],
        ['37.348333', '45.383056'],
        ['37.365000', '45.749722'],
        ['37.386667', '46.099722'],
        ['37.421667', '46.658333'],
        ['37.558611', '47.370556'],
        ['37.607222', '47.619444'],
        ['37.682778', '48.074722'],
        ['37.771667', '48.409167'],
        ['37.842778', '48.673056'],
        ['37.883333', '48.900000'],
        ['37.953333', '49.136667'],
        ['38.226667', '50.075000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G487',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.914722', '42.708889'],
        ['37.810556', '42.980556'],
        ['37.988333', '43.033056'],
        ['38.171944', '43.086389'],
        ['38.780000', '43.263056'],
        ['39.088611', '43.349722'],
        ['39.710278', '43.558056'],
        ['40.108606', '43.688258'],
        ['40.710278', '43.881389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G534',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.863333', '48.518333'],
        ['40.326667', '48.890000'],
        ['40.705278', '49.376667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G67',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.375944', '41.193333']] },
  },
  {
    type: 'Feature',
    name: 'G720',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.838333', '55.603611'],
        ['20.321667', '55.150000'],
        ['20.599611', '54.893278'],
        ['21.145000', '54.700000'],
        ['22.175000', '54.333333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G80',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['26.953611', '41.462222'],
        ['27.186944', '41.211111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G805',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.448056', '55.258611'],
        ['18.729444', '55.502778'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G818',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.958333', '54.912778'],
        ['19.050000', '55.533889'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G822',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.633333', '44.350000'],
        ['37.222500', '44.400000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G823',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.868611', '45.735278'],
        ['37.365000', '45.749722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G835',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.133333', '45.350000'],
        ['37.365000', '45.749722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G900',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.914722', '42.708889'],
        ['37.247222', '43.089444'],
        ['37.623333', '43.511389'],
        ['37.993889', '43.919722'],
        ['38.091667', '44.026389'],
        ['38.315000', '44.269722'],
        ['38.408333', '44.366389'],
        ['38.801111', '44.774444'],
        ['39.590000', '45.564722'],
        ['39.863333', '45.828056'],
        ['40.659444', '46.585278'],
        ['40.886667', '46.793056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G901',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['37.045278', '41.903889'],
        ['37.666667', '42.685000'],
        ['37.988333', '43.033056'],
        ['38.300000', '43.368056'],
        ['38.510000', '43.588056'],
        ['38.833611', '43.935000'],
        ['39.026667', '44.139444'],
        ['39.478333', '44.524722'],
        ['39.928333', '44.912222'],
        ['40.385000', '45.298056'],
        ['40.565000', '45.449722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G904',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.313333', '47.555000'],
        ['39.798611', '47.752778'],
        ['40.376111', '47.805556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'G918',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.565000', '45.449722']] },
  },
  {
    type: 'Feature',
    name: 'G919',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.090000', '45.851944']] },
  },
  {
    type: 'Feature',
    name: 'G926',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.710278', '43.881389']] },
  },
  {
    type: 'Feature',
    name: 'H24',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.656389', '41.799167']] },
  },
  {
    type: 'Feature',
    name: 'H83',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.095583', '53.952806'],
        ['30.433056', '53.551667'],
        ['30.936389', '52.958333'],
        ['30.997194', '52.533500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'H84',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.887694', '52.109361'],
        ['24.221389', '52.559722'],
        ['24.163056', '52.943056'],
        ['24.151389', '53.021389'],
        ['24.055944', '53.607639'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'J21',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.892761', '48.002583'],
        ['14.103156', '48.229711'],
        ['16.018594', '48.417136'],
        ['16.288436', '47.874875'],
        ['15.800319', '46.886989'],
        ['15.449444', '46.955311'],
        ['14.562322', '46.597586'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'J23',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.449444', '46.955311'],
        ['16.018594', '48.417136'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR213',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.203056', '56.997500'],
        ['35.100000', '56.813611'],
        ['34.936389', '56.517500'],
        ['35.122500', '56.357222'],
        ['35.098611', '56.000000'],
        ['35.725833', '55.850833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR488',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.911111', '55.861944'],
        ['36.948333', '55.733889'],
        ['36.966667', '55.670000'],
        ['36.983333', '55.616667'],
        ['36.989167', '55.411667'],
        ['37.346944', '55.268611'],
        ['37.537500', '55.271667'],
        ['37.661111', '55.274167'],
        ['37.761111', '55.223056'],
        ['38.200833', '55.203056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR700',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.951389', '54.991389'],
        ['31.316667', '54.683333'],
        ['31.533333', '54.686944'],
        ['32.900000', '54.700000'],
        ['33.300000', '54.666667'],
        ['34.900000', '54.675000'],
        ['35.595000', '54.669444'],
        ['35.948611', '54.603889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR702',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.898333', '53.308333'],
        ['35.421389', '53.374722'],
        ['35.856389', '53.428056'],
        ['36.600556', '54.031111'],
        ['36.803056', '54.279444'],
        ['37.323611', '54.608889'],
        ['37.344444', '54.920556'],
        ['37.364444', '55.211111'],
        ['37.346944', '55.268611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR708',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.219167', '52.120000'],
        ['38.931111', '52.389167'],
        ['38.648333', '53.280833'],
        ['38.496111', '53.744722'],
        ['38.902222', '54.240278'],
        ['38.643333', '54.631111'],
        ['38.490556', '54.857778'],
        ['38.288611', '55.152778'],
        ['38.200833', '55.203056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR712',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.721944', '54.330278'],
        ['36.803056', '54.279444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR806',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.084722', '54.503333'],
        ['31.533333', '54.686944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR830',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.606667', '56.379444'],
        ['31.043889', '56.289444'],
        ['32.939167', '55.854167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR846',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.997222', '43.356389'],
        ['39.988889', '43.379167'],
        ['39.993611', '43.385278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR905',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.271111', '55.825556'],
        ['37.369722', '55.790000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR906',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.843056', '55.766667'],
        ['38.228056', '55.711389'],
        ['38.296667', '55.708333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR907',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.620000', '55.575000'],
        ['37.657222', '55.520833'],
        ['37.632500', '55.453333'],
        ['37.537500', '55.271667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR908',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.394722', '55.833333'],
        ['37.271111', '55.825556'],
        ['36.911111', '55.861944'],
        ['36.902500', '55.993889'],
        ['37.032778', '56.107222'],
        ['37.373333', '56.189167'],
        ['37.778333', '56.093333'],
        ['37.771111', '56.024444'],
        ['37.767500', '55.962778'],
        ['37.672500', '55.900556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR909',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.672500', '55.900556'],
        ['37.833333', '55.826667'],
        ['37.843056', '55.766667'],
        ['37.836389', '55.651667'],
        ['37.753056', '55.601667'],
        ['37.620000', '55.575000'],
        ['37.428056', '55.670000'],
        ['37.383333', '55.726667'],
        ['37.369722', '55.790000'],
        ['37.386389', '55.806667'],
        ['37.394722', '55.833333'],
        ['37.464722', '55.883333'],
        ['37.574722', '55.910000'],
        ['37.672500', '55.900556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'KR910',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.331389', '56.237500'],
        ['37.373333', '56.189167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L130',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.478333', '44.285556'],
        ['28.569722', '44.541389'],
        ['28.626944', '44.697500'],
        ['28.656194', '44.832833'],
        ['28.736389', '44.992500'],
        ['28.915833', '45.178056'],
        ['29.110833', '45.377500'],
        ['28.865000', '45.975000'],
        ['28.733611', '46.272500'],
        ['28.575000', '46.626389'],
        ['28.202778', '47.439167'],
        ['27.721667', '48.452222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L132',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.222222', '48.885833'],
        ['12.541397', '49.335833'],
        ['12.614503', '49.567917'],
        ['12.712044', '49.965822'],
        ['12.813511', '50.373247'],
        ['12.911667', '50.476944'],
        ['13.057186', '50.629694'],
        ['13.321350', '50.964494'],
        ['13.366739', '51.108539'],
        ['13.471667', '51.499722'],
        ['13.593056', '51.867500'],
        ['13.682500', '52.126111'],
        ['13.732500', '52.269722'],
        ['13.830556', '52.548056'],
        ['13.961667', '52.914722'],
        ['14.410000', '53.275833'],
        ['15.007778', '54.080000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L134',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.329167', '55.744722'],
        ['20.082222', '56.146111'],
        ['20.743056', '56.905833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L136',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.573333', '56.212778'],
        ['20.093056', '56.518611'],
        ['20.743056', '56.905833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L140',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.969089', '48.190489'],
        ['17.182778', '48.090833'],
        ['17.980556', '47.846389'],
        ['18.330000', '47.736944'],
        ['21.860000', '47.248333'],
        ['26.053611', '46.977778'],
        ['27.203333', '46.876111'],
        ['27.584167', '46.839722'],
        ['27.728889', '46.825556'],
        ['28.166667', '46.781667'],
        ['29.900000', '46.544722'],
        ['30.669722', '46.426389'],
        ['31.541111', '46.555278'],
        ['32.004444', '46.611111'],
        ['32.508333', '46.675000'],
        ['32.884722', '46.733333'],
        ['33.495833', '46.807500'],
        ['36.005000', '46.970000'],
        ['36.986944', '47.226667'],
        ['37.558611', '47.370556'],
        ['38.313333', '47.555000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L156',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.052378', '49.930922'],
        ['17.976900', '49.702242'],
        ['17.528397', '49.322853'],
        ['17.241944', '49.020000'],
        ['16.789194', '48.728789'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L158',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.811111', '55.794444'],
        ['40.624444', '55.829167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L172',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.441111', '45.130278'],
        ['16.804167', '44.958056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L173',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.559444', '48.058889'],
        ['12.396944', '48.097222'],
        ['12.126944', '48.160278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L175',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.969089', '48.190489'],
        ['17.299722', '48.119444'],
        ['18.486111', '47.854722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L187',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.100000', '41.458333'],
        ['18.885083', '42.100444'],
        ['18.800389', '42.281972'],
        ['18.720278', '42.323889'],
        ['18.554167', '42.410556'],
        ['18.480000', '42.450278'],
        ['18.277442', '42.553844'],
        ['18.249167', '42.602500'],
        ['18.130556', '42.802222'],
        ['17.855833', '43.261111'],
        ['17.537778', '43.753611'],
        ['17.105278', '44.470278'],
        ['16.958056', '44.709722'],
        ['16.804167', '44.958056'],
        ['16.675833', '45.201389'],
        ['16.306697', '45.895558'],
        ['16.274050', '46.378303'],
        ['15.991908', '46.682494'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L196',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.304722', '43.496581'],
        ['16.482778', '44.029444'],
        ['16.804167', '44.958056'],
        ['16.953056', '45.229444'],
        ['17.146747', '45.747800'],
        ['17.296667', '45.991389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L198',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.468056', '56.826667'],
        ['27.901667', '56.751667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L199',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.546667', '56.853889'],
        ['26.543333', '56.676389'],
        ['28.200000', '56.360000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L23',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.125278', '53.741389'],
        ['12.470000', '53.748889'],
        ['12.699722', '53.752222'],
        ['12.789622', '53.753325'],
        ['13.481522', '53.761978'],
        ['13.563136', '53.762736'],
        ['13.704167', '53.762500'],
        ['13.796667', '53.762222'],
        ['14.275556', '53.759444'],
        ['15.850556', '53.695278'],
        ['16.947222', '53.638611'],
        ['17.976389', '53.576111'],
        ['18.781389', '53.521111'],
        ['19.318611', '53.495556'],
        ['20.349167', '53.439444'],
        ['23.894167', '53.178056'],
        ['24.373889', '53.114444'],
        ['25.697778', '52.927500'],
        ['27.238056', '52.689722'],
        ['31.015000', '52.083333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L29',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.306667', '52.899167'],
        ['15.815000', '53.129722'],
        ['16.622778', '53.244722'],
        ['16.898889', '53.282500'],
        ['18.781389', '53.521111'],
        ['19.891111', '53.684444'],
        ['20.500000', '53.769167'],
        ['21.167222', '53.858056'],
        ['22.900278', '54.069722'],
        ['23.406944', '54.194444'],
        ['23.835278', '54.290000'],
        ['24.277028', '54.386694'],
        ['25.778611', '54.831111'],
        ['27.428889', '55.051111'],
        ['28.221389', '55.146389'],
        ['28.926389', '55.265000'],
        ['30.428056', '55.501667'],
        ['30.948056', '55.583333'],
        ['32.939167', '55.854167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L32',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.175000', '54.333333'],
        ['22.900278', '54.069722'],
        ['23.553056', '53.829722'],
        ['24.055944', '53.607639'],
        ['25.003056', '53.376389'],
        ['25.424722', '53.251944'],
        ['26.403056', '52.954722'],
        ['27.238056', '52.689722'],
        ['28.781389', '52.181389'],
        ['29.965000', '51.866389'],
        ['30.631667', '51.716389'],
        ['34.041389', '50.505833'],
        ['35.545278', '49.917222'],
        ['37.612222', '48.888056'],
        ['38.940556', '48.181944'],
        ['39.808333', '47.950000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L33',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.613611', '49.540000'],
        ['31.125556', '49.873333'],
        ['30.833333', '50.051944'],
        ['30.395000', '50.703333'],
        ['30.261667', '50.833333'],
        ['29.586667', '51.466389'],
        ['29.049722', '51.946389'],
        ['28.781389', '52.181389'],
        ['27.676389', '53.118056'],
        ['26.722500', '53.894722'],
        ['25.195833', '54.213056'],
        ['24.277028', '54.386694'],
        ['22.755000', '54.651667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L333',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'L4',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.495833', '46.807500'],
        ['33.923056', '47.594444'],
        ['34.900556', '49.023056'],
        ['35.153611', '49.378333'],
        ['35.545278', '49.917222'],
        ['36.828333', '50.316667'],
        ['37.285556', '50.919167'],
        ['37.406389', '51.075833'],
        ['37.473056', '51.161389'],
        ['37.777222', '51.546667'],
        ['37.985000', '51.804444'],
        ['38.268056', '52.152500'],
        ['38.496111', '53.744722'],
        ['38.238611', '54.345556'],
        ['38.195000', '54.497778'],
        ['38.131389', '54.718889'],
        ['37.934444', '55.388611'],
        ['37.687500', '55.650556'],
        ['37.307222', '55.971667'],
        ['37.051944', '56.079167'],
        ['36.383333', '56.361667'],
        ['35.655278', '56.644444'],
        ['34.945833', '56.911111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L40',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.861111', '47.208333'],
        ['26.561111', '45.496667'],
        ['30.505000', '43.803333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L44',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.237528', '41.803778'],
        ['12.016667', '41.292778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L5',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.237528', '41.803778'],
        ['12.972500', '42.107222'],
        ['13.122778', '42.165833'],
        ['13.595556', '42.348889'],
        ['13.928056', '42.502778'],
        ['14.096667', '42.580278'],
        ['14.506111', '42.766389'],
        ['15.205556', '43.055833'],
        ['16.304722', '43.496581'],
        ['16.822778', '43.736667'],
        ['17.661389', '44.115833'],
        ['19.105000', '44.596389'],
        ['19.223889', '44.605833'],
        ['19.362778', '44.616944'],
        ['20.776667', '44.722778'],
        ['21.169444', '44.748889'],
        ['21.533333', '44.771667'],
        ['22.610556', '44.778611'],
        ['24.668333', '44.763056'],
        ['25.161311', '44.753892'],
        ['26.651944', '44.713056'],
        ['26.978564', '44.695383'],
        ['27.654722', '44.655833'],
        ['28.478333', '44.285556'],
        ['29.443333', '44.066389'],
        ['29.841389', '43.969167'],
        ['30.505000', '43.803333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L50',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.016667', '41.292778'],
        ['12.188611', '41.160000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L53',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.116667', '42.266667'],
        ['17.668889', '41.201389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L59',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.006389', '53.091944'],
        ['18.958333', '54.912778'],
        ['19.091111', '55.810278'],
        ['19.285000', '56.249722'],
        ['19.566667', '56.871389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L601',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.435692', '45.002281'],
        ['26.651944', '44.713056'],
        ['28.808333', '43.700000'],
        ['29.624167', '42.363889'],
        ['30.502500', '41.617500'],
        ['30.724722', '41.428889'],
        ['31.031111', '41.233056'],
        ['31.253333', '41.090833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L602',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.199581', '50.344808'],
        ['12.381111', '50.291111'],
        ['12.718786', '50.177008'],
        ['13.000000', '50.080814'],
        ['21.496667', '46.687500'],
        ['22.370000', '46.045278'],
        ['22.646667', '45.837500'],
        ['23.703056', '45.312778'],
        ['26.216667', '43.983333'],
        ['26.931111', '43.555833'],
        ['27.193611', '43.396389'],
        ['27.379444', '43.282222'],
        ['28.020556', '42.883611'],
        ['28.141667', '42.809722'],
        ['29.142778', '42.170556'],
        ['30.724722', '41.428889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L603',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.126944', '48.160278'],
        ['12.384081', '47.977450'],
        ['12.601111', '47.821667'],
        ['12.806667', '47.683889'],
        ['15.626797', '46.171375'],
        ['16.306697', '45.895558'],
        ['17.399444', '45.135000'],
        ['17.902500', '44.773889'],
        ['19.180278', '44.136667'],
        ['21.616667', '42.230000'],
        ['22.791389', '41.329444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L604',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.541397', '49.335833'],
        ['13.242222', '48.880000'],
        ['13.583333', '48.653333'],
        ['15.652372', '46.708769'],
        ['15.841944', '46.476111'],
        ['15.976167', '46.309675'],
        ['16.306697', '45.895558'],
        ['16.953056', '45.229444'],
        ['17.113056', '45.061667'],
        ['17.759722', '44.370556'],
        ['18.105556', '43.866944'],
        ['18.497500', '43.241389'],
        ['18.558056', '43.142778'],
        ['18.666667', '43.033333'],
        ['18.924167', '42.776417'],
        ['19.254611', '42.386194'],
        ['19.335000', '42.228333'],
        ['19.453056', '41.979722'],
        ['19.718194', '41.416139'],
        ['19.947500', '41.068056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L605',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.480833', '48.438611'],
        ['12.749781', '48.397050'],
        ['13.181469', '48.296444'],
        ['21.104539', '46.262786'],
        ['21.790000', '45.922778'],
        ['22.586769', '45.516794'],
        ['23.374722', '45.103889'],
        ['24.679722', '44.399444'],
        ['25.090833', '44.164444'],
        ['25.418333', '43.978889'],
        ['25.816667', '43.750000'],
        ['27.032500', '43.098056'],
        ['28.059722', '42.503056'],
        ['28.766111', '42.082222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L606',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.401389', '46.157778'],
        ['22.610556', '44.778611'],
        ['22.976944', '44.538889'],
        ['23.717222', '44.045000'],
        ['24.239167', '43.688889'],
        ['24.980278', '43.127222'],
        ['26.099444', '42.251111'],
        ['26.568333', '41.871667'],
        ['26.953611', '41.462222'],
        ['27.186944', '41.211111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L607',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.718194', '41.416139'],
        ['19.313889', '41.775278'],
        ['19.171667', '41.883056'],
        ['18.885083', '42.100444'],
        ['18.277442', '42.553844'],
        ['17.543333', '42.905278'],
        ['17.314444', '43.017500'],
        ['17.073611', '43.136667'],
        ['16.304722', '43.496581'],
        ['15.364228', '44.095322'],
        ['14.858333', '44.530278'],
        ['14.653889', '44.703611'],
        ['14.416547', '44.902881'],
        ['14.204167', '45.470278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L608',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.008889', '47.537778'],
        ['16.804167', '44.958056'],
        ['17.003056', '44.836944'],
        ['17.333333', '44.633333'],
        ['17.759722', '44.370556'],
        ['18.449828', '43.933864'],
        ['19.144444', '43.531667'],
        ['19.443333', '43.355556'],
        ['19.638333', '43.239722'],
        ['20.311389', '42.833889'],
        ['21.397222', '42.159444'],
        ['22.758333', '41.247222'],
        ['22.991489', '41.098714'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L610',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.222222', '48.885833'],
        ['12.658047', '48.785739'],
        ['13.075833', '48.687778'],
        ['13.505225', '48.584842'],
        ['19.355833', '46.047222'],
        ['20.227222', '45.421944'],
        ['20.861111', '44.954167'],
        ['21.169444', '44.748889'],
        ['22.392222', '43.912222'],
        ['27.216111', '42.065556'],
        ['28.357778', '41.286389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L611',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.100000', '41.458333'],
        ['18.663611', '41.927222'],
        ['18.526944', '42.014167'],
        ['17.935556', '42.348611'],
        ['17.583889', '42.544167'],
        ['17.148333', '42.783611'],
        ['16.541944', '43.110833'],
        ['15.767500', '43.519167'],
        ['12.995556', '45.262778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L612',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.158333', '41.184167'],
        ['16.863611', '41.379722'],
        ['16.636389', '41.530278'],
        ['16.049167', '41.912778'],
        ['15.471667', '42.300833'],
        ['15.202500', '42.478333'],
        ['14.896111', '42.679167'],
        ['14.149167', '43.160000'],
        ['13.875833', '43.333333'],
        ['13.749328', '43.412894'],
        ['13.471111', '43.586444'],
        ['13.180475', '43.957308'],
        ['12.876389', '44.339167'],
        ['12.713333', '44.542500'],
        ['12.505833', '44.797778'],
        ['12.281444', '45.071083'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L613',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.391667', '41.233333'],
        ['17.563889', '41.618611'],
        ['16.116667', '42.266667'],
        ['15.772778', '42.499167'],
        ['14.232778', '43.483889'],
        ['13.648889', '44.106667'],
        ['12.801944', '45.016944'],
        ['12.500000', '45.333333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L614',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.281444', '45.071083'],
        ['12.801944', '45.016944'],
        ['13.091389', '44.985833'],
        ['13.918119', '44.892367'],
        ['15.065278', '44.669722'],
        ['15.533056', '44.575000'],
        ['16.143333', '44.448333'],
        ['16.816389', '44.303611'],
        ['17.661389', '44.115833'],
        ['18.449828', '43.933864'],
        ['19.241667', '43.773333'],
        ['19.504167', '43.718611'],
        ['19.731389', '43.670833'],
        ['20.891111', '43.418333'],
        ['21.376389', '43.308611'],
        ['22.797500', '42.972778'],
        ['23.813889', '42.667222'],
        ['24.079444', '42.594167'],
        ['25.521944', '42.183333'],
        ['26.568333', '41.871667'],
        ['27.330506', '41.475403'],
        ['27.935000', '41.150750'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L615',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.500000', '45.333333'],
        ['12.789167', '45.283889'],
        ['12.995556', '45.262778'],
        ['13.447778', '45.188056'],
        ['14.972500', '44.920278'],
        ['15.769167', '44.771111'],
        ['16.901389', '44.548333'],
        ['17.759722', '44.370556'],
        ['19.180278', '44.136667'],
        ['19.560278', '44.070833'],
        ['19.809167', '44.026944'],
        ['20.464444', '43.908333'],
        ['21.626944', '43.687500'],
        ['22.363889', '43.486944'],
        ['22.768333', '43.374167'],
        ['23.326667', '43.214167'],
        ['27.216111', '42.065556'],
        ['28.404444', '41.493611'],
        ['29.142889', '41.126972'],
        ['29.630000', '41.045833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L616',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.055556', '42.112500'],
        ['20.585556', '43.013611'],
        ['19.874694', '44.323889'],
        ['19.787222', '45.252500'],
        ['19.696667', '46.184444'],
        ['18.330000', '47.736944'],
        ['18.084444', '48.015278'],
        ['18.050556', '48.290556'],
        ['17.516950', '50.270103'],
        ['17.443333', '50.388333'],
        ['17.156944', '50.840833'],
        ['16.934444', '51.185833'],
        ['16.748889', '51.469722'],
        ['16.616667', '51.669722'],
        ['15.832500', '52.816861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L617',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.516667', '41.129167'],
        ['22.242778', '42.308333'],
        ['22.077778', '42.724722'],
        ['21.626944', '43.687500'],
        ['21.437500', '44.006111'],
        ['21.331667', '44.183333'],
        ['21.245556', '44.325556'],
        ['20.861111', '44.954167'],
        ['20.094167', '46.163889'],
        ['19.115861', '48.453250'],
        ['18.883889', '48.783889'],
        ['18.820833', '48.873333'],
        ['18.745319', '48.979400'],
        ['18.654444', '49.106389'],
        ['18.447300', '49.393553'],
        ['18.052378', '49.930922'],
        ['17.995556', '50.030278'],
        ['17.799722', '50.368056'],
        ['17.563333', '50.769444'],
        ['17.510556', '50.857500'],
        ['17.325556', '51.165278'],
        ['17.123889', '51.495556'],
        ['16.997500', '51.700000'],
        ['16.727222', '52.130556'],
        ['16.478889', '52.526389'],
        ['16.225278', '52.922500'],
        ['15.920833', '53.388056'],
        ['15.274444', '54.341667'],
        ['15.241944', '54.388056'],
        ['14.944167', '54.823056'],
        ['14.758692', '55.065578'],
        ['13.321833', '56.728972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L619',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.338581', '52.975206'],
        ['12.830556', '52.959167'],
        ['13.105278', '52.949444'],
        ['13.268056', '52.943333'],
        ['13.654947', '52.927853'],
        ['13.961667', '52.914722'],
        ['14.156667', '52.906111'],
        ['14.306667', '52.899167'],
        ['15.004722', '52.863889'],
        ['15.832500', '52.816861'],
        ['16.193611', '52.543333'],
        ['16.727222', '52.130556'],
        ['16.997500', '51.700000'],
        ['17.123889', '51.495556'],
        ['17.325556', '51.165278'],
        ['18.411667', '50.653333'],
        ['21.010278', '49.336389'],
        ['22.442222', '47.793889'],
        ['23.135833', '47.250278'],
        ['23.744722', '46.760000'],
        ['24.819444', '45.864444'],
        ['24.931181', '45.768969'],
        ['25.488333', '45.286667'],
        ['25.725714', '45.077811'],
        ['26.330000', '44.536667'],
        ['26.826667', '44.082222'],
        ['27.300000', '43.676667'],
        ['27.817167', '43.231028'],
        ['28.202778', '42.927778'],
        ['29.142778', '42.170556'],
        ['29.516111', '41.198889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L620',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.230000', '51.161944'],
        ['12.619722', '51.092778'],
        ['13.321350', '50.964494'],
        ['13.485278', '50.935278'],
        ['13.709167', '50.893333'],
        ['14.000758', '50.837914'],
        ['14.495731', '50.585761'],
        ['22.108056', '47.573611'],
        ['22.418611', '47.422778'],
        ['22.610278', '47.328889'],
        ['23.744722', '46.760000'],
        ['24.306583', '46.446917'],
        ['25.087222', '46.048056'],
        ['26.509167', '45.292778'],
        ['27.654722', '44.655833'],
        ['28.808333', '43.700000'],
        ['28.791944', '43.059167'],
        ['28.766111', '42.082222'],
        ['28.774444', '41.871111'],
        ['28.779281', '41.746164'],
        ['28.789722', '41.478333'],
        ['28.799444', '41.228056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L621',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.013611', '56.380556'],
        ['12.183333', '56.316944'],
        ['12.333056', '56.260556'],
        ['12.573778', '56.168861'],
        ['12.813667', '56.052694'],
        ['13.604000', '55.661250'],
        ['14.094167', '55.411944'],
        ['14.758692', '55.065578'],
        ['15.296111', '54.916667'],
        ['15.748056', '54.730833'],
        ['16.881389', '54.244167'],
        ['18.781389', '53.521111'],
        ['19.350000', '53.226944'],
        ['19.633056', '53.061111'],
        ['20.133889', '52.810000'],
        ['20.328333', '52.704444'],
        ['21.216111', '52.079167'],
        ['21.685556', '51.745000'],
        ['21.911389', '51.497500'],
        ['21.984722', '51.416667'],
        ['22.102500', '51.289722'],
        ['22.371944', '50.998333'],
        ['22.477778', '50.883333'],
        ['22.566111', '50.786944'],
        ['22.708611', '50.629444'],
        ['23.045556', '50.242778'],
        ['23.211944', '50.021667'],
        ['23.756111', '49.630556'],
        ['25.958333', '47.951667'],
        ['26.360833', '47.672333'],
        ['27.040556', '46.719444'],
        ['27.338611', '46.289444'],
        ['27.927778', '45.416389'],
        ['28.435278', '44.726944'],
        ['28.569722', '44.541389'],
        ['29.143333', '43.733333'],
        ['30.264444', '42.610556'],
        ['30.668889', '42.287222'],
        ['31.022778', '42.000556'],
        ['31.308611', '41.766667'],
        ['31.531111', '41.583056'],
        ['31.585833', '41.537500'],
        ['32.069167', '41.132778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L622',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.860000', '47.248333'],
        ['22.891667', '46.531667'],
        ['23.063056', '46.394167'],
        ['24.183889', '45.635556'],
        ['24.902500', '45.079444'],
        ['25.708056', '44.500833'],
        ['26.094272', '44.109011'],
        ['26.216667', '43.983333'],
        ['26.940278', '43.198889'],
        ['27.032500', '43.098056'],
        ['27.537972', '42.587167'],
        ['28.059722', '42.503056'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L623',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.727222', '52.130556'],
        ['17.231111', '51.830000'],
        ['17.398889', '51.730000'],
        ['18.738333', '50.894444'],
        ['19.279444', '50.536389'],
        ['19.658333', '50.275556'],
        ['19.961944', '50.066389'],
        ['20.291389', '49.840556'],
        ['21.010278', '49.336389'],
        ['22.375000', '48.823889'],
        ['22.643056', '48.608333'],
        ['22.827222', '48.457222'],
        ['23.343333', '48.026667'],
        ['25.139167', '47.269167'],
        ['25.478611', '47.120278'],
        ['26.209444', '46.793611'],
        ['26.825556', '46.510917'],
        ['28.501111', '43.735556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L624',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.368800', '50.928150'],
        ['14.881281', '50.550794'],
        ['15.065306', '50.413239'],
        ['22.442222', '47.793889'],
        ['23.561111', '47.346667'],
        ['24.395636', '47.001044'],
        ['25.592222', '46.486667'],
        ['26.689722', '45.994722'],
        ['27.513889', '45.612500'],
        ['27.927778', '45.416389'],
        ['28.482000', '45.127167'],
        ['28.736389', '44.992500'],
        ['29.612778', '44.412778'],
        ['30.025556', '44.133333'],
        ['30.505000', '43.803333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L69',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.984444', '48.471389'],
        ['35.103056', '48.359833'],
        ['36.328333', '48.226667'],
        ['36.975278', '48.158056'],
        ['37.682778', '48.074722'],
        ['38.416111', '47.982222'],
        ['39.000000', '47.900000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L71',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.281389', '49.142222'],
        ['23.059167', '54.307500'],
        ['23.309389', '54.499806'],
        ['23.423611', '54.586944'],
        ['24.549167', '55.420278'],
        ['24.904444', '55.672500'],
        ['25.571389', '56.138889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L72',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.438056', '55.235278'],
        ['24.031667', '56.298889'],
        ['24.824722', '56.601944'],
        ['25.546667', '56.853889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L725',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.240833', '47.772500'],
        ['12.601111', '47.821667'],
        ['12.741667', '47.840278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L726',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.066742', '49.704272'],
        ['15.782703', '49.464108'],
        ['15.947267', '49.407894'],
        ['16.236314', '49.308278'],
        ['16.692581', '49.150064'],
        ['17.528397', '49.322853'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L727',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['17.161389', '55.638611']] },
  },
  {
    type: 'Feature',
    name: 'L73',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['23.965194', '56.920861']] },
  },
  {
    type: 'Feature',
    name: 'L730',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.306667', '52.899167'],
        ['15.333056', '53.475278'],
        ['15.526389', '53.514167'],
        ['15.702500', '53.584722'],
        ['16.543056', '53.915556'],
        ['17.018333', '54.097500'],
        ['17.536194', '54.126222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L732',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.293639', '54.636056'],
        ['25.443333', '55.461889'],
        ['25.571389', '56.138889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L733',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.410000', '53.275833'],
        ['15.422500', '53.672500'],
        ['16.116389', '53.944444'],
        ['17.406667', '54.387222'],
        ['18.958333', '54.912778'],
        ['20.291667', '55.474444'],
        ['22.612778', '56.382778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L735',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.548056', '51.430000'],
        ['13.341111', '51.662500'],
        ['13.983889', '51.845278'],
        ['14.174472', '51.898597'],
        ['14.755556', '52.058333'],
        ['17.036111', '52.697222'],
        ['17.616667', '52.850000'],
        ['20.500000', '53.552778'],
        ['22.900278', '54.069722'],
        ['23.406944', '54.194444'],
        ['24.335806', '54.282528'],
        ['25.588333', '54.388333'],
        ['26.799722', '54.434722'],
        ['31.084722', '54.503333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L736',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.838333', '55.603611'],
        ['21.318889', '55.531667'],
        ['22.771111', '55.443056'],
        ['26.474167', '55.136667'],
        ['27.428889', '55.051111'],
        ['31.084722', '54.503333'],
        ['33.858333', '54.709167'],
        ['35.221111', '54.737500'],
        ['36.176944', '54.736667'],
        ['36.648056', '54.733333'],
        ['37.372778', '54.725556'],
        ['38.195000', '54.497778'],
        ['38.898056', '54.233333'],
        ['40.354167', '53.875833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L737',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.055556', '42.112500'],
        ['22.239444', '41.163333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L738',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.438056', '55.235278'],
        ['22.347222', '56.130278'],
        ['22.612778', '56.382778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L742',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['27.971667', '42.395000'],
        ['27.537972', '42.587167'],
        ['26.872500', '43.026944'],
        ['26.645833', '43.187222'],
        ['25.816667', '43.750000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L743',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.878056', '47.037222'],
        ['31.533333', '46.316667'],
        ['31.100000', '45.865000'],
        ['30.937222', '45.729722'],
        ['30.050000', '44.976389'],
        ['29.612778', '44.412778'],
        ['29.447222', '44.196111'],
        ['29.143333', '43.733333'],
        ['28.791944', '43.059167'],
        ['28.137222', '42.643056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L744',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.816667', '43.750000'],
        ['26.886944', '43.475833'],
        ['27.193611', '43.396389'],
        ['27.817167', '43.231028'],
        ['28.536389', '43.105000'],
        ['28.791944', '43.059167'],
        ['30.596111', '42.738889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L746',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.267222', '46.493611'],
        ['22.242222', '46.351944'],
        ['23.088056', '45.960278'],
        ['25.754167', '44.905000'],
        ['28.501111', '43.735556'],
        ['30.264444', '42.610556'],
        ['31.107500', '42.457222'],
        ['32.123889', '42.262778'],
        ['32.205000', '42.246944'],
        ['32.611944', '42.166111'],
        ['33.077778', '42.071389'],
        ['33.706278', '41.951417'],
        ['34.594722', '41.746111'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L747',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.084750', '56.512778'],
        ['21.093611', '56.073889'],
        ['21.226944', '55.756667'],
        ['21.318889', '55.531667'],
        ['21.353889', '55.444722'],
        ['21.373056', '55.397778'],
        ['21.438056', '55.235278'],
        ['22.175000', '54.333333'],
        ['23.058333', '49.888333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L748',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.755000', '54.651667'],
        ['25.588333', '54.388333'],
        ['25.886389', '54.329722'],
        ['26.829167', '54.138889'],
        ['28.020639', '53.884444'],
        ['30.798056', '53.488333'],
        ['32.464722', '53.216667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L749',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.838333', '55.603611'],
        ['21.373056', '55.397778'],
        ['21.671944', '55.355000'],
        ['22.752944', '55.193833'],
        ['23.882222', '55.013889'],
        ['24.247528', '54.952889'],
        ['25.735278', '54.673056'],
        ['27.863056', '54.289722'],
        ['28.908056', '54.073056'],
        ['29.333056', '53.978056'],
        ['30.184722', '53.781667'],
        ['30.758056', '53.645000'],
        ['32.464722', '53.216667'],
        ['33.873056', '52.988056'],
        ['34.653056', '52.853056'],
        ['35.508056', '52.698056'],
        ['36.164722', '52.574722'],
        ['37.109722', '52.389722'],
        ['38.268056', '52.152500'],
        ['38.876944', '52.132222'],
        ['39.219167', '52.120000'],
        ['39.530833', '52.107778'],
        ['40.270556', '52.075278'],
        ['40.899167', '52.043889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L81',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.703333', '41.020000'],
        ['15.529722', '41.150000'],
        ['14.807222', '41.683056'],
        ['14.133611', '42.168056'],
        ['13.888333', '42.341944'],
        ['13.467222', '42.684167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L850',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.496667', '46.687500'],
        ['22.242222', '46.351944'],
        ['24.819444', '45.864444'],
        ['26.561111', '45.496667'],
        ['27.901944', '45.191944'],
        ['28.473611', '45.056111'],
        ['28.736389', '44.992500'],
        ['30.130556', '44.812500'],
        ['39.983333', '42.990000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L851',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.267222', '46.493611'],
        ['23.088056', '45.960278'],
        ['25.488333', '45.286667'],
        ['26.435692', '45.002281'],
        ['27.654722', '44.655833'],
        ['30.505000', '43.803333'],
        ['30.966667', '43.683333'],
        ['31.728333', '43.473333'],
        ['32.315000', '43.293333'],
        ['32.971667', '43.118333'],
        ['34.087778', '42.780556'],
        ['37.045278', '41.903889'],
        ['37.661944', '41.708611'],
        ['38.790000', '41.340000'],
        ['39.169778', '41.215333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L852',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.409722', '44.988333'],
        ['23.636389', '44.670278'],
        ['24.679722', '44.399444'],
        ['25.223372', '44.237422'],
        ['26.216667', '43.983333'],
        ['29.557778', '42.585833'],
        ['29.870278', '42.461667'],
        ['31.403056', '42.121389'],
        ['31.885833', '42.009167'],
        ['32.232778', '41.927222'],
        ['32.366667', '41.895000'],
        ['32.461111', '41.872500'],
        ['32.759444', '41.800278'],
        ['33.031111', '41.733611'],
        ['33.538056', '41.607222'],
        ['33.578611', '41.596944'],
        ['34.135278', '41.454722'],
        ['34.337222', '41.402500'],
        ['34.862222', '41.263611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L856',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.200556', '47.938611'],
        ['12.587500', '47.974722'],
        ['16.969089', '48.190489'],
        ['17.290333', '48.223861'],
        ['17.717222', '48.505278'],
        ['18.654444', '49.106389'],
        ['18.884811', '49.250836'],
        ['19.469444', '49.611667'],
        ['21.361111', '50.746111'],
        ['21.880833', '51.042778'],
        ['22.460833', '51.366944'],
        ['23.664722', '52.016389'],
        ['23.887694', '52.109361'],
        ['24.093056', '52.213056'],
        ['24.964722', '52.419722'],
        ['27.676389', '53.118056'],
        ['28.648056', '53.349722'],
        ['29.229167', '53.491111'],
        ['29.760556', '53.616944'],
        ['30.840000', '53.864167'],
        ['31.859722', '54.084722'],
        ['33.530556', '54.423611'],
        ['35.221111', '54.737500'],
        ['35.931111', '55.102222'],
        ['36.791389', '55.528611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L862',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.668889', '41.201389'],
        ['17.248333', '41.928333'],
        ['16.815556', '42.658611'],
        ['16.541944', '43.110833'],
        ['16.304722', '43.496581'],
        ['16.042778', '43.867500'],
        ['15.852222', '44.134167'],
        ['15.667778', '44.390000'],
        ['15.533056', '44.575000'],
        ['15.270278', '44.932222'],
        ['15.173889', '45.061667'],
        ['14.867481', '45.465872'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L863',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.146747', '45.747800'],
        ['18.381667', '45.101944'],
        ['19.874694', '44.323889'],
        ['21.626944', '43.687500'],
        ['22.493056', '42.846389'],
        ['22.665000', '42.593889'],
        ['22.878611', '42.276944'],
        ['23.455556', '41.402778'],
        ['23.564722', '41.233056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L865',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.048611', '42.618333'],
        ['12.163611', '42.698611'],
        ['12.310700', '42.800347'],
        ['12.677778', '43.051667'],
        ['12.906389', '43.207222'],
        ['13.036667', '43.295000'],
        ['13.167303', '43.383725'],
        ['13.471111', '43.586444'],
        ['13.648889', '44.106667'],
        ['13.686111', '44.221944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L867',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.330506', '41.475403'],
        ['26.766389', '41.971111'],
        ['24.239167', '43.688889'],
        ['23.374722', '45.103889'],
        ['23.088056', '45.960278'],
        ['21.860000', '47.248333'],
        ['17.995556', '50.030278'],
        ['17.443333', '50.388333'],
        ['14.585556', '52.379722'],
        ['14.028136', '52.602503'],
        ['13.105278', '52.949444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L868',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.364228', '44.095322'],
        ['15.173889', '45.061667'],
        ['15.133611', '45.165278'],
        ['15.007147', '45.495017'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L87',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.408333', '51.312500'],
        ['12.548056', '51.430000'],
        ['12.719167', '51.668889'],
        ['12.924167', '51.951111'],
        ['13.654947', '52.927853'],
        ['13.911028', '53.178592'],
        ['14.325833', '53.507778'],
        ['14.895000', '54.277500'],
        ['15.000000', '54.416389'],
        ['15.296111', '54.916667'],
        ['15.634722', '55.473333'],
        ['16.284111', '56.685333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L91',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.270278', '42.681389'],
        ['39.337778', '43.910278'],
        ['39.448333', '44.366389'],
        ['39.478333', '44.524722'],
        ['39.576389', '44.962222'],
        ['39.673056', '45.218611'],
        ['39.758333', '45.508056'],
        ['39.863333', '45.828056'],
        ['40.093333', '46.532778'],
        ['40.095833', '47.138889'],
        ['40.100000', '47.885278'],
        ['40.158889', '48.085000'],
        ['40.241111', '48.482500'],
        ['40.326667', '48.890000'],
        ['40.465000', '49.533056'],
        ['39.345556', '51.141389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L919',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.537972', '42.587167'],
        ['27.581389', '42.937222'],
        ['27.817167', '43.231028'],
        ['28.441944', '43.528611'],
        ['28.808333', '43.700000'],
        ['29.315556', '44.468611'],
        ['29.803333', '45.186667'],
        ['30.196389', '46.173333'],
        ['30.431111', '47.157778'],
        ['30.661667', '48.088056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L94',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.667778', '55.992500'],
        ['39.789722', '56.028611'],
        ['40.599444', '56.263611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.137778', '51.826111'],
        ['37.985000', '51.804444'],
        ['38.797500', '51.771667'],
        ['39.626667', '51.732222'],
        ['39.992222', '51.723611'],
        ['40.189444', '51.718333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L97',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.438056', '55.235278'],
        ['24.958611', '56.369444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L975',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.715000', '55.491389'],
        ['12.884722', '55.482500'],
        ['13.557528', '55.411306'],
        ['14.094167', '55.411944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L979',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.755556', '52.058333'],
        ['15.459167', '52.169167'],
        ['15.883333', '52.233889'],
        ['17.631389', '52.592222'],
        ['18.070000', '52.677222'],
        ['20.463333', '53.107778'],
        ['23.629722', '53.592778'],
        ['24.055944', '53.607639'],
        ['25.079722', '53.801389'],
        ['26.793056', '54.034444'],
        ['27.338056', '54.103056'],
        ['29.015278', '54.296944'],
        ['29.371111', '54.335278'],
        ['29.608056', '54.360000'],
        ['29.931389', '54.393333'],
        ['30.262778', '54.426389'],
        ['31.084722', '54.503333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L980',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.621944', '52.400278'],
        ['14.070761', '52.399789'],
        ['14.585556', '52.379722'],
        ['15.564444', '52.271111'],
        ['15.883333', '52.233889'],
        ['16.211389', '52.194444'],
        ['16.727222', '52.130556'],
        ['17.421111', '52.059167'],
        ['17.856639', '52.012472'],
        ['18.000000', '51.996667'],
        ['18.867500', '51.896944'],
        ['19.125000', '51.865833'],
        ['19.280833', '51.846944'],
        ['19.656111', '51.800000'],
        ['21.227222', '51.482222'],
        ['21.688056', '51.383889'],
        ['24.100000', '50.833333'],
        ['24.795000', '50.758333'],
        ['27.207778', '50.465833'],
        ['29.933056', '50.074722'],
        ['31.760833', '49.715556'],
        ['33.616667', '49.313333'],
        ['34.900556', '49.023056'],
        ['36.441667', '48.650000'],
        ['37.186944', '48.445278'],
        ['39.218333', '47.856667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L981',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.727222', '52.130556'],
        ['17.175000', '51.952778'],
        ['17.678333', '51.750000'],
        ['19.111944', '51.149167'],
        ['19.485556', '50.986944'],
        ['20.109444', '50.710833'],
        ['20.251111', '50.647222'],
        ['21.219722', '50.395833'],
        ['21.612778', '50.290556'],
        ['22.699722', '49.990556'],
        ['23.058333', '49.888333'],
        ['23.756111', '49.630556'],
        ['24.501667', '49.345000'],
        ['25.598333', '48.913333'],
        ['25.980000', '48.758333'],
        ['26.812778', '48.414722'],
        ['29.611111', '47.012778'],
        ['30.669722', '46.426389'],
        ['31.435000', '46.073333'],
        ['32.096667', '45.743333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L983',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.379211', '55.001500'],
        ['12.522500', '55.007222'],
        ['12.730278', '55.015278'],
        ['12.800833', '55.016389'],
        ['12.999667', '55.022806'],
        ['13.426972', '55.035528'],
        ['14.116278', '55.054611'],
        ['14.406667', '55.060000'],
        ['14.758692', '55.065578'],
        ['16.070833', '55.033611'],
        ['16.302222', '55.026389'],
        ['17.685556', '54.974444'],
        ['18.958333', '54.912778'],
        ['22.734722', '54.941389'],
        ['23.208056', '54.869722'],
        ['25.615333', '54.471472'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L984',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.093719', '50.069194'],
        ['12.405739', '50.065147'],
        ['13.000000', '50.080814'],
        ['13.326522', '50.089461'],
        ['17.661750', '49.953767'],
        ['18.052378', '49.930922'],
        ['18.283269', '49.933883'],
        ['18.450833', '49.935556'],
        ['18.923056', '49.939722'],
        ['19.064722', '49.940278'],
        ['19.900000', '49.940278'],
        ['20.081389', '49.939722'],
        ['20.140833', '49.939444'],
        ['20.724722', '49.935000'],
        ['21.175278', '49.930000'],
        ['21.383611', '49.926667'],
        ['21.683889', '49.921667'],
        ['22.668333', '49.899444'],
        ['23.058333', '49.888333'],
        ['25.072222', '49.605278'],
        ['26.451667', '49.388333'],
        ['27.845000', '49.150000'],
        ['28.849167', '48.966389'],
        ['32.601389', '48.524444'],
        ['32.984444', '48.471389'],
        ['36.216667', '47.966667'],
        ['37.225278', '47.748889'],
        ['38.313333', '47.555000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L986',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.203286', '51.936447'],
        ['12.456111', '51.899167'],
        ['13.151111', '51.794722'],
        ['14.252500', '51.513333'],
        ['14.961667', '51.406944'],
        ['16.491944', '51.078611'],
        ['17.899167', '50.770278'],
        ['18.411667', '50.653333'],
        ['21.175278', '49.930000'],
        ['21.841111', '49.741944'],
        ['22.656111', '49.504722'],
        ['24.691389', '48.884167'],
        ['25.457222', '48.720556'],
        ['26.812778', '48.414722'],
        ['29.223056', '47.727778'],
        ['30.431111', '47.157778'],
        ['32.004444', '46.611111'],
        ['32.908333', '46.125000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L987',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.036861', '55.162722'],
        ['13.501194', '55.250028'],
        ['15.634722', '55.473333'],
        ['17.161389', '55.638611'],
        ['17.785556', '55.697500'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L99',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.736389', '44.992500'],
        ['30.169444', '44.712222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L990',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.050833', '54.991111'],
        ['12.627778', '55.230833'],
        ['12.682778', '55.253056'],
        ['13.006389', '55.384722'],
        ['13.379583', '55.534528'],
        ['13.962833', '55.776778'],
        ['14.836667', '56.132778'],
        ['15.937000', '56.554944'],
        ['16.284111', '56.685333'],
        ['16.602333', '56.883611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L995',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.048611', '42.618333'],
        ['12.599000', '42.248875'],
        ['12.919444', '42.029725'],
        ['13.094722', '41.909444'],
        ['13.222222', '41.821667'],
        ['13.347667', '41.734756'],
        ['13.970667', '41.296778'],
        ['14.653333', '41.190833'],
        ['14.869167', '41.157222'],
        ['15.171389', '41.107778'],
        ['15.703333', '41.020000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L996',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.832500', '52.816861'],
        ['15.671111', '53.032222'],
        ['15.476389', '53.288611'],
        ['15.392222', '53.398611'],
        ['15.333056', '53.475278'],
        ['15.276667', '53.636667'],
        ['15.242778', '53.734444'],
        ['15.055833', '54.262500'],
        ['15.000000', '54.416389'],
        ['14.857778', '54.805556'],
        ['14.758692', '55.065578'],
        ['14.094167', '55.411944'],
        ['13.604000', '55.661250'],
        ['12.813667', '56.052694'],
        ['12.573778', '56.168861'],
        ['12.513333', '56.423333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L997',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.241111', '56.106667'],
        ['12.183333', '56.316944'],
        ['12.123611', '56.531667'],
        ['12.011667', '56.928333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'L999',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.799722', '49.303611'],
        ['20.724722', '49.935000'],
        ['21.014444', '50.133333'],
        ['21.762500', '50.616667'],
        ['22.086111', '50.823611'],
        ['23.543056', '51.723056'],
        ['24.984722', '52.359722'],
        ['25.697778', '52.927500'],
        ['26.514722', '53.274722'],
        ['26.956389', '53.456389'],
        ['28.020639', '53.884444'],
        ['28.924444', '54.378889'],
        ['29.481389', '54.675000'],
        ['30.861389', '55.280000'],
        ['31.316944', '55.366667'],
        ['33.240556', '55.709722'],
        ['34.355556', '55.892222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M10',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.298333', '41.723333'],
        ['33.031111', '41.733611'],
        ['34.594722', '41.746111'],
        ['37.661944', '41.708611'],
        ['39.583056', '41.643333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M11',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['37.224722', '41.214444'],
        ['37.554444', '41.190000'],
        ['37.618056', '41.186944'],
        ['37.883889', '41.164444'],
        ['38.272222', '41.133056'],
        ['38.605833', '41.105278'],
        ['38.701944', '41.096944'],
        ['39.137222', '41.057222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M126',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.018333', '41.596667'],
        ['12.237528', '41.803778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M127',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.718194', '41.416139'],
        ['19.788333', '41.983611'],
        ['19.850556', '42.479167'],
        ['19.852778', '42.645833'],
        ['19.874694', '44.323889'],
        ['19.546111', '45.247222'],
        ['19.444167', '45.526667'],
        ['19.355833', '46.047222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M136',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.700000', '46.583333'],
        ['37.682778', '48.074722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M141',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.926322', '48.270156'],
        ['17.290333', '48.223861'],
        ['18.816944', '48.578611'],
        ['20.266667', '48.582500'],
        ['22.448611', '48.950278'],
        ['23.044444', '48.940833'],
        ['24.691389', '48.884167'],
        ['26.357778', '49.164167'],
        ['27.846944', '49.390833'],
        ['29.695278', '49.650000'],
        ['30.833333', '50.051944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M155',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.838333', '55.603611'],
        ['20.133056', '55.692222'],
        ['20.436389', '55.780833'],
        ['21.982389', '56.215806'],
        ['22.612778', '56.382778'],
        ['23.093333', '56.577500'],
        ['23.965194', '56.920861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M159',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.799722', '49.303611'],
        ['22.175000', '54.333333'],
        ['22.734722', '54.941389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M167',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.099722', '44.918333'],
        ['12.281444', '45.071083'],
        ['12.493333', '45.132500'],
        ['12.918333', '45.241667'],
        ['12.995556', '45.262778'],
        ['13.773442', '45.465569'],
        ['14.170864', '45.565586'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M169',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.595556', '42.348889'],
        ['14.184167', '42.435833'],
        ['14.331389', '42.442778'],
        ['15.202500', '42.478333'],
        ['15.772778', '42.499167'],
        ['16.048889', '42.507778'],
        ['17.200556', '42.536944'],
        ['17.583889', '42.544167'],
        ['18.277442', '42.553844'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M173',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.850556', '42.479167'],
        ['20.192222', '44.140556'],
        ['20.311167', '44.819361'],
        ['20.227222', '45.421944'],
        ['20.094167', '46.163889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M178',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.133953', '45.475575'],
        ['13.918119', '44.892367'],
        ['13.686111', '44.221944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M19',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.808050', '46.706736'],
        ['16.036944', '46.546111'],
        ['16.274050', '46.378303'],
        ['16.675278', '46.090278'],
        ['17.146747', '45.747800'],
        ['18.099722', '45.446667'],
        ['18.641944', '45.270556'],
        ['19.077500', '45.126667'],
        ['19.430778', '45.009472'],
        ['21.331667', '44.183333'],
        ['22.417778', '43.685833'],
        ['22.986667', '43.422222'],
        ['23.809444', '43.031667'],
        ['24.184444', '42.850000'],
        ['26.568333', '41.871667'],
        ['27.078889', '41.554444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M196',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.500000', '45.333333'],
        ['13.004167', '45.462222'],
        ['13.589722', '45.851111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M22',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.494722', '41.332778'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M26',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['19.718194', '41.416139']] },
  },
  {
    type: 'Feature',
    name: 'M405',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.898333', '56.297778'],
        ['19.907222', '56.485278'],
        ['20.093056', '56.518611'],
        ['22.379167', '56.902500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M406',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.442222', '47.793889'],
        ['25.478611', '47.120278'],
        ['26.053611', '46.977778'],
        ['27.393611', '46.627778'],
        ['28.237778', '46.403056'],
        ['29.051389', '46.178611'],
        ['31.510278', '45.456389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M423',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.571111', '46.690556'],
        ['21.673333', '46.810000'],
        ['21.924139', '47.100361'],
        ['22.069722', '47.196111'],
        ['22.418611', '47.422778'],
        ['22.589333', '47.532972'],
        ['22.893861', '47.727417'],
        ['23.343333', '48.026667'],
        ['23.655000', '49.528333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M435',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.443333', '44.310000'],
        ['31.416667', '45.410000'],
        ['31.396667', '45.781389'],
        ['31.271667', '48.000000'],
        ['31.209444', '49.040833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M44',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.543889', '54.316944'],
        ['13.496111', '54.506111'],
        ['13.386194', '54.916667'],
        ['12.813667', '56.052694'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M54',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.770278', '50.605556'],
        ['40.192222', '51.183333'],
        ['39.626667', '51.732222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M55',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.359722', '52.968333'],
        ['33.873056', '52.988056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M56',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.025278', '54.388333'],
        ['39.255833', '54.333056'],
        ['40.130278', '54.117500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M6',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.573778', '56.168861']] },
  },
  {
    type: 'Feature',
    name: 'M602',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.379211', '55.001500'],
        ['12.780278', '54.878889'],
        ['12.935278', '54.795833'],
        ['13.300278', '54.598611'],
        ['13.543889', '54.316944'],
        ['13.728889', '54.100278'],
        ['13.966667', '53.953056'],
        ['14.275556', '53.759444'],
        ['14.614722', '53.669722'],
        ['15.333056', '53.475278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M607',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.755556', '52.058333'],
        ['17.072222', '55.194167'],
        ['17.161389', '55.638611'],
        ['17.228611', '56.337778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M611',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.050833', '54.991111'],
        ['12.379211', '55.001500'],
        ['12.761389', '55.095833'],
        ['13.036861', '55.162722'],
        ['14.094167', '55.411944'],
        ['16.814722', '56.000000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M614',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.111667', '45.258333'],
        ['31.396667', '45.781389'],
        ['32.004444', '46.611111'],
        ['32.833333', '47.766667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M63',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.433333', '50.850000'],
        ['35.662222', '50.818611'],
        ['35.871389', '50.789722'],
        ['36.190833', '50.803611'],
        ['36.740833', '50.825278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M64',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.433333', '50.850000'],
        ['35.650278', '50.970000'],
        ['36.043056', '51.184722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M66',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.469444', '49.611667'],
        ['20.060556', '50.806111'],
        ['20.115833', '50.914722'],
        ['20.360000', '51.387778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M70',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.241944', '54.388056'],
        ['16.838333', '53.506667'],
        ['18.319167', '52.635000'],
        ['19.656111', '51.800000'],
        ['22.443056', '51.468056'],
        ['23.645833', '51.302778'],
        ['27.348889', '50.706944'],
        ['28.692500', '50.456389'],
        ['29.570139', '50.186806'],
        ['29.933056', '50.074722'],
        ['31.613611', '49.540000'],
        ['31.996667', '49.416667'],
        ['33.366667', '48.950000'],
        ['34.277222', '48.646111'],
        ['35.103056', '48.359833'],
        ['36.216667', '47.966667'],
        ['37.225278', '47.748889'],
        ['39.000000', '47.900000'],
        ['39.218333', '47.856667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M725',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.123050', '55.203383'],
        ['12.379211', '55.001500'],
        ['12.780278', '54.878889'],
        ['12.935278', '54.795833'],
        ['13.300278', '54.598611'],
        ['13.543889', '54.316944'],
        ['13.576389', '54.193056'],
        ['13.645833', '53.924167'],
        ['13.704167', '53.762500'],
        ['13.731389', '53.686111'],
        ['13.911028', '53.178592'],
        ['13.961667', '52.914722'],
        ['14.028136', '52.602503'],
        ['14.070761', '52.399789'],
        ['14.088147', '52.316569'],
        ['14.103056', '52.245556'],
        ['14.174472', '51.898597'],
        ['14.193611', '51.804444'],
        ['14.252500', '51.513333'],
        ['14.330883', '51.120789'],
        ['14.368800', '50.928150'],
        ['14.428853', '50.766814'],
        ['14.495731', '50.585761'],
        ['14.874664', '49.532328'],
        ['15.221472', '49.243314'],
        ['15.545578', '48.969175'],
        ['15.615789', '48.888106'],
        ['15.652372', '46.708769'],
        ['15.841944', '46.476111'],
        ['15.976167', '46.309675'],
        ['16.306697', '45.895558'],
        ['16.305000', '44.996667'],
        ['16.305833', '44.967500'],
        ['16.303611', '44.150000'],
        ['16.304722', '43.496581'],
        ['16.073889', '43.170556'],
        ['15.748333', '42.703889'],
        ['15.471667', '42.300833'],
        ['14.806944', '41.306667'],
        ['14.769444', '41.250000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M726',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.083333', '41.333333'],
        ['12.918056', '41.541111'],
        ['12.919444', '42.029725'],
        ['12.310700', '42.800347'],
        ['12.269167', '42.885556'],
        ['12.180278', '43.066389'],
        ['12.076667', '54.210000'],
        ['12.530000', '54.566389'],
        ['13.386194', '54.916667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M727',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.018333', '41.596667'],
        ['12.188611', '41.160000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M729',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.532222', '41.224444'],
        ['12.161389', '41.493889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M730',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.217500', '43.817778'],
        ['12.348333', '43.793889'],
        ['12.399722', '43.784722'],
        ['12.982397', '43.678514'],
        ['13.071364', '43.661931'],
        ['13.471111', '43.586444'],
        ['13.837847', '43.578819'],
        ['14.132222', '43.571389'],
        ['14.424722', '43.564167'],
        ['14.966667', '43.547778'],
        ['15.767500', '43.519167'],
        ['16.304722', '43.496581'],
        ['17.277222', '43.351667'],
        ['17.855833', '43.261111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M736',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.970667', '41.296778'],
        ['14.031111', '41.622778'],
        ['14.079722', '41.881667'],
        ['14.133611', '42.168056'],
        ['14.172778', '42.375556'],
        ['14.184167', '42.435833'],
        ['14.096667', '42.580278'],
        ['14.026667', '42.694444'],
        ['13.682222', '43.250833'],
        ['13.623878', '43.344044'],
        ['13.471111', '43.586444'],
        ['12.480833', '48.438611'],
        ['12.526547', '49.040269'],
        ['12.541397', '49.335833'],
        ['12.086111', '49.707778'],
        ['12.068611', '52.872222'],
        ['12.132222', '52.951389'],
        ['12.699722', '53.752222'],
        ['12.970278', '54.123056'],
        ['13.137689', '54.459656'],
        ['13.386194', '54.916667'],
        ['13.426972', '55.035528'],
        ['13.501194', '55.250028'],
        ['13.557528', '55.411306'],
        ['13.604000', '55.661250'],
        ['13.733111', '56.339139'],
        ['13.815278', '56.758389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M743',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.758692', '55.065578'],
        ['14.080000', '55.263056'],
        ['13.557528', '55.411306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M747',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.401389', '46.157778'],
        ['21.314444', '45.896944'],
        ['22.586769', '45.516794'],
        ['22.959722', '45.450000'],
        ['23.703056', '45.312778'],
        ['24.902500', '45.079444'],
        ['25.330292', '44.992817'],
        ['25.754167', '44.905000'],
        ['26.651944', '44.713056'],
        ['26.981800', '44.640142'],
        ['27.554167', '44.510833'],
        ['28.478333', '44.285556'],
        ['29.447222', '44.196111'],
        ['29.766111', '44.161944'],
        ['30.025556', '44.133333'],
        ['30.438611', '44.086389'],
        ['40.145833', '42.490278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M748',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.343889', '52.332500'],
        ['12.471478', '52.258850'],
        ['12.671308', '52.123761'],
        ['12.970733', '51.919164'],
        ['13.151111', '51.794722'],
        ['13.341111', '51.662500'],
        ['13.627669', '51.460847'],
        ['13.884722', '51.278056'],
        ['14.179444', '51.065833'],
        ['14.368800', '50.928150'],
        ['16.692581', '49.150064'],
        ['17.167844', '48.853200'],
        ['17.386111', '48.715833'],
        ['17.541111', '48.617500'],
        ['18.050556', '48.290556'],
        ['18.574167', '47.974444'],
        ['18.733056', '47.808333'],
        ['21.104539', '46.262786'],
        ['22.976944', '44.538889'],
        ['23.800000', '43.783333'],
        ['24.034167', '43.402222'],
        ['24.220556', '43.095000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M749',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.355833', '46.047222'],
        ['19.937778', '45.317778'],
        ['20.311167', '44.819361'],
        ['20.602778', '44.211944'],
        ['20.810333', '43.717500'],
        ['21.040556', '43.115833'],
        ['21.397222', '42.159444'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M850',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.965194', '56.920861'],
        ['24.824722', '56.601944'],
        ['25.171667', '56.479444'],
        ['26.978056', '55.812778'],
        ['28.713056', '53.856389'],
        ['28.914722', '53.616389'],
        ['29.079722', '53.350000'],
        ['29.423056', '52.786389'],
        ['29.965000', '51.866389'],
        ['30.261667', '51.476389'],
        ['30.833333', '50.051944'],
        ['30.921944', '49.818611'],
        ['31.076944', '49.404722'],
        ['31.209444', '49.040833'],
        ['31.433333', '48.448333'],
        ['31.505556', '48.218333'],
        ['31.598333', '47.955000'],
        ['31.846667', '47.628333'],
        ['32.508333', '46.675000'],
        ['32.908333', '46.125000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M853',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.373611', '41.017500'],
        ['33.474722', '41.304583'],
        ['33.706278', '41.951417'],
        ['33.814167', '42.189167'],
        ['34.087778', '42.780556'],
        ['35.026944', '46.547222'],
        ['35.255833', '47.196944'],
        ['35.921944', '48.996111'],
        ['36.290444', '49.928917'],
        ['36.485000', '50.313333'],
        ['36.740833', '50.825278'],
        ['36.884722', '51.108056'],
        ['37.017778', '51.366667'],
        ['37.148056', '51.616389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M854',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.978056', '55.812778'],
        ['27.694722', '54.588056'],
        ['27.863056', '54.289722'],
        ['28.020639', '53.884444'],
        ['28.374167', '53.107778'],
        ['28.781389', '52.181389'],
        ['29.031667', '51.593056'],
        ['29.414722', '50.583889'],
        ['29.570139', '50.186806'],
        ['29.695278', '49.650000'],
        ['29.758333', '49.456667'],
        ['29.946944', '48.726944'],
        ['30.088333', '48.165000'],
        ['30.431111', '47.157778'],
        ['30.669722', '46.426389'],
        ['31.100000', '45.865000'],
        ['31.416667', '45.410000'],
        ['31.838333', '44.815000'],
        ['32.030000', '44.535000'],
        ['32.255000', '44.206667'],
        ['32.501667', '43.833333'],
        ['32.971667', '43.118333'],
        ['33.181111', '42.798889'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M855',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'M856',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.298333', '41.723333'],
        ['31.947778', '42.798889'],
        ['31.728333', '43.473333'],
        ['31.443333', '44.310000'],
        ['31.111667', '45.258333'],
        ['31.100000', '45.865000'],
        ['30.661667', '48.088056'],
        ['30.684722', '49.194167'],
        ['30.692500', '49.557778'],
        ['30.833333', '50.051944'],
        ['30.900972', '50.285694'],
        ['30.977778', '50.946389'],
        ['31.007222', '51.813056'],
        ['31.015000', '52.083333'],
        ['30.997194', '52.533500'],
        ['30.936389', '52.958333'],
        ['30.798056', '53.488333'],
        ['30.758056', '53.645000'],
        ['30.618056', '54.170000'],
        ['30.583056', '54.301667'],
        ['30.538056', '54.460000'],
        ['30.433889', '54.851389'],
        ['30.359944', '55.127889'],
        ['30.428056', '55.501667'],
        ['30.489722', '55.800000'],
        ['30.556111', '56.129722'],
        ['30.606667', '56.379444'],
        ['30.704444', '56.793056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M857',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.439167', '49.412778'],
        ['21.762500', '50.616667'],
        ['21.880833', '51.042778'],
        ['21.910556', '51.148889'],
        ['21.984722', '51.416667'],
        ['22.040833', '51.610556'],
        ['22.098611', '51.809167'],
        ['22.200833', '52.155278'],
        ['22.295833', '52.425833'],
        ['22.418889', '52.771111'],
        ['22.595556', '53.256944'],
        ['22.789167', '53.776944'],
        ['22.900278', '54.069722'],
        ['23.059167', '54.307500'],
        ['23.208056', '54.869722'],
        ['23.410000', '55.438333'],
        ['23.755556', '56.371389'],
        ['23.843056', '56.591111'],
        ['23.965194', '56.920861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M859',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.045278', '41.903889'],
        ['35.413056', '42.151389'],
        ['34.798611', '42.238333'],
        ['34.393333', '42.293611'],
        ['33.892500', '42.359722'],
        ['33.417222', '42.420556'],
        ['33.131944', '42.456111'],
        ['32.048333', '42.584167'],
        ['32.017500', '42.587500'],
        ['30.596111', '42.738889'],
        ['16.422722', '46.683489'],
        ['16.036944', '46.546111'],
        ['15.841944', '46.476111'],
        ['15.694289', '46.422506'],
        ['15.336317', '46.291589'],
        ['13.826667', '45.708611'],
        ['13.619167', '45.625000'],
        ['13.522864', '45.585806'],
        ['13.419722', '45.544444'],
        ['13.279167', '45.487222'],
        ['12.861667', '45.314167'],
        ['12.789167', '45.283889'],
        ['12.281444', '45.071083'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M860',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.427778', '51.655833'],
        ['21.984722', '51.416667'],
        ['23.825556', '50.404167'],
        ['24.241389', '50.142222'],
        ['25.072222', '49.605278'],
        ['25.555000', '49.282222'],
        ['26.812778', '48.414722'],
        ['28.202778', '47.439167'],
        ['28.649167', '47.114444'],
        ['28.904500', '46.926028'],
        ['29.691944', '46.363333'],
        ['31.111667', '45.258333'],
        ['32.030000', '44.535000'],
        ['32.476389', '44.157500'],
        ['32.758333', '43.923333'],
        ['33.122222', '43.617222'],
        ['34.087778', '42.780556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M861',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.055944', '53.607639'],
        ['24.450278', '53.146667'],
        ['25.370833', '51.928889'],
        ['26.080833', '50.937222'],
        ['27.344167', '49.049444'],
        ['27.721667', '48.452222'],
        ['28.466111', '47.503889'],
        ['28.904500', '46.926028'],
        ['29.328056', '46.469167'],
        ['30.561667', '44.980000'],
        ['31.000000', '44.425000'],
        ['31.728333', '43.473333'],
        ['31.947778', '42.798889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M863',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.976667', '49.444722'],
        ['19.656111', '51.800000'],
        ['22.160556', '52.320556'],
        ['22.962500', '52.469167'],
        ['23.921944', '52.851389'],
        ['25.424722', '53.251944'],
        ['26.604444', '53.548333'],
        ['26.823056', '53.601389'],
        ['28.020639', '53.884444'],
        ['28.908056', '54.073056'],
        ['29.195000', '54.132778'],
        ['29.441389', '54.183333'],
        ['31.084722', '54.503333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M864',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.125278', '53.741389'],
        ['13.137689', '54.459656'],
        ['13.300278', '54.598611'],
        ['13.994722', '54.916667'],
        ['14.406667', '55.060000'],
        ['15.634722', '55.473333'],
        ['16.814722', '56.000000'],
        ['17.976389', '56.182500'],
        ['18.285556', '56.228889'],
        ['18.898333', '56.297778'],
        ['20.215278', '56.435278'],
        ['21.084750', '56.512778'],
        ['22.531944', '56.564167'],
        ['23.093333', '56.577500'],
        ['23.843056', '56.591111'],
        ['24.824722', '56.601944'],
        ['25.267222', '56.575556'],
        ['26.642222', '56.483333'],
        ['28.200000', '56.360000'],
        ['29.072500', '56.279444'],
        ['30.242500', '56.163056'],
        ['30.556111', '56.129722'],
        ['31.001111', '56.081389'],
        ['31.918056', '55.978056'],
        ['32.939167', '55.854167'],
        ['34.355556', '55.892222'],
        ['35.042500', '55.904167'],
        ['35.846667', '55.913889'],
        ['37.307222', '55.971667'],
        ['37.759722', '55.985278'],
        ['39.634167', '56.026111'],
        ['39.789722', '56.028611'],
        ['40.750278', '56.039722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M865',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.758692', '55.065578'],
        ['16.501667', '55.274444'],
        ['18.167222', '55.448611'],
        ['19.838333', '55.603611'],
        ['26.551111', '55.459167'],
        ['30.861389', '55.280000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M866',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.820833', '48.873333'],
        ['19.469444', '49.611667'],
        ['19.912778', '50.095556'],
        ['20.204444', '50.586667'],
        ['20.251111', '50.647222'],
        ['20.693889', '51.284444'],
        ['20.746667', '51.315833'],
        ['22.200833', '52.155278'],
        ['22.962500', '52.469167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M867',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.154547', '47.934167'],
        ['12.426111', '47.717500'],
        ['12.538625', '47.626286'],
        ['16.305833', '44.967500'],
        ['16.915833', '44.589444'],
        ['17.105278', '44.470278'],
        ['17.491389', '44.225000'],
        ['17.661389', '44.115833'],
        ['18.105556', '43.866944'],
        ['18.489167', '43.648611'],
        ['18.787222', '43.477222'],
        ['19.077778', '43.308056'],
        ['19.288611', '43.184444'],
        ['19.588611', '43.006944'],
        ['20.021944', '42.747500'],
        ['21.055556', '42.112500'],
        ['22.758333', '41.247222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M869',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.013611', '56.380556'],
        ['12.232222', '56.391667'],
        ['18.876667', '56.531667'],
        ['20.093056', '56.518611'],
        ['21.084750', '56.512778'],
        ['22.461944', '56.719722'],
        ['23.965194', '56.920861'],
        ['25.546667', '56.853889'],
        ['26.469444', '56.819167'],
        ['27.901667', '56.751667'],
        ['29.084722', '56.780278'],
        ['30.248611', '56.791389'],
        ['30.704444', '56.793056'],
        ['31.139444', '56.780000'],
        ['34.144722', '56.662222'],
        ['34.968333', '56.453333'],
        ['35.905833', '56.207778'],
        ['36.124444', '56.149167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M870',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.093333', '56.577500'],
        ['23.426389', '56.363056'],
        ['24.549167', '55.420278'],
        ['24.636806', '55.344528'],
        ['25.615333', '54.471472'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M872',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.217500', '43.817778'],
        ['12.348333', '43.793889'],
        ['13.368056', '43.272778'],
        ['13.692778', '42.808056'],
        ['13.896111', '42.655000'],
        ['14.184167', '42.435833'],
        ['15.184722', '41.875833'],
        ['15.837778', '41.500000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M874',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.734722', '54.941389'],
        ['24.688917', '54.800444'],
        ['25.735278', '54.673056'],
        ['29.808056', '54.568333'],
        ['31.084722', '54.503333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M92',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['27.468056', '56.826667']] },
  },
  {
    type: 'Feature',
    name: 'M977',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.505000', '43.803333'],
        ['29.766111', '44.161944'],
        ['23.744722', '46.760000'],
        ['23.561111', '47.346667'],
        ['23.343333', '48.026667'],
        ['23.185000', '49.078333'],
        ['23.058333', '49.888333'],
        ['23.045556', '50.242778'],
        ['23.023333', '50.861667'],
        ['23.003611', '51.386944'],
        ['22.978056', '52.066389'],
        ['22.962500', '52.469167'],
        ['22.944444', '52.921667'],
        ['22.929167', '53.294167'],
        ['22.900278', '54.069722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M983',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.241389', '50.142222'],
        ['24.688056', '51.901389'],
        ['24.841389', '52.551389'],
        ['24.989167', '53.262500'],
        ['25.195833', '54.213056'],
        ['25.293639', '54.636056'],
        ['25.221500', '55.471333'],
        ['25.158611', '56.175278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M984',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.281111', '46.802500'],
        ['16.621003', '48.784744'],
        ['17.528397', '49.322853'],
        ['18.572836', '49.847494'],
        ['18.941667', '50.027778'],
        ['20.162778', '50.606389'],
        ['20.251111', '50.647222'],
        ['21.371667', '51.215278'],
        ['23.404444', '52.188056'],
        ['23.466389', '52.224722'],
        ['24.144722', '52.591111'],
        ['26.722500', '53.894722'],
        ['27.188333', '54.196111'],
        ['27.794722', '54.578611'],
        ['28.926389', '55.265000'],
        ['29.489722', '55.706667'],
        ['30.242500', '56.163056'],
        ['30.606667', '56.379444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M985',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.597500', '46.706111'],
        ['17.087703', '48.067567'],
        ['17.182778', '48.090833'],
        ['17.299722', '48.119444'],
        ['17.544722', '48.178611'],
        ['18.050556', '48.290556'],
        ['18.571111', '48.686944'],
        ['18.820833', '48.873333'],
        ['19.215278', '49.153611'],
        ['19.331667', '49.235556'],
        ['19.646667', '49.455000'],
        ['19.812500', '49.760556'],
        ['19.900000', '49.940278'],
        ['19.961944', '50.066389'],
        ['20.218889', '50.583056'],
        ['20.251111', '50.647222'],
        ['20.360000', '51.387778'],
        ['20.738333', '51.811944'],
        ['21.401667', '52.533889'],
        ['22.027500', '53.191111'],
        ['22.900278', '54.069722'],
        ['23.059167', '54.307500'],
        ['24.630278', '56.368889'],
        ['24.824722', '56.601944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M986',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.471111', '43.586444'],
        ['13.921667', '44.014167'],
        ['14.300833', '44.368333'],
        ['14.472847', '44.527097'],
        ['14.653889', '44.703611'],
        ['14.981667', '45.020000'],
        ['15.133611', '45.165278'],
        ['15.312500', '45.278056'],
        ['15.572222', '45.441111'],
        ['16.306697', '45.895558'],
        ['16.675278', '46.090278'],
        ['16.962778', '46.240278'],
        ['22.319722', '48.340000'],
        ['22.827222', '48.457222'],
        ['23.188333', '48.536667'],
        ['23.638889', '48.648333'],
        ['24.691389', '48.884167'],
        ['25.501667', '49.120000'],
        ['26.000833', '49.261944'],
        ['26.451667', '49.388333'],
        ['27.850000', '49.761667'],
        ['28.962222', '50.041667'],
        ['29.570139', '50.186806'],
        ['30.451667', '50.401667'],
        ['31.796667', '50.748333'],
        ['32.508611', '51.517778'],
        ['33.310000', '52.350000'],
        ['33.873056', '52.988056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M987',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.564722', '41.233056'],
        ['23.774722', '41.401667'],
        ['24.850000', '43.700000'],
        ['25.333056', '43.977778'],
        ['25.446731', '44.042528'],
        ['26.330000', '44.536667'],
        ['26.651944', '44.713056'],
        ['27.901944', '45.191944'],
        ['28.322222', '45.348056'],
        ['28.728611', '45.532222'],
        ['29.104722', '45.700000'],
        ['30.196389', '46.173333'],
        ['30.669722', '46.426389'],
        ['31.350000', '46.776667'],
        ['31.878056', '47.037222'],
        ['32.833333', '47.766667'],
        ['33.212139', '48.051000'],
        ['33.573333', '48.258889'],
        ['34.277222', '48.646111'],
        ['34.900556', '49.023056'],
        ['35.448333', '49.388056'],
        ['36.290444', '49.928917'],
        ['36.828333', '50.316667'],
        ['37.733889', '50.791667'],
        ['38.884444', '51.371667'],
        ['39.626667', '51.732222'],
        ['39.951111', '51.813333'],
        ['40.086111', '51.846944'],
        ['40.355278', '51.913056'],
        ['40.899167', '52.043889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M990',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.814722', '56.000000'],
        ['17.701667', '55.931667'],
        ['19.091111', '55.810278'],
        ['20.082222', '56.146111'],
        ['21.084750', '56.512778'],
        ['22.379167', '56.902500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M991',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.375000', '48.823889'],
        ['24.691389', '48.884167'],
        ['25.598333', '48.913333'],
        ['26.686111', '48.948889'],
        ['27.841667', '48.970000'],
        ['28.849167', '48.966389'],
        ['31.433333', '48.448333'],
        ['32.357778', '48.241389'],
        ['32.888333', '48.118333'],
        ['33.578611', '47.920556'],
        ['35.790000', '47.246667'],
        ['37.421667', '46.658333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M992',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.511914', '50.209928'],
        ['17.747931', '50.209481'],
        ['18.103056', '50.208056'],
        ['18.537222', '50.319722'],
        ['19.150278', '50.474167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M994',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.961667', '52.914722'],
        ['14.525833', '52.631667'],
        ['15.832500', '52.816861'],
        ['16.627500', '53.165833'],
        ['16.898889', '53.282500'],
        ['17.403889', '53.496111'],
        ['19.145083', '54.201306'],
        ['19.654167', '54.447778'],
        ['21.438056', '55.235278'],
        ['21.671944', '55.355000'],
        ['23.426389', '56.363056'],
        ['23.843056', '56.591111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M995',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.650278', '50.970000'],
        ['35.871389', '50.789722'],
        ['35.764722', '50.533056'],
        ['35.698333', '50.355000'],
        ['36.208333', '49.368056'],
        ['36.523333', '48.880000'],
        ['37.225278', '47.748889'],
        ['37.421667', '46.658333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M996',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.566667', '56.871389'],
        ['20.093056', '56.518611'],
        ['20.215278', '56.435278'],
        ['20.747778', '56.067222'],
        ['22.035556', '55.676111'],
        ['22.771111', '55.443056'],
        ['24.247528', '54.952889'],
        ['24.688917', '54.800444'],
        ['25.615333', '54.471472'],
        ['25.886389', '54.329722'],
        ['26.722500', '53.894722'],
        ['27.688056', '53.506389'],
        ['28.676944', '53.101944'],
        ['29.423056', '52.786389'],
        ['30.287222', '52.410278'],
        ['31.015000', '52.083333'],
        ['34.216667', '50.828333'],
        ['35.618333', '50.231667'],
        ['36.290444', '49.928917'],
        ['36.830000', '49.675000'],
        ['37.953333', '49.136667'],
        ['38.766667', '48.730556'],
        ['39.375000', '48.418333'],
        ['39.943333', '48.195000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'M997',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.734722', '54.941389'],
        ['23.882222', '55.013889'],
        ['26.474167', '55.136667'],
        ['28.221389', '55.146389'],
        ['30.861389', '55.280000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N11',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.656389', '41.799167']] },
  },
  {
    type: 'Feature',
    name: 'N127',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.442222', '47.793889'],
        ['22.610278', '47.328889'],
        ['22.716389', '47.031389'],
        ['22.891667', '46.531667'],
        ['23.088056', '45.960278'],
        ['23.374722', '45.103889'],
        ['23.573889', '44.493333'],
        ['23.717222', '44.045000'],
        ['23.800000', '43.783333'],
        ['23.803889', '43.470833'],
        ['23.806111', '43.284722'],
        ['23.807778', '43.151111'],
        ['23.809444', '43.031667'],
        ['23.813889', '42.667222'],
        ['23.871111', '42.501667'],
        ['23.936944', '42.310000'],
        ['24.012500', '41.999444'],
        ['24.121667', '41.546389'],
        ['24.434167', '41.195278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N128',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.874694', '44.323889'],
        ['20.464444', '43.908333'],
        ['22.077778', '42.724722'],
        ['22.242778', '42.308333'],
        ['22.365556', '41.980833'],
        ['22.791389', '41.329444'],
        ['22.991489', '41.098714'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N130',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.530000', '41.670000'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N131',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.306697', '45.895558'],
        ['16.932500', '45.558611'],
        ['17.861667', '45.044722'],
        ['17.969461', '44.984128'],
        ['19.105000', '44.596389'],
        ['19.180000', '44.571667'],
        ['19.874694', '44.323889'],
        ['20.955556', '43.780556'],
        ['22.797500', '42.972778'],
        ['23.645556', '42.301944'],
        ['24.012500', '41.999444'],
        ['24.701111', '41.420833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N132',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.698611', '43.348611'],
        ['19.603889', '42.534167'],
        ['20.530000', '41.670000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N133',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.161389', '55.638611'],
        ['17.505556', '55.209444'],
        ['17.685556', '54.974444'],
        ['17.826667', '54.795278'],
        ['18.208056', '54.299722'],
        ['18.614861', '53.750583'],
        ['18.781389', '53.521111'],
        ['18.955000', '53.190833'],
        ['19.006389', '53.091944'],
        ['19.277222', '52.563056'],
        ['19.507222', '52.103056'],
        ['19.606389', '51.901667'],
        ['19.656111', '51.800000'],
        ['19.710000', '51.698333'],
        ['19.802500', '51.521944'],
        ['19.844167', '51.442500'],
        ['19.887778', '51.358611'],
        ['19.957500', '51.224167'],
        ['20.019167', '51.104167'],
        ['20.115833', '50.914722'],
        ['20.251111', '50.647222'],
        ['20.304444', '50.557778'],
        ['21.010278', '49.336389'],
        ['21.090278', '49.118056'],
        ['21.142500', '48.975000'],
        ['21.248167', '48.683083'],
        ['21.291389', '48.523056'],
        ['21.860000', '47.248333'],
        ['21.924139', '47.100361'],
        ['22.000000', '46.923889'],
        ['22.060556', '46.781667'],
        ['22.242222', '46.351944'],
        ['22.370000', '46.045278'],
        ['22.408075', '45.953222'],
        ['22.500833', '45.727778'],
        ['22.586769', '45.516794'],
        ['22.847500', '44.867222'],
        ['22.976944', '44.538889'],
        ['23.250000', '43.833333'],
        ['23.289167', '43.517500'],
        ['23.312778', '43.328333'],
        ['23.326667', '43.214167'],
        ['23.645556', '42.301944'],
        ['23.774722', '41.401667'],
        ['23.805000', '41.276389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N137',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['20.494722', '41.332778']] },
  },
  {
    type: 'Feature',
    name: 'N138',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.304722', '43.496581'],
        ['17.200556', '42.536944'],
        ['17.758611', '41.918611'],
        ['18.129167', '41.502778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N140',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.097778', '41.766944'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N141',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.718194', '41.416139'],
        ['20.494722', '41.332778'],
        ['22.758333', '41.247222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N142',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.603889', '42.534167'],
        ['19.588611', '43.006944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N145',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.841667', '46.253056'],
        ['27.584167', '47.406667'],
        ['28.730556', '48.125000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N146',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.530000', '41.670000'],
        ['19.850556', '42.479167'],
        ['19.638333', '43.239722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N15',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.573778', '56.168861'],
        ['12.011667', '56.928333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N156',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.759722', '55.985278'],
        ['37.687500', '55.650556'],
        ['37.500000', '55.106111'],
        ['37.372778', '54.725556'],
        ['37.331389', '54.600000'],
        ['37.055000', '53.573333'],
        ['37.109722', '52.389722'],
        ['37.133056', '51.926389'],
        ['37.137778', '51.826111'],
        ['37.148056', '51.616389'],
        ['37.473056', '51.161389'],
        ['37.681667', '50.866389'],
        ['37.733889', '50.791667'],
        ['37.987222', '50.426667'],
        ['38.226667', '50.075000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N164',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.575528', '42.944639'],
        ['20.464444', '43.908333'],
        ['20.812500', '44.326944'],
        ['21.169444', '44.748889'],
        ['21.409722', '44.988333'],
        ['22.408075', '45.953222'],
        ['22.891667', '46.531667'],
        ['23.031864', '46.741867'],
        ['23.561111', '47.346667'],
        ['24.209722', '47.908889'],
        ['27.489444', '49.951111'],
        ['28.692500', '50.456389'],
        ['29.280556', '50.606944'],
        ['29.666667', '50.680000'],
        ['30.831389', '51.094444'],
        ['32.386389', '52.070278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N165',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.379167', '56.902500'],
        ['24.824722', '56.601944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N166',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.403056', '42.183889'],
        ['39.501944', '42.631389'],
        ['39.601667', '43.078333'],
        ['39.710278', '43.558056'],
        ['39.771667', '43.946389'],
        ['39.840000', '44.369167'],
        ['39.928333', '44.912222'],
        ['40.015000', '45.433056'],
        ['40.244167', '45.763889'],
        ['40.465000', '46.349722'],
        ['40.448333', '47.124722'],
        ['40.376111', '47.805556'],
        ['40.558056', '48.451944'],
        ['40.693333', '48.917778'],
        ['40.705278', '49.376667'],
        ['40.713056', '49.642500'],
        ['40.501389', '50.066389'],
        ['40.088611', '50.868333'],
        ['39.962500', '51.107778'],
        ['39.626667', '51.732222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N168',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.428889', '55.051111'],
        ['30.836111', '54.916667'],
        ['31.616667', '55.008056'],
        ['32.611389', '55.116667'],
        ['34.150278', '55.267778'],
        ['34.784444', '55.324167'],
        ['35.114167', '55.351944'],
        ['35.575000', '55.389444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N169',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.503056', '56.466944'],
        ['31.798056', '56.120833'],
        ['31.918056', '55.978056'],
        ['32.182778', '55.651111'],
        ['33.046111', '55.843889'],
        ['34.338611', '55.994167'],
        ['35.261667', '56.093889'],
        ['35.557222', '56.003333'],
        ['35.846667', '55.913889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N171',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.489722', '55.706667']] },
  },
  {
    type: 'Feature',
    name: 'N180',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.446731', '44.042528'],
        ['26.435692', '45.002281'],
        ['28.166667', '46.781667'],
        ['29.233056', '47.886667'],
        ['29.946944', '48.726944'],
        ['30.422778', '49.270000'],
        ['30.692500', '49.557778'],
        ['30.921944', '49.818611'],
        ['31.796667', '50.748333'],
        ['32.982500', '51.653889'],
        ['33.898333', '52.321667'],
        ['34.653056', '52.853056'],
        ['35.421389', '53.374722'],
        ['37.331389', '54.600000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N181',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.924444', '41.233056'],
        ['24.121667', '41.546389'],
        ['24.220556', '43.095000'],
        ['24.239167', '43.688889'],
        ['24.753889', '43.978056'],
        ['25.090833', '44.164444'],
        ['25.223372', '44.237422'],
        ['25.708056', '44.500833'],
        ['26.561111', '45.496667'],
        ['27.858333', '47.104167'],
        ['28.730556', '48.125000'],
        ['29.305556', '48.880000'],
        ['29.758333', '49.456667'],
        ['29.898889', '49.633889'],
        ['30.977778', '50.946389'],
        ['32.386389', '52.070278'],
        ['32.673333', '52.275000'],
        ['33.873056', '52.988056'],
        ['35.337778', '53.954722'],
        ['35.682500', '54.180556'],
        ['36.383056', '54.583889'],
        ['36.648056', '54.733333'],
        ['36.791389', '55.528611'],
        ['36.835833', '55.740556'],
        ['37.051944', '56.079167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N182',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.898889', '53.948333'],
        ['23.871944', '54.432500'],
        ['23.812361', '55.464139'],
        ['23.755556', '56.371389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N183',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.091111', '55.810278'],
        ['21.353889', '55.444722'],
        ['22.752944', '55.193833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N188',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.813889', '42.667222'],
        ['23.362500', '42.920278'],
        ['22.989444', '43.126667'],
        ['22.363889', '43.486944'],
        ['21.331667', '44.183333'],
        ['21.169444', '44.748889'],
        ['21.308333', '45.240000'],
        ['20.708056', '46.163611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N190',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.375000', '48.823889'],
        ['24.500556', '48.323333'],
        ['25.958333', '47.951667'],
        ['27.469444', '47.493333'],
        ['28.649167', '47.114444'],
        ['29.943889', '46.648611'],
        ['30.669722', '46.426389'],
        ['31.533333', '46.316667'],
        ['32.908333', '46.125000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N191',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.495833', '46.807500'],
        ['32.941667', '47.083333'],
        ['31.846667', '47.628333'],
        ['30.975278', '48.043889'],
        ['28.849167', '48.966389'],
        ['27.846944', '49.390833'],
        ['26.358056', '49.998333'],
        ['26.037778', '50.121389'],
        ['24.100000', '50.833333'],
        ['23.095833', '51.219167'],
        ['22.443056', '51.468056'],
        ['22.124444', '51.585556'],
        ['22.040833', '51.610556'],
        ['21.685556', '51.745000'],
        ['19.820000', '52.695278'],
        ['19.491944', '52.959167'],
        ['19.177222', '53.211111'],
        ['18.781389', '53.521111'],
        ['19.145083', '54.201306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N195',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.958333', '47.951667'],
        ['24.691389', '48.884167'],
        ['23.655000', '49.528333'],
        ['23.058333', '49.888333'],
        ['22.775000', '50.206944'],
        ['22.737778', '50.248056'],
        ['22.520556', '50.488889'],
        ['22.211667', '50.825278'],
        ['22.171111', '50.868889'],
        ['21.910556', '51.148889'],
        ['21.862222', '51.200278'],
        ['21.688056', '51.383889'],
        ['21.580278', '51.496944'],
        ['21.427778', '51.655833'],
        ['21.363333', '51.698889'],
        ['19.820000', '52.695278'],
        ['19.006389', '53.091944'],
        ['17.976389', '53.576111'],
        ['17.015278', '54.010556'],
        ['15.633889', '54.606389'],
        ['14.944167', '54.823056'],
        ['14.441111', '54.949444'],
        ['14.094167', '55.411944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N197',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['16.284111', '56.685333']] },
  },
  {
    type: 'Feature',
    name: 'N319',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.145833', '42.490278']] },
  },
  {
    type: 'Feature',
    name: 'N33',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.911028', '53.178592'],
        ['13.983056', '53.569722'],
        ['14.094167', '54.158889'],
        ['14.158611', '54.833056'],
        ['14.166667', '54.916667'],
        ['14.094167', '55.411944'],
        ['14.215000', '56.187500'],
        ['14.307778', '56.764444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N37',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.375944', '41.193333']] },
  },
  {
    type: 'Feature',
    name: 'N5',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.566667', '56.871389'],
        ['20.082222', '56.146111'],
        ['20.553333', '55.400000'],
        ['20.698333', '54.368333'],
        ['21.195000', '53.089722'],
        ['21.401667', '52.533889'],
        ['21.685556', '51.745000'],
        ['21.910556', '51.148889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N50',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.945833', '56.911111'],
        ['35.410833', '56.576667'],
        ['35.905833', '56.207778'],
        ['36.057778', '56.092778'],
        ['36.791389', '55.528611'],
        ['37.500000', '55.106111'],
        ['38.131389', '54.718889'],
        ['38.898056', '54.233333'],
        ['39.212778', '53.873056'],
        ['39.605556', '53.412778'],
        ['40.477500', '52.498889'],
        ['40.624167', '52.341944'],
        ['40.899167', '52.043889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N503',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.061667', '45.278056'],
        ['12.281444', '45.071083'],
        ['12.172500', '44.905000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N600',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142778', '42.170556'],
        ['29.143333', '43.096111'],
        ['29.143333', '43.733333'],
        ['29.110833', '45.377500'],
        ['29.104722', '45.700000'],
        ['29.088889', '46.520278'],
        ['29.066667', '47.574444'],
        ['29.142222', '47.993056'],
        ['29.305556', '48.880000'],
        ['29.396944', '49.663333'],
        ['29.414722', '50.583889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N604',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['29.146667', '41.461389'],
        ['29.142778', '42.170556'],
        ['29.715833', '42.591667'],
        ['30.640000', '43.253333'],
        ['33.495833', '46.807500'],
        ['34.209167', '47.511111'],
        ['34.644167', '47.928056'],
        ['35.103056', '48.359833'],
        ['35.921944', '48.996111'],
        ['37.640000', '50.301667'],
        ['37.987222', '50.426667'],
        ['39.662778', '51.007778'],
        ['39.820000', '51.060556'],
        ['39.962500', '51.107778'],
        ['40.192222', '51.183333'],
        ['40.600833', '51.316944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N605',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.797500', '42.972778'],
        ['24.220556', '43.095000'],
        ['24.980278', '43.127222'],
        ['25.165000', '43.134167'],
        ['25.387778', '43.142222'],
        ['25.711778', '43.153222'],
        ['26.151944', '43.172500'],
        ['26.421389', '43.180833'],
        ['26.645833', '43.187222'],
        ['26.940278', '43.198889'],
        ['27.116667', '43.205556'],
        ['27.817167', '43.231028'],
        ['30.640000', '43.253333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N606',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.281389', '45.900000'],
        ['13.004167', '45.462222'],
        ['13.126944', '45.386944'],
        ['13.247500', '45.311389'],
        ['13.918119', '44.892367'],
        ['14.472847', '44.527097'],
        ['14.769167', '44.385000'],
        ['15.364228', '44.095322'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N609',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.188056', '52.431389'],
        ['36.164722', '52.574722'],
        ['35.421389', '53.374722'],
        ['35.337778', '53.954722'],
        ['35.221111', '54.737500'],
        ['34.668056', '55.013056'],
        ['34.281944', '55.203611'],
        ['34.150278', '55.267778'],
        ['33.766389', '55.453056'],
        ['33.643611', '55.512500'],
        ['33.374722', '55.645000'],
        ['33.240556', '55.709722'],
        ['32.939167', '55.854167'],
        ['31.503056', '56.466944'],
        ['31.109722', '56.628611'],
        ['30.704444', '56.793056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N613',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['29.557778', '42.585833'],
        ['30.640000', '43.253333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N616',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.161944', '56.422500'],
        ['22.347222', '56.130278'],
        ['22.771111', '55.443056'],
        ['23.165833', '54.925833'],
        ['23.208056', '54.869722'],
        ['23.423611', '54.586944'],
        ['23.898889', '53.948333'],
        ['24.055944', '53.607639'],
        ['24.268056', '53.069722'],
        ['24.363611', '52.809167'],
        ['24.454722', '52.556944'],
        ['24.688056', '51.901389'],
        ['25.003889', '50.985278'],
        ['25.555000', '49.282222'],
        ['25.958333', '47.951667'],
        ['26.825556', '46.510917'],
        ['27.004444', '46.280556'],
        ['27.053333', '46.217222'],
        ['27.513889', '45.612500'],
        ['28.097778', '44.823889'],
        ['28.478333', '44.285556'],
        ['28.724722', '43.852222'],
        ['28.808333', '43.700000'],
        ['28.791944', '43.059167'],
        ['28.766111', '42.082222'],
        ['28.774444', '41.871111'],
        ['28.779281', '41.746164'],
        ['28.789722', '41.478333'],
        ['28.799444', '41.228056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N617',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.310000', '52.350000'],
        ['32.982500', '51.653889'],
        ['32.486944', '50.557500'],
        ['31.996667', '49.416667'],
        ['31.830556', '49.099444'],
        ['31.271667', '48.000000'],
        ['31.050000', '47.431944'],
        ['30.875278', '46.975278'],
        ['30.669722', '46.426389'],
        ['30.530000', '46.015000'],
        ['30.458611', '45.774722'],
        ['30.050000', '44.976389'],
        ['29.635556', '43.718333'],
        ['29.142778', '42.170556'],
        ['29.146667', '41.461389'],
        ['29.142889', '41.126972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N618',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.309167', '41.577222'],
        ['27.216111', '42.065556'],
        ['26.492222', '42.542778'],
        ['24.220556', '43.095000'],
        ['22.417778', '43.685833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N619',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.838333', '55.603611'],
        ['20.495556', '56.090278'],
        ['21.084750', '56.512778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N623',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.516389', '56.280278'],
        ['26.474167', '55.136667'],
        ['27.441389', '54.551389'],
        ['27.863056', '54.289722'],
        ['28.506389', '53.881389'],
        ['28.914722', '53.616389'],
        ['29.170278', '53.437222'],
        ['29.489444', '53.210556'],
        ['29.583333', '53.143333'],
        ['30.321389', '52.604722'],
        ['31.015000', '52.083333'],
        ['34.114444', '50.642778'],
        ['35.545278', '49.917222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N624',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['16.814722', '56.000000']] },
  },
  {
    type: 'Feature',
    name: 'N644',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.321389', '41.125278'],
        ['26.674722', '41.129722'],
        ['28.384167', '41.136389'],
        ['29.142889', '41.126972'],
        ['30.674167', '41.591111'],
        ['31.135833', '41.678889'],
        ['31.738889', '41.806667'],
        ['32.238333', '41.909444'],
        ['33.077778', '42.071389'],
        ['33.814167', '42.189167'],
        ['34.432778', '42.111389'],
        ['34.858333', '42.052222'],
        ['35.076806', '42.022306'],
        ['37.045278', '41.903889'],
        ['40.656389', '41.799167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N7',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.869167', '41.157222']] },
  },
  {
    type: 'Feature',
    name: 'N72',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.142222', '53.808056'],
        ['34.735278', '54.365833'],
        ['35.682500', '54.180556'],
        ['36.097500', '54.113889'],
        ['36.716389', '54.011389'],
        ['37.627500', '53.878611'],
        ['38.496111', '53.744722'],
        ['38.931111', '52.389167'],
        ['39.106667', '52.225556'],
        ['39.219167', '52.120000'],
        ['39.626667', '51.732222'],
        ['40.600833', '51.316944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N731',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.605833', '42.096944'],
        ['23.806111', '43.284722'],
        ['24.239167', '43.688889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N732',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.100000', '41.458333'],
        ['19.171667', '41.883056'],
        ['19.254611', '42.386194'],
        ['19.575528', '42.944639'],
        ['19.588611', '43.006944'],
        ['19.638333', '43.239722'],
        ['19.731389', '43.670833'],
        ['19.809167', '44.026944'],
        ['19.874694', '44.323889'],
        ['20.311167', '44.819361'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N737',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.463056', '44.416389'],
        ['13.180475', '43.957308'],
        ['13.018611', '43.771111'],
        ['12.719722', '43.422778'],
        ['12.269167', '42.885556'],
        ['12.166667', '42.761389'],
        ['12.048611', '42.618333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N739',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.928889', '45.931667'],
        ['19.444167', '45.526667'],
        ['19.787222', '45.252500'],
        ['20.311167', '44.819361'],
        ['21.331667', '44.183333'],
        ['22.095833', '43.560556'],
        ['22.797500', '42.972778'],
        ['23.389389', '42.698111'],
        ['23.656111', '42.593889'],
        ['23.704444', '42.575000'],
        ['23.871111', '42.501667'],
        ['24.313333', '42.305000'],
        ['24.876306', '42.053417'],
        ['25.440000', '41.995833'],
        ['26.568333', '41.871667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N743',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.914722', '42.708889'],
        ['34.393333', '42.293611'],
        ['33.814167', '42.189167'],
        ['32.583611', '42.333611'],
        ['30.596111', '42.738889'],
        ['28.808333', '43.700000'],
        ['28.736389', '44.992500'],
        ['28.723056', '45.225278'],
        ['28.728611', '45.532222'],
        ['28.734167', '45.821944'],
        ['28.733611', '46.272500'],
        ['28.733056', '46.594167'],
        ['28.730556', '48.125000'],
        ['28.849167', '48.966389'],
        ['28.774722', '49.686111'],
        ['28.692500', '50.456389'],
        ['28.743333', '51.459722'],
        ['28.781389', '52.181389'],
        ['28.676944', '53.101944'],
        ['28.648056', '53.349722'],
        ['28.713056', '53.856389'],
        ['28.806111', '54.485833'],
        ['28.859167', '54.833333'],
        ['28.926389', '55.265000'],
        ['29.062778', '56.030833'],
        ['29.072500', '56.279444'],
        ['29.084722', '56.780278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N744',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.572836', '49.847494'],
        ['19.911111', '51.013056'],
        ['20.019167', '51.104167'],
        ['20.360000', '51.387778'],
        ['20.503611', '51.810833'],
        ['20.657167', '52.259250'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N746',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.681944', '52.685833'],
        ['13.170833', '53.029722'],
        ['13.983056', '53.569722'],
        ['14.275556', '53.759444'],
        ['15.007778', '54.080000'],
        ['15.241944', '54.388056'],
        ['15.655000', '54.916667'],
        ['16.814722', '56.000000'],
        ['17.228611', '56.337778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N748',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.133611', '42.168056'],
        ['14.331389', '42.442778'],
        ['14.506111', '42.766389'],
        ['14.797778', '43.311667'],
        ['14.966667', '43.547778'],
        ['15.168108', '43.937861'],
        ['15.364228', '44.095322'],
        ['15.667778', '44.390000'],
        ['15.969444', '44.678889'],
        ['16.305000', '44.996667'],
        ['16.554444', '45.221667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N77',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.983333', '42.990000']] },
  },
  {
    type: 'Feature',
    name: 'N82',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.651667', '51.841111'],
        ['40.355278', '51.913056'],
        ['40.096111', '51.975000'],
        ['39.530833', '52.107778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N850',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.815278', '56.758389'],
        ['12.790000', '55.989444'],
        ['12.672500', '55.898611'],
        ['12.516389', '55.777222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N851',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.481667', '55.223056'],
        ['12.628611', '55.373611'],
        ['12.798056', '55.552778'],
        ['13.040556', '55.806111'],
        ['13.453833', '56.126444'],
        ['13.733111', '56.339139'],
        ['14.033333', '56.564444'],
        ['14.307778', '56.764444'],
        ['14.519722', '56.920278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N858',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.042778', '51.640000'],
        ['12.457222', '51.765833'],
        ['12.970733', '51.919164'],
        ['13.682500', '52.126111'],
        ['14.103056', '52.245556'],
        ['14.585556', '52.379722'],
        ['15.832500', '52.816861'],
        ['17.035278', '53.116111'],
        ['18.149722', '53.378889'],
        ['18.781389', '53.521111'],
        ['19.761944', '53.717500'],
        ['20.500000', '53.858611'],
        ['21.253056', '53.997222'],
        ['22.197778', '54.163611'],
        ['23.059167', '54.307500'],
        ['23.871944', '54.432500'],
        ['24.219972', '54.484000'],
        ['25.778611', '54.831111'],
        ['27.428889', '55.051111'],
        ['28.221389', '55.146389'],
        ['29.176389', '55.141667'],
        ['29.359167', '55.140278'],
        ['30.359944', '55.127889'],
        ['30.988056', '55.097778'],
        ['33.643611', '55.512500'],
        ['35.081667', '55.605278'],
        ['35.550278', '55.632222'],
        ['36.791389', '55.528611'],
        ['37.687500', '55.650556'],
        ['39.634167', '56.026111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N869',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.850833', '56.963611'],
        ['36.572500', '56.890833'],
        ['36.301667', '56.818889'],
        ['36.001667', '56.738333'],
        ['35.790000', '56.680833'],
        ['35.655278', '56.644444'],
        ['35.410833', '56.576667'],
        ['34.968333', '56.453333'],
        ['34.499167', '56.319167'],
        ['32.939167', '55.854167'],
        ['30.861389', '55.280000'],
        ['29.481389', '54.675000'],
        ['28.806111', '54.485833'],
        ['27.338056', '54.103056'],
        ['26.722500', '53.894722'],
        ['25.001389', '53.469722'],
        ['23.894167', '53.178056'],
        ['22.944444', '52.921667'],
        ['22.418889', '52.771111'],
        ['21.438333', '52.490833'],
        ['20.657167', '52.259250'],
        ['20.410556', '52.147778'],
        ['19.934972', '52.043833'],
        ['19.575000', '51.965556'],
        ['19.125000', '51.865833'],
        ['18.863889', '51.808611'],
        ['17.883333', '51.587500'],
        ['17.385556', '51.471389'],
        ['15.543889', '51.017778'],
        ['15.293333', '50.953056'],
        ['14.860456', '50.864450'],
        ['14.428853', '50.766814'],
        ['13.569061', '50.566306'],
        ['13.065578', '50.445139'],
        ['12.405739', '50.065147'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N871',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.200556', '47.938611'],
        ['12.396944', '48.097222'],
        ['12.565506', '48.231908'],
        ['12.749781', '48.397050'],
        ['12.947778', '48.538611'],
        ['13.509722', '48.898333'],
        ['15.367089', '50.795275'],
        ['16.067222', '50.973056'],
        ['16.934444', '51.185833'],
        ['17.555833', '51.333056'],
        ['18.057500', '51.448889'],
        ['18.593889', '51.569722'],
        ['19.081389', '51.676944'],
        ['19.656111', '51.800000'],
        ['20.410556', '52.147778'],
        ['20.657167', '52.259250'],
        ['21.021667', '52.566944'],
        ['21.738889', '53.156667'],
        ['22.900278', '54.069722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N872',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.225000', '56.005833'],
        ['12.454444', '56.113333'],
        ['12.498333', '56.133889'],
        ['12.573778', '56.168861'],
        ['13.202806', '56.641250'],
        ['13.321833', '56.728972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N9',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.958889', '55.584444'],
        ['19.091111', '55.810278'],
        ['20.436389', '55.780833'],
        ['21.226944', '55.756667'],
        ['26.551111', '55.459167'],
        ['30.412222', '54.055556'],
        ['32.464722', '53.216667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N978',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.105000', '44.596389'],
        ['19.258056', '44.643056'],
        ['21.308333', '45.240000'],
        ['22.500833', '45.727778'],
        ['23.088056', '45.960278'],
        ['25.592222', '46.486667'],
        ['27.858333', '47.104167'],
        ['29.188889', '47.469722'],
        ['31.271667', '48.000000'],
        ['32.673056', '48.357778'],
        ['32.984444', '48.471389'],
        ['34.900556', '49.023056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N982',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.055833', '42.395833'],
        ['13.122778', '42.165833'],
        ['13.135792', '41.994758'],
        ['13.222222', '41.821667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N983',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.758692', '55.065578'],
        ['15.097222', '54.890000'],
        ['15.633889', '54.606389'],
        ['17.003611', '53.706111'],
        ['18.574722', '52.605000'],
        ['18.945833', '52.333333'],
        ['19.656111', '51.800000'],
        ['20.229167', '51.495556'],
        ['21.364444', '50.872222'],
        ['23.058333', '49.888333'],
        ['25.391111', '49.802222'],
        ['25.700556', '49.800000'],
        ['27.850000', '49.761667'],
        ['29.933056', '50.074722'],
        ['31.830278', '50.018333'],
        ['33.768056', '49.824444'],
        ['35.191944', '49.659722'],
        ['35.488333', '49.623056'],
        ['36.290444', '49.928917'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'N994',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.615333', '54.471472'],
        ['25.293639', '54.636056'],
        ['24.977806', '55.479611'],
        ['24.904444', '55.672500'],
        ['24.630278', '56.368889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P10',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.543333', '42.905278'],
        ['17.661667', '43.041667'],
        ['17.855833', '43.261111'],
        ['18.150278', '43.596944'],
        ['18.449828', '43.933864'],
        ['18.727500', '44.999167'],
        ['18.641944', '45.270556'],
        ['18.560050', '45.528425'],
        ['18.413333', '45.746111'],
        ['17.662500', '47.787222'],
        ['17.544722', '48.178611'],
        ['17.541111', '48.617500'],
        ['17.537503', '48.821394'],
        ['17.528397', '49.322853'],
        ['16.678858', '49.795844'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P11',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.995556', '45.262778'],
        ['13.918119', '44.892367'],
        ['14.416547', '44.902881'],
        ['14.972500', '44.920278'],
        ['15.270278', '44.932222'],
        ['15.743333', '44.942222'],
        ['16.804167', '44.958056'],
        ['17.059444', '44.964722'],
        ['17.969461', '44.984128'],
        ['18.727500', '44.999167'],
        ['19.077500', '45.126667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P12',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.470000', '53.748889'],
        ['12.970278', '54.123056'],
        ['13.496111', '54.506111'],
        ['13.768611', '54.658333'],
        ['14.158611', '54.833056'],
        ['14.344167', '54.916667'],
        ['14.758692', '55.065578'],
        ['15.634722', '55.473333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P125',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.189722', '45.157778'],
        ['12.789167', '45.283889'],
        ['12.861667', '45.314167'],
        ['13.004167', '45.462222'],
        ['13.176944', '45.640278'],
        ['13.360361', '45.828806'],
        ['13.411944', '46.006111'],
        ['13.472778', '46.218333'],
        ['13.532222', '46.416111'],
        ['13.569311', '46.541256'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P127',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.494722', '41.332778'],
        ['22.097778', '41.766944'],
        ['22.883333', '41.966667'],
        ['24.876306', '42.053417'],
        ['25.440000', '41.995833'],
        ['26.766389', '41.971111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P129',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.554167', '42.410556'],
        ['18.924167', '42.776417'],
        ['19.288611', '43.184444'],
        ['19.443333', '43.355556'],
        ['19.731389', '43.670833'],
        ['20.192222', '44.140556'],
        ['20.776667', '44.722778'],
        ['21.308333', '45.240000'],
        ['22.408075', '45.953222'],
        ['23.744722', '46.760000'],
        ['25.047500', '47.735556'],
        ['25.488056', '48.073889'],
        ['27.845000', '49.150000'],
        ['29.075278', '49.675278'],
        ['29.933056', '50.074722'],
        ['31.796667', '50.748333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P131',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.348333', '43.793889']] },
  },
  {
    type: 'Feature',
    name: 'P133',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.996389', '46.315278'],
        ['22.242222', '46.351944'],
        ['22.891667', '46.531667'],
        ['23.744722', '46.760000'],
        ['24.395636', '47.001044'],
        ['25.139167', '47.269167'],
        ['25.876444', '47.514750'],
        ['26.360833', '47.672333'],
        ['26.826194', '47.507472'],
        ['27.302778', '47.335583'],
        ['27.353056', '47.317222'],
        ['27.579694', '47.234278'],
        ['27.851667', '47.168333'],
        ['28.904500', '46.926028'],
        ['29.900000', '46.544722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P139',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.799722', '49.303611'],
        ['21.371667', '51.215278'],
        ['21.785556', '51.690000'],
        ['21.892222', '51.810556'],
        ['22.200833', '52.155278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P141',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.420278', '56.894722'],
        ['21.084750', '56.512778'],
        ['21.608056', '56.325000'],
        ['25.735278', '54.673056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P142',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.640000', '50.301667'],
        ['37.733889', '50.791667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P148',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.148056', '51.616389'],
        ['37.308889', '51.541944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P150',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.727222', '52.130556'],
        ['17.462778', '52.202778'],
        ['17.774194', '52.231694'],
        ['18.000000', '52.252222'],
        ['18.657500', '52.309444'],
        ['18.945833', '52.333333'],
        ['19.227500', '52.348056'],
        ['19.774722', '52.374389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P151',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.416547', '44.902881'],
        ['14.566961', '45.224122'],
        ['14.739067', '45.523439'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P156',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.161944', '56.422500'],
        ['24.548056', '53.974444'],
        ['25.001389', '53.469722'],
        ['26.130000', '52.153611'],
        ['26.395833', '51.846944'],
        ['27.348889', '50.706944'],
        ['28.228889', '49.704167'],
        ['28.620917', '49.239889'],
        ['28.849167', '48.966389'],
        ['29.687778', '48.064722'],
        ['30.431111', '47.157778'],
        ['30.669722', '46.426389'],
        ['30.863056', '45.927778'],
        ['30.937222', '45.729722'],
        ['31.111667', '45.258333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P159',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.708056', '44.500833'],
        ['25.754167', '44.905000'],
        ['25.841667', '46.253056'],
        ['25.958333', '47.951667'],
        ['23.825556', '50.404167'],
        ['20.698333', '54.368333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P164',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.901944', '56.820278']] },
  },
  {
    type: 'Feature',
    name: 'P171',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.441389', '54.551389'],
        ['26.978056', '55.812778'],
        ['26.642222', '56.483333'],
        ['26.543333', '56.676389'],
        ['26.469444', '56.819167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P182',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.937778', '48.587500'],
        ['17.239167', '48.718611'],
        ['17.386111', '48.715833'],
        ['17.554444', '48.712500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P185',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.898889', '53.948333'],
        ['23.835278', '54.290000'],
        ['23.610306', '55.451222'],
        ['23.426389', '56.363056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P192',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.331667', '44.183333'],
        ['20.227222', '45.421944'],
        ['19.696667', '46.184444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P193',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.556389', '43.333611'],
        ['29.143333', '43.733333'],
        ['28.478333', '44.285556'],
        ['26.561111', '45.496667'],
        ['22.442222', '47.793889'],
        ['21.291389', '48.523056'],
        ['21.091111', '48.667500'],
        ['20.175278', '49.313611'],
        ['18.300833', '50.564722'],
        ['17.114444', '51.308056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P20',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.397222', '42.159444'],
        ['22.516667', '41.129167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P203',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.343889', '52.332500'],
        ['13.732500', '52.269722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P26',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.594444', '48.133333'],
        ['23.188333', '48.536667'],
        ['23.655000', '49.528333'],
        ['23.951389', '49.811944'],
        ['24.241389', '50.142222'],
        ['24.795000', '50.758333'],
        ['25.003889', '50.985278'],
        ['25.886389', '51.919722'],
        ['26.130000', '52.153611'],
        ['27.238056', '52.689722'],
        ['28.130278', '53.111944'],
        ['28.648056', '53.349722'],
        ['28.914722', '53.616389'],
        ['29.058333', '53.883056'],
        ['29.195000', '54.132778'],
        ['29.371111', '54.335278'],
        ['29.714444', '54.723333'],
        ['30.428056', '55.501667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P27',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.528397', '49.322853'],
        ['18.165717', '49.246008'],
        ['18.510444', '49.202861'],
        ['18.940092', '49.173297'],
        ['19.215278', '49.153611'],
        ['19.620833', '49.101111'],
        ['20.350111', '49.064667'],
        ['20.836111', '49.010278'],
        ['21.142500', '48.975000'],
        ['22.375000', '48.823889'],
        ['23.044444', '48.940833'],
        ['24.011667', '49.264722'],
        ['25.072222', '49.605278'],
        ['25.700556', '49.800000'],
        ['26.358056', '49.998333'],
        ['27.043889', '50.181944'],
        ['27.816667', '50.316667'],
        ['28.692500', '50.456389'],
        ['29.280556', '50.606944'],
        ['29.666667', '50.680000'],
        ['30.977778', '50.946389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P28',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.918119', '44.892367'],
        ['13.648975', '45.210183'],
        ['13.626150', '45.390433'],
        ['13.614917', '45.477472'],
        ['13.522864', '45.585806'],
        ['13.514722', '45.632222'],
        ['13.480278', '45.829806'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P29',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.992778', '41.453333'],
        ['33.046667', '41.846944'],
        ['33.131944', '42.456111'],
        ['33.181111', '42.798889'],
        ['34.745833', '47.351667'],
        ['35.103056', '48.359833'],
        ['35.448333', '49.388056'],
        ['35.545278', '49.917222'],
        ['35.698333', '50.355000'],
        ['35.662222', '50.818611'],
        ['35.650278', '50.970000'],
        ['35.635556', '51.157500'],
        ['35.578056', '51.865278'],
        ['35.508056', '52.698056'],
        ['35.421389', '53.374722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P30',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.103056', '48.359833'],
        ['35.663333', '49.114444'],
        ['36.290444', '49.928917'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P31',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.480833', '48.438611'],
        ['12.706111', '48.601389'],
        ['12.887500', '48.732222'],
        ['13.176389', '49.122500'],
        ['13.265586', '49.241258'],
        ['13.344825', '49.570333'],
        ['13.628831', '50.715467'],
        ['13.811111', '50.931111'],
        ['14.116117', '51.384406'],
        ['14.252500', '51.513333'],
        ['14.463889', '51.744167'],
        ['14.755556', '52.058333'],
        ['15.671111', '53.032222'],
        ['16.543056', '53.915556'],
        ['17.406667', '54.387222'],
        ['18.448056', '55.258611'],
        ['19.091111', '55.810278'],
        ['19.573333', '56.212778'],
        ['19.907222', '56.485278'],
        ['20.420278', '56.894722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P36',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.516667', '41.129167'],
        ['22.392222', '41.320000'],
        ['22.097778', '41.766944'],
        ['22.242778', '42.308333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P41',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.815833', '48.021111'],
        ['18.324722', '48.333333'],
        ['17.537503', '48.821394'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P45',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.966667', '53.953056'],
        ['14.212778', '53.893889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P46',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.796667', '53.762222'],
        ['14.212778', '53.893889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P567',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.669722', '46.426389'],
        ['32.387222', '45.881944'],
        ['39.983333', '42.990000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P605',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.730278', '55.015278'],
        ['12.788056', '55.041944'],
        ['13.036861', '55.162722'],
        ['13.557528', '55.411306'],
        ['13.962833', '55.776778'],
        ['14.215000', '56.187500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P606',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['16.284111', '56.685333']] },
  },
  {
    type: 'Feature',
    name: 'P607',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['34.144722', '56.662222']] },
  },
  {
    type: 'Feature',
    name: 'P65',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['37.307222', '55.971667']] },
  },
  {
    type: 'Feature',
    name: 'P66',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.004167', '45.462222']] },
  },
  {
    type: 'Feature',
    name: 'P727',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.357778', '41.286389'],
        ['28.001111', '41.982222'],
        ['27.803611', '42.225278'],
        ['27.537972', '42.587167'],
        ['26.826667', '44.082222'],
        ['26.772883', '44.277917'],
        ['26.651944', '44.713056'],
        ['26.561111', '45.496667'],
        ['25.958333', '47.951667'],
        ['25.981667', '48.260000'],
        ['25.980000', '48.758333'],
        ['26.000833', '49.261944'],
        ['26.037778', '50.121389'],
        ['26.080833', '50.937222'],
        ['26.121389', '51.893056'],
        ['26.130000', '52.153611'],
        ['26.234167', '52.462222'],
        ['26.403056', '52.954722'],
        ['26.514722', '53.274722'],
        ['26.604444', '53.548333'],
        ['26.667778', '53.731111'],
        ['26.722500', '53.894722'],
        ['26.799722', '54.434722'],
        ['26.978056', '55.812778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P730',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.379211', '55.001500']] },
  },
  {
    type: 'Feature',
    name: 'P733',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.614503', '49.567917'],
        ['13.241222', '49.909336'],
        ['14.905103', '50.685236'],
        ['15.293333', '50.953056'],
        ['16.128611', '51.630556'],
        ['16.472778', '51.920000'],
        ['16.727222', '52.130556'],
        ['17.463333', '52.787778'],
        ['18.149722', '53.378889'],
        ['18.434444', '53.594444'],
        ['18.614861', '53.750583'],
        ['19.145083', '54.201306'],
        ['19.355556', '54.637778'],
        ['19.838333', '55.603611'],
        ['20.082222', '56.146111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P735',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.480278', '45.829806'],
        ['13.589722', '45.851111'],
        ['15.702931', '45.972669'],
        ['16.306697', '45.895558'],
        ['17.146747', '45.747800'],
        ['18.097500', '45.602222'],
        ['18.560050', '45.528425'],
        ['19.012778', '45.400556'],
        ['19.401111', '45.410000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P738',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.077778', '42.724722'],
        ['21.683889', '42.958056'],
        ['20.891111', '43.418333'],
        ['19.556667', '43.982222'],
        ['19.180278', '44.136667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P739',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['16.814722', '56.000000']] },
  },
  {
    type: 'Feature',
    name: 'P740',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.446731', '44.042528'],
        ['25.708056', '44.500833'],
        ['26.435692', '45.002281'],
        ['28.123889', '46.192222'],
        ['29.611111', '47.012778'],
        ['31.598333', '47.955000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P746',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.251111', '50.647222'],
        ['21.268333', '50.074722'],
        ['21.841111', '49.741944'],
        ['22.738056', '49.205556'],
        ['22.989167', '49.051944'],
        ['25.047500', '47.735556'],
        ['26.825556', '46.510917'],
        ['27.927778', '45.416389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P748',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.576389', '41.358056'],
        ['17.011111', '41.728611'],
        ['17.248333', '41.928333'],
        ['17.935556', '42.348611'],
        ['18.277442', '42.553844'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P749',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.852500', '49.826111'],
        ['31.392222', '50.068333'],
        ['30.900972', '50.285694'],
        ['30.261667', '51.476389'],
        ['29.965000', '51.866389'],
        ['30.048056', '52.608056'],
        ['30.133056', '53.349444'],
        ['30.149722', '53.491667'],
        ['30.203056', '53.938333'],
        ['30.230000', '54.161111'],
        ['30.262778', '54.426389'],
        ['30.359944', '55.127889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P851',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.832500', '52.816861'],
        ['17.036111', '52.697222'],
        ['18.000000', '52.592222'],
        ['19.171667', '52.451667'],
        ['19.774722', '52.374389'],
        ['20.657167', '52.259250'],
        ['21.999194', '52.159167'],
        ['22.200833', '52.155278'],
        ['23.208889', '52.227778'],
        ['23.466389', '52.224722'],
        ['24.093056', '52.213056'],
        ['25.048056', '52.189722'],
        ['26.130000', '52.153611'],
        ['26.856389', '52.108056'],
        ['29.049722', '51.946389'],
        ['29.965000', '51.866389'],
        ['30.631667', '51.716389'],
        ['33.955556', '50.345278'],
        ['36.208333', '49.368056'],
        ['36.833333', '49.108333'],
        ['37.374722', '48.876944'],
        ['37.842778', '48.673056'],
        ['38.940556', '48.181944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P859',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.448611', '41.337778'],
        ['21.397222', '42.159444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P861',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.265586', '49.241258'],
        ['13.561542', '49.773339'],
        ['14.495731', '50.585761'],
        ['14.860456', '50.864450'],
        ['15.094667', '51.013753'],
        ['15.232500', '51.165556'],
        ['15.746667', '51.721667'],
        ['16.020556', '52.026667'],
        ['16.478889', '52.526389'],
        ['17.035278', '53.116111'],
        ['17.403889', '53.496111'],
        ['18.208056', '54.299722'],
        ['18.958333', '54.912778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P862',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.036861', '55.162722']] },
  },
  {
    type: 'Feature',
    name: 'P863',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.836667', '56.132778']] },
  },
  {
    type: 'Feature',
    name: 'P870',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.426389', '56.363056'],
        ['23.254722', '55.428056'],
        ['23.165833', '54.925833'],
        ['23.059167', '54.307500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P873',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['17.668889', '41.201389']] },
  },
  {
    type: 'Feature',
    name: 'P90',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.053611', '46.977778'],
        ['27.858333', '47.104167'],
        ['29.233056', '47.886667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P92',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.837778', '41.500000'],
        ['16.776444', '41.144250'],
        ['19.192500', '41.057778'],
        ['19.718194', '41.416139'],
        ['20.174167', '41.559722'],
        ['20.530000', '41.670000'],
        ['21.108333', '41.811667'],
        ['21.196944', '41.832222'],
        ['21.630556', '41.935000'],
        ['22.605833', '42.096944'],
        ['24.876306', '42.053417'],
        ['25.452167', '42.060833'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.485278', '50.935278'],
        ['13.811111', '50.931111'],
        ['14.368800', '50.928150'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P974',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.061944', '47.756667'],
        ['18.084444', '48.015278'],
        ['17.541111', '48.617500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P975',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.708056', '46.163611'],
        ['21.473056', '45.905556'],
        ['22.586769', '45.516794'],
        ['24.668333', '44.763056'],
        ['26.498333', '44.051667'],
        ['29.624167', '42.363889'],
        ['31.022778', '42.000556'],
        ['31.657222', '41.829167'],
        ['31.738889', '41.806667'],
        ['32.141389', '41.695000'],
        ['32.232778', '41.669444'],
        ['32.323333', '41.644167'],
        ['32.504167', '41.593056'],
        ['32.992778', '41.453333'],
        ['33.238889', '41.351111'],
        ['33.385889', '41.291917'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P981',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.337222', '45.468889'],
        ['23.374722', '45.103889'],
        ['24.331667', '44.920556'],
        ['25.161311', '44.753892'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P985',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.998056', '55.550000'],
        ['40.879444', '55.310833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P987',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.020639', '53.884444'],
        ['28.648056', '53.349722'],
        ['28.751944', '53.148611'],
        ['28.955000', '52.751667'],
        ['29.586667', '51.466389'],
        ['30.900972', '50.285694'],
        ['31.706944', '49.650556'],
        ['31.996667', '49.416667'],
        ['32.741944', '48.688333'],
        ['32.984444', '48.471389'],
        ['33.578611', '47.920556'],
        ['33.923056', '47.594444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'P994',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.480833', '48.438611'],
        ['12.396944', '48.097222'],
        ['12.384081', '47.977450'],
        ['12.538625', '47.626286'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q10',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.283269', '49.933883'],
        ['19.094167', '50.229167'],
        ['20.158611', '50.614722'],
        ['20.251111', '50.647222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q104',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.331389', '48.394444'],
        ['12.749781', '48.397050'],
        ['13.310278', '48.413056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q112',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.345556', '48.144167'],
        ['12.559444', '48.058889'],
        ['12.818333', '47.955833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q113',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.345556', '48.144167'],
        ['12.913792', '48.091317'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q12',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.289167', '43.517500'],
        ['23.921111', '43.388889'],
        ['25.530000', '43.043333'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q13',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.797500', '42.972778'],
        ['28.020556', '42.883611'],
        ['30.596111', '42.738889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q14',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.768333', '43.374167'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q15',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.797500', '42.972778'],
        ['28.766111', '42.082222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q160',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.237528', '41.803778']] },
  },
  {
    type: 'Feature',
    name: 'Q18',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.768333', '43.374167'],
        ['23.921111', '43.388889'],
        ['28.556389', '43.333611'],
        ['30.640000', '43.253333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q19',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.861667', '45.314167']] },
  },
  {
    type: 'Feature',
    name: 'Q195',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.048611', '42.618333']] },
  },
  {
    type: 'Feature',
    name: 'Q201',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.079167', '52.389444'],
        ['12.561486', '52.483914'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q217',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.603056', '46.059167']] },
  },
  {
    type: 'Feature',
    name: 'Q230',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.548056', '51.430000'],
        ['12.850278', '51.394722'],
        ['13.194264', '51.353717'],
        ['13.884722', '51.278056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q24',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.645556', '42.301944'],
        ['23.704444', '42.575000'],
        ['23.813889', '42.667222'],
        ['24.220556', '43.095000'],
        ['24.850000', '43.700000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q240',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.057186', '50.629694'],
        ['13.485278', '50.935278'],
        ['13.598889', '51.015547'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q246',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.709167', '50.893333'],
        ['13.811111', '50.931111'],
        ['13.981786', '50.993839'],
        ['14.179444', '51.065833'],
        ['14.330883', '51.120789'],
        ['14.559769', '51.119625'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q25',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.108889', '43.155556']] },
  },
  {
    type: 'Feature',
    name: 'Q258',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.175278', '49.930000'],
        ['21.326389', '49.958889'],
        ['21.497500', '49.991111'],
        ['22.134028', '50.108611'],
        ['22.326667', '50.299167'],
        ['22.520556', '50.488889'],
        ['22.708611', '50.629444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q26',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['28.404444', '41.493611'],
        ['27.776944', '41.966667'],
        ['26.596111', '42.814444'],
        ['25.418333', '43.626389'],
        ['25.002500', '43.973333'],
        ['24.655833', '44.258611'],
        ['22.646667', '45.837500'],
        ['22.408075', '45.953222'],
        ['21.267222', '46.493611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q27',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.417778', '43.685833'],
        ['25.711778', '43.153222'],
        ['28.137222', '42.643056'],
        ['29.624167', '42.363889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q277',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.593889', '51.569722'],
        ['19.633056', '51.580000'],
        ['19.960750', '51.581417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q280',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.699722', '53.752222'],
        ['12.276536', '54.274811'],
        ['12.065000', '54.486389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q282',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.896667', '53.530000']] },
  },
  {
    type: 'Feature',
    name: 'Q29',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.797500', '42.972778'],
        ['27.537972', '42.587167'],
        ['29.624167', '42.363889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q296',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.076667', '54.210000'],
        ['12.285556', '54.608611'],
        ['12.313611', '54.945556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q316',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.410000', '53.275833'],
        ['16.838333', '53.506667'],
        ['19.761944', '53.717500'],
        ['22.900278', '54.069722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q333',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.126944', '45.386944']] },
  },
  {
    type: 'Feature',
    name: 'Q34',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.799722', '49.303611'],
        ['23.857222', '51.118056'],
        ['25.886389', '51.919722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q35',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.469444', '49.611667'],
        ['23.645833', '51.302778'],
        ['25.886389', '51.919722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q350',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.290333', '48.223861'],
        ['17.544722', '48.178611'],
        ['18.084444', '48.015278'],
        ['18.733056', '47.808333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q411',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.004722', '42.849167'],
        ['12.163611', '42.698611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q44',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.453833', '56.126444'],
        ['14.311389', '56.460556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q549',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.599000', '42.248875'],
        ['12.620556', '42.471389'],
        ['12.906389', '43.207222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q560',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.319722', '48.340000'],
        ['22.643056', '48.608333'],
        ['23.044444', '48.940833'],
        ['23.185000', '49.078333'],
        ['23.655000', '49.528333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q561',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.061667', '45.278056'],
        ['12.500000', '45.333333'],
        ['13.279167', '45.487222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q571',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.861667', '45.044722'],
        ['18.099722', '45.446667'],
        ['18.097500', '45.602222'],
        ['18.413333', '45.746111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q58',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.918056', '41.541111']] },
  },
  {
    type: 'Feature',
    name: 'Q6',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.289167', '43.517500'],
        ['25.711778', '43.153222'],
        ['29.870278', '42.461667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q7',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.417778', '43.685833'],
        ['28.536389', '43.105000'],
        ['29.624167', '42.363889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q702',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.281444', '45.071083']] },
  },
  {
    type: 'Feature',
    name: 'Q707',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.960833', '46.068056'],
        ['13.188889', '46.138056'],
        ['13.304444', '46.173333'],
        ['13.472778', '46.218333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q772',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.232778', '43.483889']] },
  },
  {
    type: 'Feature',
    name: 'Q789',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.048611', '42.618333']] },
  },
  {
    type: 'Feature',
    name: 'Q8',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.768333', '43.374167'],
        ['23.312778', '43.328333'],
        ['23.806111', '43.284722'],
        ['29.870278', '42.461667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q800',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.076667', '54.210000'],
        ['12.276536', '54.274811'],
        ['13.300278', '54.598611'],
        ['13.768611', '54.658333'],
        ['14.338889', '54.774722'],
        ['14.564167', '54.819444'],
        ['16.501667', '55.274444'],
        ['17.958889', '55.584444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q81',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.869167', '41.157222']] },
  },
  {
    type: 'Feature',
    name: 'Q85',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.108889', '43.155556']] },
  },
  {
    type: 'Feature',
    name: 'Q9',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.417778', '43.685833'],
        ['28.766111', '42.082222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q920',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.467222', '42.684167'],
        ['14.184167', '42.435833'],
        ['16.049167', '41.912778'],
        ['16.826111', '41.576111'],
        ['16.935000', '41.528333'],
        ['17.668889', '41.201389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q95',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.348333', '43.793889']] },
  },
  {
    type: 'Feature',
    name: 'Q97',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.047500', '47.735556'],
        ['23.058333', '49.888333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Q984',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.281111', '46.802500']] },
  },
  {
    type: 'Feature',
    name: 'Q985',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.597500', '46.706111']] },
  },
  {
    type: 'Feature',
    name: 'Q99',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.656111', '51.800000'],
        ['20.921667', '51.604722'],
        ['21.329444', '51.538333'],
        ['21.580278', '51.496944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R114',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.634194', '41.489278'],
        ['39.583056', '41.643333'],
        ['39.403056', '42.183889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R23',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.646667', '49.455000'],
        ['19.331667', '49.235556'],
        ['19.215278', '49.153611'],
        ['18.820833', '48.873333'],
        ['18.571111', '48.686944'],
        ['18.050556', '48.290556'],
        ['17.290333', '48.223861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R230',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.633333', '44.350000'],
        ['37.165000', '44.121389'],
        ['37.610556', '43.947222'],
        ['38.036667', '43.778056'],
        ['38.510000', '43.588056'],
        ['39.088611', '43.349722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R232',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.100000', '49.220000'],
        ['20.350111', '49.064667'],
        ['20.677222', '48.889444'],
        ['21.248167', '48.683083'],
        ['21.291389', '48.523056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R235',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.958889', '55.584444'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R239',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.958333', '54.912778'],
        ['19.634167', '55.201389'],
        ['20.291667', '55.474444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R242',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.825000', '47.197778'],
        ['40.376111', '47.805556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R356',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.785556', '55.697500'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R53',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.115861', '48.453250'],
        ['19.528611', '48.660833'],
        ['19.957222', '48.873611'],
        ['20.350111', '49.064667'],
        ['20.887222', '49.286389'],
        ['21.010278', '49.336389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R720',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.933556', '47.500889'],
        ['40.376111', '47.805556'],
        ['40.689444', '47.926111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'R801',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.654167', '54.447778'],
        ['19.990000', '54.608333'],
        ['20.599611', '54.893278'],
        ['21.216389', '55.145833'],
        ['21.438056', '55.235278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T101',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.500000', '45.333333']] },
  },
  {
    type: 'Feature',
    name: 'T105',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.758889', '49.365833'],
        ['12.657222', '49.222778'],
        ['12.526547', '49.040269'],
        ['12.348611', '48.790278'],
        ['12.311111', '48.693333'],
        ['12.273928', '48.596361'],
        ['12.002328', '48.572625'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T106',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.441667', '48.608333'],
        ['12.494722', '48.701944'],
        ['12.548333', '48.796667'],
        ['12.626389', '48.906389'],
        ['12.747222', '49.074722'],
        ['12.857222', '49.226667'],
        ['12.997417', '49.419258'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T108',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.273928', '48.596361'],
        ['12.441667', '48.608333'],
        ['12.617500', '48.620278'],
        ['12.678889', '48.706111'],
        ['12.863889', '48.964444'],
        ['12.968611', '49.108611'],
        ['13.043056', '49.211111'],
        ['13.132219', '49.330944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T110',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.887500', '48.732222'],
        ['13.512797', '49.015675'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T117',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.055944', '53.607639'],
        ['25.099722', '53.741389'],
        ['26.722500', '53.894722'],
        ['28.020639', '53.884444'],
        ['28.914722', '53.616389'],
        ['29.273333', '53.581667'],
        ['30.149722', '53.491667'],
        ['32.464722', '53.216667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T128',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.048611', '42.618333'],
        ['12.180278', '43.066389'],
        ['12.358056', '43.566944'],
        ['12.713333', '44.542500'],
        ['12.801944', '45.016944'],
        ['12.861667', '45.314167'],
        ['12.921111', '45.683056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T132',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.995556', '45.262778'],
        ['12.861667', '45.314167'],
        ['12.742500', '45.625833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T136',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.132219', '49.330944'],
        ['13.344825', '49.570333'],
        ['13.561542', '49.773339'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T139',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.267222', '46.493611'],
        ['22.847500', '44.867222'],
        ['22.976944', '44.538889'],
        ['23.573889', '44.493333'],
        ['23.922750', '44.318611'],
        ['24.136944', '44.036667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T147',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.597222', '45.968333']] },
  },
  {
    type: 'Feature',
    name: 'T159',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.124167', '48.749722']] },
  },
  {
    type: 'Feature',
    name: 'T161',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.124167', '48.749722'],
        ['12.947778', '48.538611'],
        ['13.336667', '48.436389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T162',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.321389', '41.125278'],
        ['26.611111', '41.034444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T170',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.016389', '50.264444'],
        ['12.338639', '50.190489'],
        ['12.718786', '50.177008'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T171',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.057500', '51.626111']] },
  },
  {
    type: 'Feature',
    name: 'T174',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.548056', '51.430000'],
        ['13.471667', '51.499722'],
        ['14.244722', '51.552222'],
        ['14.738056', '51.583056'],
        ['15.746667', '51.721667'],
        ['18.000000', '51.996667'],
        ['19.344167', '52.129722'],
        ['19.871389', '52.177472'],
        ['20.657167', '52.259250'],
        ['21.584444', '52.438056'],
        ['22.538889', '52.613333'],
        ['23.921944', '52.851389'],
        ['24.163056', '52.943056'],
        ['24.989167', '53.262500'],
        ['25.823333', '53.572222'],
        ['26.722500', '53.894722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T178',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.548056', '51.430000']] },
  },
  {
    type: 'Feature',
    name: 'T195',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.090278', '49.118056'],
        ['20.664722', '49.403611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T197',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.494722', '41.332778'],
        ['21.196944', '41.832222'],
        ['21.326944', '42.136667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T198',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.530000', '41.670000'],
        ['20.990000', '41.976667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T199',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.849167', '48.966389'],
        ['30.422778', '49.270000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T2',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.192500', '41.057778'],
        ['18.908889', '41.193333'],
        ['18.663611', '41.927222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T202',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.230000', '51.161944'],
        ['12.408333', '51.312500'],
        ['12.671667', '51.466667'],
        ['13.029722', '51.571944'],
        ['13.788086', '51.923589'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T203',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.541397', '49.335833'],
        ['12.656547', '49.435983'],
        ['13.138242', '50.506167'],
        ['13.485278', '50.935278'],
        ['13.529444', '51.099444'],
        ['13.627669', '51.460847'],
        ['13.656944', '51.566944'],
        ['13.788086', '51.923589'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T204',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.698889', '50.938333'],
        ['14.605278', '51.171111'],
        ['14.520047', '51.380789'],
        ['14.416517', '52.047631'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T205',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.416517', '52.047631'],
        ['14.755556', '52.058333'],
        ['15.252500', '51.906389'],
        ['15.935000', '51.692500'],
        ['16.128611', '51.630556'],
        ['16.714444', '51.440278'],
        ['17.114444', '51.308056'],
        ['17.617222', '51.183333'],
        ['18.738333', '50.894444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T207',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.083889', '52.566667'],
        ['12.233611', '52.566667'],
        ['12.724722', '52.613889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T208',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.615833', '53.120000'],
        ['13.668056', '53.378056'],
        ['13.731389', '53.686111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T209',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.338581', '52.975206'],
        ['12.724722', '52.613889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T214',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.713333', '44.542500'],
        ['14.126111', '43.439167'],
        ['16.049167', '41.912778'],
        ['20.494722', '41.332778'],
        ['22.365556', '41.980833'],
        ['22.605833', '42.096944'],
        ['23.005556', '42.288333'],
        ['23.656111', '42.593889'],
        ['23.813889', '42.667222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T215',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.895556', '42.096944'],
        ['21.630556', '41.935000'],
        ['22.365556', '41.980833'],
        ['22.883333', '41.966667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T216',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.174167', '41.559722'],
        ['20.494722', '41.332778'],
        ['21.448611', '41.337778'],
        ['22.242778', '42.308333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T217',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.895556', '42.096944'],
        ['20.990000', '41.976667'],
        ['21.108333', '41.811667'],
        ['21.448611', '41.337778'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T218',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.326944', '42.136667'],
        ['21.630556', '41.935000'],
        ['22.758333', '41.247222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T219',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['29.389444', '43.723333'],
        ['29.803333', '45.186667'],
        ['30.433333', '46.066667'],
        ['30.669722', '46.426389'],
        ['30.975278', '48.043889'],
        ['31.209444', '49.040833'],
        ['31.315556', '49.351944'],
        ['31.443333', '49.745000'],
        ['31.796667', '50.748333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T224',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.998194', '54.801944'],
        ['18.181667', '54.989444'],
        ['18.448056', '55.258611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T226',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.708056', '46.163611'],
        ['22.847500', '44.867222'],
        ['23.473889', '44.468611'],
        ['23.755833', '44.286111'],
        ['24.136944', '44.036667'],
        ['24.559722', '43.755833'],
        ['25.711778', '43.153222'],
        ['26.596111', '42.814444'],
        ['26.768056', '42.747778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T227',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.079444', '42.594167'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T228',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.392222', '43.912222'],
        ['23.091111', '43.392222'],
        ['23.326667', '43.214167'],
        ['23.813889', '42.667222'],
        ['24.145556', '42.681667'],
        ['26.767500', '42.604167'],
        ['27.537972', '42.587167'],
        ['28.020556', '42.883611'],
        ['28.441944', '43.528611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T229',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.449828', '43.933864'],
        ['18.489167', '43.648611'],
        ['18.543611', '43.250278'],
        ['18.558056', '43.142778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T23',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.995600', '46.804175'],
        ['16.422722', '46.683489'],
        ['18.928889', '45.931667'],
        ['19.401111', '45.410000'],
        ['19.546111', '45.247222'],
        ['20.028056', '44.755833'],
        ['20.768889', '43.980000'],
        ['20.955556', '43.780556'],
        ['22.077778', '42.724722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T237',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['15.703333', '41.020000']] },
  },
  {
    type: 'Feature',
    name: 'T239',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.028136', '52.602503'],
        ['13.787222', '53.124167'],
        ['13.668056', '53.378056'],
        ['13.597131', '53.527933'],
        ['13.508333', '53.708056'],
        ['13.481522', '53.761978'],
        ['13.137689', '54.459656'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T240',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.998194', '54.801944'],
        ['17.685556', '54.974444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T242',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.226667', '50.075000'],
        ['38.750000', '49.333333'],
        ['39.375000', '48.418333'],
        ['39.808333', '47.950000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T254',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.656111', '49.504722'],
        ['27.841667', '48.970000'],
        ['32.888333', '48.118333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T261',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.242778', '42.308333']] },
  },
  {
    type: 'Feature',
    name: 'T262',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.605833', '42.096944'],
        ['22.239444', '41.163333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T263',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.605833', '42.096944'],
        ['21.326944', '42.136667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T264',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.776944', '41.966667'],
        ['28.357778', '41.286389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T265',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.871389', '52.177472'],
        ['21.216111', '52.079167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T266',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.656111', '51.800000'],
        ['21.427778', '51.655833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T267',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.171111', '50.868889'],
        ['22.477778', '50.883333'],
        ['23.023333', '50.861667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T269',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.171111', '50.868889'],
        ['23.825556', '50.404167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T270',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.753500', '53.765806'],
        ['19.633056', '53.061111'],
        ['20.133889', '52.810000'],
        ['20.328333', '52.704444'],
        ['21.744500', '52.223333'],
        ['21.892222', '51.810556'],
        ['22.040833', '51.610556'],
        ['22.121667', '51.500833'],
        ['22.102500', '51.289722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T271',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['30.704444', '56.793056']] },
  },
  {
    type: 'Feature',
    name: 'T273',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.084722', '54.503333'],
        ['33.530556', '54.423611'],
        ['34.735278', '54.365833'],
        ['36.383056', '54.583889'],
        ['37.015833', '54.661111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T275',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T276',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.385889', '41.291917'],
        ['33.474722', '41.304583'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T278',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.916167', '51.778286'],
        ['13.563414', '52.019353'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T280',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.654947', '52.927853'],
        ['13.675278', '53.131944'],
        ['13.731389', '53.686111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T282',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.719167', '50.835000'],
        ['22.069722', '50.841111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T285',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.473889', '44.468611'],
        ['23.922750', '44.318611'],
        ['24.348333', '44.364722'],
        ['24.679722', '44.399444'],
        ['25.183167', '44.450256'],
        ['25.708056', '44.500833'],
        ['26.651944', '44.713056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T286',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.766389', '55.453056'],
        ['34.156944', '55.346944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T287',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.850833', '56.963611'],
        ['37.052778', '56.820833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T288',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.968333', '56.453333'],
        ['36.383333', '56.361667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T292',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.232778', '43.483889']] },
  },
  {
    type: 'Feature',
    name: 'T294',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.873056', '52.988056'],
        ['34.414722', '53.533056'],
        ['34.655000', '53.900000'],
        ['35.221111', '54.737500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T296',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.421389', '53.374722'],
        ['37.015833', '54.661111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T298',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.313611', '54.945556'],
        ['12.597778', '54.780000'],
        ['12.716944', '54.710000'],
        ['13.137689', '54.459656'],
        ['13.360278', '54.325556'],
        ['13.576389', '54.193056'],
        ['13.728889', '54.100278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T302',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['31.349444', '41.548889']] },
  },
  {
    type: 'Feature',
    name: 'T307',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.126944', '45.386944']] },
  },
  {
    type: 'Feature',
    name: 'T310',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.999444', '41.298889'],
        ['30.724722', '41.428889'],
        ['31.349444', '41.548889'],
        ['32.298333', '41.723333'],
        ['33.046667', '41.846944'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T315',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.674167', '41.591111'],
        ['31.349444', '41.548889'],
        ['32.044556', '41.513778'],
        ['32.298333', '41.723333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T316',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['17.161389', '55.638611']] },
  },
  {
    type: 'Feature',
    name: 'T322',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.109722', '52.389722'],
        ['37.499444', '54.549444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T323',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.109722', '52.389722'],
        ['36.716389', '54.011389'],
        ['37.015833', '54.661111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T325',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.248056', '53.474722'],
        ['36.716389', '54.011389'],
        ['37.261111', '54.614722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T33',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.442222', '47.793889'],
        ['22.893861', '47.727417'],
        ['23.160917', '47.694444'],
        ['23.345972', '47.671222'],
        ['23.726944', '47.651389'],
        ['24.000000', '47.636389'],
        ['25.876444', '47.514750'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T335',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.510444', '49.202861'],
        ['18.447300', '49.393553'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T34',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.123889', '46.192222'],
        ['28.288056', '46.351111'],
        ['28.575000', '46.626389'],
        ['28.863611', '47.187222'],
        ['29.066667', '47.574444'],
        ['29.233056', '47.886667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T343',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.883333', '41.966667'],
        ['23.319167', '42.291944'],
        ['23.406944', '42.356667'],
        ['23.704444', '42.575000'],
        ['23.813889', '42.667222'],
        ['24.174167', '42.779722'],
        ['24.620833', '42.849444'],
        ['27.116667', '43.205556'],
        ['27.817167', '43.231028'],
        ['28.556389', '43.333611'],
        ['30.596111', '42.738889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T344',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.281389', '49.142222'],
        ['23.543056', '51.723056'],
        ['25.048056', '52.189722'],
        ['26.234167', '52.462222'],
        ['27.238056', '52.689722'],
        ['29.489444', '53.210556'],
        ['30.133056', '53.349444'],
        ['30.798056', '53.488333'],
        ['32.142222', '53.808056'],
        ['35.682500', '54.180556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T346',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.914722', '53.616389'],
        ['29.389722', '53.818333'],
        ['30.230000', '54.161111'],
        ['30.583056', '54.301667'],
        ['31.084722', '54.503333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T353',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.844167', '51.442500'],
        ['20.015278', '51.424722'],
        ['20.360000', '51.387778'],
        ['20.746667', '51.315833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T354',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.688056', '51.383889'],
        ['22.040833', '51.610556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T355',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.656111', '51.800000'],
        ['19.960750', '51.581417'],
        ['20.746667', '51.315833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T356',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.538056', '51.547500'],
        ['21.785556', '51.690000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T357',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.538056', '51.547500'],
        ['21.911389', '51.497500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T359',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.019167', '51.104167'],
        ['20.015278', '51.424722'],
        ['19.802500', '51.521944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T369',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.163611', '42.698611'],
        ['12.166667', '42.761389'],
        ['12.180278', '43.066389'],
        ['12.063611', '43.683056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T375',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.646667', '49.455000'],
        ['20.772500', '50.314167'],
        ['21.129444', '50.578056'],
        ['21.781389', '50.761667'],
        ['22.069722', '50.841111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T379',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.735278', '54.365833'],
        ['35.948611', '54.603889'],
        ['36.648056', '54.733333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T383',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.430278', '50.753611'],
        ['37.733889', '50.791667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T384',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.268056', '52.152500'],
        ['38.583889', '52.032222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T386',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.189444', '51.718333'],
        ['40.651667', '51.841111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T387',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.355278', '51.913056']] },
  },
  {
    type: 'Feature',
    name: 'T389',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.239444', '41.163333'],
        ['22.097778', '41.766944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T390',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.417778', '43.685833'],
        ['24.620833', '42.849444'],
        ['26.099444', '42.251111'],
        ['26.766389', '41.971111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T391',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.797500', '42.972778'],
        ['24.705556', '42.512222'],
        ['26.766389', '41.971111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T392',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.855833', '43.261111'],
        ['17.491389', '44.225000'],
        ['17.333333', '44.633333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T397',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.823333', '53.572222'],
        ['26.667778', '53.731111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T398',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.521389', '54.518056'],
        ['28.859167', '54.833333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T4',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.825556', '46.510917'],
        ['26.729167', '46.756111'],
        ['26.498306', '47.334250'],
        ['26.360833', '47.672333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T402',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.516389', '55.777222'],
        ['12.563889', '56.100556'],
        ['12.573778', '56.168861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T406',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.543056', '51.723056'],
        ['24.093056', '52.213056'],
        ['26.799722', '54.434722'],
        ['29.062778', '56.030833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T408',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.453833', '56.126444'],
        ['14.269444', '56.491667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T415',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.132222', '43.571389'],
        ['14.232778', '43.483889'],
        ['16.049167', '41.912778'],
        ['16.523333', '41.414444'],
        ['16.576389', '41.358056'],
        ['16.776444', '41.144250'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T422',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.044556', '41.513778'],
        ['31.738889', '41.806667'],
        ['31.589167', '41.947500'],
        ['31.403056', '42.121389'],
        ['31.107500', '42.457222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T425',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.371944', '50.998333'],
        ['22.675472', '50.935194'],
        ['23.023333', '50.861667'],
        ['24.100000', '50.833333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T459',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.182778', '55.651111'],
        ['32.323611', '55.477222'],
        ['32.500000', '55.257222'],
        ['32.611389', '55.116667'],
        ['32.734444', '54.960556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T461',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.414722', '53.533056'],
        ['35.337778', '53.954722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T462',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.221111', '54.737500'],
        ['35.595000', '54.669444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T464',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.189444', '51.718333'],
        ['38.859444', '51.743056'],
        ['38.268056', '52.152500'],
        ['37.703889', '52.544167'],
        ['37.079722', '52.966389'],
        ['36.248056', '53.474722'],
        ['35.909444', '53.901667'],
        ['35.682500', '54.180556'],
        ['35.221111', '54.737500'],
        ['34.976667', '55.051389'],
        ['34.986667', '55.105000'],
        ['35.065278', '55.520556'],
        ['35.081667', '55.605278'],
        ['35.457500', '55.831944'],
        ['35.554722', '55.890000'],
        ['36.057778', '56.092778'],
        ['38.136111', '56.952778'],
        ['40.931389', '56.941667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T469',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.421389', '53.374722'],
        ['36.716389', '54.011389'],
        ['37.499444', '54.549444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T484',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.132222', '43.571389']] },
  },
  {
    type: 'Feature',
    name: 'T487',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.551111', '55.459167'],
        ['30.428056', '55.501667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T49',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.338639', '50.190489'],
        ['13.065578', '50.445139'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T490',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.583056', '47.965000'],
        ['24.395636', '47.001044'],
        ['23.800000', '43.783333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T491',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.250000', '43.833333'],
        ['23.744722', '46.760000'],
        ['23.946389', '47.953611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T500',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.288056', '46.351111'],
        ['28.730556', '48.125000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T504',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.598333', '47.955000'],
        ['31.702778', '47.616667'],
        ['31.878056', '47.037222'],
        ['32.004444', '46.611111'],
        ['32.236667', '45.810000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T508',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.733556', '55.151583']] },
  },
  {
    type: 'Feature',
    name: 'T509',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.055944', '53.607639'],
        ['25.823333', '53.572222'],
        ['27.688056', '53.506389'],
        ['29.170278', '53.437222'],
        ['32.464722', '53.216667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T515',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.884722', '46.733333'],
        ['32.279444', '47.853056'],
        ['32.019444', '48.318333'],
        ['31.438889', '49.324444'],
        ['30.900972', '50.285694'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T516',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.774722', '41.401667'],
        ['24.876306', '42.053417'],
        ['26.151944', '43.172500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T517',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.121667', '41.546389'],
        ['24.876306', '42.053417'],
        ['26.767500', '42.604167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T52',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.093056', '52.213056'],
        ['24.841389', '52.551389'],
        ['25.697778', '52.927500'],
        ['26.514722', '53.274722'],
        ['26.956389', '53.456389'],
        ['28.020639', '53.884444'],
        ['29.931389', '54.393333'],
        ['31.041389', '54.668333'],
        ['32.734444', '54.960556'],
        ['34.281944', '55.203611'],
        ['34.790833', '55.278611'],
        ['35.575000', '55.389444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T521',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.189444', '51.718333'],
        ['38.859444', '51.743056'],
        ['38.268056', '52.152500'],
        ['37.703889', '52.544167'],
        ['37.079722', '52.966389'],
        ['36.248056', '53.474722'],
        ['35.909444', '53.901667'],
        ['35.682500', '54.180556'],
        ['35.221111', '54.737500'],
        ['34.976667', '55.051389'],
        ['34.986667', '55.105000'],
        ['35.065278', '55.520556'],
        ['35.081667', '55.605278'],
        ['35.457500', '55.831944'],
        ['35.554722', '55.890000'],
        ['36.057778', '56.092778'],
        ['38.136111', '56.952778'],
        ['40.931389', '56.941667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T526',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.195000', '54.132778'],
        ['29.608056', '54.360000'],
        ['30.988056', '55.097778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T527',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.776444', '41.144250'],
        ['17.011111', '41.728611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T535',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.130000', '52.153611'],
        ['26.859722', '52.166389'],
        ['28.781389', '52.181389'],
        ['30.287222', '52.410278'],
        ['30.997194', '52.533500'],
        ['31.548333', '52.650000'],
        ['33.873056', '52.988056'],
        ['35.421389', '53.374722'],
        ['36.248056', '53.474722'],
        ['37.055000', '53.573333'],
        ['37.540000', '53.633056'],
        ['38.496111', '53.744722'],
        ['38.971667', '53.832500'],
        ['39.212778', '53.873056'],
        ['39.417222', '54.152222'],
        ['39.875000', '54.793889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T555',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.537972', '42.587167'],
        ['28.141667', '42.809722'],
        ['28.791944', '43.059167'],
        ['29.143333', '43.096111'],
        ['30.640000', '43.253333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T557',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.898333', '53.308333'],
        ['35.421389', '53.374722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T559',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.898056', '54.233333'],
        ['39.478056', '53.922222'],
        ['39.773333', '53.760000'],
        ['40.354167', '53.875833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T561',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.945833', '56.911111'],
        ['34.968333', '56.453333'],
        ['34.995000', '56.249167'],
        ['35.042500', '55.904167'],
        ['35.054167', '55.815556'],
        ['35.065278', '55.732778'],
        ['35.081667', '55.605278'],
        ['35.091667', '55.520833'],
        ['35.114167', '55.351944'],
        ['35.140278', '55.153333'],
        ['35.221111', '54.737500'],
        ['35.682500', '54.180556'],
        ['35.909444', '53.901667'],
        ['36.248056', '53.474722'],
        ['37.109722', '52.389722'],
        ['37.985000', '51.804444'],
        ['38.884444', '51.371667'],
        ['39.345556', '51.141389'],
        ['39.662778', '51.007778'],
        ['40.088611', '50.868333'],
        ['40.770278', '50.605556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T611',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.901389', '50.863333'],
        ['40.651667', '51.841111'],
        ['40.477500', '52.498889'],
        ['39.773333', '53.760000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T614',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['38.918333', '53.439167'],
        ['38.238611', '54.345556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T620',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.495731', '50.585761'],
        ['13.880214', '50.804408'],
        ['13.709167', '50.893333'],
        ['13.598889', '51.015547'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T621',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.860000', '47.248333'],
        ['26.498333', '44.051667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T622',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.610278', '47.328889'],
        ['26.498333', '44.051667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T623',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.501111', '43.735556'],
        ['26.561111', '45.496667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T628',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.605556', '53.412778'],
        ['39.773333', '53.760000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T629',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.550833', '56.077500'],
        ['40.637222', '56.460556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T630',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.637222', '56.460556']] },
  },
  {
    type: 'Feature',
    name: 'T633',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.701667', '55.931667'],
        ['19.091111', '55.810278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T641',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.571667', '41.997778'],
        ['31.022778', '42.000556'],
        ['32.205000', '42.246944'],
        ['32.583611', '42.333611'],
        ['33.131944', '42.456111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T643',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.623611', '41.753333'],
        ['31.081667', '41.840000'],
        ['31.589167', '41.947500'],
        ['31.885833', '42.009167'],
        ['32.277500', '42.089444'],
        ['32.611944', '42.166111'],
        ['33.131944', '42.456111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T654',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.323333', '56.996389'],
        ['40.931389', '56.941667'],
        ['40.750278', '56.039722'],
        ['40.624444', '55.829167'],
        ['40.466111', '55.563333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T67',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142778', '42.170556'],
        ['28.536389', '43.105000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T670',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.302222', '55.026389'],
        ['16.761111', '54.959167'],
        ['17.826667', '54.795278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T671',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.116389', '53.944444'],
        ['17.018333', '54.097500'],
        ['17.448889', '54.224167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T672',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.702500', '53.584722'],
        ['16.748889', '53.750833'],
        ['17.481944', '53.885556'],
        ['17.880444', '53.963667'],
        ['19.145083', '54.201306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T673',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.761111', '54.959167'],
        ['17.647778', '54.650000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T68',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['28.536389', '43.105000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T693',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.072500', '56.279444']] },
  },
  {
    type: 'Feature',
    name: 'T700',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.834444', '48.164444'],
        ['12.749781', '48.397050'],
        ['12.706111', '48.601389'],
        ['12.678889', '48.706111'],
        ['12.658047', '48.785739'],
        ['12.626389', '48.906389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T701',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.601111', '47.821667'],
        ['12.892761', '48.002583'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T702',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.565506', '48.231908'],
        ['12.834444', '48.164444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T703',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.709167', '48.762778'],
        ['13.509722', '48.898333'],
        ['13.176389', '49.122500'],
        ['13.043056', '49.211111'],
        ['12.758889', '49.365833'],
        ['12.656547', '49.435983'],
        ['12.323333', '49.722500'],
        ['12.252500', '49.830000'],
        ['12.093719', '50.069194'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T704',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.331389', '48.394444'],
        ['12.565506', '48.231908'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T707',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.293333', '50.953056'],
        ['16.020000', '51.098611'],
        ['16.259167', '51.145556'],
        ['17.114444', '51.308056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T708',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.043889', '50.181944'],
        ['26.037778', '50.121389'],
        ['23.058333', '49.888333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T709',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.874664', '49.532328'],
        ['15.782703', '49.464108'],
        ['16.678858', '49.795844'],
        ['17.441492', '49.873244'],
        ['18.052378', '49.930922'],
        ['18.195278', '50.002778'],
        ['18.574444', '50.191944'],
        ['19.150278', '50.474167'],
        ['19.356111', '50.507222'],
        ['20.148056', '50.631667'],
        ['20.251111', '50.647222'],
        ['21.129444', '50.578056'],
        ['21.719167', '50.835000'],
        ['21.972222', '50.945000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T710',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.585556', '52.379722'],
        ['15.756111', '51.896667'],
        ['16.339167', '51.647778'],
        ['16.748889', '51.469722'],
        ['17.114444', '51.308056'],
        ['17.325556', '51.165278'],
        ['17.695833', '50.911389'],
        ['17.899167', '50.770278'],
        ['18.247500', '50.526111'],
        ['18.537222', '50.319722'],
        ['18.941667', '50.027778'],
        ['20.100000', '49.220000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T714',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.961667', '51.406944'],
        ['15.871389', '51.168611'],
        ['16.504167', '51.003889'],
        ['17.125833', '50.837500'],
        ['17.503056', '50.733056'],
        ['17.949722', '50.609722'],
        ['18.247500', '50.526111'],
        ['19.658333', '50.275556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T719',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.043889', '50.181944'],
        ['24.209722', '47.908889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T72',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.184444', '42.850000'],
        ['25.521944', '42.183333'],
        ['26.766389', '41.971111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T720',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.995556', '50.030278'],
        ['17.998333', '50.350278'],
        ['18.008056', '51.488333'],
        ['18.000000', '52.592222'],
        ['18.070000', '52.677222'],
        ['18.781389', '53.521111'],
        ['19.425556', '53.564444'],
        ['19.871389', '53.592222'],
        ['19.980611', '53.562861'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T727',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.832500', '52.816861'],
        ['17.113611', '53.080556'],
        ['19.318611', '53.495556'],
        ['20.500000', '53.698333'],
        ['21.283056', '53.825278'],
        ['22.900278', '54.069722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T73',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.572500', '56.890833'],
        ['36.821111', '56.718333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T730',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.489444', '49.951111'],
        ['22.319722', '48.340000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T731',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.280556', '50.606944'],
        ['27.348889', '50.706944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T738',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.961667', '51.406944'],
        ['15.610833', '51.128333'],
        ['16.085556', '50.928611'],
        ['16.942222', '50.700278'],
        ['18.108611', '50.375556'],
        ['18.537222', '50.319722'],
        ['19.094167', '50.229167'],
        ['19.912778', '50.095556'],
        ['20.569722', '50.102222'],
        ['21.031667', '50.107028'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T74',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.435692', '45.002281'],
        ['26.509167', '45.292778'],
        ['26.561111', '45.496667'],
        ['26.689722', '45.994722'],
        ['26.767778', '46.292222'],
        ['26.825556', '46.510917'],
        ['27.040556', '46.719444'],
        ['27.203333', '46.876111'],
        ['27.332972', '47.000306'],
        ['27.579694', '47.234278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T742',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.447778', '45.188056'],
        ['14.566961', '45.224122'],
        ['15.572222', '45.441111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T745',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.826667', '44.082222'],
        ['23.946389', '47.953611'],
        ['22.989167', '49.051944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T746',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.945833', '56.911111'],
        ['23.088056', '45.960278'],
        ['23.397956', '45.813850'],
        ['24.902500', '45.079444'],
        ['26.826667', '44.082222'],
        ['28.791944', '43.059167'],
        ['29.870278', '42.461667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T747',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.637222', '56.460556'],
        ['40.323333', '56.996389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T748',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['40.465000', '49.533056'],
        ['40.501389', '50.066389'],
        ['40.770278', '50.605556'],
        ['40.901389', '50.863333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T749',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['35.757778', '56.825000']] },
  },
  {
    type: 'Feature',
    name: 'T75',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['14.869167', '41.157222']] },
  },
  {
    type: 'Feature',
    name: 'T751',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.116111', '56.941389'],
        ['30.248611', '56.791389'],
        ['30.606667', '56.379444'],
        ['31.043889', '56.289444'],
        ['31.798056', '56.120833'],
        ['32.939167', '55.854167'],
        ['32.993333', '55.585278'],
        ['33.041389', '55.342778'],
        ['33.061111', '55.242222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T752',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.773333', '53.760000']] },
  },
  {
    type: 'Feature',
    name: 'T753',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['38.270556', '56.872222']] },
  },
  {
    type: 'Feature',
    name: 'T757',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.648056', '54.733333'],
        ['37.687500', '55.650556'],
        ['38.778333', '56.283333'],
        ['38.978056', '56.443056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T759',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.773333', '53.760000']] },
  },
  {
    type: 'Feature',
    name: 'T77',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.654722', '44.655833'],
        ['28.097778', '44.823889'],
        ['28.456278', '44.906694'],
        ['28.736389', '44.992500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T777',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.537972', '42.587167'],
        ['27.437500', '42.990556'],
        ['27.379444', '43.282222'],
        ['27.300000', '43.676667'],
        ['26.216667', '43.983333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T779',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.616667', '55.008056'],
        ['32.541944', '54.792222'],
        ['32.734444', '54.960556'],
        ['33.061111', '55.242222'],
        ['34.156944', '55.346944'],
        ['34.891389', '55.189722'],
        ['35.575000', '55.389444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T78',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.368800', '50.928150'],
        ['15.367089', '50.795275'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T781',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.643611', '55.512500'],
        ['34.587500', '55.710278'],
        ['35.065278', '55.732778'],
        ['35.540833', '55.753889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T782',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.084722', '56.780278']] },
  },
  {
    type: 'Feature',
    name: 'T784',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.323333', '56.996389']] },
  },
  {
    type: 'Feature',
    name: 'T787',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.998056', '55.550000'],
        ['40.466111', '55.563333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T795',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.944722', '51.888056'],
        ['37.133056', '51.926389'],
        ['38.268056', '52.152500'],
        ['38.931111', '52.389167'],
        ['39.139444', '52.486667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T8',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.453056', '41.979722'],
        ['19.603889', '42.534167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T802',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.242222', '46.351944'],
        ['23.063056', '46.394167'],
        ['24.306583', '46.446917'],
        ['24.955931', '46.468736'],
        ['25.592222', '46.486667'],
        ['27.295278', '46.790833'],
        ['27.584167', '46.839722'],
        ['28.106111', '46.925833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T804',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.205000', '51.689444'],
        ['12.548056', '51.430000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T826',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.316944', '55.366667'],
        ['31.602500', '56.092500'],
        ['34.338611', '55.994167'],
        ['35.846667', '55.913889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T828',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.838611', '54.457778'],
        ['38.496111', '53.744722'],
        ['38.918333', '53.439167'],
        ['39.599167', '52.444722'],
        ['39.956111', '52.007500'],
        ['40.086111', '51.846944'],
        ['40.189444', '51.718333'],
        ['40.443889', '51.471111'],
        ['40.600833', '51.316944'],
        ['40.770278', '50.605556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T829',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.876389', '51.429722'],
        ['37.017778', '51.366667'],
        ['37.473056', '51.161389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T830',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.634167', '56.026111']] },
  },
  {
    type: 'Feature',
    name: 'T832',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.838611', '54.457778'],
        ['38.268056', '52.152500'],
        ['39.106667', '52.225556'],
        ['40.148333', '52.307500'],
        ['40.624167', '52.341944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T833',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.934444', '55.388611'],
        ['38.898056', '54.233333'],
        ['40.130278', '54.117500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T834',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.301667', '56.818889'],
        ['36.496111', '56.688889'],
        ['36.652222', '56.583333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T835',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.364722', '53.016389'],
        ['34.653056', '52.853056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T843',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.338639', '50.190489']] },
  },
  {
    type: 'Feature',
    name: 'T86',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.791944', '43.059167'],
        ['30.264444', '42.610556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T871',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.905103', '50.685236'],
        ['15.367089', '50.795275'],
        ['16.085556', '50.928611'],
        ['16.290833', '50.965833'],
        ['16.504167', '51.003889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T873',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.001667', '56.738333'],
        ['36.383333', '56.361667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T888',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['36.548861', '41.265500']] },
  },
  {
    type: 'Feature',
    name: 'T889',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.079444', '42.594167'],
        ['26.492222', '42.542778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T898',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.408075', '45.953222'],
        ['23.088056', '45.960278'],
        ['23.278756', '45.918039'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T899',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.348333', '44.364722'],
        ['25.161311', '44.753892'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T90',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.893861', '47.727417'],
        ['23.125000', '47.491278'],
        ['23.322500', '47.260833'],
        ['23.744722', '46.760000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T906',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.076667', '54.210000']] },
  },
  {
    type: 'Feature',
    name: 'T910',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['39.983333', '42.990000']] },
  },
  {
    type: 'Feature',
    name: 'T950',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.866389', '51.539167'],
        ['13.075833', '51.458611'],
        ['13.168611', '51.458611'],
        ['13.870556', '51.456944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T951',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.825278', '51.171944'],
        ['13.337222', '51.174444'],
        ['14.605278', '51.171111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T983',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.488056', '48.073889'],
        ['26.686111', '48.948889'],
        ['27.850000', '49.761667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'T995',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.343333', '48.026667'],
        ['23.345000', '47.803333'],
        ['23.345972', '47.671222'],
        ['23.423361', '47.496667'],
        ['23.451944', '47.431944'],
        ['23.522500', '47.271667'],
        ['23.744722', '46.760000'],
        ['24.152583', '45.785000'],
        ['24.183889', '45.635556'],
        ['24.331667', '44.920556'],
        ['24.668333', '44.763056'],
        ['25.183167', '44.450256'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UA16',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.216111', '42.065556'],
        ['28.357778', '41.286389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UA17',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['28.766111', '42.082222']] },
  },
  {
    type: 'Feature',
    name: 'UA28',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.385889', '41.291917'],
        ['33.254556', '41.017250'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UA4',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['27.309167', '41.577222'],
        ['28.384167', '41.136389'],
        ['29.142889', '41.126972'],
        ['29.630000', '41.045833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UB5',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.991489', '41.098714']] },
  },
  {
    type: 'Feature',
    name: 'UG1',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['28.779281', '41.746164'],
        ['28.789722', '41.478333'],
        ['28.799444', '41.228056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UG123',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['29.146667', '41.461389'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UG18',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.991489', '41.098714']] },
  },
  {
    type: 'Feature',
    name: 'UG33',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['26.321389', '41.125278']] },
  },
  {
    type: 'Feature',
    name: 'UG67',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.375944', '41.193333']] },
  },
  {
    type: 'Feature',
    name: 'UG80',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['26.953611', '41.462222'],
        ['27.186944', '41.211111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UG901',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['37.045278', '41.903889'],
        ['37.666667', '42.685000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL333',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'UL601',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.253333', '41.090833'],
        ['31.031111', '41.233056'],
        ['30.724722', '41.428889'],
        ['30.502500', '41.617500'],
        ['29.624167', '42.363889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL602',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.724722', '41.428889'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL603',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.616667', '42.230000'],
        ['20.621111', '43.003056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL605',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['28.766111', '42.082222']] },
  },
  {
    type: 'Feature',
    name: 'UL606',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['26.953611', '41.462222'],
        ['27.186944', '41.211111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL608',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.991489', '41.098714'],
        ['22.758333', '41.247222'],
        ['21.397222', '42.159444'],
        ['20.311389', '42.833889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL610',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.357778', '41.286389'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL614',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['27.330506', '41.475403'],
        ['27.935000', '41.150750'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL615',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.216111', '42.065556'],
        ['28.404444', '41.493611'],
        ['29.142889', '41.126972'],
        ['29.630000', '41.045833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL616',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.055556', '42.112500'],
        ['20.585556', '43.013611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL617',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.516667', '41.129167']] },
  },
  {
    type: 'Feature',
    name: 'UL619',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.516111', '41.198889'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL620',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.799444', '41.228056'],
        ['28.789722', '41.478333'],
        ['28.779281', '41.746164'],
        ['28.774444', '41.871111'],
        ['28.766111', '42.082222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL621',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.264444', '42.610556'],
        ['30.668889', '42.287222'],
        ['31.022778', '42.000556'],
        ['31.308611', '41.766667'],
        ['31.531111', '41.583056'],
        ['31.585833', '41.537500'],
        ['32.069167', '41.132778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL718',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['34.536389', '41.570278'],
        ['35.690278', '41.022500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL737',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.239444', '41.163333']] },
  },
  {
    type: 'Feature',
    name: 'UL742',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['29.656667', '41.247778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL746',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.264444', '42.610556'],
        ['31.107500', '42.457222'],
        ['32.123889', '42.262778'],
        ['32.205000', '42.246944'],
        ['32.611944', '42.166111'],
        ['33.077778', '42.071389'],
        ['33.706278', '41.951417'],
        ['34.594722', '41.746111'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL850',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.242222', '48.880000'],
        ['13.709167', '48.762778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL851',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.087778', '42.780556'],
        ['37.045278', '41.903889'],
        ['37.661944', '41.708611'],
        ['38.790000', '41.340000'],
        ['39.169778', '41.215333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL852',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.870278', '42.461667'],
        ['31.403056', '42.121389'],
        ['31.885833', '42.009167'],
        ['32.232778', '41.927222'],
        ['32.366667', '41.895000'],
        ['32.461111', '41.872500'],
        ['32.759444', '41.800278'],
        ['33.031111', '41.733611'],
        ['33.538056', '41.607222'],
        ['33.578611', '41.596944'],
        ['34.135278', '41.454722'],
        ['34.337222', '41.402500'],
        ['34.862222', '41.263611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL863',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.455556', '41.402778'],
        ['23.564722', '41.233056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UL867',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.766389', '41.971111'],
        ['27.330506', '41.475403'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM10',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.298333', '41.723333'],
        ['33.031111', '41.733611'],
        ['34.594722', '41.746111'],
        ['37.661944', '41.708611'],
        ['39.583056', '41.643333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM11',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['37.224722', '41.214444'],
        ['37.554444', '41.190000'],
        ['37.618056', '41.186944'],
        ['37.883889', '41.164444'],
        ['38.272222', '41.133056'],
        ['38.605833', '41.105278'],
        ['38.701944', '41.096944'],
        ['39.137222', '41.057222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM19',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['27.078889', '41.554444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM688',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.337222', '41.402500'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM749',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.040556', '43.115833'],
        ['21.397222', '42.159444'],
        ['21.916667', '41.076667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM853',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.373611', '41.017500'],
        ['33.474722', '41.304583'],
        ['33.706278', '41.951417'],
        ['33.814167', '42.189167'],
        ['34.087778', '42.780556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM854',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.181111', '42.798889'],
        ['33.706278', '41.951417'],
        ['34.246111', '41.325278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM855',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'UM856',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.298333', '41.723333'],
        ['31.947778', '42.798889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM859',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.045278', '41.903889'],
        ['35.413056', '42.151389'],
        ['34.798611', '42.238333'],
        ['34.393333', '42.293611'],
        ['33.892500', '42.359722'],
        ['33.417222', '42.420556'],
        ['33.131944', '42.456111'],
        ['32.048333', '42.584167'],
        ['32.017500', '42.587500'],
        ['30.596111', '42.738889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM860',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.087778', '42.780556'],
        ['35.076806', '42.022306'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM861',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['31.947778', '42.798889']] },
  },
  {
    type: 'Feature',
    name: 'UM867',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.021944', '42.747500'],
        ['21.055556', '42.112500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UM987',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.774722', '41.401667'],
        ['23.564722', '41.233056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN127',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.434167', '41.195278'],
        ['24.121667', '41.546389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN128',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.791389', '41.329444'],
        ['22.991489', '41.098714'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN130',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['21.916667', '41.076667']] },
  },
  {
    type: 'Feature',
    name: 'UN131',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['24.701111', '41.420833']] },
  },
  {
    type: 'Feature',
    name: 'UN133',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.805000', '41.276389'],
        ['23.774722', '41.401667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN37',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.375944', '41.193333']] },
  },
  {
    type: 'Feature',
    name: 'UN604',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142778', '42.170556'],
        ['29.146667', '41.461389'],
        ['29.142889', '41.126972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN616',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.766111', '42.082222'],
        ['28.774444', '41.871111'],
        ['28.779281', '41.746164'],
        ['28.789722', '41.478333'],
        ['28.799444', '41.228056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN617',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['29.146667', '41.461389'],
        ['29.142778', '42.170556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN618',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.309167', '41.577222'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN644',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.321389', '41.125278'],
        ['26.674722', '41.129722'],
        ['28.384167', '41.136389'],
        ['29.142889', '41.126972'],
        ['30.674167', '41.591111'],
        ['31.135833', '41.678889'],
        ['31.738889', '41.806667'],
        ['32.238333', '41.909444'],
        ['33.077778', '42.071389'],
        ['33.814167', '42.189167'],
        ['34.432778', '42.111389'],
        ['34.858333', '42.052222'],
        ['35.076806', '42.022306'],
        ['37.045278', '41.903889'],
        ['40.656389', '41.799167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN739',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.568333', '41.871667'],
        ['26.953611', '41.462222'],
        ['27.186944', '41.211111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UN743',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.596111', '42.738889'],
        ['32.583611', '42.333611'],
        ['33.814167', '42.189167'],
        ['34.393333', '42.293611'],
        ['36.914722', '42.708889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UP20',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.516667', '41.129167']] },
  },
  {
    type: 'Feature',
    name: 'UP29',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.992778', '41.453333'],
        ['33.046667', '41.846944'],
        ['33.131944', '42.456111'],
        ['33.181111', '42.798889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UP727',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.357778', '41.286389'],
        ['28.001111', '41.982222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UP975',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.624167', '42.363889'],
        ['31.022778', '42.000556'],
        ['31.657222', '41.829167'],
        ['31.738889', '41.806667'],
        ['32.141389', '41.695000'],
        ['32.232778', '41.669444'],
        ['32.323333', '41.644167'],
        ['32.504167', '41.593056'],
        ['32.992778', '41.453333'],
        ['33.238889', '41.351111'],
        ['33.385889', '41.291917'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UP994',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.480833', '48.438611'],
        ['12.371708', '47.557808'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UQ162',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.336667', '48.436389']] },
  },
  {
    type: 'Feature',
    name: 'UQ26',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.776944', '41.966667'],
        ['28.404444', '41.493611'],
        ['29.142889', '41.126972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UQ863',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.008889', '47.537778']] },
  },
  {
    type: 'Feature',
    name: 'UR114',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.634194', '41.489278'],
        ['39.583056', '41.643333'],
        ['39.403056', '42.183889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT162',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.321389', '41.125278'],
        ['26.611111', '41.034444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT243',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['36.548861', '41.265500']] },
  },
  {
    type: 'Feature',
    name: 'UT262',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.239444', '41.163333']] },
  },
  {
    type: 'Feature',
    name: 'UT264',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.776944', '41.966667'],
        ['28.357778', '41.286389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT275',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT276',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.385889', '41.291917'],
        ['33.474722', '41.304583'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT284',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['38.272222', '41.133056']] },
  },
  {
    type: 'Feature',
    name: 'UT310',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.999444', '41.298889'],
        ['30.724722', '41.428889'],
        ['31.349444', '41.548889'],
        ['32.298333', '41.723333'],
        ['33.046667', '41.846944'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT315',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.674167', '41.591111'],
        ['31.349444', '41.548889'],
        ['32.044556', '41.513778'],
        ['32.298333', '41.723333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT389',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['22.239444', '41.163333']] },
  },
  {
    type: 'Feature',
    name: 'UT422',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.044556', '41.513778'],
        ['31.738889', '41.806667'],
        ['31.589167', '41.947500'],
        ['31.403056', '42.121389'],
        ['31.107500', '42.457222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT61',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.246111', '41.325278'],
        ['34.536389', '41.570278'],
        ['35.076806', '42.022306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT641',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.571667', '41.997778'],
        ['31.022778', '42.000556'],
        ['32.205000', '42.246944'],
        ['32.583611', '42.333611'],
        ['33.131944', '42.456111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT643',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.623611', '41.753333'],
        ['31.081667', '41.840000'],
        ['31.589167', '41.947500'],
        ['31.885833', '42.009167'],
        ['32.277500', '42.089444'],
        ['32.611944', '42.166111'],
        ['33.131944', '42.456111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UT888',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['36.548861', '41.265500']] },
  },
  {
    type: 'Feature',
    name: 'UW100',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.181111', '42.798889'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW101',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.814167', '42.189167'],
        ['34.087778', '42.780556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW102',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.309167', '41.577222'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW107',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.349444', '41.548889'],
        ['31.585833', '41.537500'],
        ['32.044556', '41.513778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW308',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW309',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.385889', '41.291917'],
        ['33.474722', '41.304583'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW315',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.044556', '41.513778'],
        ['32.298333', '41.723333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW36',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.428056', '41.740833'],
        ['18.590000', '41.865556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW702',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.142889', '41.126972'],
        ['29.516111', '41.198889'],
        ['29.999444', '41.298889'],
        ['31.349444', '41.548889'],
        ['32.298333', '41.723333'],
        ['33.046667', '41.846944'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW703',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['34.246111', '41.325278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW704',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.087778', '42.780556'],
        ['35.076806', '42.022306'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW71',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.690278', '41.022500'],
        ['35.928611', '41.092500'],
        ['36.548861', '41.265500'],
        ['37.224722', '41.214444'],
        ['37.554444', '41.190000'],
        ['37.618056', '41.186944'],
        ['37.883889', '41.164444'],
        ['38.272222', '41.133056'],
        ['38.605833', '41.105278'],
        ['38.701944', '41.096944'],
        ['39.137222', '41.057222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW715',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'UW72',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['36.548861', '41.265500']] },
  },
  {
    type: 'Feature',
    name: 'UW81',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['26.674722', '41.129722']] },
  },
  {
    type: 'Feature',
    name: 'UW86',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.076806', '42.022306'],
        ['35.166111', '41.832222'],
        ['35.966389', '41.508611'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW90',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.461111', '41.872500'],
        ['33.706278', '41.951417'],
        ['34.857500', '42.011944'],
        ['35.076806', '42.022306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.076806', '42.022306'],
        ['35.413056', '42.151389'],
        ['36.914722', '42.708889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UW99',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['36.671111', '41.758333'],
        ['36.733333', '42.004167'],
        ['36.796111', '42.249722'],
        ['36.914722', '42.708889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UY505',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.121667', '41.546389'],
        ['24.033611', '41.014167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UY520',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.216111', '42.065556'],
        ['27.806944', '41.450278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UZ205',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.758889', '49.365833']] },
  },
  {
    type: 'Feature',
    name: 'UZ39',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.947778', '48.538611'],
        ['13.075833', '48.687778'],
        ['13.242222', '48.880000'],
        ['13.394519', '49.119197'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'UZ650',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.252500', '49.830000'],
        ['12.461247', '49.810744'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W100',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.181111', '42.798889'],
        ['33.706278', '41.951417'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W101',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.814167', '42.189167'],
        ['34.087778', '42.780556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W102',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.309167', '41.577222'],
        ['27.216111', '42.065556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W107',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.349444', '41.548889'],
        ['31.585833', '41.537500'],
        ['32.044556', '41.513778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W308',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W309',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.385889', '41.291917'],
        ['33.474722', '41.304583'],
        ['33.799861', '41.350306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W315',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.044556', '41.513778'],
        ['32.298333', '41.723333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W316',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.347222', '45.002222'],
        ['37.806111', '45.438889'],
        ['39.010000', '46.548056'],
        ['39.825000', '47.197778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W343',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.347222', '45.002222'],
        ['37.398333', '44.716389'],
        ['37.561667', '44.631389'],
        ['37.748333', '44.533056'],
        ['38.012139', '44.572528'],
        ['38.283889', '44.642778'],
        ['38.801111', '44.774444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W36',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.776444', '41.144250'],
        ['17.668889', '41.201389'],
        ['18.129167', '41.502778'],
        ['18.428056', '41.740833'],
        ['18.590000', '41.865556'],
        ['18.663611', '41.927222'],
        ['18.885083', '42.100444'],
        ['19.254611', '42.386194'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W40',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['40.710278', '43.881389']] },
  },
  {
    type: 'Feature',
    name: 'W425',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.248167', '48.683083'],
        ['21.687778', '48.662778'],
        ['22.245833', '48.634722'],
        ['22.508861', '48.521083'],
        ['23.343333', '48.026667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W45',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.304722', '43.496581'],
        ['15.168108', '43.937861'],
        ['14.695556', '44.340000'],
        ['14.472847', '44.527097'],
        ['14.416547', '44.902881'],
        ['15.133611', '45.165278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W531',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.700000', '46.583333'],
        ['36.845556', '46.911111'],
        ['36.986944', '47.226667'],
        ['37.225278', '47.748889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W533',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.005000', '46.970000'],
        ['36.650000', '47.366667'],
        ['37.225278', '47.748889'],
        ['37.682778', '48.074722'],
        ['38.337222', '48.270278'],
        ['38.739444', '48.387778'],
        ['39.375000', '48.418333'],
        ['39.863333', '48.518333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W534',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.644167', '47.928056'],
        ['34.704444', '47.587222'],
        ['34.745833', '47.351667'],
        ['35.026944', '46.547222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W536',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.885278', '50.212778'],
        ['34.428333', '50.221667'],
        ['35.618333', '50.231667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W537',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.691389', '48.884167'],
        ['25.457222', '48.720556'],
        ['25.981667', '48.260000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W538',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.216667', '50.828333'],
        ['35.545278', '49.917222'],
        ['36.208333', '49.368056'],
        ['36.523333', '48.880000'],
        ['37.037222', '48.544444'],
        ['37.186944', '48.445278'],
        ['37.682778', '48.074722'],
        ['38.150278', '47.702222'],
        ['38.313333', '47.555000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W54',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.673056', '45.218611'],
        ['40.576667', '45.068056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W545',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.981667', '48.260000'],
        ['26.602222', '48.502778'],
        ['27.841667', '48.970000'],
        ['28.620917', '49.239889'],
        ['29.183333', '49.550000'],
        ['29.570139', '50.186806'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W546',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.366667', '48.950000'],
        ['35.316667', '47.866667'],
        ['36.251667', '47.518333'],
        ['36.650000', '47.366667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W567',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.255833', '47.196944'],
        ['35.316667', '47.866667'],
        ['35.921944', '48.996111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W571',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.501667', '49.120000'],
        ['25.555000', '49.282222'],
        ['25.700000', '49.525000'],
        ['26.037778', '50.121389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W587',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.241389', '50.142222'],
        ['26.037778', '50.121389'],
        ['26.416667', '49.833333'],
        ['26.935000', '49.361667'],
        ['27.209444', '49.048889'],
        ['27.721667', '48.452222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W613',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.620917', '49.239889'],
        ['27.845000', '49.150000'],
        ['26.935000', '49.361667'],
        ['26.681389', '49.074167'],
        ['25.981667', '48.260000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W614',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.043889', '50.181944'],
        ['26.416667', '49.833333'],
        ['25.700000', '49.525000'],
        ['25.398333', '49.296667'],
        ['24.691389', '48.884167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W617',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.421667', '46.658333'],
        ['36.845556', '46.911111'],
        ['35.991667', '47.370000'],
        ['35.316667', '47.866667'],
        ['35.103056', '48.359833'],
        ['34.900556', '49.023056'],
        ['34.593611', '49.356944'],
        ['34.395000', '49.570000'],
        ['33.885278', '50.212778'],
        ['34.760000', '50.853333'],
        ['35.433333', '50.850000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W624',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.640000', '50.301667'],
        ['36.208333', '49.368056'],
        ['35.448333', '49.388056'],
        ['34.395000', '49.570000'],
        ['33.616667', '49.313333'],
        ['31.996667', '49.416667'],
        ['31.852500', '49.826111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W627',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.616667', '49.313333'],
        ['35.103056', '48.359833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W633',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.316667', '47.866667'],
        ['36.216667', '47.966667'],
        ['37.054722', '48.028056'],
        ['37.682778', '48.074722'],
        ['38.579167', '48.150000'],
        ['38.940556', '48.181944'],
        ['39.375000', '48.418333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W641',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.730556', '48.125000'],
        ['30.324167', '49.297778'],
        ['30.692500', '49.557778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W644',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.441667', '48.650000'],
        ['37.100000', '48.348333'],
        ['37.682778', '48.074722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W673',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.103056', '48.359833'],
        ['33.550000', '48.108889'],
        ['33.212139', '48.051000'],
        ['32.874444', '48.026667'],
        ['31.598333', '47.955000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W693',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['34.760000', '50.853333'],
        ['35.545278', '49.917222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W70',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['37.347222', '45.002222'],
        ['37.878333', '45.011389'],
        ['38.820000', '45.028056'],
        ['39.164056', '45.020917'],
        ['39.318333', '45.653056'],
        ['39.387500', '45.881667'],
        ['39.538333', '46.378056'],
        ['39.825000', '47.197778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W702',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['33.706278', '41.951417'],
        ['33.046667', '41.846944'],
        ['32.298333', '41.723333'],
        ['31.349444', '41.548889'],
        ['29.999444', '41.298889'],
        ['29.516111', '41.198889'],
        ['29.142889', '41.126972'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W71',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.690278', '41.022500'],
        ['35.928611', '41.092500'],
        ['36.548861', '41.265500'],
        ['37.224722', '41.214444'],
        ['37.554444', '41.190000'],
        ['37.618056', '41.186944'],
        ['37.883889', '41.164444'],
        ['38.272222', '41.133056'],
        ['38.605833', '41.105278'],
        ['38.701944', '41.096944'],
        ['39.137222', '41.057222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W715',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['29.142889', '41.126972']] },
  },
  {
    type: 'Feature',
    name: 'W72',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['36.548861', '41.265500']] },
  },
  {
    type: 'Feature',
    name: 'W79',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.825000', '47.197778'],
        ['39.751111', '46.359167'],
        ['39.716667', '45.859444'],
        ['39.694444', '45.531111'],
        ['39.673056', '45.218611'],
        ['39.840000', '44.369167'],
        ['40.000000', '43.964722'],
        ['40.108606', '43.688258'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W790',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['30.116111', '56.941389']] },
  },
  {
    type: 'Feature',
    name: 'W81',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['26.674722', '41.129722']] },
  },
  {
    type: 'Feature',
    name: 'W86',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.076806', '42.022306'],
        ['35.166111', '41.832222'],
        ['35.966389', '41.508611'],
        ['36.548861', '41.265500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W90',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.461111', '41.872500'],
        ['33.706278', '41.951417'],
        ['34.857500', '42.011944'],
        ['35.076806', '42.022306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W93',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['39.576389', '44.962222'],
        ['40.583333', '44.914722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['35.076806', '42.022306'],
        ['35.413056', '42.151389'],
        ['36.914722', '42.708889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'W99',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['36.548861', '41.265500'],
        ['36.671111', '41.758333'],
        ['36.733333', '42.004167'],
        ['36.796111', '42.249722'],
        ['36.914722', '42.708889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y100',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.633889', '54.606389'],
        ['16.497500', '54.260278'],
        ['16.881389', '54.244167'],
        ['17.448889', '54.224167'],
        ['18.208056', '54.299722'],
        ['19.145083', '54.201306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y104',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.002328', '48.572625'],
        ['12.124167', '48.749722'],
        ['12.222222', '48.885833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y106',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.154547', '47.934167'],
        ['12.208197', '47.732714'],
        ['12.232222', '47.641667'],
        ['12.281111', '46.802500'],
        ['12.281389', '45.900000'],
        ['12.281444', '45.071083'],
        ['12.247222', '44.405833'],
        ['12.217500', '43.817778'],
        ['12.208056', '43.626389'],
        ['12.180278', '43.066389'],
        ['12.269167', '42.885556'],
        ['12.310700', '42.800347'],
        ['12.599000', '42.248875'],
        ['12.972500', '42.107222'],
        ['13.281944', '42.059722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y11',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.837847', '43.578819'],
        ['14.232778', '43.483889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y128',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.958056', '44.709722'],
        ['17.215556', '43.496667'],
        ['17.314444', '43.017500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y129',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.248167', '48.683083'],
        ['21.318889', '48.956389'],
        ['21.439167', '49.412778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y130',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.965194', '56.920861'],
        ['24.630278', '56.368889'],
        ['26.474167', '55.136667'],
        ['27.694722', '54.588056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y132',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['19.335000', '42.228333']] },
  },
  {
    type: 'Feature',
    name: 'Y133',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.749781', '48.397050'],
        ['13.142500', '48.487778'],
        ['13.509722', '48.898333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y137',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.533056', '44.575000'],
        ['15.743333', '44.942222'],
        ['15.907500', '45.223889'],
        ['16.306697', '45.895558'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y140',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.682222', '43.250833'],
        ['16.429722', '41.763611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y141',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.050556', '48.290556'],
        ['18.816944', '48.578611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y147',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['28.200000', '56.360000']] },
  },
  {
    type: 'Feature',
    name: 'Y174',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.015278', '54.296944'],
        ['29.441389', '54.183333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y177',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.379444', '46.563611'],
        ['13.699278', '46.465864'],
        ['16.953056', '45.229444'],
        ['17.153333', '45.134444'],
        ['17.902500', '44.773889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y179',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.533333', '46.316667'],
        ['32.004444', '46.611111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y186',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.478333', '44.285556'],
        ['29.530278', '43.719167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y187',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['30.050000', '44.976389'],
        ['29.612778', '44.412778'],
        ['29.530278', '43.719167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y188',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.501111', '43.735556'],
        ['28.724722', '43.852222'],
        ['29.447222', '44.196111'],
        ['29.612778', '44.412778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y189',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.537972', '42.587167'],
        ['28.137222', '42.643056'],
        ['29.557778', '42.585833'],
        ['29.715833', '42.591667'],
        ['30.596111', '42.738889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y192',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.530278', '43.719167'],
        ['28.569722', '44.541389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y193',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.530278', '43.719167'],
        ['29.315556', '44.468611'],
        ['29.803333', '45.186667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y194',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.724722', '43.852222'],
        ['29.315556', '44.468611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y195',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.141667', '42.809722'],
        ['29.557778', '42.585833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y2',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['17.668889', '41.201389']] },
  },
  {
    type: 'Feature',
    name: 'Y200',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.561486', '52.483914']] },
  },
  {
    type: 'Feature',
    name: 'Y206',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.712500', '52.228056'],
        ['14.461667', '52.377222'],
        ['14.209678', '52.525528'],
        ['14.028136', '52.602503'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y209',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.712500', '52.228056'],
        ['15.883333', '52.233889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y210',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.712500', '52.228056'],
        ['15.756111', '51.896667'],
        ['17.443333', '50.388333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y211',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.870556', '51.456944'],
        ['14.174472', '51.898597'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y218',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.028136', '52.602503'],
        ['14.224722', '52.812222'],
        ['14.306667', '52.899167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y219',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.481389', '54.675000'],
        ['30.988056', '55.097778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y230',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.598889', '51.015547'],
        ['14.961667', '51.406944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y236',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.916167', '51.778286'],
        ['13.341111', '51.662500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y237',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['24.630278', '56.368889']] },
  },
  {
    type: 'Feature',
    name: 'Y265',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.500000', '45.333333']] },
  },
  {
    type: 'Feature',
    name: 'Y301',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.598528', '48.230528'],
        ['19.571944', '48.505833'],
        ['18.883889', '48.783889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y314',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['26.978056', '55.812778']] },
  },
  {
    type: 'Feature',
    name: 'Y32',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.435692', '45.002281'],
        ['27.338611', '46.289444'],
        ['27.728889', '46.825556'],
        ['27.822222', '46.951944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y33',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['27.332972', '47.000306'],
        ['27.353056', '47.317222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y34',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['26.053611', '46.977778'],
        ['27.000000', '47.250556'],
        ['27.302778', '47.335583'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y343',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.916667', '55.300000'],
        ['22.612778', '56.382778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y344',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.716667', '55.140833'],
        ['26.474167', '55.136667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y345',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.163611', '42.698611']] },
  },
  {
    type: 'Feature',
    name: 'Y40',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.944167', '56.374722'],
        ['23.165833', '54.925833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y400',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.800389', '42.281972'],
        ['19.254611', '42.386194'],
        ['19.335000', '42.228333'],
        ['20.530000', '41.670000'],
        ['22.392222', '41.320000'],
        ['22.758333', '41.247222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y41',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.501667', '55.274444'],
        ['16.690278', '55.170000'],
        ['18.208056', '54.299722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y44',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['17.161389', '55.638611']] },
  },
  {
    type: 'Feature',
    name: 'Y448',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.835278', '54.290000'],
        ['24.247528', '54.952889'],
        ['24.904444', '55.672500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y449',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.871944', '54.432500'],
        ['24.247528', '54.952889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y500',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.895556', '42.096944'],
        ['22.758333', '41.247222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y505',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.311167', '44.819361'],
        ['21.626944', '43.687500'],
        ['22.797500', '42.972778'],
        ['23.481389', '42.296944'],
        ['24.121667', '41.546389'],
        ['24.033611', '41.014167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y51',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.860000', '47.248333'],
        ['23.031864', '46.741867'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y510',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.310700', '42.800347']] },
  },
  {
    type: 'Feature',
    name: 'Y520',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.546111', '45.247222'],
        ['20.311167', '44.819361'],
        ['21.462778', '44.324444'],
        ['22.392222', '43.912222'],
        ['23.289167', '43.517500'],
        ['23.806111', '43.284722'],
        ['24.220556', '43.095000'],
        ['25.992222', '42.499722'],
        ['26.956111', '42.159444'],
        ['27.216111', '42.065556'],
        ['27.806944', '41.450278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y526',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.360361', '45.828806'],
        ['13.589722', '45.851111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y550',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.055556', '42.112500'],
        ['21.196944', '41.832222'],
        ['21.448611', '41.337778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y551',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.559722', '43.755833'],
        ['25.992222', '42.499722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y553',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.655833', '44.258611'],
        ['24.753889', '43.978056'],
        ['24.813889', '43.804444'],
        ['24.850000', '43.700000'],
        ['25.387778', '43.142222'],
        ['25.992222', '42.499722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y558',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.496667', '46.687500'],
        ['22.716389', '47.031389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y559',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.924139', '47.100361'],
        ['22.253056', '46.909167'],
        ['22.891667', '46.531667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y560',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.566961', '45.224122'],
        ['14.267878', '45.482461'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y564',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.891111', '53.684444'],
        ['20.041667', '53.644722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y565',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.328333', '52.704444'],
        ['20.833333', '53.260833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y566',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.833333', '53.260833'],
        ['21.195000', '53.089722'],
        ['22.295833', '52.425833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y572',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.496667', '46.687500'],
        ['21.571111', '46.690556'],
        ['23.031864', '46.741867'],
        ['23.744722', '46.760000'],
        ['26.209444', '46.793611'],
        ['27.295278', '46.790833'],
        ['28.166667', '46.781667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y574',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.928889', '45.931667'],
        ['20.623333', '45.855833'],
        ['21.314444', '45.896944'],
        ['21.473056', '45.905556'],
        ['21.790000', '45.922778'],
        ['22.408075', '45.953222'],
        ['22.646667', '45.837500'],
        ['22.968889', '45.828056'],
        ['23.397956', '45.813850'],
        ['24.152583', '45.785000'],
        ['24.819444', '45.864444'],
        ['25.087222', '46.048056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y575',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.077778', '42.724722'],
        ['21.029444', '43.997778'],
        ['20.886667', '44.163333'],
        ['20.311167', '44.819361'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y576',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.088056', '45.960278'],
        ['23.703056', '45.312778'],
        ['25.090833', '44.164444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y579',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.184167', '42.435833'],
        ['14.165278', '42.835833'],
        ['13.875833', '43.333333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y621',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.133992', '50.554986'],
        ['14.216386', '50.897353'],
        ['14.179444', '51.065833'],
        ['14.116117', '51.384406'],
        ['14.193611', '51.804444'],
        ['14.174472', '51.898597'],
        ['14.585556', '52.379722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y647',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['18.391667', '41.233333']] },
  },
  {
    type: 'Feature',
    name: 'Y66',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.379444', '46.563611'],
        ['13.628611', '46.440556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y664',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.281444', '45.071083']] },
  },
  {
    type: 'Feature',
    name: 'Y700',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.015817', '48.230153'],
        ['12.947778', '48.538611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y701',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.015817', '48.230153'],
        ['13.142500', '48.487778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y714',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['20.744444', '44.143500'],
        ['21.626944', '43.687500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y765',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.018611', '43.771111'],
        ['13.471111', '43.586444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y800',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.042778', '51.640000'],
        ['12.548056', '51.430000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y88',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.304722', '43.496581'],
        ['16.946667', '43.633333'],
        ['17.537778', '43.753611'],
        ['18.105556', '43.866944'],
        ['18.449828', '43.933864'],
        ['19.180278', '44.136667'],
        ['19.365000', '44.185000'],
        ['19.874694', '44.323889'],
        ['20.812500', '44.326944'],
        ['21.245556', '44.325556'],
        ['21.462778', '44.324444'],
        ['22.555278', '44.311944'],
        ['23.425556', '44.294444'],
        ['23.755833', '44.286111'],
        ['24.655833', '44.258611'],
        ['25.223372', '44.237422'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y9',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.472778', '46.218333']] },
  },
  {
    type: 'Feature',
    name: 'Y94',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.326667', '43.214167'],
        ['23.362500', '42.920278'],
        ['23.389389', '42.698111'],
        ['23.406944', '42.356667'],
        ['23.410278', '42.294722'],
        ['23.455556', '41.402778'],
        ['23.805000', '41.276389'],
        ['23.924444', '41.233056'],
        ['24.140278', '41.153889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y941',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.500000', '45.333333'],
        ['12.493333', '45.132500'],
        ['12.505833', '44.797778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.464722', '53.216667'],
        ['30.618056', '54.170000'],
        ['26.978056', '55.812778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Y97',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['32.464722', '53.216667'],
        ['30.961667', '54.195278'],
        ['30.538056', '54.460000'],
        ['29.359167', '55.140278'],
        ['29.181389', '55.240000'],
        ['28.750278', '55.498333'],
        ['27.821389', '56.038889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z115',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.561486', '52.483914']] },
  },
  {
    type: 'Feature',
    name: 'Z121',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.241944', '49.020000'],
        ['17.718278', '49.229944'],
        ['18.708997', '49.694664'],
        ['19.351944', '49.829167'],
        ['19.900000', '49.940278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z122',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.061667', '45.278056'],
        ['12.493333', '45.132500'],
        ['13.091389', '44.985833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z126',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.572836', '49.847494'],
        ['19.150278', '50.474167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z127',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.572836', '49.847494'],
        ['19.064722', '49.940278'],
        ['19.912778', '50.095556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z130',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.272778', '52.392222'],
        ['12.690278', '52.818056'],
        ['12.830556', '52.959167'],
        ['12.957778', '53.073889'],
        ['13.155000', '53.249722'],
        ['13.508333', '53.708056'],
        ['13.645833', '53.924167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z131',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.042778', '51.640000'],
        ['12.172928', '51.804114'],
        ['12.199722', '51.837500'],
        ['12.397392', '52.084322'],
        ['12.646667', '52.395833'],
        ['12.934722', '52.745556'],
        ['13.170833', '53.029722'],
        ['13.597131', '53.527933'],
        ['13.731389', '53.686111'],
        ['13.796667', '53.762222'],
        ['14.094167', '54.158889'],
        ['14.297222', '54.461944'],
        ['14.857778', '54.805556'],
        ['15.634722', '55.473333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z14',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.530000', '54.566389'],
        ['12.716944', '54.710000'],
        ['12.935278', '54.795833'],
        ['14.082222', '54.580833'],
        ['13.925278', '53.625278'],
        ['13.602500', '53.041389'],
        ['13.036389', '52.512222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z159',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.995556', '50.030278'],
        ['17.869722', '50.348333'],
        ['17.569722', '51.089444'],
        ['17.231111', '51.830000'],
        ['17.175000', '51.952778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z169',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.620833', '49.101111'],
        ['19.331667', '49.235556'],
        ['19.469444', '49.611667'],
        ['19.612500', '51.306111'],
        ['19.656111', '51.800000'],
        ['19.995278', '51.850833'],
        ['22.200833', '52.155278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z172',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['16.128611', '51.630556'],
        ['16.497500', '54.260278'],
        ['17.072222', '55.194167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z175',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.976667', '49.444722'],
        ['20.360000', '51.387778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z176',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.976667', '49.444722'],
        ['23.404444', '52.188056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z179',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.923611', '49.351944'],
        ['22.200833', '52.155278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z181',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.613611', '52.510833'],
        ['14.994167', '52.525278'],
        ['15.411389', '52.540000'],
        ['15.671389', '52.519722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z182',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.909583', '52.335861'],
        ['22.160556', '52.320556'],
        ['23.208889', '52.227778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z186',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.976667', '49.444722'],
        ['23.664722', '52.016389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z187',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.976667', '49.444722'],
        ['20.251111', '50.647222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z20',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.321783', '52.073311'],
        ['12.397392', '52.084322'],
        ['12.671308', '52.123761'],
        ['12.956036', '52.163992'],
        ['13.732500', '52.269722'],
        ['14.088147', '52.316569'],
        ['14.585556', '52.379722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z21',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.548056', '51.430000'],
        ['12.905278', '51.290833'],
        ['13.210525', '51.170789'],
        ['13.366739', '51.108539'],
        ['13.598889', '51.015547'],
        ['13.811111', '50.931111'],
        ['14.000758', '50.837914'],
        ['14.874664', '49.532328'],
        ['14.956169', '49.281406'],
        ['15.387128', '48.892914'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z212',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.297222', '54.461944'],
        ['14.564167', '54.819444'],
        ['14.758692', '55.065578'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z220',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.194264', '51.353717'],
        ['13.870556', '51.456944'],
        ['14.252500', '51.513333'],
        ['14.738056', '51.583056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z225',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.210525', '51.170789'],
        ['14.961667', '51.406944'],
        ['15.986944', '51.444722'],
        ['16.176667', '51.450556'],
        ['16.748889', '51.469722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z30',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.560008', '49.009728'],
        ['14.734169', '49.300456'],
        ['14.874664', '49.532328'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z31',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.495731', '50.585761'],
        ['14.216386', '50.897353'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z317',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.308333', '45.240000'],
        ['22.242222', '46.351944'],
        ['22.716389', '47.031389'],
        ['23.343333', '48.026667'],
        ['24.011667', '49.264722'],
        ['25.003889', '50.985278'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z330',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.763611', '54.916667'],
        ['14.094167', '55.411944'],
        ['14.836667', '56.132778'],
        ['15.383806', '56.678194'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z348',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.884722', '51.278056'],
        ['15.010556', '51.203056'],
        ['15.610833', '51.128333'],
        ['16.020000', '51.098611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z349',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.293333', '50.953056'],
        ['15.610833', '51.128333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z35',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.344000', '49.937283'],
        ['13.241222', '49.909336'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z36',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.103056', '52.245556'],
        ['13.983889', '51.845278'],
        ['13.951667', '51.735278'],
        ['13.870556', '51.456944'],
        ['13.709167', '50.893333'],
        ['13.628831', '50.715467'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z364',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['25.370833', '51.928889'],
        ['25.195833', '54.213056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z366',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.031667', '51.593056'],
        ['29.583333', '53.143333'],
        ['29.760556', '53.616944'],
        ['29.829444', '53.798611'],
        ['29.993056', '54.218611'],
        ['30.229444', '54.809722'],
        ['30.359944', '55.127889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z367',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['21.923611', '49.351944'],
        ['23.664722', '52.016389'],
        ['24.221389', '52.559722'],
        ['24.363611', '52.809167'],
        ['25.195833', '54.213056'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z368',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.543056', '51.723056'],
        ['24.221389', '52.559722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z369',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.887694', '52.109361'],
        ['26.722500', '53.894722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z388',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.281444', '45.071083']] },
  },
  {
    type: 'Feature',
    name: 'Z39',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.947778', '48.538611'],
        ['13.075833', '48.687778'],
        ['13.242222', '48.880000'],
        ['13.394519', '49.119197'],
        ['13.820325', '49.420892'],
        ['14.101172', '49.617014'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z400',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.360278', '54.325556'],
        ['13.496111', '54.506111'],
        ['13.560778', '54.916667'],
        ['13.557528', '55.411306'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z401',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.066742', '49.704272'],
        ['15.128769', '49.520544'],
        ['15.221472', '49.243314'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z402',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['31.015000', '52.083333'],
        ['30.298333', '52.473611'],
        ['30.048056', '52.608056'],
        ['28.881667', '53.228889'],
        ['28.648056', '53.349722'],
        ['27.689722', '53.618056'],
        ['26.722500', '53.894722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z410',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.614503', '49.567917']] },
  },
  {
    type: 'Feature',
    name: 'Z419',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.961667', '51.406944'],
        ['15.984139', '51.238778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z427',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['13.472778', '46.218333']] },
  },
  {
    type: 'Feature',
    name: 'Z43',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.018889', '44.920556'],
        ['12.118056', '44.999722'],
        ['12.281444', '45.071083'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z442',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['19.566667', '56.871389'],
        ['21.084750', '56.512778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z443',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.876667', '56.531667'],
        ['21.608056', '56.325000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z451',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.961250', '56.120472'],
        ['14.033333', '56.564444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z456',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.118056', '44.999722'],
        ['12.172500', '44.905000'],
        ['12.505833', '44.797778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z460',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['22.160556', '52.320556'],
        ['22.295833', '52.425833'],
        ['22.538889', '52.613333'],
        ['22.944444', '52.921667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z490',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.961250', '56.120472'],
        ['13.453833', '56.126444'],
        ['14.836667', '56.132778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z491',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.501194', '55.250028'],
        ['14.116278', '55.054611'],
        ['14.441111', '54.949444'],
        ['14.564167', '54.819444'],
        ['14.709444', '54.583611'],
        ['14.895000', '54.277500'],
        ['15.007778', '54.080000'],
        ['15.192222', '53.739722'],
        ['15.333056', '53.475278'],
        ['14.881667', '53.378889'],
        ['14.410000', '53.275833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z493',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.501194', '55.250028'],
        ['14.166667', '54.916667'],
        ['14.338889', '54.774722'],
        ['14.895000', '54.277500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z494',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.713056', '53.856389'],
        ['27.566389', '55.784722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z495',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.522864', '45.585806'],
        ['13.700278', '45.619167'],
        ['13.878525', '45.652172'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z647',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.341389', '47.987778'],
        ['18.486111', '47.854722'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z648',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.341389', '47.987778'],
        ['17.980556', '47.846389'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z649',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.048333', '48.127500'],
        ['17.299722', '48.119444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z650',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.188889', '47.469722'],
        ['28.166667', '46.781667'],
        ['27.393611', '46.627778'],
        ['27.288333', '46.606389'],
        ['26.825556', '46.510917'],
        ['26.548889', '46.506667'],
        ['26.367222', '46.503333'],
        ['26.125833', '46.498611'],
        ['25.592222', '46.486667'],
        ['23.744722', '46.760000'],
        ['22.854192', '46.995831'],
        ['22.716389', '47.031389'],
        ['22.069722', '47.196111'],
        ['21.860000', '47.248333'],
        ['18.324722', '48.333333'],
        ['17.541111', '48.617500'],
        ['12.461247', '49.810744'],
        ['12.252500', '49.830000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z653',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['29.586667', '51.466389'],
        ['29.965000', '51.866389'],
        ['30.521667', '52.203056'],
        ['30.997194', '52.533500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z702',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.180278', '56.040556'],
        ['12.477778', '56.145556'],
        ['14.307778', '56.764444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z703',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.117222', '56.120556'],
        ['12.333056', '56.260556'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z706',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.050278', '55.199444'],
        ['12.241111', '56.106667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z71',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.118056', '44.999722'],
        ['12.493333', '45.132500'],
        ['12.789167', '45.283889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z711',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.313611', '54.945556'],
        ['12.379211', '55.001500'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z715',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.016389', '50.264444'],
        ['12.199581', '50.344808'],
        ['12.961389', '50.598333'],
        ['13.057186', '50.629694'],
        ['13.709167', '50.893333'],
        ['13.811111', '50.931111'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z717',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.132222', '52.951389'],
        ['12.338581', '52.975206'],
        ['12.830556', '52.959167'],
        ['13.170833', '53.029722'],
        ['13.615833', '53.120000'],
        ['13.675278', '53.131944'],
        ['13.911028', '53.178592'],
        ['14.410000', '53.275833'],
        ['16.551944', '54.435278'],
        ['18.448056', '55.258611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z72',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.208056', '54.299722'],
        ['18.448056', '55.258611'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z73',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['18.247500', '50.526111'],
        ['19.150278', '50.474167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z736',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.781389', '52.181389'],
        ['28.955000', '52.751667'],
        ['29.170278', '53.437222'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z737',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.894167', '53.178056'],
        ['27.133056', '52.546389'],
        ['28.781389', '52.181389'],
        ['31.015000', '52.083333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z79',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.331389', '48.394444'],
        ['12.559444', '48.058889'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z80',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['15.000000', '54.416389'],
        ['15.655000', '54.916667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z803',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.588000', '41.804667'],
        ['12.918056', '41.541111'],
        ['13.680528', '41.446019'],
        ['13.970667', '41.296778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z806',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.048611', '42.618333'],
        ['12.004722', '42.849167'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z810',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.906389', '43.207222'],
        ['12.919444', '42.029725'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z811',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['23.343333', '48.026667'],
        ['23.211944', '50.021667'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z860',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['24.093056', '52.213056'],
        ['24.984722', '52.359722'],
        ['27.238056', '52.689722'],
        ['31.359722', '52.968333'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z888',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['28.730556', '48.125000'],
        ['28.813333', '47.545000'],
        ['28.863611', '47.187222'],
        ['28.904500', '46.926028'],
        ['28.865000', '45.975000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z905',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.653333', '41.190833'],
        ['14.483333', '41.514167'],
        ['14.133611', '42.168056'],
        ['13.692778', '42.808056'],
        ['13.368056', '43.272778'],
        ['13.018611', '43.771111'],
        ['12.172500', '44.905000'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z906',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['14.132222', '43.571389'],
        ['12.281444', '45.071083'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z910',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.180278', '43.066389'],
        ['13.055833', '42.395833'],
        ['13.345556', '42.168889'],
        ['14.031111', '41.622778'],
        ['14.491389', '41.304167'],
        ['14.653333', '41.190833'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z923',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.016667', '41.292778']] },
  },
  {
    type: 'Feature',
    name: 'Z924',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['13.135792', '41.994758'],
        ['13.281944', '42.059722'],
        ['13.737500', '42.188889'],
        ['13.891667', '42.255278'],
        ['14.172778', '42.375556'],
        ['14.331389', '42.442778'],
        ['14.720000', '42.605833'],
        ['14.896111', '42.679167'],
        ['15.437500', '42.907500'],
        ['16.073889', '43.170556'],
        ['17.040000', '43.558611'],
        ['17.537778', '43.753611'],
        ['18.105556', '43.866944'],
        ['18.449828', '43.933864'],
        ['19.180278', '44.136667'],
        ['19.365000', '44.185000'],
        ['19.874694', '44.323889'],
        ['21.409722', '44.988333'],
        ['22.959722', '45.450000'],
        ['24.152583', '45.785000'],
        ['25.087222', '46.048056'],
        ['25.841667', '46.253056'],
        ['26.348889', '46.387500'],
        ['26.825556', '46.510917'],
        ['28.106111', '46.925833'],
        ['29.388889', '47.323056'],
        ['31.598333', '47.955000'],
        ['32.357778', '48.241389'],
        ['32.673056', '48.357778'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z93',
    color: 'rgb(15, 15, 15)',
    geometry: { type: 'LineString', coordinates: [['12.338639', '50.190489']] },
  },
  {
    type: 'Feature',
    name: 'Z95',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.971389', '53.098333'],
        ['18.251111', '53.245833'],
        ['18.491389', '53.371389'],
        ['18.781389', '53.521111'],
        ['18.753500', '53.765806'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z96',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['17.035278', '53.116111'],
        ['17.265833', '53.112500'],
        ['17.537778', '53.107500'],
        ['17.971389', '53.098333'],
        ['18.452222', '53.144722'],
        ['18.696667', '53.167500'],
        ['18.955000', '53.190833'],
        ['19.177222', '53.211111'],
        ['19.350000', '53.226944'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z982',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.247222', '44.405833'],
        ['12.594722', '44.176944'],
        ['13.471111', '43.586444'],
      ],
    },
  },
  {
    type: 'Feature',
    name: 'Z998',
    color: 'rgb(15, 15, 15)',
    geometry: {
      type: 'LineString',
      coordinates: [
        ['12.068611', '52.872222'],
        ['12.429722', '52.705278'],
        ['12.876111', '52.249444'],
        ['13.279722', '51.830556'],
        ['13.627669', '51.460847'],
      ],
    },
  },
]
