import React from 'react'
import * as geolib from 'geolib'
import { useSelector } from 'react-redux'

function ADF({ projection }) {
  const reduxComm = useSelector((state) => state.comm)
  const bearing =
    (geolib.getGreatCircleBearing(
      { latitude: 48.884167, longitude: 24.691389 },
      reduxComm.cP,
    ) +
      360) %
    360
  const bearing2 =
    (geolib.getGreatCircleBearing(
      { latitude: 50.60833333, longitude: 26.13966667 },
      reduxComm.cP,
    ) +
      360) %
    360

  const cp = geolib.computeDestinationPoint(
    { latitude: 48.884167, longitude: 24.691389 },
    300000,
    bearing,
  )
  const cp2 = geolib.computeDestinationPoint(
    { latitude: 50.60833333, longitude: 26.13966667 },
    300000,
    bearing2,
  )
  const [x1, y1] = projection([24.691389, 48.884167])
  const [x21, y21] = projection([26.13966667, 50.60833333])
  const [x2 = 2000, y2 = 250] = projection([cp?.longitude, cp?.latitude])
  const [x22 = 2000, y22 = 250] = projection([cp2?.longitude, cp2?.latitude])
  // console.log(bearing, x2, y2, reduxComm)
  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2 + Math.random() * 10}
        y2={y2 + Math.random() * 10}
        stroke={'green'}
      />
      <line
        x1={x21}
        y1={y21}
        x2={x22 + Math.random() * 10}
        y2={y22 + Math.random() * 10}
        stroke={'green'}
      />
    </>
  )
}

export default ADF
