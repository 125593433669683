/*
    node_js
    1/31/2021 2:44 AM
    by Oleksandr
*/

import acGroups from './acGroups.json'
import { strategicConstrains } from '../fdp/stategicConstrains'
import { calc, getDiffBetweenAngles } from '../../utils/convertors'

const {
  getCASfromMach,
  getMachfromCAS,
  getTASfromCAS,
} = require('../geometry/airspeedConverter')

const geolib = require('geolib')

class Flight {
  constructor(data) {
    let {
      flight,
      rfl,
      fpl,
      cas,
      squawk,
      adesRunway,
      adesRunwayDetails,
      type,
      exC,
      ades,
      xfl,
    } = data

    this.callsign = flight.c
    this.route = fpl[10]

    this.currentPosition = flight.cP
    this.nextPoint = flight.nP || 1
    this.prevPoint = flight.prP || this.nextPoint - 1
    this.heading = parseInt(flight.h)
    this.bearing = parseInt(flight.sH)
    this.isOnHeading = flight.isOH
    this.isOnOrbit = flight.isOO || false
    this.isOrbitStarted = flight.isOSt || false
    this.orbitSide = flight.oS //(L or R)
    this.isOnHeadingTime = 0
    this.ROT = 0
    this.distanceToNextPoint = 0
    this.distanceToPrevPoint = 999999999
    this.passedPoint = false

    this.rfl = rfl
    this.cas = cas
    this.Mach = flight.M
    this.selectedAltitude = flight.sA
    this.speed = flight.s
    this.isOnSpeed = flight.isOS
    this.altitude = flight.a
    this.roc = flight.r || 0
    this.selectedRoc = flight.sR || 0
    this.isDescending = flight.isDesc || false
    this.isClimbing = flight.isClimb || false
    this.exC = exC || null
    this.xfl = flight.xfl || xfl || null
    this.selectedSpeed = flight.sS
    this.isAdvanced = flight.isAdvanced
    this.isAssumed = flight.isAssumed
    this.isCorrelated = flight.isCorrelated
    this.isApproach = flight.isApp
    this.isClearedILS = flight.isCILS || true
    this.isGA = flight.isGA || false
    this.isInApproachArea = flight.isIAA
    this.ades = ades || null
    this.adesRunway = adesRunway
    this.adesRunwayDetails = adesRunwayDetails
    this.isSelfApproach = false
    this.tas = getTASfromCAS(this.speed, this.altitude)
    this.gs = this.tas //TODO Ground speed calculation with wind effect
    this.isActive = true
    this.squawk = squawk

    this.perf = null
    if (acGroups[type]) {
      this.perf = acGroups[type]
    }

    ///TODO LoA
    let sC = strategicConstrains.filter(
      (sC) => sC.ades === this.ades && sC.points.includes(this.exC),
    )
    if (sC.length > 0) {
      this.xfl = this.rfl >= sC[0].xfl ? sC[0].xfl : null
    }
    this.exCIndex = this.route.map((e) => e.name).indexOf(this.exC)
  }

  checkIfOnSpeed = (perf) => {
    if (this.isOnSpeed) {
      if (this.selectedSpeed > perf[16]) {
        this.selectedSpeed = perf[5]
      } else if (this.selectedSpeed < perf[15]) {
        this.selectedSpeed = perf[15]
      }
    } else {
      this.selectedSpeed = perf[4]
    }
  }

  calculateNextPoint = (calcInterval = 4) => {
    if (this.route.length === 1) {
      this.isTerminated = true
      this.isActive = false
      return
    }
    this.passedPoint = false
    this.calculateHeading(calcInterval)
    let perf = null
    if (this.perf) {
      if (
        !this.tempPerf ||
        (this.tempPerf.alt > this.altitude / 100 &&
          this.tempPerf.prevAlt &&
          this.tempPerf.prevAlt >= this.altitude / 100) ||
        (this.tempPerf.nextAlt &&
          this.tempPerf.nextAlt > this.altitude / 100 &&
          this.tempPerf.alt < this.altitude / 100)
      ) {
        const filteredPerf_layers = this.perf.perf_layers
          .map((perf, ind, arr) => {
            perf['prevAlt'] = arr[ind - 1]?.alt
            perf['nextAlt'] = arr[ind + 1]?.alt
            return perf
          })
          .filter((ac) => {
            return ac.alt >= this.altitude / 100
          })
        this.tempPerf = filteredPerf_layers[0]
      }

      if (this.tempPerf.perf) {
        perf = this.tempPerf['perf']
        this.minSpeed = parseInt(perf[15])
        if (this.minSpeed > 180 && this.altitude < 25000) this.minSpeed = 180
        this.maxSpeed = parseInt(perf[16])
        this.maxFL = parseInt(this.perf['perf'][0])
        if (this.selectedAltitude / 100 > this.maxFL) {
          this.selectedAltitude = this.maxFL * 100
        }
        if (this.isApproach) {
          if (this.altitude > this.selectedAltitude) {
            this.standartRoc = (parseInt(this.gs) * 5) / (60 / calcInterval)
            this.maxRoc = parseInt(perf[13]) / (60 / calcInterval)
          } else {
            this.standartRoc = 125
            this.maxRoc = parseInt(perf[11]) / (60 / calcInterval)
          }
        } else {
          if (this.altitude > this.selectedAltitude) {
            this.standartRoc = parseInt(perf[12]) / (60 / calcInterval)
            this.maxRoc = parseInt(perf[13]) / (60 / calcInterval)
          }
          if (this.altitude < this.selectedAltitude) {
            this.standartRoc = parseInt(perf[10]) / (60 / calcInterval)
            this.maxRoc = parseInt(perf[11]) / (60 / calcInterval)
          }
        }
      }
    } else {
      this.standartRoc = 125 * (calcInterval / 4)
      this.maxRoc = 230 * (calcInterval / 4)
    }

    if (this.distanceToDestination < (this.altitude + 2000) / 300) {
      if (
        this.standartRoc > this.roc &&
        this.standartRoc + 20 * (calcInterval / 4) < this.maxRoc
      ) {
        this.standartRoc += 20 * (calcInterval / 4)
      } else if (
        this.standartRoc <= this.roc &&
        this.roc + 20 * (calcInterval / 4) < this.maxRoc
      ) {
        this.standartRoc = this.roc + 20 * (calcInterval / 4)
      }
    }

    if (
      (calcInterval > 4 &&
        Math.abs(this.altitude - this.selectedAltitude) <= this.roc) ||
      Math.abs(this.altitude - this.selectedAltitude) <= this.selectedRoc
    ) {
      this.selectedRoc = 0
      this.roc = 0
      this.altitude = this.selectedAltitude
      this.isDescending = false
      this.isClimbing = false
    } else {
      if (
        Math.abs(this.altitude - this.selectedAltitude) <= 300 &&
        this.standartRoc > parseInt(300 / 15)
      )
        this.standartRoc = parseInt(300 / 15)
      else if (
        Math.abs(this.altitude - this.selectedAltitude) <= 1200 &&
        this.standartRoc > parseInt(1000 / 15)
      )
        this.standartRoc = parseInt(1000 / 15)
      this.selectedRoc =
        Math.abs(this.altitude - this.selectedAltitude) > this.standartRoc
          ? this.standartRoc
          : Math.abs(this.altitude - this.selectedAltitude) / 2
    }

    if (
      this.selectedAltitude > this.altitude &&
      this.isDescending &&
      this.roc > 0
    ) {
      this.roc -= 30
      if (this.roc < 0) {
        this.roc = 20
        this.isDescending = false
        this.isClimbing = true
      }
    } else if (
      this.selectedAltitude < this.altitude &&
      this.isClimbing &&
      this.roc > 0
    ) {
      this.roc -= 30
      if (this.roc < 0) {
        this.roc = 20
        this.isDescending = true
        this.isClimbing = false
      }
    } else if (!this.isDescending && !this.isClimbing) {
      if (this.altitude > this.selectedAltitude) {
        this.isDescending = true
      } else if (this.altitude < this.selectedAltitude) {
        this.isClimbing = true
      }
    } else {
      let rocDiff = Math.abs(this.selectedRoc - this.roc)
      if (this.selectedRoc > this.roc) {
        this.roc =
          rocDiff > this.selectedRoc / 3 && rocDiff <= this.selectedRoc
            ? this.roc + this.selectedRoc / 3
            : this.selectedRoc
      } else if (this.selectedRoc < this.roc) {
        this.roc = rocDiff > 20 ? this.roc - 20 : this.selectedRoc
      }
    }

    if (this.isClimbing) this.altitude = parseInt(this.altitude + this.roc)
    else if (this.isDescending)
      this.altitude = parseInt(this.altitude - this.roc)

    if (this.altitude === this.selectedAltitude) {
      this.isDescending = false
      this.isClimbing = false
    }

    if (this.altitude > 5000 && this.altitude < 10000) {
      if (perf) {
        if (this.isApproach) {
          this.checkIfOnSpeed(perf)
        } else {
          this.selectedSpeed = perf[0]
        }
      } else if (this.isApproach && this.selectedSpeed >= 250) {
        this.selectedSpeed = 250
      } else if (this.cas > 250) {
        this.selectedSpeed = 250
      } else {
        this.selectedSpeed = this.cas
      }
    }
    if (this.altitude > 4000 && this.altitude < 7500 && this.isApproach) {
      if (perf) {
        this.checkIfOnSpeed(perf)
      } else if (this.cas > 220 && this.selectedSpeed > 220) {
        this.selectedSpeed = 220
      }
    }
    if (this.altitude > 3000 && this.altitude <= 4000 && this.isApproach) {
      if (perf) {
        this.checkIfOnSpeed(perf)
      } else if (this.cas > 200 && this.selectedSpeed > 200) {
        this.selectedSpeed = 200
      }
    }
    if (this.altitude <= 3000) {
      if (perf) {
        this.checkIfOnSpeed(perf)
      } else if (this.cas > 180) {
        this.selectedSpeed = 180
      }
    }
    if (this.altitude >= 10000 && this.altitude < 25000) {
      if (!this.isOnSpeed) {
        if (perf) {
          this.selectedSpeed = perf[0]
        } else {
          this.selectedSpeed = this.cas
        }
      } else if (this.isOnSpeed && perf) {
        if (this.selectedSpeed > this.maxSpeed) {
          this.selectedSpeed = this.maxSpeed
        } else if (this.selectedSpeed < this.minSpeed) {
          this.selectedSpeed = this.minSpeed
        }
      }
    }

    if (this.altitude >= 25000) {
      if (perf) {
        if (this.isOnSpeed) {
          this.selectedSpeed = getCASfromMach(this.Mach, this.altitude)
          if (this.selectedSpeed > this.maxSpeed) {
            this.selectedSpeed = this.maxSpeed
          } else if (this.selectedSpeed < this.minSpeed) {
            this.selectedSpeed = this.minSpeed
          }
        } else {
          this.selectedSpeed = perf[0]
        }
      } else {
        this.selectedSpeed = getCASfromMach(this.Mach, this.altitude)
      }
    }

    if (this.speed - this.selectedSpeed < -1 * (calcInterval / 4))
      this.speed += 2 * (calcInterval / 4)
    else if (this.speed - this.selectedSpeed >= 1 * (calcInterval / 4))
      this.speed -= 1 * (calcInterval / 4)
    else if (this.speed - this.selectedSpeed === -1 * (calcInterval / 4))
      this.speed += 1 * (calcInterval / 4)
    // else if (this.speed - this.selectedSpeed <= 1 &&
    //     this.speed - this.selectedSpeed >= -1) this.speed = this.selectedSpeed
    this.tas = getTASfromCAS(this.speed, this.altitude)
    this.calculatedMach = getMachfromCAS(this.speed, this.altitude)

    this.gs = this.tas //TODO wind effect

    this.currentPosition = geolib.computeDestinationPoint(
      this.currentPosition,
      (this.tas * 1.852 * 1000) / 60 / (60 / calcInterval),
      this.heading + 6,
    )
    this.distanceToNextPoint = getDistanceToNextPoint(
      this.currentPosition,
      this.route[this.nextPoint],
    )
    while (
      calcInterval > 4 &&
      this.distanceToNextPoint <= (this.gs / 3600) * calcInterval &&
      this.route.length > this.nextPoint + 1
    ) {
      this.nextPoint++
      this.distanceToNextPoint = getDistanceToNextPoint(
        this.currentPosition,
        this.route[this.nextPoint],
      )
    }

    if (this.nextPoint === this.prevPoint) {
      this.distanceToPrevPoint = this.distanceToNextPoint
    } else {
      let tempDisToPrevPoint = geolib.convertDistance(
        geolib.getDistance(this.currentPosition, this.route[this.prevPoint]),
        'sm',
      )
      if (tempDisToPrevPoint < this.distanceToPrevPoint) {
        this.distanceToPrevPoint = tempDisToPrevPoint
      } else {
        this.distanceToPrevPoint = 999999999
        this.prevPoint++
        this.passedPoint = true
      }
    }

    if (calcInterval > 4) {
      this.distanceToDestination = geolib.convertDistance(
        geolib.getDistance(
          this.currentPosition,
          this.route[this.route.length - 1],
        ),
        'sm',
      )
    } else {
      this.distanceRoute = this.route.reduce(
        (acc, r, ind) => (ind >= this.nextPoint ? acc + r.dist : acc),
        0,
      )
      this.distanceToDestination = this.distanceRoute + this.distanceToNextPoint
    }

    if (this.exCIndex >= 0 && this.xfl) {
      let distToExCop = geolib.convertDistance(
        geolib.getDistance(this.currentPosition, this.route[this.exCIndex]),
        'sm',
      )
      if (distToExCop < Math.abs(this.altitude - this.xfl * 100 + 4000) / 300) {
        if (this.selectedAltitude > this.xfl * 100) {
          this.TOD = true
          if (!this.isAssumed) {
            this.selectedAltitude = this.xfl * 100
          }
        } else {
          this.TOD = false
        }
      }
      if (
        distToExCop <
        Math.abs(this.altitude - this.xfl * 100 + 10000) / 300
      ) {
        if (this.selectedAltitude < this.xfl * 100) {
          this.TOC = true
          if (!this.isAssumed) {
            this.selectedAltitude = this.xfl * 100
          }
        } else {
          this.TOC = false
        }
      }
    }
    if (calcInterval > 4 && this.distanceToDestination < this.altitude / 300) {
      this.isApproach = true
      this.selectedAltitude = 3000
    } else if (
      this.distanceToDestination < this.altitude / 300 &&
      this.altitude > 4000
    ) {
      this.isApproach = true
      if (this.altitude - this.selectedAltitude < 100) {
        this.TOD = true
        if (
          this.altitude > 12000 &&
          !this.isAssumed &&
          this.selectedAltitude > 12000
        )
          this.selectedAltitude = 12000
      } else {
        this.TOD = false
      }
      //TODO Allmost done Request discend
    }
    let radiusOfTurn =
      (((this.tas * this.tas) / 11.26) * Math.tan((30 * Math.PI) / 180)) / 6076
    if (this.adesRunwayDetails) {
      this.isInApproachArea = geolib.isPointInPolygon(
        this.currentPosition,
        this.adesRunwayDetails.approachArea,
      )

      if (this.altitude < 5100) {
        if (
          this.selectedAltitude !== this.adesRunwayDetails.elevation &&
          this.isClearedILS &&
          this.altitude - this.adesRunwayDetails.elevation >
            Math.tan((3 * Math.PI) / 180) *
              6076 *
              geolib.convertDistance(
                geolib.getDistance(
                  this.currentPosition,
                  this.adesRunwayDetails.threshold,
                ),
                'sm',
              ) &&
          this.isInApproachArea &&
          !this.isGA
        ) {
          this.selectedAltitude = this.adesRunwayDetails.elevation
        }
        if (this.isInApproachArea && this.isClearedILS && !this.isGA) {
          if (
            this.isOnHeading &&
            geolib.getDistanceFromLine(
              this.currentPosition,
              this.adesRunwayDetails.threshold,
              geolib.computeDestinationPoint(
                this.adesRunwayDetails.threshold,
                18 * 1.852 * 1000,
                (this.adesRunwayDetails.heading + 180 + 6) % 360,
              ),
            ) /
              1852 <
              radiusOfTurn / 1.3 &&
            getDiffBetweenAngles(this.adesRunwayDetails.heading, this.heading) <
              31
          ) {
            this.nextPoint = this.route.length - 1
            this.isOnHeading = false
          }
        } else if (this.isGA && this.selectedAltitude < 3000) {
          this.selectedAltitude = 3000
        }
      }

      if (this.isGA && !this.isInApproachArea) {
        this.isGA = false
      }
    }

    let compareValue =
      calcInterval <= 4 ? radiusOfTurn * 3 : (this.gs / 3600) * calcInterval
    if (this.distanceToNextPoint < compareValue) {
      if (!this.route[this.nextPoint].isRunway) {
        this.nextPoint += 1
      } else {
        if (
          (this.altitude < this.adesRunwayDetails.elevation + 400 &&
            this.distanceToNextPoint < 1) ||
          (calcInterval > 4 &&
            this.altitude < this.adesRunwayDetails.elevation + 3000)
        ) {
          this.nextPoint = this.route.length
        } else if (!this.isSelfApproach) {
          this.isSelfApproach = true
          this.isOnHeading = true
          this.isOnHeadingTime = 0
          this.bearing = (this.adesRunwayDetails.heading + 180) % 360
        } else if (this.selectedAltitude > 3000) {
          this.nextPoint = this.route.length - 3
          this.selectedAltitude = 3000
          this.prevPoint = this.nextPoint - 1
        }
      }
    }
    if (this.route.length === this.nextPoint) {
      this.isTerminated = true
      this.isActive = false
      this.altitude = 1000
    }
  }

  calculateHeading = (calcInterval = 4) => {
    this.isOnHeadingTime++
    if (this.isSelfApproach && this.isOnHeadingTime > 60 / (calcInterval / 4)) {
      this.isSelfApproach = false
      this.isOnHeading = false
    } else if (this.isOnHeadingTime > 400) {
      this.isOnHeading = false
    }
    if (!this.isOnHeading && !this.isOnOrbit) {
      let bearing = geolib.getGreatCircleBearing(
        this.currentPosition,
        this.route[this.nextPoint],
      )
      this.bearing = bearing - 6
    }
    let stdROT =
      this.altitude > 7000
        ? (Math.tan((30 * Math.PI) / 180) * 1091) / this.tas
        : (Math.tan((35 * Math.PI) / 180) * 1091) / this.tas

    //edited *4 to accept fast calculation
    if (this.isOnOrbit) {
      if (
        Math.abs(this.heading - this.bearing) >= stdROT * 4 ||
        !this.isOrbitStarted
      ) {
        if (this.ROT < stdROT) this.ROT += stdROT * 0.2
        this.orbitSide === 'R'
          ? (this.heading += this.ROT * 4)
          : (this.heading -= this.ROT * 4)
      } else {
        this.ROT = Math.abs(this.heading - this.bearing) / 2
        this.orbitSide === 'R'
          ? (this.heading += this.ROT)
          : (this.heading -= this.ROT)
      }
      if (Math.abs(this.heading - this.bearing) >= 90)
        this.isOrbitStarted = true
      if (
        Math.abs(this.heading - this.bearing) <= 5 &&
        this.isOrbitStarted === true
      )
        this.isOnOrbit = false
    } else if (calcInterval > 4) {
      this.heading = this.bearing
    } else if (
      Math.abs(this.heading - this.bearing) >= stdROT * calcInterval &&
      calcInterval <= 4
    ) {
      if (this.ROT < stdROT) this.ROT += stdROT * 0.2
      if ((this.heading - this.bearing + 360) % 360 > 180) {
        this.heading += this.ROT * calcInterval
      } else {
        this.heading -= this.ROT * calcInterval
      }
    } else {
      this.ROT = Math.abs(this.heading - this.bearing) / 2
      if ((this.heading - this.bearing + 360) % 360 > 180) {
        this.heading += this.ROT
      } else {
        this.heading -= this.ROT
      }
    }

    if (this.heading < 0) this.heading = 360 + this.heading
    if (this.heading > 360) this.heading = this.heading - 360
  }
}

const getDistanceToNextPoint = (currentPosition, nextPoint) => {
  return geolib.convertDistance(
    geolib.getDistance(currentPosition, nextPoint),
    'sm',
  )
}

export default Flight
