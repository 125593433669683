/*
    node_js
    9/28/2021 8:30 PM
    by Oleksandr
*/

import React from 'react'

const Loading = () => {
  return (
    <div
      style={{
        background: 'transparent',
        overflow: 'hidden',
        opacity: 0.5,
        zIndex: 1000001,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div
        className="lds-roller"
        style={{ backgroundColor: 'transparent', overflow: 'hidden' }}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loading
